import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CreateAds } from "services/apiService";

const AdsCreate: React.FC = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [AdsformData, setAdsFormData] = useState({
    name: "",
    slug: "",
    background_color: "",
    title_one: "",
    title_two: "",
    description_one: "",
    description_two: "",
    link_one: "",
    link_two: "",
    is_active: 1,
    image_path: "",
    responsive_image_path: "",
  });

  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isResponsiveModelopen, setisResponsiveModelopen] =
    useState<boolean>(false);

  const [errors, setErrors] = useState({
    name: "",
    slug: "",
    background_color: "",
    title_one: "",
    title_two: "",
    description_one: "",
    description_two: "",
    link_one: "",
    link_two: "",
    image_path: "",
    responsive_image_path: "",
  });

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };
    setAdsFormData((prev) => ({
      ...prev,
      [id]: value,
      slug: id === "name" ? generateSlug(value) : prev.slug,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!AdsformData.name) newErrors.name = "Name is required.";
    if (!AdsformData.image_path)
      newErrors.image_path = "Image path is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleToggleChange = (checked: number, field: string) => {
    setAdsFormData((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const handleImageSelected = (image: string) => {
    setAdsFormData((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };

  const handleResponsiveImageSelected = (image: string) => {
    setAdsFormData((prevState) => ({
      ...prevState,
      responsive_image_path: image,
    }));
    setisResponsiveModelopen(false);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          ...AdsformData,
          image_path: AdsformData.image_path.toString(),
          responsive_image_path: AdsformData.responsive_image_path
            ? AdsformData.responsive_image_path.toString()
            : "",
        };
        const response = await CreateAds(payload);
        if (response.status_code === 200) {
          toast.success("Ads created successfully");
          navigate("/admin/ads-list");
        } else {
          toast.error("Ads creation Failed");
        }
      } catch (error) {
        toast.error("Please provide a unique and descriptive name.");
        console.log("error", error);
      }
    }
  };

  const handleDeleteImage = () => {
    setAdsFormData((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Ad Name
              </Typography>
              <TextField
                variant="filled"
                id="name"
                fullWidth
                value={AdsformData.name}
                onChange={handleTextFieldChange}
                error={!!errors.name}
                helperText={"Please provide a unique and descriptive name."}
              />
            </Box>

            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Slug
              </Typography>
              <TextField
                variant="filled"
                id="slug"
                fullWidth
                value={AdsformData.slug}
                disabled
                helperText={"Please provide a unique and descriptive name."}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Background Color
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
                <TextField
                  variant="filled"
                  name="color"
                  id="background_color"
                  value={AdsformData.background_color}
                  onChange={handleTextFieldChange}
                  placeholder="#000000"
                  className="common_input"
                  sx={{ width: "25%" }}
                />
                <input
                  type="color"
                  id="background_color"
                  value={AdsformData.background_color}
                  onChange={handleTextFieldChange}
                  style={{
                    width: "50px",
                    height: "50px",
                    border: "none",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                />
              </Box>
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Title One
              </Typography>
              <TextField
                variant="filled"
                id="title_one"
                fullWidth
                value={AdsformData.title_one}
                onChange={handleTextFieldChange}
                error={!!errors.title_one}
                helperText={errors.title_one}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Title Two
              </Typography>
              <TextField
                variant="filled"
                id="title_two"
                fullWidth
                value={AdsformData.title_two}
                onChange={handleTextFieldChange}
                error={!!errors.title_two}
                helperText={errors.title_two}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Description One
              </Typography>
              <TextField
                variant="filled"
                id="description_one"
                fullWidth
                multiline
                rows={5}
                value={AdsformData.description_one}
                onChange={handleTextFieldChange}
                error={!!errors.description_one}
                helperText={errors.description_one}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Description Two
              </Typography>
              <TextField
                variant="filled"
                id="description_two"
                fullWidth
                multiline
                rows={5}
                value={AdsformData.description_two}
                onChange={handleTextFieldChange}
                error={!!errors.description_two}
                helperText={errors.description_two}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Link One
              </Typography>
              <TextField
                variant="filled"
                id="link_one"
                fullWidth
                value={AdsformData.link_one}
                onChange={handleTextFieldChange}
                error={!!errors.link_one}
                helperText={errors.link_one}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Link Two
              </Typography>
              <TextField
                variant="filled"
                id="link_two"
                fullWidth
                value={AdsformData.link_two}
                onChange={handleTextFieldChange}
                error={!!errors.link_two}
                helperText={errors.link_two}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Ads Image
              </Typography>
              <div
                style={{
                  width: "100%",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    border: "1px dashed #ccc",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "10px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {AdsformData.image_path ? (
                    <>
                      <img
                        src={`${AppUrl}${AdsformData.image_path}`}
                        alt="Selected"
                        style={{
                          width: "300px",
                          transition: "transform 0.2s",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      />
                      <button
                        style={{
                          backgroundColor: "rgba(255, 0, 0, 0.7)",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          fontSize: "16px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                          transition: "background-color 0.2s",
                        }}
                        onClick={handleDeleteImage}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </>
                  ) : (
                    <p style={{ color: "orange" }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      &nbsp; &nbsp; No image selected. Please upload an image.
                    </p>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleOpenModal}
                    style={{
                      padding: "5px 20px",
                      fontSize: "13px",
                      borderRadius: "0px",
                      border: "none",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      cursor: "pointer",
                      marginTop: "10px",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                    Upload Image
                  </Button>
                </div>
              </div>
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Responsive Ad Image
              </Typography>
              <div
                style={{
                  width: "100%",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    border: "1px dashed #ccc",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "10px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {AdsformData.responsive_image_path ? (
                    <>
                      <img
                        src={`${AppUrl}${AdsformData.responsive_image_path}`}
                        alt="Selected"
                        style={{
                          width: "300px",
                          transition: "transform 0.2s",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      />
                      <button
                        style={{
                          backgroundColor: "rgba(255, 0, 0, 0.7)",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          fontSize: "16px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                          transition: "background-color 0.2s",
                        }}
                        onClick={handleDeleteImage}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </>
                  ) : (
                    <p style={{ color: "orange" }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      &nbsp; &nbsp; No image selected. Please upload an image.
                    </p>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleOpenModal}
                    style={{
                      padding: "5px 20px",
                      fontSize: "13px",
                      borderRadius: "0px",
                      border: "none",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      cursor: "pointer",
                      marginTop: "10px",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                    Upload Image
                  </Button>
                </div>
              </div>

              {/* <div
                style={{
                  textAlign: "center",
                  border: "1px dashed #ccc",
                  padding: "20px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                {AdsformData.image_path ? (
                  <img
                    src={`${AppUrl}${AdsformData.responsive_image_path}`}
                    alt="Selected"
                    style={{ width: "200px", height: "150px" }}
                  />
                ) : (
                  <p style={{ color: "orange" }}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    &nbsp;No image selected. Please upload an image.
                  </p>
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleOpenResponsiveModal}
                    style={{
                      fontSize: "10px",
                      borderRadius: "5px",
                      border: "none",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      cursor: "pointer",
                      marginTop: "10px",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp;&nbsp;Upload
                    Image
                  </Button>
                </div>
              </div> */}
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Status
              </Typography>
              <Toggle
                checked={AdsformData.is_active === 1}
                onChange={(checked) => handleToggleChange(checked, "is_active")}
              />
            </Box>
            <Box textAlign="end" mt={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp; Create Ad
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default AdsCreate;
