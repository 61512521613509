import React, { useState, useEffect } from "react";
import { FixedSizeGrid } from "react-window";
import { getMediaList } from "services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Card,
  CardMedia,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
const MediaLibrary: React.FC = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const { data: mediaData, isLoading } = useQuery("mediaList", getMediaList);
  const [filterType, setFilterType] = useState<string>("all");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [columnCount, setColumnCount] = useState<number>(5);
  const filteredMedia = React.useMemo(() => {
    return mediaData?.data.filter((media: string) => {
      const matchesType = filterType === "all" || media.endsWith(filterType);
      const matchesQuery = media
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      return matchesType && matchesQuery;
    });
  }, [mediaData, filterType, searchQuery]);
  const copyImageName = (imageName: string) => {
    navigator.clipboard.writeText(imageName).then(
      () => toast.success("Image name copied to clipboard!"),
      () => toast.error("Failed to copy text.")
    );
  };
  // Update column count based on window width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1440) {
        setColumnCount(7); // 7 columns for large screens
      } else if (window.innerWidth >= 1024) {
        setColumnCount(5); // 5 columns for medium screens
      } else {
        setColumnCount(3); // 3 columns for smaller screens
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  if (isLoading) {
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Skeleton variant="rectangular" width={200} height={40} />
          <div>
            <Skeleton
              variant="rectangular"
              width={200}
              height={40}
              sx={{ marginRight: 2 }}
            />
            <Skeleton variant="rectangular" width={120} height={40} />
          </div>
        </div>
        {/* Skeleton Loading for Grid */}
        <FixedSizeGrid
          columnCount={columnCount}
          rowCount={5} // Show a few rows while loading
          columnWidth={210}
          rowHeight={250}
          height={650}
          width={1500}
        >
          {({ columnIndex, rowIndex, style }) => (
            <div style={style} className="p-2">
              <Skeleton variant="rectangular" width={210} height={150} />
            </div>
          )}
        </FixedSizeGrid>
      </div>
    );
  }
  const Cell = ({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
  }) => {
    const index = rowIndex * columnCount + columnIndex;
    if (index >= filteredMedia?.length) return null;
    const media = filteredMedia[index];
    const isVideo = media.endsWith(".mp4");

    return (
      <div style={style} className="p-2">
        <Card
          sx={{
            position: "relative",
            overflow: "hidden",
            height: "100%",
            "&:hover .hover-overlay": {
              opacity: 1,
            },
          }}
        >
          {isVideo ? (
            <video
              controls
              src={`${AppUrl}${media}`}
              style={{
                height: 150,
                width: "100%",
                objectFit: "contain",
              }}
            />
          ) : (
            <CardMedia
              component="img"
              image={`${AppUrl}${media}`}
              alt="media"
              sx={{
                height: 150,
                objectFit: "contain",
                backgroundColor: "#f5f5f5",
                filter: "brightness(100%)",
                transition: "filter 0.3s ease",
              }}
            />
          )}
          <div
            className="hover-overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0,
              transition: "opacity 0.3s",
            }}
          >
            <IconButton
              onClick={() => copyImageName(media)}
              sx={{
                marginRight: 2,
                color: "#fff",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.4)",
                },
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </IconButton>
            <IconButton
              onClick={() =>
                toast.error("Delete functionality not implemented yet!")
              }
              sx={{
                color: "#fff",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.4)",
                },
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </div>
        </Card>
      </div>
    );
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <FormControl variant="outlined" size="small" sx={{ width: "200px" }}>
          <InputLabel id="filter-label">Filter by Type</InputLabel>
          <Select
            labelId="filter-label"
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            label="Filter by Type"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value=".jpg">JPG</MenuItem>
            <MenuItem value=".png">PNG</MenuItem>
            <MenuItem value=".gif">GIF</MenuItem>
            <MenuItem value=".mp4">MP4</MenuItem>
          </Select>
        </FormControl>
        <div>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ marginRight: 2 }}
          />
          <Button
            variant="contained"
            onClick={() => navigate("/admin/media-create")}
          >
            Add Media
          </Button>
        </div>
      </div>
      <FixedSizeGrid
        columnCount={columnCount}
        rowCount={Math.ceil(filteredMedia?.length / columnCount)}
        columnWidth={210}
        rowHeight={250}
        height={650}
        width={1500}
      >
        {Cell}
      </FixedSizeGrid>
    </>
  );
};
export default MediaLibrary;
