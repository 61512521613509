import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { createFaq, faqCategoryDropDowndata } from "services/apiService";

const FaqCreate = () => {
  const [faqCategoryDropDown, setFaqCategoryDropDown] = useState([]);
  const [faqCategoryFormData, setFaqCategoryFormData] = useState({
    title: "",
    description: "",
    is_active: 1,
    faq_category_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    faq_category_id: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    faqCategoriesDropDownList();
  }, []);

  const faqCategoriesDropDownList = async () => {
    setIsLoading(true);
    try {
      const response = await faqCategoryDropDowndata();
      setFaqCategoryDropDown(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleTextFieldChange = (e) => {
    const { id, value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z0-9 ]/g, "");
    setFaqCategoryFormData((prevData) => ({
      ...prevData,
      [id]: sanitizedValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setFaqCategoryFormData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleCategoryChange = (e) => {
    setFaqCategoryFormData((prevData) => ({
      ...prevData,
      faq_category_id: e.target.value,
    }));
  };

  const validateForm = () => {
    const { title, description, faq_category_id } = faqCategoryFormData;
    const newErrors: any = {};
    if (!title) newErrors.title = "Faq Name is required.";
    if (!description) newErrors.description = "Description is required.";
    if (!faq_category_id) newErrors.faq_category_id = "Category is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await createFaq(faqCategoryFormData);
      if (response.status_code === 200) {
        navigate("/admin/faq-list");
        toast.success("FAQ created successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error creating FAQ. Please try again.");
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Faq Name
            </Typography>
            <TextField
              variant="filled"
              id="title"
              fullWidth
              placeholder="Type here"
              value={faqCategoryFormData.title}
              onChange={handleTextFieldChange}
              error={!!errors.title}
              helperText={errors.title}
              inputProps={{
                maxLength: 30,
                pattern: "[A-Za-z0-9 ]*",
                title: "Only alphanumeric characters and spaces are allowed.",
              }}
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Description
            </Typography>
            <TextField
              variant="filled"
              id="description"
              fullWidth
              placeholder="Type here"
              value={faqCategoryFormData.description}
              onChange={handleTextFieldChange}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Select Category
            </Typography>
            <FormControl fullWidth variant="filled">
              <Select
                labelId="category-select-label"
                id="category-select"
                value={faqCategoryFormData.faq_category_id}
                onChange={handleCategoryChange}
                displayEmpty
              >
                {isLoading ? (
                  <MenuItem value="">
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : faqCategoryDropDown.length > 0 ? (
                  faqCategoryDropDown.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No Categories Available
                  </MenuItem>
                )}
              </Select>
              {errors.faq_category_id && (
                <FormHelperText>{errors.faq_category_id}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Status
            </Typography>
            <Toggle
              checked={faqCategoryFormData.is_active === 1}
              onChange={(checked) => handleToggleChange(checked, "is_active")}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" pb={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              &nbsp;&nbsp; Create Faq
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FaqCreate;
