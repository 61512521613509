import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal, Typography, Button, TextField, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Toggle from "common/Toggle";
import { CoupnEditProps } from "types";
import {
  BlogUpdate,
  getAllBlogCategoriesList,
  getAllBlogTagsAPICall,
  getBlogApi,
} from "services/apiService";
import ImageUploaderModal from "common/ImageUploaderModal";
import toast from "react-hot-toast";
import ReusableEditor from "common/ReusableCKEditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const BlogsEdit: React.FC<CoupnEditProps> = ({
  open,
  id,
  handleClose,
  onActionComplete,
}) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [blogForm, setBlogForm] = useState({
    title: "",
    blog_category_id: "",
    blog_tag_id: "",
    description: "",
    message: "",
    image_path: "",
    is_active: 1,
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [blogCategories, setblogCategories] = useState([]);
  const [blogTagsCategories, setblogTagsCategories] = useState([]);

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    blog_category_id: "",
    blog_tag_id: "",
  });

  useEffect(() => {
    fetchBlogData(id);
    getAllBlogCategories();
    getAllBlogTags();
  }, [id]);

  const getAllBlogCategories = async () => {
    try {
      const response = await getAllBlogCategoriesList();
      if (response.status_code === 200) {
        setblogCategories(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllBlogTags = async () => {
    try {
      const response = await getAllBlogTagsAPICall();
      if (response.status_code === 200) {
        console.log("getAllBlogCategoriesAPICall", response.data);
        setblogTagsCategories(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBlogData = async (id: number) => {
    try {
      const response = await getBlogApi(id);
      if (response.status_code === 200) {
        setBlogForm({
          title: response.data.title || "",
          blog_category_id: response.data.blog_category_id || "",
          blog_tag_id: response.data.blog_tag_id || "",
          description: response.data.description || "",
          message: response.data.message || "",
          image_path: response.data.image_path || "",
          is_active:
            response.data.is_active !== undefined ? response.data.is_active : 1,
        });
      }
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  const handleImageSelected = (image: string) => {
    setBlogForm((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };

  const handleTextFieldChange = (e) => {
    const { id, value } = e.target;
    setBlogForm((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    setBlogForm((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const validateForm = () => {
    let formErrors = { ...errors };
    let isValid = true;

    if (!blogForm.title) {
      formErrors.title = "Blog title is required";
      isValid = false;
    }

    if (!blogForm.blog_category_id) {
      formErrors.blog_category_id = "Blog category is required";
      isValid = false;
    }

    if (!blogForm.blog_tag_id) {
      formErrors.blog_tag_id = "Blog tag is required";
      isValid = false;
    }

    if (!blogForm.description) {
      formErrors.description = "Description is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const payload = {
          id: id,
          blog_category_id: Number(blogForm.blog_category_id),
          blog_tag_id: Number(blogForm.blog_tag_id),
          title: blogForm.title,
          description: blogForm.description,
          image_path: blogForm.image_path.toString(),
          message: blogForm.message,
          is_active: blogForm.is_active,
        };
        const response = await BlogUpdate(payload);
        if (response.status_code === 200) {
          toast.success("Blogs updated Successfully");
          onActionComplete();
          handleClose();
        }
      } catch (error) {
        toast.error("Blogs update failed");
      }
    }
  };

  const handleDeleteImage = () => {
    setBlogForm((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <Modal open={open} onClose={handleClose} style={{ zIndex: "1299" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1100,
            bgcolor: "background.paper",
            border: "2px solid white",
            boxShadow: 24,
            p: 1,
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ padding: 4 }}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Blog Title"
                    variant="outlined"
                    name="title"
                    id="title"
                    value={blogForm.title}
                    onChange={handleTextFieldChange}
                    placeholder="Type Here"
                    fullWidth
                    error={!!errors.title}
                    helperText={errors.title || ""}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="label">
                    Blog Category
                  </Typography>
                  <select
                    id="blog_category_id"
                    name="blog_category_id"
                    value={blogForm.blog_category_id}
                    onChange={handleTextFieldChange}
                    className="form-select"
                    style={{ width: "100%" }}
                  >
                    {!blogCategories ? (
                      <option value="">No Data...</option>
                    ) : blogCategories.length === 0 ? (
                      <option value="">No blog category found</option>
                    ) : (
                      <>
                        <option value="">Select blog category</option>
                        {blogCategories.map((blogCategory) => (
                          <option key={blogCategory.id} value={blogCategory.id}>
                            {blogCategory.name.toUpperCase()}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {errors.blog_category_id && (
                    <div className="text-danger">{errors.blog_category_id}</div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="label">
                    Blog Tag
                  </Typography>
                  <select
                    id="blog_tag_id"
                    name="blog_tag_id"
                    value={blogForm.blog_tag_id}
                    onChange={handleTextFieldChange}
                    className="form-select"
                    style={{ width: "100%" }}
                  >
                    {!blogTagsCategories ? (
                      <option value="">No Data...</option>
                    ) : blogTagsCategories.length === 0 ? (
                      <option value="">No blog tag found</option>
                    ) : (
                      <>
                        <option value="">Select blog tag</option>
                        {blogTagsCategories.map((blogCategory) => (
                          <option key={blogCategory.id} value={blogCategory.id}>
                            {blogCategory.name.toUpperCase()}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {errors.blog_tag_id && (
                    <div className="text-danger">{errors.blog_tag_id}</div>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="label">
                    Description
                  </Typography>
                  <TextField
                    variant="filled"
                    name="description"
                    id="description"
                    value={blogForm.description}
                    onChange={handleTextFieldChange}
                    placeholder="Type description here"
                    multiline
                    rows={7}
                    fullWidth
                    error={!!errors.description}
                    helperText={errors.description || ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" className="label">
                    Message
                  </Typography>
                  <CKEditor
                    editor={ClassicEditor}
                    data={blogForm.message || ""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setBlogForm((prevData) => ({
                        ...prevData,
                        message: data,
                      }));
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        description: "",
                      }));
                    }}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "blockQuote",
                        "|",
                        "numberedList",
                        "bulletedList",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "10px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "20px",
                      border: "1px dashed #ccc",
                      padding: "20px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "10px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    {blogForm.image_path ? (
                      <>
                        <img
                          src={`${AppUrl}${blogForm.image_path}`}
                          alt="Selected"
                          style={{
                            width: "150px",
                            transition: "transform 0.2s",
                            cursor: "pointer",
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.transform = "scale(1.05)";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.transform = "scale(1)";
                          }}
                        />
                        <button
                          style={{
                            backgroundColor: "rgba(255, 0, 0, 0.7)",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            fontSize: "16px",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                            transition: "background-color 0.2s",
                          }}
                          onClick={handleDeleteImage}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </>
                    ) : (
                      <p style={{ color: "orange" }}>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        &nbsp; &nbsp; No image selected. Please upload an image.
                      </p>
                    )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      onClick={handleOpenModal}
                      style={{
                        padding: "5px 20px",
                        fontSize: "13px",
                        borderRadius: "0px",
                        border: "none",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        cursor: "pointer",
                        marginTop: "10px",
                        transition: "background-color 0.2s",
                      }}
                    >
                      <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                      Upload Image
                    </Button>
                  </div>
                </div>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Toggle
                    checked={blogForm.is_active === 1}
                    onChange={(checked) =>
                      handleToggleChange(checked, "is_active")
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default BlogsEdit;
