import React, { useEffect, useState } from "react";
import { DeleteFaq, getAllFaqPagesList } from "services/apiService";
import DynamicButton from "../../../common/DynamicButton.jsx";
import {
  faCirclePlus,
  faMagnifyingGlass,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import loadingImage from "../../../assect/loadingSvg/loading.svg";
import Swal from "sweetalert2";
import FaqEdit from "./FaqEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const FaqList = () => {
  const [faqSearch, setFaqSearch] = useState("");
  const [faqListData, setFaqListData] = useState([]);
  const [filteredFaqListData, setFilteredFaqListData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isOpenSubPageEdit, setIsOpenSubpagesEdit] = useState<boolean>(false);
  const [selectedSubPagesId, setSelectedSubpagesId] = useState<number | null>(
    null
  );
  const headers = ["S.no", "Faq Category Name", "Title", "Action"];

  useEffect(() => {
    getAllFaqList();
  }, []);

  const getAllFaqList = async () => {
    setLoader(true);
    try {
      const response = await getAllFaqPagesList();
      setFaqListData(response.data);
      setFilteredFaqListData(response.data);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setFaqSearch(searchValue);
    const filteredData = faqListData.filter(
      (faq: any) =>
        faq.faq_category_name
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        faq.title.toLowerCase().includes(searchValue.toLowerCase())
    );

    setFilteredFaqListData(filteredData);
  };

  const handleModelOpen = (id: number) => {
    setSelectedSubpagesId(id);
    setIsOpenSubpagesEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenSubpagesEdit(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteFaq(id);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The Faq has been deleted.", "success");
            getAllFaqList();
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Faq is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const renderRow = (faq: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td>{faq.faq_category_name}</td>
        <td title={faq.title}>
          {faq.title?.length > 30 ? faq.title?.slice(0, 30) + "..." : faq.title}
        </td>
        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleModelOpen(faq.faq_id)}
            />

            <DynamicButton
              icon={faTrash}
              iconStyle={{ color: "red" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleDelete(faq.faq_id, faq.faq_category_name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/faq-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Faq
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            value={faqSearch}
            placeholder="Search FAQs..."
            style={{ boxShadow: "none" }}
            onChange={handleSearch}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
            </span>
          </div>
        </div>
      </div>

      <div className="product-table-container">
        <div className="product-table-content">
          {loader ? (
            <div className="loadingsvg-Image text-center">
              <img
                src={loadingImage}
                width={150}
                height={150}
                alt="Loading..."
              />
            </div>
          ) : (
            <CommonTable
              data={filteredFaqListData}
              isLoading={loader}
              headers={headers}
              renderRow={renderRow}
            />
          )}
        </div>
      </div>
      <FaqEdit
        onActionComplete={() => {
          getAllFaqList();
        }}
        open={isOpenSubPageEdit}
        handleClose={handleModelClose}
        id={selectedSubPagesId}
      />
    </>
  );
};

export default FaqList;
