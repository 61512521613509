import React, { useEffect, useState } from "react";
import CommonTable from "../../common/CommonTable";
import {
  faCirclePlus,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DynamicButton from "../../common/DynamicButton";
import {
  BrandDelete,
  getAllBrands,
  pageNationData,
} from "../../services/apiService";
import { BrandsListData } from "../../types/index";
import Pagenation from "../../common/Pagenation";
import loadingImage from "../../assect/loadingSvg/loading.svg";
import "../../styles/commonTable.css";
import BrandEdit from "./BrandEdit";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import No_Image_Available from "../../Images/No_Image_Available-Table.png";

const BrandList: React.FC = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [brandSearch, setBrandSearch] = useState<string>("");
  const [allBrands, setAllBrands] = useState<BrandsListData[]>([]);
  const [loader, setLoader] = useState(false);
  const [paginationLength, setPaginationLength] = useState<number | null>(null);
  const [currentPageNationPage, setCurrentPageNationPage] = useState<number>(1);
  const [isOpenBrandEdit, setIsOpenBrandEdit] = useState<boolean>(false);
  const [selectedBrandId, setSelectedBrandId] = useState<number | null>(null);
  const [page_numbers] = useState<number | null>(0);
  const numberOfItems: number | null = 10;
  const numberofItems = 10;
  const headers = ["Sr.No", "Brand Name", "Status", "Image", "Action"];

  useEffect(() => {
    fetchAllBrands(brandSearch, page_numbers, numberOfItems);
    fetchBrandsCount();
  }, []);

  const fetchAllBrands = async (
    brandSearch: string,
    page_numbers: number | null,
    numberOfItems: number | null
  ) => {
    setLoader(true);
    try {
      const response = await getAllBrands(
        brandSearch,
        page_numbers,
        numberOfItems
      );
      setAllBrands(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  const fetchBrandsCount = async () => {
    try {
      const response = await pageNationData();
      setPaginationLength(response.data.brands_count);
    } catch (error) {
      console.error(error);
    }
  };

  const renderRow = (brand: BrandsListData, index: number) => {
    const serialNumber =
      (currentPageNationPage - 1) * numberofItems + index + 1;
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{serialNumber}</td>
        <td className="table_product_name" title={brand.brand_name}>
          {brand.brand_name.length > 30
            ? brand.brand_name.slice(0, 30) + "..."
            : brand.brand_name}
        </td>
        <td className="align-content-center">
          <span
            style={{
              color: brand.is_active === 1 ? "green" : "red",
              backgroundColor: brand.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {brand?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>
        <td
          className="table_product_name align-content-center"
          style={{ color: "grey" }}
        >
          <div className="d-flex justify-content-center">
            {brand.brand_image ? (
              <img
                src={`${AppUrl}${brand.brand_image}`}
                alt="ProductImage"
                width={100}
              />
            ) : (
              <img
                src={No_Image_Available}
                alt="No_Image_Available"
                width={100}
              />
            )}
          </div>
        </td>
        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              onClick={() => handleModelOpen(brand.id)}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
            />
            <DynamicButton
              icon={faTrash}
              onClick={() => handleDEleteBrand(brand.id, brand.brand_name)}
              iconStyle={{ color: "#FF0000" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
            />
          </div>
        </td>
      </tr>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setBrandSearch(value);
    fetchAllBrands(value || "", page_numbers, numberOfItems);
  };

  const handlePageChange = (page: number) => {
    const dataIndex = (page - 1) * numberOfItems;
    fetchAllBrands("", dataIndex, numberOfItems);
    setCurrentPageNationPage(page);
  };

  const handleModelOpen = (id: number) => {
    setSelectedBrandId(id);
    setIsOpenBrandEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenBrandEdit(false);
  };

  const handleDEleteBrand = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const Brandpayload = {
            brand_id: id,
          };
          const response = await BrandDelete(Brandpayload);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The Brand has been deleted.", "success");
            fetchAllBrands(brandSearch, page_numbers, numberOfItems);
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Brand is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/brand-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Brand
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            placeholder="Search Brands..."
            className="form-control"
            style={{ boxShadow: "none" }}
            value={brandSearch}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="product-table-container">
        <div className="product-table-content">
          {loader ? (
            <div className="loadingsvg-Image text-center">
              <img
                src={loadingImage}
                width={150}
                height={150}
                alt="Loading..."
              />
            </div>
          ) : (
            <CommonTable
              data={allBrands}
              isLoading={loader}
              headers={headers}
              renderRow={renderRow}
            />
          )}
        </div>
        <div className="pagination-container">
          <div className="data-info" style={{ color: "grey" }}>
            {paginationLength > 0 && (
              <span>
                Showing{" "}
                <strong>
                  {Math.min(
                    (currentPageNationPage - 1) * numberofItems + 1,
                    paginationLength
                  )}
                </strong>{" "}
                -{" "}
                <strong>
                  {Math.min(
                    currentPageNationPage * numberofItems,
                    paginationLength
                  )}
                </strong>{" "}
                out of <strong>{paginationLength}</strong> records
              </span>
            )}
          </div>
          <Pagenation
            pagenationLength={paginationLength}
            numberofItems={numberOfItems}
            currentPageNationPage={currentPageNationPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      <BrandEdit
        onActionComplete={() => {
          fetchAllBrands(brandSearch, page_numbers, numberOfItems);
          fetchBrandsCount();
        }}
        open={isOpenBrandEdit}
        handleClose={handleModelClose}
        id={selectedBrandId}
      />
    </>
  );
};

export default BrandList;
