import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/Settings.css";
import {
  SettingGroupdropdown,
  SettingsChild,
  UpdateCommonSettings,
} from "services/apiService";
import {
  Box,
  Button,
  TextField,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import toast from "react-hot-toast";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

const Dashboard = () => {
  const [activeCard, setActiveCard] = useState<number | null>(null);
  const [sidebar, setSidebar] = useState<any[]>([]);
  const [renderContent, setRenderContent] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [runTour, setRunTour] = useState(true);
  const [editableRows, setEditableRows] = useState<{ [key: number]: boolean }>(
    {}
  );

  const tourSteps: Step[] = [
    {
      target: ".custom-sidebar",
      content: "Here is the sidebar. Select a category to load settings.",
    },
    {
      target: ".create_category_fields",
      content: "These are your settings fields. Edit them if needed.",
    },
    {
      target: ".toggle-switch",
      content: "This toggle changes the status. Try it!",
    },
    {
      target: ".d-flex",
      content: "Here you can save or cancel your changes.",
    },
  ];

  useEffect(() => {
    fetchSideBar();
  }, []);

  useEffect(() => {
    if (activeCard !== null) {
      fetchRenderContent(activeCard);
    }
  }, [activeCard]);

  const fetchSideBar = async () => {
    try {
      const response = await SettingGroupdropdown();
      if (response.status_code === 200) {
        setSidebar(response.data);
      }
    } catch (error) {
      console.error("Error fetching sidebar", error);
    }
  };

  const fetchRenderContent = async (id: number) => {
    try {
      const response = await SettingsChild(id);
      if (response.status_code === 200) {
        setRenderContent(response.data);
      } else {
        setRenderContent([]);
      }
    } catch (error) {
      console.error("Error fetching render content", error);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) => {
    const { name, value } = e.target;
    setRenderContent((prevData) =>
      prevData?.map((item: any) =>
        item.id === id ? { ...item, [name]: value } : item
      )
    );
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        ...prevErrors[id],
        key_value: value ? "" : "This field is required",
      },
    }));
  };

  const handleToggleChange = (id: number, checked: boolean) => {
    if (editableRows[id]) {
      setRenderContent((prevData) =>
        prevData?.map((item: any) =>
          item.id === id ? { ...item, is_active: checked ? 1 : 0 } : item
        )
      );
    }
  };

  const handleEditRow = (id: number) => {
    setEditableRows({ ...editableRows, [id]: true });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: {} }));
  };

  const handleSaveRow = (id: number) => {
    const editedItem = renderContent.find((item: any) => item.id === id);

    if (!editedItem?.key_value || editedItem.key_value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: { key_value: "This field is required" },
      }));
      return;
    }

    handleSubmit(id);
    setEditableRows({ ...editableRows, [id]: false });
  };

  const handleCancelEditRow = (id: number) => {
    setEditableRows({ ...editableRows, [id]: false });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: {} }));
  };

  const handleSubmit = async (id: number) => {
    const editedItem = renderContent.find((item: any) => item.id === id);

    if (!editedItem) {
      toast.error("Invalid Item Selected");
      return;
    }

    const payload = {
      id: editedItem.id,
      key_name: editedItem.key_name,
      key_value: editedItem.key_value,
      is_active: editedItem.is_active,
    };

    try {
      const response = await UpdateCommonSettings(payload);
      if (response.status_code === 200) {
        toast.success("Settings Updated Successfully");
      } else {
        toast.error("Settings Update Failed");
      }
    } catch (error) {
      toast.error("Settings Update Failed");
    }
  };

  const renderDynamicForm = () => {
    if (activeCard === null) {
      return (
        <div className="col-12 col-lg-9 main-content p-4">
          Please select a sidebar category to load settings.
        </div>
      );
    }

    if (renderContent === null) {
      return (
        <div className="col-12 col-lg-9 main-content p-4">
          No data available for the selected category.
        </div>
      );
    }

    return (
      <div className="card p-4 shadow">
        <Typography
          variant="h5"
          className="text-center mb-4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            backgroundColor: "#007bff",
            padding: "10px",
            color: "white",
          }}
        >
          Settings Form
        </Typography>

        {renderContent?.map((item: any) => (
          <Box
            key={item.id}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="create_category_fields mb-4"
            style={{
              border: "1px solid #ddd",
              padding: "15px",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <TextField
              variant="outlined"
              label={item.key_name.replace(/_/g, " ")}
              name="key_value"
              value={item.key_value}
              onChange={(e) => handleInputChange(e, item.id)}
              placeholder="Enter Key Value"
              fullWidth
              className="common_input"
              InputProps={{
                readOnly: !editableRows[item.id],
              }}
              error={!!errors[item.id]?.key_value}
              helperText={errors[item.id]?.key_value}
              style={{ marginRight: "20px" }}
            />

            <ToggleButtonGroup
              value={item.is_active}
              exclusive
              onChange={(e, newValue) => handleToggleChange(item.id, newValue)}
              className="toggle-status-group"
              style={{ marginLeft: "20px", marginRight: "20px" }}
            >
              <ToggleButton
                disabled={!editableRows[item.id]}
                value={1}
                style={{
                  color: "white",
                  backgroundColor: item.is_active === 1 ? "green" : "gray",
                }}
              >
                Active
              </ToggleButton>
              <ToggleButton
                disabled={!editableRows[item.id]}
                value={0}
                style={{
                  color: "white",
                  backgroundColor: item.is_active === 0 ? "red" : "gray",
                }}
              >
                Inactive
              </ToggleButton>
            </ToggleButtonGroup>

            {!editableRows[item.id] ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleEditRow(item.id)}
              >
                Edit
              </Button>
            ) : (
              <Box display="flex">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSaveRow(item.id)}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleCancelEditRow(item.id)}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        ))}
      </div>
    );
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRunTour(false);
    }
  };

  return (
    <>
      <div className="container-fluid row">
        <div className="col-12 col-lg-3 custom-sidebar p-4">
          <Button
            variant="outlined"
            onClick={() => setRunTour(true)}
            className="mb-3"
          >
            Help
          </Button>
          <div className="row g-4">
            {sidebar?.map((item) => (
              <div
                key={item.id}
                className={`col-6 sidebar-card ${
                  activeCard === item.id ? "active-card" : ""
                }`}
                onClick={() => setActiveCard(item.id)}
              >
                <div
                  className="card text-center p-3"
                  style={{ borderRadius: "3px" }}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="display-6 mb-2"
                  />
                  <h6>{item.name}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-12 col-lg-9 main-content p-4">
          {renderDynamicForm()}
        </div>
      </div>
      <Joyride
        steps={tourSteps}
        continuous
        run={runTour}
        callback={handleJoyrideCallback}
      />
    </>
  );
};

export default Dashboard;
