import {
  faCirclePlus,
  faMagnifyingGlass,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import React, { useEffect, useState } from "react";
import {
  CatogorieDelete,
  getAllCategories,
  pageNationData,
} from "services/apiService";
import DynamicButton from "../../common/DynamicButton.jsx";
import { CategoriesListData } from "types/index.js";
import Pagenation from "../../common/Pagenation";
import loadingImage from "../../assect/loadingSvg/loading.svg";
import imageNotFound from "../../Images/imageNotFound.png";
import CategoriesEdit from "../Categorie/CategoriesEdit";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

const CategoriesList = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [categoriesSearch, setCategoriesSearch] = useState<string>("");
  const [isOpenCategoriesEdit, setIsOpenCategoriesEdit] =
    useState<boolean>(false);
  const [selectedCategoriesId, setSelectedCategoriesId] = useState<
    number | null
  >(null);
  const [allCategories, setAllCategories] = useState<CategoriesListData[]>([]);
  const [loader, setloader] = useState(false);
  const [pagenationLength, setPagenationLength] = useState(null);
  const [currentPageNationPage, setCurrentPageNationPage] = useState(1);
  const [page_numbers] = useState<number | null>(0);
  const numberofItems: number | null = 10;
  const headers = [
    "S.no",
    "Category Name",
    "Parent Category ",
    "Image",
    "Status",
    "Action",
  ];
  useEffect(() => {
    fetchAllCategories(categoriesSearch, page_numbers, numberofItems);
    featchCategoriesCount();
  }, []);

  const fetchAllCategories = async (
    categoriesSearch: string,
    page_numbers: number,
    numberofItems: number
  ) => {
    setloader(true);
    try {
      const response = await getAllCategories(
        categoriesSearch,
        page_numbers,
        numberofItems
      );
      setAllCategories(response.data);
    } catch (error) {
      console.log("error");
    }
    setloader(false);
  };
  const featchCategoriesCount = async () => {
    try {
      const response = await pageNationData();
      setPagenationLength(response.data.categories_count);
    } catch (error) {}
  };

  const handleModelOpen = (id: number) => {
    setIsOpenCategoriesEdit(true);
    setSelectedCategoriesId(id);
  };

  const handleModelClose = () => {
    setIsOpenCategoriesEdit(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const Catogoriepayload = {
            category_id: id,
          };
          const response = await CatogorieDelete(Catogoriepayload);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The Catogorie has been deleted.", "success");
            fetchAllCategories(categoriesSearch, page_numbers, numberofItems);
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Catogorie is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const renderRow = (categories: any, index: number) => {
    const serialNumber =
      (currentPageNationPage - 1) * numberofItems + index + 1;
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{serialNumber}</td>
        <td className="table_product_name" title={categories.category_name}>
          {categories.category_name.length > 30
            ? categories.category_name.slice(0, 30) + "..."
            : categories.category_name}
        </td>

        <td className="table_product_name">
          {categories.parent_category.length > 0 ? (
            <span style={{ color: "#1976d2", fontWeight: "600" }}>
              {categories.parent_category.length > 30
                ? categories.parent_category.slice(0, 30) + "..."
                : categories.parent_category}
            </span>
          ) : (
            <span>
              <p style={{ color: "red" }}>No parent</p>
            </span>
          )}
        </td>

        <td className="align-content-center">
          <div className=" d-flex justify-content-center">
            <img
              src={`${
                categories.category_image
                  ? AppUrl + categories.category_image
                  : imageNotFound
              }`}
              alt="ProductImage"
              width={100}
            />
          </div>
        </td>
        <td className="align-content-center" style={{ color: "grey" }}>
          <span
            style={{
              color: categories.is_active === 1 ? "green" : "red",
              backgroundColor:
                categories.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {categories?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>
        <td className="align-content-center ">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleModelOpen(categories.id)}
            />
            <DynamicButton
              icon={faTrash}
              onClick={() =>
                handleDelete(categories.id, categories.category_name)
              }
              iconStyle={{ color: "#FF0000" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
            />
          </div>
        </td>
      </tr>
    );
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCategoriesSearch(value);
    if (value !== "") {
      fetchAllCategories(value, null, null);
    } else {
      fetchAllCategories("", page_numbers, numberofItems);
    }
  };
  const handlePageChange = (page: number) => {
    const dataIndex = (page - 1) * numberofItems;
    if (page) {
      fetchAllCategories("", dataIndex, numberofItems);
    }
    setCurrentPageNationPage(page);
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/categories-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Categorie
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search Category Name...."
            style={{ boxShadow: "none" }}
            value={categoriesSearch}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="product-table-container">
        <div className="product-table-content">
          {loader ? (
            <div className="loadingsvg-Image text-center">
              <img
                src={loadingImage}
                width={150}
                height={150}
                alt="Loading..."
              />
            </div>
          ) : (
            <CommonTable
              data={allCategories}
              isLoading={loader}
              headers={headers}
              renderRow={renderRow}
            />
          )}
        </div>
        <div className="pagination-container">
          <div className="data-info" style={{ color: "grey" }}>
            {pagenationLength > 0 && (
              <span>
                Showing{" "}
                <strong>
                  {Math.min(
                    (currentPageNationPage - 1) * numberofItems + 1,
                    pagenationLength
                  )}
                </strong>{" "}
                -{" "}
                <strong>
                  {Math.min(
                    currentPageNationPage * numberofItems,
                    pagenationLength
                  )}
                </strong>{" "}
                out of <strong>{pagenationLength}</strong> records
              </span>
            )}
          </div>
          <Pagenation
            pagenationLength={pagenationLength}
            numberofItems={numberofItems}
            currentPageNationPage={currentPageNationPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <CategoriesEdit
        open={isOpenCategoriesEdit}
        handleClose={handleModelClose}
        id={selectedCategoriesId}
        onActionComplete={() => {
          fetchAllCategories(categoriesSearch, page_numbers, numberofItems);
          featchCategoriesCount();
        }}
      />
    </>
  );
};

export default CategoriesList;
