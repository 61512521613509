import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ClassificationDropDown } from "../../services/apiService";

export const fetchClassificationDropDown = createAsyncThunk(
  "jsondata/fetchClassificationDropDown",
  async () => {
    try {
      const response = await ClassificationDropDown();
      return response.data;
    } catch (error) {}
  }
);

const initialState = {
  classificationDropDownData: [],
  error: null,
  loading: false,
};

export const classificatiponDropDownData = createSlice({
  name: "classificationData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassificationDropDown.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClassificationDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.classificationDropDownData = action.payload;
      })
      .addCase(fetchClassificationDropDown.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message || "Unknown error";
      });
  },
});
export default classificatiponDropDownData.reducer;
