import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Toggle from "../../common/Toggle";
import {
  CategoriesCreateDropDown,
  CategoryCreateApi,
} from "services/apiService";
import ImageUploaderModal from "common/ImageUploaderModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const CategoriesCreate: React.FC = () => {
  const [CategiresForm, setCategiresForm] = useState({
    category_name: "",
    slug: "",
    description: "",
    color_code: "#000000",
    is_active: 1,
    parent_id: "",
    category_image: "",
  });

  const [errors, setErrors] = useState({
    category_name: "",
    description: "",
  });
  const [categoryDropdownList, setCategoryDropdown] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategoriesCreateDropDown();
  }, []);

  const validateForm = () => {
    const newErrors: any = {};
    if (!CategiresForm.category_name)
      newErrors.category_name = "Name is required.";
    if (!CategiresForm.description)
      newErrors.description = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fetchCategoriesCreateDropDown = async () => {
    try {
      const response = await CategoriesCreateDropDown();
      setCategoryDropdown(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    let sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
    let error = "";
    if (sanitizedValue.length < 3) {
      error = "Input must be at least 3 characters.";
    }
    if (id === "category_name" && sanitizedValue.length > 40) {
      error = "Input must not exceed 40 characters.";
    }
    if (id === "category_name") {
      const generateSlug = (name: string) => {
        return name.trim().toLowerCase().replace(/\s+/g, "-");
      };
      setCategiresForm((prevData) => ({
        ...prevData,
        [id]: sanitizedValue,
        slug: generateSlug(sanitizedValue),
      }));
    } else if (id === "color_code") {
      if (selectedCategory === 0) {
        setCategiresForm((prevData) => ({
          ...prevData,
          color_code: sanitizedValue,
        }));
      }
    } else {
      setCategiresForm((prevData) => ({
        ...prevData,
        [id]: sanitizedValue,
      }));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: error,
    }));
  };

  const handleSelectChange = (event) => {
    const parentId = event.target.value as number;
    setSelectedCategory(parentId);

    setCategiresForm((prevData) => ({
      ...prevData,
      parent_id: parentId.toString(),
      color_code: parentId !== 0 ? "" : prevData.color_code,
    }));
  };
  const handleImageSelected = (image: string) => {
    setCategiresForm((prevState) => ({
      ...prevState,
      category_image: image,
    }));
    setModalOpen(false);
  };

  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setCategiresForm((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleHexInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCategiresForm((prev) => ({
      ...prev,
      color_code: value,
    }));
  };
  const handleColorPickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCategiresForm((prev) => ({
      ...prev,
      color_code: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        if (!CategiresForm.category_name) {
          toast.error("Please Fill The Name");
          return;
        }
        const payload = {
          category_name: CategiresForm.category_name,
          slug: CategiresForm.slug,
          description: CategiresForm.description,
          color_code: CategiresForm.color_code,
          is_active: CategiresForm.is_active,
          parent_id: Number(CategiresForm.parent_id),
          category_image: CategiresForm.category_image.toString(),
        };

        const categoryCreateResponse = await CategoryCreateApi(payload);
        if (categoryCreateResponse.status_code === 200) {
          toast.success("Category created successfully!");
          navigate("/admin/categories-list");
          setCategiresForm({
            category_name: "",
            slug: "",
            description: "",
            color_code: "",
            is_active: 1,
            parent_id: "",
            category_image: "",
          });
        } else {
          toast.error("Category creation failed. Please try again.");
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      toast.error("Form has validation errors");
    }
  };

  const handleDeleteImage = () => {
    setCategiresForm((prevState) => ({
      ...prevState,
      category_image: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="w-100">
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Category Name
            </Typography>
            <TextField
              variant="filled"
              name="category_name"
              id="category_name"
              value={CategiresForm.category_name}
              onChange={handleInputChange}
              placeholder="Type Here"
              fullWidth
              inputProps={{
                maxLength: 40,
              }}
              className="common_input"
              error={!!errors.category_name}
              helperText={errors.category_name}
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Description
            </Typography>
            <TextField
              variant="filled"
              name="description"
              id="description"
              value={CategiresForm.description}
              onChange={handleInputChange}
              placeholder="Type description here"
              fullWidth
              multiline
              rows={4}
              className="common_input"
              sx={{ backgroundColor: "#f9f9f9" }}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Box>
          <Box
            className="create_category_fields"
            mb={2}
            sx={{ maxWidth: "100%" }}
          >
            <Typography variant="subtitle1" className="text-capitalize label">
              Parent Category
            </Typography>
            <FormControl fullWidth variant="filled">
              <InputLabel id="category-select-label">
                Select Category
              </InputLabel>
              <Select
                labelId="category-select-label"
                id="parent_id"
                name="parent_id"
                value={selectedCategory}
                onChange={handleSelectChange}
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                <MenuItem value={0}>No Parent</MenuItem>
                {categoryDropdownList && categoryDropdownList.length > 0 ? (
                  categoryDropdownList.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.category_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No categories available</MenuItem>
                )}
              </Select>
              <FormHelperText>
                Please select a category or choose 'No Parent'
              </FormHelperText>
            </FormControl>
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Color
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <TextField
                variant="filled"
                id="hex_input"
                value={CategiresForm.color_code}
                onChange={handleHexInputChange}
                placeholder="#000000"
                className="common_input"
                sx={{ width: "25%" }}
              />
              <input
                type="color"
                id="color_picker"
                value={CategiresForm.color_code}
                onChange={handleColorPickerChange}
                style={{
                  width: "30%",
                  height: "100%",
                  border: "none",
                  cursor: "pointer",
                }}
              />
            </Box>
          </Box>

          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Status
            </Typography>
            <Toggle
              checked={CategiresForm.is_active === 1}
              onChange={(checked) => handleToggleChange(checked, "is_active")}
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Preview
            </Typography>

            <div
              style={{
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  border: "1px dashed #ccc",
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  borderRadius: "10px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                {CategiresForm.category_image ? (
                  <>
                    <img
                      src={`${AppUrl}${CategiresForm.category_image}`}
                      alt="Selected"
                      style={{
                        width: "300px",
                        height: "200px",
                        borderRadius: "10px",
                        transition: "transform 0.2s",
                        cursor: "pointer",
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    />
                    <button
                      style={{
                        backgroundColor: "rgba(255, 0, 0, 0.7)",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        fontSize: "16px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                        transition: "background-color 0.2s",
                      }}
                      onClick={handleDeleteImage}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </>
                ) : (
                  <p style={{ color: "orange" }}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    &nbsp; &nbsp; No image selected. Please upload an image.
                  </p>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleOpenModal}
                  style={{
                    padding: "5px 15px",
                    fontSize: "12px",
                    borderRadius: "0px",
                    border: "none",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    cursor: "pointer",
                    marginTop: "10px",
                    transition: "background-color 0.2s",
                  }}
                >
                  <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                  Thumnail Image
                </Button>
              </div>
            </div>
          </Box>
          <Box display="flex" justifyContent="flex-end" pb={2}>
            <Button
              variant="contained"
              color="primary"
              className="mt-5"
              type="submit"
              style={{ borderRadius: "0px" }}
            >
              <FontAwesomeIcon
                style={{ marginRight: "10px" }}
                icon={faCirclePlus}
              />
              Create Categorie
            </Button>
          </Box>
        </div>
      </form>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default CategoriesCreate;
