export const validateField = (fields: any, values: any) => {
  const errors: any = {};
  const valuesMap = values.section_value?.reduce(
    (acc, { key_name, key_value }) => {
      acc[key_name] = key_value;
      return acc;
    },
    {}
  );

  fields?.forEach((field: any) => {
    const { key_name, validations, is_multi_select, type } = field;
    const value = valuesMap[key_name] || "";

    if (!validations) {
      return;
    }
    if (
      validations.required &&
      (value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === ""))
    ) {
      errors[key_name] = `${key_name.toLowerCase()} is required`;
      return;
    }

    // Validation logic based on field `type`
    switch (type) {
      case "text_input":
        if (typeof value === "string") {
          const trimmedValue = value.trim();
          if (validations.min && trimmedValue.length < validations.min) {
            errors[
              key_name
            ] = `The input must be at least ${validations.min} characters long`;
          } else if (validations.max && trimmedValue.length > validations.max) {
            errors[
              key_name
            ] = `The input must be no more than ${validations.max} characters long`;
          }
        }
        break;

      case "drop_down":
        if (is_multi_select === 1) {
          const valuesArray =
            typeof value === "string" ? value.split(",") : value;
          if (!Array.isArray(valuesArray)) {
            errors[key_name] = `${key_name} must be an array`;
          } else if (valuesArray.length === 0) {
            errors[key_name] = "Please select at least one option";
          } else if (validations.min && valuesArray.length < validations.min) {
            errors[
              key_name
            ] = `You must select at least ${validations.min} options`;
          } else if (validations.max && valuesArray.length > validations.max) {
            errors[
              key_name
            ] = `You can select up to ${validations.max} options`;
          }
        } else if (is_multi_select === 0) {
          if (value === undefined || value === null || value.trim() === "") {
            errors[key_name] = "This field cannot be empty";
          }
        }
        break;

      case "FILE":

        if (!value || (Array.isArray(value) && value.length === 0)) {
          errors[key_name] = "Please upload a file";
        } else if (value) {
          const filteredFiles = value.split(",").filter((file) => file !== "");

          if (filteredFiles.length < validations.min) {
            errors[
              key_name
            ] = `Please upload at least ${validations.min} files`;
          } else if (filteredFiles.length > validations.max) {
            errors[
              key_name
            ] = `Please upload no more than ${validations.max} files`;
          }
        }
        break;

      case "desc_input":
        if (typeof value === "string") {
          const trimmedValue = value.trim();
          if (validations.min && trimmedValue.length < validations.min) {
            errors[
              key_name
            ] = `The description must be at least ${validations.min} characters long`;
          } else if (validations.max && trimmedValue.length > validations.max) {
            errors[
              key_name
            ] = `The description must be no more than ${validations.max} characters long`;
          }
        }
        break;

      case "boolean":
        if (typeof value !== "boolean") {
          errors[key_name] = `${key_name.toLowerCase()} must be true or false`;
        }
        break;

      case "number":
        if (typeof value !== "number") {
          errors[key_name] = `${key_name.toLowerCase()} must be a number`;
        } else if (value === 0 && validations.required) {
          errors[key_name] = `${key_name.toLowerCase()} is required`;
        }
        break;

      default:
        break;
    }
  });

  return errors;
};
