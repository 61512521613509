import React, { useEffect, useState, useMemo } from "react";
import {
  getUserDataOderCreate,
  AllProducts,
  productVariantEdit,
  orderCreateMyAdmin,
  getUserCardData,
  getUserCartTotal,
  getUserBillingAddress,
  getUserShippingAddress,
  DeleteCartItems,
  UpdateCartItems,
} from "services/apiService";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Typography,
  TextField,
  Box,
  Checkbox,
  List,
  ListItemText,
  Avatar,
  Grid,
  Skeleton,
  Radio,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { FixedSizeList } from "react-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import Cart from "./UserCartDetails";

const OrderCreate = () => {
  const [getUserData, setGetUserData] = useState([]);
  const [getProduct, setGetProduct] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [searchTermUser, setSearchTermUser] = useState("");
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [searchTermProduct, setSearchTermProduct] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [finalProduct, setfinalProduct] = useState(null);
  const [openUser, setOpenUser] = useState(false);
  const [varaint, setVariant] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [UserCartProducts, setUserCartProducts] = useState([]);
  const [userCartTotalAmount, setUserCartTotalAmount] = useState("");
  const [userbillingAddresses, setUserbillingAddresses] = useState("");
  const [usershippingAddresses, setUsershippingAddresses] = useState("");

  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  useEffect(() => {
    fetchUserData();
    fetchProductData();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      fetchAllUserData();
    }
  }, [selectedUser]);

  const fetchAllUserData = async () => {
    if (!selectedUser) return;

    const apiCalls = [
      {
        fetchFunction: () => getUserCardData(selectedUser),
        setState: setUserCartProducts,
      },
      {
        fetchFunction: async () => {
          try {
            return await getUserCartTotal(selectedUser);
          } catch (error) {
            console.warn("Cart total API failed:", error);
            return { data: null };
          }
        },
        setState: setUserCartTotalAmount,
      },
      {
        fetchFunction: () => getUserBillingAddress(selectedUser),
        setState: setUserbillingAddresses,
      },
      {
        fetchFunction: () => getUserShippingAddress(selectedUser),
        setState: setUsershippingAddresses,
      },
    ];

    try {
      await Promise.all(
        apiCalls.map(async ({ fetchFunction, setState }) => {
          const response = await fetchFunction();
          if (response?.status_code === 200) {
            setState(response.data);
          } else if (response?.data === null) {
            setState(null);
          }
        })
      );
    } catch (error) {
      toast.error("An error occurred while fetching user data.");
      console.error(error);
    }
  };

  const fetchUserData = async () => {
    setLoadingUsers(true);
    try {
      const response = await getUserDataOderCreate();
      if (response.status_code === 200) {
        setGetUserData(response.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const fetchProductData = async () => {
    try {
      const response = await AllProducts();
      if (response.status_code === 200) {
        setGetProduct(response.data);
      }
    } catch (error) {
      console.log("Error fetching product data:", error);
    }
  };

  const handleMoveToCart = (id) => {
    fetchProductVariantDropdown(id);
    setOpenModal(true);
  };

  const fetchProductVariantDropdown = async (productId: number) => {
    try {
      const response = await productVariantEdit(productId);
      if (response.status_code === 200) {
        setVariant(response.data.variants);
        setfinalProduct(productId);
      }
    } catch (error) {
      console.log(error, error);
    }
  };

  const handleAddtoCart = async () => {
    try {
      const payload = {
        product_id: finalProduct,
        quantity: 1,
        variant_id: selectedVariant,
        attribute_id: 0,
        user_id: selectedUser,
      };
      const response = await orderCreateMyAdmin(payload);
      if (response.status_code === 200) {
        toast.success(response.message);
        handleProductSelect(finalProduct);
        fetchAllUserData();
        setSelectedVariant(null);
        handleModalClose();
      }
    } catch (error) {
      toast.error("Oops, product already in cart.");
      handleModalClose();
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user.id);
    setSearchTermProduct("");
    setSelectedProducts([]);
    setOpenUser(false);
  };

  const handleProductSearch = (event) => {
    setSearchTermProduct(event.target.value);
  };

  const handleProductSelect = (productId: string) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleVariantSelect = (variantId) => {
    setSelectedVariant(variantId);
  };

  const filteredUsers = useMemo(() => {
    return getUserData.filter((user) =>
      user.name_email.toLowerCase().includes(searchTermUser.toLowerCase())
    );
  }, [getUserData, searchTermUser]);

  const filteredProducts = useMemo(() => {
    return getProduct.filter((product) =>
      product.product_name
        .toLowerCase()
        .includes(searchTermProduct.toLowerCase())
    );
  }, [getProduct, searchTermProduct]);

  const renderUser = ({ index, style }) => {
    const user = filteredUsers[index];
    return (
      <MenuItem key={user.id} value={user.id} style={style}>
        <Checkbox
          checked={selectedUser === user.id}
          onChange={() => handleUserSelect(user)}
        />
        <Avatar
          src={`${user.avatar || "placeholder.png"}`}
          alt={user.name_email}
          style={{ marginRight: "10px" }}
        />
        {user.name_email}
      </MenuItem>
    );
  };

  const renderProduct = ({ index, style }) => {
    const product = filteredProducts[index];
    return (
      <MenuItem
        key={product.product_id}
        value={product.product_id}
        style={style}
        onClick={() => handleProductSelect(product)}
      >
        <Box key={product.product_id} display="flex" alignItems="center">
          <Checkbox
            checked={selectedProducts.includes(product.product_id)}
            onChange={() => handleProductSelect(product.product_id)}
            value={product.product_id}
            name="product-select-checkbox"
            inputProps={{ "aria-label": product.product_name }}
          />
          <Avatar
            src={`${AppUrl}${product.thumnail_image || "placeholder.png"}`}
            alt={product.product_name}
            style={{ marginRight: "10px" }}
            variant="square"
          />
          <ListItemText primary={product.product_name} />
        </Box>
      </MenuItem>
    );
  };

  const handleDelete = async (id: number) => {
    try {
      const payload = {
        product_id: id,
        user_id: Number(selectedUser),
      };
      const response = await DeleteCartItems(payload);
      if (response.status_code === 200) {
        toast.success(response.message);
        fetchAllUserData();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdate = async (productId, updatedQuantity) => {
    try {
      const payload = {
        product_id: Number(productId),
        quantity: Number(updatedQuantity),
        user_id: 1,
      };
      const response = await UpdateCartItems(payload);
      if (response.status_code === 200) {
        fetchAllUserData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "20px" }}
            >
              <InputLabel id="user-select-label">Select User</InputLabel>
              <Select
                label="Select User"
                id="user-select"
                value={selectedUser || ""}
                open={openUser}
                onOpen={() => setOpenUser(true)}
                onClose={() => setOpenUser(false)}
                renderValue={(selected) =>
                  getUserData.find((user) => user.id === selected)
                    ?.name_email || "Select User"
                }
                onChange={(event) =>
                  handleUserSelect(
                    getUserData.find((user) => user.id === event.target.value)
                  )
                }
              >
                {loadingUsers ? (
                  <Skeleton height={50} />
                ) : (
                  <FixedSizeList
                    height={200}
                    itemSize={50}
                    itemCount={filteredUsers.length}
                    width="100%"
                  >
                    {renderUser}
                  </FixedSizeList>
                )}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              placeholder="Search Product"
              variant="outlined"
              onChange={handleProductSearch}
              disabled={!selectedUser}
              style={{ marginBottom: "10px" }}
            />
            <List
              style={{
                pointerEvents: selectedUser ? "auto" : "none",
                opacity: selectedUser ? 1 : 0.5,
              }}
            >
              {loadingUsers ? (
                <Skeleton height={50} />
              ) : (
                <FixedSizeList
                  height={450}
                  itemSize={50}
                  itemCount={filteredProducts.length}
                  width="100%"
                >
                  {renderProduct}
                </FixedSizeList>
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>Selected Product</Typography>
            <Box
              sx={{
                marginTop: "20px",
                maxHeight: "550px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
              }}
            >
              {selectedProducts.length > 0 ? (
                selectedProducts.map((productId) => {
                  const product = getProduct.find(
                    (product) => product.product_id === productId
                  );
                  return product ? (
                    <Card
                      key={product.product_id}
                      style={{ marginTop: "10px" }}
                    >
                      <CardContent>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Avatar
                            src={`${AppUrl}${
                              product.thumnail_image || "placeholder.png"
                            }`}
                            alt="Product Image"
                            style={{ marginRight: "10px" }}
                            variant="square"
                          />
                          <Box>
                            <Typography variant="body1">
                              {product.product_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {product.price}
                            </Typography>
                          </Box>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleMoveToCart(product.product_id)}
                          >
                            Move to &nbsp;
                            <FontAwesomeIcon icon={faCartShopping} />
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  ) : null;
                })
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No product selected.
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <br />
        <Cart
          cartItems={UserCartProducts}
          cartTotalAmount={userCartTotalAmount}
          billingAddresses={userbillingAddresses}
          shippingAddresses={usershippingAddresses}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
        />
      </div>

      <Dialog
        open={openModal}
        onClose={handleModalClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "5px",
            padding: "10px",
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.0rem",
              padding: "8px 0",
              color: "grey",
              borderBottom: "1px solid #e0e0e0",
              textTransform: "uppercase",
            }}
          >
            Select a Variant
          </Box>
        </DialogTitle>
        <DialogContent>
          <List
            sx={{
              maxHeight: "300px",
              padding: "0",
            }}
          >
            {varaint?.map((variant) => (
              <MenuItem
                key={variant.variant_id}
                onClick={() => handleVariantSelect(variant.variant_id)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px 16px",
                  borderRadius: "8px",
                  marginBottom: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                    transform: "scale(1.02)",
                    transition: "0.2s ease-in-out",
                  },
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={selectedVariant === variant.variant_id}
                    value={variant.variant_id}
                    sx={{
                      color: "#1467a5",
                      "&.Mui-checked": {
                        color: "#1467a5 ",
                      },
                    }}
                  />
                  <Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
                    {variant.value}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleAddtoCart();
            }}
            disabled={!selectedVariant}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderCreate;
