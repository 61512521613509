import React, { useEffect, useState } from "react";
import DynamicButton from "../../common/DynamicButton";
import {
  faCirclePlus,
  faMagnifyingGlass,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import {
  DeleteClassification,
  getAllClassificationList,
} from "services/apiService";
import { ClassificationEdit } from "./ClassificationEdit";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Pagination } from "@mui/material";
import loadingImage from "../../assect/loadingSvg/loading.svg";
import Swal from "sweetalert2";
import No_Image_Available from "../../Images/No_Image_Available-Table.png";

const ClassificationsList = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [classificationSearch, setClassificationSearch] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [isOpenClassificationEdit, setIsOpenClassificationEdit] =
    useState(false);
  const [selectedClassificationId, setSelectedClassificationId] = useState<
    number | null
  >(null);
  const [allClassification, setAllClassification] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const headers = [
    "S.no",
    "Classification Name",
    "Image",
    "Slug",
    "Status",
    "Action",
  ];

  useEffect(() => {
    getClassification();
  }, []);

  const getClassification = async () => {
    try {
      const response = await getAllClassificationList();
      setAllClassification(response.data);
    } catch (error) {
      console.error("Error fetching classifications:", error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClassificationSearch(e.target.value);
  };

  const filteredClassifications = allClassification?.filter((classification) =>
    classification.classification_name
      .toLowerCase()
      .includes(classificationSearch.toLowerCase())
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClassifications = filteredClassifications.slice(
    startIndex,
    endIndex
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleModelOpen = (id: number) => {
    setSelectedClassificationId(id);
    setIsOpenClassificationEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenClassificationEdit(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteClassification(id);
          if (response.status_code === 200) {
            Swal.fire(
              "Deleted!",
              "The ClassificatioN has been deleted.",
              "success"
            );
            getClassification();
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Classification is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const renderRow = (classification: any, index: number) => (
    <tr key={index}>
      <td>{classification.classification_id}</td>
      <td title={classification.classification_name}>
        {classification.classification_name.length > 30
          ? `${classification.classification_name.slice(0, 30)}...`
          : classification.classification_name}
      </td>

      <td className="align-content-center">
        <div className="d-flex justify-content-center">
          {classification.image_path ? (
            <img
              src={`${AppUrl}${classification.image_path}`}
              alt="ProductImage"
              width={100}
            />
          ) : (
            <img
              src={No_Image_Available}
              alt="No_Image_Available"
              width={100}
            />
          )}
        </div>
      </td>
      <td title={classification.slug}>
        {classification.slug.length > 30
          ? `${classification.slug.slice(0, 30)}...`
          : classification.slug}
      </td>
      <td>
        <span
          style={{
            color: classification.is_active === 1 ? "green" : "red",
            backgroundColor:
              classification.is_active === 1 ? "#e0f7e9" : "#f8d7da",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        >
          {classification.is_active === 1 ? "Active" : "Inactive"}
        </span>
      </td>
      <td>
        <div className="actions-buttons">
          <DynamicButton
            icon={faPen}
            iconStyle={{ color: "#63E6BE" }}
            style={{
              padding: "0",
              border: "none",
              background: "none",
              cursor: "pointer",
              margin: "2px",
            }}
            onClick={() => handleModelOpen(classification.classification_id)}
          />

          <DynamicButton
            icon={faTrashCan}
            iconStyle={{ color: "#f54242" }}
            style={{
              padding: "0",
              border: "none",
              background: "none",
              cursor: "pointer",
              margin: "2px",
            }}
            onClick={() =>
              handleDelete(
                classification.classification_id,
                classification.classification_name
              )
            }
          />
        </div>
      </td>
    </tr>
  );

  return (
    <>
      <div className="d-flex justify-content-between  ">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/classification-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create classification
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search Classification..."
            style={{ boxShadow: "none" }}
            value={classificationSearch}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="product-table-content">
        {loader ? (
          <div className="loadingsvg-Image text-center">
            <img src={loadingImage} width={150} height={150} alt="Loading..." />
          </div>
        ) : (
          <CommonTable
            data={currentClassifications}
            isLoading={loader}
            headers={headers}
            renderRow={renderRow}
          />
        )}
      </div>

      <div className="pagination-container">
        <div className="data-info" style={{ color: "grey" }}>
          <span>
            Showing {startIndex + 1}-
            {Math.min(
              startIndex + itemsPerPage,
              filteredClassifications.length
            )}{" "}
            of {filteredClassifications.length}
          </span>
        </div>
        <Pagination
          count={Math.ceil(filteredClassifications.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>

      <ClassificationEdit
        onActionComplete={getClassification}
        open={isOpenClassificationEdit}
        handleClose={handleModelClose}
        id={selectedClassificationId}
      />
    </>
  );
};

export default ClassificationsList;
