import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  SelectChangeEvent,
} from "@mui/material";
import { ImageUploaderModalProps } from "types";
import { getMediaList } from "services/apiService";
import ImageUpload from "./ImageUpload";
import { FixedSizeGrid as Grid } from "react-window";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const ImageUploaderModal: React.FC<ImageUploaderModalProps> = ({
  isOpen,
  onClose,
  onImageSelect,
  allowMultiple = false,
}) => {
  const [imageGallery, setImageGallery] = useState<string[]>([]);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [filterType, setFilterType] = useState<string>("all");
  const baseUrl = process.env.REACT_APP_IMAGE_URL || "";
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const gridContainerRef = useRef<HTMLDivElement>(null);

  const fetchAllImages = useCallback(async () => {
    setIsLoading(true);
    const response = await getMediaList();
    setImageGallery(response?.data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchAllImages();
    }
  }, [isOpen, fetchAllImages, activeTab]);

  const handleImageUploadSuccess = useCallback(() => {
    fetchAllImages();
  }, [fetchAllImages]);

  const handleCheckboxChange = (imgName: string) => {
    setSelectedImages((prevSelected) => {
      if (allowMultiple) {
        if (prevSelected?.includes(imgName)) {
          return prevSelected.filter((name) => name !== imgName);
        } else {
          return [...prevSelected, imgName];
        }
      } else {
        if (prevSelected?.includes(imgName)) {
          return prevSelected;
        } else {
          onImageSelect([imgName]);
        }
      }
    });
  };

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);
  };

  const handleConfirmSelection = () => {
    onImageSelect(selectedImages);
    setSelectedImages([]);
    onClose();
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterType(event.target.value);
  };

  const filteredImages = imageGallery.filter((img) => {
    const extension = img.split(".").pop()?.toLowerCase();
    if (extension === "mp4") return false;
    if (filterType === "all") return true;
    return `.${extension}` === filterType;
  });

  const cellRenderer = ({ columnIndex, rowIndex, style }: any) => {
    const imageIndex = rowIndex * 6 + columnIndex;
    const img = filteredImages[imageIndex];

    return img ? (
      <div style={style} className="image-item">
        <img
          src={`${baseUrl}${img}`}
          alt={`image-${rowIndex}-${columnIndex}`}
          className="image-thumbnail"
        />
        <label className="checkbox-label">
          <input
            type="checkbox"
            onChange={() => handleCheckboxChange(img)}
            checked={selectedImages?.includes(img)}
          />
        </label>
      </div>
    ) : null;
  };

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogTitle>
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            border: "none",
            fontSize: "20px",
          }}
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faCircleXmark} />
        </Button>
      </DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="Gallery" />
          <Tab label="Uploader" />
        </Tabs>
        {activeTab === 0 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={2}
            >
              <FormControl
                variant="outlined"
                size="small"
                sx={{ width: "200px" }}
              >
                <InputLabel id="filter-label">Filter by Type</InputLabel>
                <Select
                  labelId="filter-label"
                  value={filterType}
                  onChange={handleFilterChange}
                  label="Filter by Type"
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value=".jpg">JPG</MenuItem>
                  <MenuItem value=".png">PNG</MenuItem>
                  <MenuItem value=".gif">GIF</MenuItem>
                </Select>
              </FormControl>
              {selectedImages?.length > 0 && (
                <p style={{ color: "grey" }}>
                  Items Selected ({selectedImages?.length})
                </p>
              )}
            </Box>

            <Box ref={gridContainerRef} sx={{ height: 450, width: "100%" }}>
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={450}
                  animation="wave"
                />
              ) : (
                <Grid
                  columnWidth={135}
                  rowHeight={150}
                  columnCount={6}
                  rowCount={Math.ceil(filteredImages.length / 6)}
                  width={gridContainerRef.current?.clientWidth || 0}
                  height={450}
                >
                  {cellRenderer}
                </Grid>
              )}
            </Box>

            {allowMultiple && (
              <DialogActions>
                <Button onClick={onClose} color="error">
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmSelection}
                  variant="contained"
                  color="primary"
                  disabled={selectedImages?.length === 0}
                >
                  Confirm Selection
                </Button>
              </DialogActions>
            )}
          </>
        )}

        {activeTab === 1 && (
          <ImageUpload onUploadSuccess={handleImageUploadSuccess} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImageUploaderModal;
