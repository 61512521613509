import { Button, Modal, TextField, Typography, Box } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  SettingsGroupEditApi,
  UpdateSettingsGroupEditApi,
} from "services/apiService";
import { TaxEditProps } from "types";

const SettingsGroupEdit: React.FC<TaxEditProps> = ({
  id,
  open,
  handleClose,
  onActionComplete,
}) => {
  const [formdata, setFormdata] = useState({
    name: "",
    is_active: 1,
  });

  const [errors, setErrors] = useState({
    name: "",
  });

  useEffect(() => {
    if (id) {
      fetchSettings(id);
    }
  }, [id]);

  const fetchSettings = async (id: number) => {
    try {
      const response = await SettingsGroupEditApi(id);
      if (response.status_code === 200) {
        const { name, is_active } = response.data;
        setFormdata({
          name,
          is_active: is_active === 1 ? 1 : 0,
        });
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const newErrors = {
      name: formdata.name.length === 0 ? "Name is required" : "",
    };

    setErrors(newErrors);
    if (!newErrors.name) {
      try {
        const payload = {
          id: id,
          name: formdata.name,
          is_active: formdata.is_active,
        };
        const respones = await UpdateSettingsGroupEditApi(payload);
        if (respones.status_code === 200) {
          toast.success("Updated Successfully");
          handleClose();
          onActionComplete();
        }
      } catch (error) {}
      onActionComplete();
    }
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Edit Setting Group
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 2 }}>
            <TextField
              variant="filled"
              name="name"
              value={formdata.name}
              onChange={handleInputChange}
              placeholder="Enter Settings Group Name"
              fullWidth
              error={!!errors.name}
              helperText={errors.name || "Only letters, and '-' allowed."}
              sx={{ mb: 2 }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Status</Typography>
            <Toggle
              checked={formdata.is_active === 1}
              onChange={(checked) =>
                setFormdata((prevData) => ({
                  ...prevData,
                  is_active: checked ? 1 : 0,
                }))
              }
            />
          </Box>
          <Box sx={{ textAlign: "right", mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mr: 2 }}
            >
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default SettingsGroupEdit;
