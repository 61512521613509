import React, { useEffect, useState } from "react";
import {
  VariantDropDown,
  productVariantEdit,
  productVariantSubmit,
  productVariantUpdate,
} from "../../../services/apiService";
import { Button, Switch } from "@mui/material";
import toast from "react-hot-toast";
import "../../../styles/ProductVariant.css";
import { ProductVariantEditFormProps } from "types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronUp, faPlus } from "@fortawesome/free-solid-svg-icons";
interface VariantField {
  variant_id: number | null;
  dropdownValue: string;
  textboxValue: string;
  isActive: boolean;
}
const ProductEditVariant: React.FC<ProductVariantEditFormProps> = ({
  productId,
}) => {
  const [variantList, setVariantList] = useState<
    { id: number; name: string }[]
  >([]);
  const [variantFields, setVariantFields] = useState<VariantField[]>([
    { variant_id: null, dropdownValue: "", textboxValue: "", isActive: true },
  ]);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setDisabled(!productId);
    if (productId) {
      productVarientEdit();
      VariantDropDownList();
    }
  }, [productId]);

  const VariantDropDownList = async () => {
    try {
      const response = await VariantDropDown();
      setVariantList(response.data);
    } catch (error) {
      console.error("Error fetching variant list:", error);
    }
  };

  const productVarientEdit = async () => {
    if (productId) {
      const response = await productVariantEdit(productId);
      const variantArray = response.data.variants;
      if (variantArray) {
        const mappedVariants = variantArray.map((variant: any) => ({
          variant_id: variant.variant_id,
          dropdownValue: variant.variant_label_id.toString(),
          textboxValue: variant.value,
          isActive: variant.is_active === 1,
        }));
        setVariantFields(mappedVariants);
      }
    }
  };

  const handleAddVariant = () => {
    setVariantFields([
      ...variantFields,
      { variant_id: null, dropdownValue: "", textboxValue: "", isActive: true },
    ]);
  };

  const handleToggleActive = (index: number) => {
    const updatedFields = [...variantFields];
    updatedFields[index].isActive = !updatedFields[index].isActive;
    setVariantFields(updatedFields);
  };

  const handleChangeDropdown = (index: number, value: string) => {
    const updatedFields = [...variantFields];
    updatedFields[index].dropdownValue = value;
    setVariantFields(updatedFields);
  };

  const handleChangeTextbox = (index: number, value: string) => {
    const updatedFields = [...variantFields];
    updatedFields[index].textboxValue = value;
    setVariantFields(updatedFields);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const payload = {
      product_id: productId,
      variants: variantFields.map((variant) => ({
        variant_id: variant.variant_id,
        variant_label_id: parseInt(variant.dropdownValue),
        value: variant.textboxValue,
        is_active: variant.isActive ? 1 : 0,
      })),
    };

    try {
      const response = await productVariantUpdate(payload);
      if (response.status_code === 200) {
        toast.success(response.message);
      }
    } catch (error) {
      toast.error("Error submitting variants");
    }
  };

  const handleCreateVariant = async (index: number) => {
    const variant = variantFields[index];
    if (variant.dropdownValue && variant.textboxValue) {
      const payload = {
        product_id: productId,
        variants: [
          {
            variant_label_id: parseInt(variant.dropdownValue),
            value: variant.textboxValue,
            is_active: variant.isActive ? 1 : 0,
          },
        ],
      };

      try {
        const response = await productVariantSubmit(payload);
        if (response.status_code === 200) {
          toast.success("Variant created successfully");
          productVarientEdit();
        }
      } catch (error) {
        toast.error("Error creating variant");
      }
    } else {
      toast.error("Please select at least one variant");
    }
  };

  return (
    <>
      <div className="card p-4 product-variant-edit-form">
        <div className="top-bar">
          <h3 className="form-title">Manage Product Variants</h3>
          <div className="top-bar-buttons">
            <Button variant="contained" onClick={handleAddVariant}>
              {" "}
              <FontAwesomeIcon icon={faPlus} /> &nbsp;&nbsp;Add Variant
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              onClick={handleSubmit}
            >
              <FontAwesomeIcon icon={faCircleChevronUp} />
              &nbsp;&nbsp; Submit Variants
            </Button>
          </div>
        </div>
        <div className="variant-list-container">
          <div className="list-header">
            <div className="col-header">Variant</div>
            <div className="col-header">Value</div>
            <div className="col-header">Status</div>
          </div>
          <div className="variant-list">
            {variantFields.map((variant, index) => (
              <div key={index} className="variant-item row align-items-center">
                <div className="col-md-4">
                  <select
                    id={`variant-dropdown-${index}`}
                    className="form-select"
                    value={variant.dropdownValue}
                    onChange={(e) =>
                      handleChangeDropdown(index, e.target.value)
                    }
                  >
                    {!variantList.length ? (
                      <option value="">No Variants</option>
                    ) : (
                      <>
                        <option value="">Select Variant</option>
                        {variantList.map((variantItem) => (
                          <option key={variantItem.id} value={variantItem.id}>
                            {variantItem.name.toUpperCase()}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter variant value"
                    value={variant.textboxValue}
                    onChange={(e) => handleChangeTextbox(index, e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <Switch
                    checked={variant.isActive}
                    onChange={() => handleToggleActive(index)}
                    color="primary"
                  />
                </div>
                {variant.variant_id === null && (
                  <div className="col-md-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handleCreateVariant(index)}
                    >
                      Create
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductEditVariant;
