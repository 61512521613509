import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CreateClassification } from "services/apiService";

const ClassificationCreate: React.FC = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const [classificationCreate, setClassificationCreate] = useState({
    classification_name: "",
    slug: "",
    image_path: "",
    is_active: 1,
  });
  const [errors, setErrors] = useState({
    classification_name: "",
    slug: "",
    image_path: "",
  });

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const validateForm = () => {
    let formErrors: any = {};
    if (!classificationCreate.classification_name.trim()) {
      formErrors.classification_name = "Classification name is required.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const maxLength = 50;
    const regex = /^[a-zA-Z\-]*$/;
    const capitalizeFirstLetter = (text: string) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const capitalizedValue = capitalizeFirstLetter(value);

    if (capitalizedValue.length > maxLength) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "Maximum length is 50 characters.",
      }));
    } else if (!regex.test(capitalizedValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "Only letters and hyphens (-) are allowed.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }

    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };

    setClassificationCreate((prevData) => ({
      ...prevData,
      [id]: capitalizedValue,
      slug:
        id === "classification_name"
          ? generateSlug(capitalizedValue)
          : prevData.slug,
    }));
  };

  const handleImageSelected = (image: string) => {
    setClassificationCreate((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          classification_name: classificationCreate.classification_name,
          slug: classificationCreate.slug,
          image_path: classificationCreate.image_path.toString(),
          is_active: classificationCreate.is_active,
        };

        const ClassificationPayload = await CreateClassification(payload);
        if (ClassificationPayload.status === "success") {
          toast.success("Classification created successfully!");
          setClassificationCreate({
            classification_name: "",
            slug: "",
            image_path: "",
            is_active: 1,
          });
          navigate("/admin/classification-list");
        }
      } catch (error) {}
    }
  };

  const handleDeleteImage = () => {
    setClassificationCreate((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Classification Name
              </Typography>
              <TextField
                variant="filled"
                id="classification_name"
                name="classification_name"
                fullWidth
                value={classificationCreate.classification_name}
                onChange={handleTextFieldChange}
                error={!!errors.classification_name}
                helperText={"Maximum length is 50 characters. "}
                inputProps={{ maxLength: 50 }}
              />
            </Box>

            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Slug
              </Typography>
              <TextField
                variant="filled"
                id="slug"
                fullWidth
                value={classificationCreate.slug}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Status
              </Typography>
              <Toggle
                checked={classificationCreate.is_active === 1}
                onChange={(checked) =>
                  setClassificationCreate((prevState) => ({
                    ...prevState,
                    is_active: checked ? 1 : 0,
                  }))
                }
              />
            </Box>

            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Classification Image
              </Typography>

              <div
                style={{
                  width: "100%",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    border: "1px dashed #ccc",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "10px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {classificationCreate.image_path ? (
                    <>
                      <img
                        src={`${AppUrl}${classificationCreate.image_path}`}
                        alt="Selected"
                        style={{
                          transition: "transform 0.2s",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      />
                      <button
                        style={{
                          backgroundColor: "rgba(255, 0, 0, 0.7)",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          fontSize: "16px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                          transition: "background-color 0.2s",
                        }}
                        onClick={handleDeleteImage}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </>
                  ) : (
                    <p style={{ color: "orange" }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      &nbsp; &nbsp; No image selected. Please upload an image.
                    </p>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleOpenModal}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                    Upload Image
                  </Button>
                </div>
              </div>
            </Box>

            <Box textAlign="end" mt={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp; Create Classification
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default ClassificationCreate;
