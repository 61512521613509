import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonTable from "common/CommonTable";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllPagesList } from "services/apiService";
import { Page } from "types";

const PagesList = () => {
  const [pagesListData, setPagesListData] = useState<Page[]>([]);
  const [pageListLoading, setPageListLoading] = useState<boolean>(false);
  useEffect(() => {
    getAllPagesData();
  }, []);
  const naviagte = useNavigate();
  const getAllPagesData = async () => {
    setPageListLoading(true);
    try {
      const response = await getAllPagesList();
      setPageListLoading(false);
      setPagesListData(response.data);
    } catch (error) {
      setPageListLoading(false);
    }
  };
  const headers = ["S.No", "Page Name", "Slug", "Status", "View Sections"];
const handleNavigation =(product:any)=>{
  console.log(product,"product");
  localStorage.setItem('pageid',product.id)
  naviagte("/admin/pages-list/sections",)
}
  const renderRow = (product: any, index: number) => (
    <tr className="mt-2 pt-4" key={index}>
      <td>{index + 1}</td>
      <td className="">
        <div className="d-flex flex-column product_name_td">
          <span>{product.name}</span>
        </div>
      </td>
      <td className=" align-content-center">
        {product.slug.length > 60
          ? product.slug.slice(0, 60) + "..."
          : product.slug}
      </td>
      <td className="align-content-center">
        <span
          className={`text-${
            product.is_active === 1 ? "success" : "danger"
          } fw-bold`}
        >
          {product.is_active === 1 ? "Active" : "Inactive"}
        </span>
      </td>

      <td className="align-content-center">
        <div className="d-flex justify-content-center">
        <button
          onClick={() =>handleNavigation(product)
           
          }
          type="button"
          className="btn d-flex gap-2 align-items-center text-white bsb-btn-5xl"
        >
          <FontAwesomeIcon icon={faCircleInfo} />
        </button>
        </div>
        
      </td>
    </tr>
  );
  return (
    <>
      <CommonTable
        data={pagesListData}
        isLoading={pageListLoading}
        headers={headers}
        renderRow={renderRow}
      />
    </>
  );
};

export default PagesList;
