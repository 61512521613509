import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { createSeller } from "services/apiService";

const SellerCreate: React.FC = () => {
  const [formData, setFormData] = useState({
    seller_name: "",
    address: "",
    phone_no: "",
    email: "",
    is_active: 1,
  });
  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    seller_name: "",
    address: "",
    phone_no: "",
    email: "",
  });

  const validateFields = () => {
    const newErrors: typeof errors = {
      seller_name: "",
      address: "",
      phone_no: "",
      email: "",
    };

    if (!formData.seller_name.trim()) {
      newErrors.seller_name = "Seller name is required.";
    } else if (formData.seller_name.length > 30) {
      newErrors.seller_name = "Seller name must not exceed 30 characters.";
    }

    if (!formData.address.trim()) {
      newErrors.address = "Seller address is required.";
    }

    if (!formData.phone_no.trim()) {
      newErrors.phone_no = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone_no)) {
      newErrors.phone_no = "Phone number must be 10 digits.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    setErrors((prev) => ({ ...prev, [id]: "" }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setFormData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateFields()) {
      try {
        const payload = {
          seller_name: formData.seller_name,
          address: formData.address,
          email: formData.email,
          phone_no: Number(formData.phone_no),
          is_active: formData.is_active,
        };
        const response = await createSeller(payload);
        if (response.status_code === 200) {
          toast.success("Form submitted successfully");
          navigate("/admin/seller-list");
        }
      } catch (error) {
        toast.error("Form Submission Failed , Please fill the form !");
      }
    }
  };

  return (
    <section className="section">
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography
                variant="body1"
                className="form-label"
                sx={{ width: "30%" }}
              >
                Seller Name
              </Typography>
              <TextField
                variant="filled"
                id="seller_name"
                fullWidth
                placeholder="Type here"
                value={formData.seller_name}
                onChange={handleInputChange}
                error={!!errors.seller_name}
                helperText={errors.seller_name}
                inputProps={{ maxLength: 50 }}
              />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography
                variant="body1"
                className="form-label"
                sx={{ width: "30%" }}
              >
                Seller Address
              </Typography>
              <TextField
                variant="filled"
                id="address"
                fullWidth
                placeholder="Enter your address, e.g., Building No, Plot No, City"
                value={formData.address}
                onChange={handleInputChange}
                error={!!errors.address}
                helperText={
                  errors.address || "Address should not exceed 100 characters.."
                }
                inputProps={{
                  maxLength: 100,
                }}
              />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography
                variant="body1"
                className="form-label"
                sx={{ width: "30%" }}
              >
                Phone Number
              </Typography>
              <TextField
                variant="filled"
                id="phone_no"
                fullWidth
                placeholder="Enter 10-digit phone number"
                value={formData.phone_no}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setFormData({ ...formData, phone_no: value });
                  }
                }}
                error={!!errors.phone_no}
                helperText={errors.phone_no}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                  style: { textAlign: "start" },
                }}
                type="tel"
              />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <Typography
                variant="body1"
                className="form-label"
                sx={{ width: "30%" }}
              >
                Email ID
              </Typography>
              <TextField
                variant="filled"
                id="email"
                fullWidth
                placeholder="Enter email"
                value={formData.email}
                onChange={handleInputChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Box>

            <Box display="flex" alignItems="center" gap={0}>
              <Typography
                variant="body1"
                className="form-label"
                sx={{ width: "30%" }}
              >
                Seller Active
              </Typography>
              <Toggle
                checked={formData.is_active === 1}
                onChange={(checked) => handleToggleChange(checked, "is_active")}
              />
            </Box>

            <Box textAlign="end" mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp;&nbsp; Create Seller
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </section>
  );
};

export default SellerCreate;
