import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCanArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import JsonSectionEdit from "./SectionEditData/JsonSectionEdit";
import {
  getEditPageSection,
  getEditSectionPlace,
  removeSection,
} from "services/apiService";
import { fetchSectionsDatas } from "../../../redux/Slice/getSectionDataSlice.js";
import { useDispatch } from "react-redux";

interface DragItem {
  index: number;
  id: string;
}
const ItemTypes = {
  SECTION: "section",
};
const Section = ({ section, index, setRefreshData, pageid }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [sectionName, setSectionName]= useState('')
  const [{ isDragging }, ref] = useDrag<
    DragItem,
    unknown,
    { isDragging: boolean }
  >({
    type: ItemTypes.SECTION,
    item: { index, id: section.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const dispatch = useDispatch();

  const handleEdit = (sectionGroupId, pageIds, sectionName) => {
    const pagid = parseInt(pageIds);
    dispatch(
      fetchSectionsDatas({
        sectionGroupId: sectionGroupId,
        pageId: pagid,
      })
    );
    setIsEditModalOpen(!isEditModalOpen);
    setSectionName(sectionName)
  };

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: "Want to delete the Section?",
      text: "This action will delete the section permanently!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      await Swal.fire({
        title: "Confirm Delete",
        text: "Are you really sure you want to delete this section?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then(async (secondResult) => {
        if (secondResult.isConfirmed) {
          const payload = {
            page_id: parseInt(pageid),
            section_id: parseInt(section.id.match(/\d+/)[0]),
            section_group: parseInt(section.section_group),
          };
          try {
            const response = await removeSection(payload);
            if (response.status_code === 200) {
              await Swal.fire(
                "Deleted!",
                "Your section has been deleted.",
                "success"
              );
              setRefreshData((prev) => prev + 1);
            } else {
              toast.error("Section deletion failed. Please try again.");
            }
          } catch (error) {
            toast.error("Section deletion failed. Please try again.");
          }
        }
      });
    }
  };

  return (
    <div
      ref={ref}
      style={{
        padding: 10,
        border: "1px solid #ccc",
        borderRadius: 5,
        marginTop: 10,
        backgroundColor: isDragging ? "#d3d3d3" : "#fff",
        opacity: isDragging ? 0.5 : 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "move",
        width: "98%",
      }}
    >
      <span style={{ flex: 1, margin: 0 }}>{section.title}</span>
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <button
          className="btn rounded text-danger bg-white p-1"
          onClick={() => handleEdit(section?.section_group, pageid, section.title)}
        >
          <FontAwesomeIcon icon={faPenToSquare} title="Edit" />
        </button>
        <button
          className="btn rounded text-danger bg-white p-1"
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={faTrashCanArrowUp} title="Delete" />
        </button>
      </div>
      <JsonSectionEdit
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        sectionId={section.id}
        sectionGroupId={section.section_group}
        sectionName={sectionName.toLowerCase()}
      />
    </div>
  );
};
const KanbanBoard = ({ pageid, modalIsOpen }) => {
  const [sections, setSections] = useState([]);
  const [refreshData, setRefreshData] = useState(0);
  const moveSection = (dragIndex, hoverIndex) => {
    const draggedSection = sections[dragIndex];
    const newSections = [...sections];
    newSections.splice(dragIndex, 1);
    newSections.splice(hoverIndex, 0, draggedSection);
    newSections.forEach((section, index) => {
      section.index = index;
    });
    setSections(newSections);
  };
  useEffect(() => {
    const fetchSections = async () => {
      const response = await getEditPageSection(pageid);
      if (response?.data) {
        const transformedData = transformData(response.data);
        setSections(transformedData);
      } else {
        setSections([]);
      }
    };
    fetchSections();
  }, [refreshData, pageid, modalIsOpen]);
  const transformData = (data) => {
    return data.map((section, index) => ({
      id: `section${section.section_id}_${index}`,
      title: section.section_name.toUpperCase(),
      isActive: section.is_active,
      section_group: section.section_group,
    }));
  };

  const saveKanbanChanges = async () => {
    const updatedKanbanSections = sections.map((item, index) => ({
      section_group: item.section_group,
      place_order: index + 1,
    }));
    const payload = {
      page_id: parseInt(pageid),
      section_place: updatedKanbanSections,
    };
    try {
      const response = await getEditSectionPlace(payload);
      if (response.status_code === 200) {
        toast.success("Section place changed successfully!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-evenly">
      <div
        style={{
          overflowY: "auto",
          height: "550px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0",
            borderBottom: "2px solid #ccc",
            width: "98%",
          }}
        >
          <strong style={{ flex: 1 }}>Section Name</strong>
          <span >
            <strong>Edit |</strong>
            <strong>Delete</strong>
          </span>
        </div>

        {sections.map((section, index) => (
          <SectionWrapper
            key={section.id}
            index={index}
            section={section}
            moveSection={moveSection}
            setRefreshData={setRefreshData}
            pageid={pageid}
          />
        ))}
      </div>
      <div className="d-flex justify-content-end me-2 mt-3 mb-3">
        <button
          className=" cls  text-white"
          onClick={saveKanbanChanges}
          style={{  width: "30%" }}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

const SectionWrapper = ({
  section,
  index,
  moveSection,
  setRefreshData,
  pageid,
}) => {
  const [, drop] = useDrop<DragItem>({
    accept: ItemTypes.SECTION,
    hover: (item: DragItem) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  return (
    <div className="d-flex justify-content-evenly" ref={drop}>
      <Section
        section={section}
        index={index}
        setRefreshData={setRefreshData}
        pageid={pageid}
      />
    </div>
  );
};

export default KanbanBoard;
