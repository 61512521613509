import React from "react";

const Pagination = ({ showPageDetail = true, extClasses = "" }) => {
  return (
    <div
      className={`product_pagination d-flex justify-content-between ${extClasses}`}
    >
      {showPageDetail && <span>Showind 1-8 of 897 results</span>}

      <div className="d-flex align-items-center gap-2">
        <div className="pagination-button" role="button">
          <i className="bi bi-arrow-left-short"></i>
        </div>
        <div className="d-flex align-items-center gap-2">
          {[1, 2, 3, 4, 6, "..", "...", 55, 56].map((i) => (
            <div className="pagination-button" role="button" key={i}>
              <span>{i}</span>
            </div>
          ))}
        </div>

        <div className="pagination-button" role="button">
          <i className="bi bi-arrow-right-short"></i>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
