import React, { useState } from "react";
import ProductCreateForm from "./ProductCreateForm";
import ProductMedia from "../../Product/Create/ProdcutMedia";
import ProductVariant from "../../Product/Create/ProductVariant";
import toast from "react-hot-toast";
import { Box, Button } from "@mui/material";

const ProductCreate: React.FC = () => {
  const [productId, setProductId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<"form" | "media" | "variants">(
    "form"
  );
  const [isMediaCompleted, setIsMediaCompleted] = useState<boolean>(false);

  const handleProductFormSuccess = (productId: number | null) => {
    if (productId) {
      setProductId(productId);
      setActiveTab("media");
    }
  };

  const handleMediaComplete = () => {
    setIsMediaCompleted(true);
    setActiveTab("variants");
  };

  const openTab = (tabId: "form" | "media" | "variants") => {
    if (tabId === "form") {
      if (productId) {
        toast("You cannot go back to the form after completion!", {
          icon: "⚠️",
          style: { background: "white", color: "red" },
        });
      }
      return;
    }

    if (tabId === "media") {
      if (!productId) {
        toast("Please complete and submit the form first!", {
          icon: "⚠️",
          style: { background: "white", color: "orange" },
        });
        return;
      }
      if (isMediaCompleted) {
        toast("You cannot go back to the media tab after completion!", {
          icon: "⚠️",
          style: { background: "white", color: "orange" },
        });
        return;
      }
      setActiveTab("media");
      return;
    }

    if (productId && isMediaCompleted) {
      setActiveTab(tabId);
    } else {
      toast("Please complete both the form and media first!", {
        icon: "⚠️",
        style: { background: "white", color: "orange" },
      });
    }
  };

  return (
    <>
      <div className="product_form w-100 pb-5">
        <div className="w-100 d-flex dm-sans">
          <div className="product_form_main_left">
            <div
              role="button"
              className={`${
                activeTab === "form" ? "InnerSide-activeTab" : ""
              } m-0 d-flex gap-1 align-items-center product_form_tab`}
              onClick={() => openTab("form")}
            >
              <i className="bi bi-card-checklist"></i>
              <h6 className="font-bold text-capitalize m-0 px-2">form</h6>
            </div>
            <div
              role="button"
              className={`${
                activeTab === "media" ? "InnerSide-activeTab" : ""
              } m-0 d-flex gap-1 align-items-center product_form_tab ${
                !productId ? "disabled-tab" : ""
              }`}
              onClick={() => openTab("media")}
            >
              <i className="bi bi-image"></i>
              <h6 className="font-bold text-capitalize m-0 px-2">media</h6>
            </div>
            <div
              role="button"
              className={`${
                activeTab === "variants" ? "InnerSide-activeTab" : ""
              } m-0 d-flex gap-1 align-items-center product_form_tab ${
                !productId ? "disabled-tab" : ""
              }`}
              onClick={() => openTab("variants")}
            >
              <i className="bi bi-layout-three-columns"></i>
              <h6 className="font-bold text-capitalize m-0 px-2">variants</h6>
            </div>
          </div>
          <div className="product_form_main_right w-100">
            {activeTab === "form" && (
              <ProductCreateForm ProductKey={handleProductFormSuccess} />
            )}
            {activeTab === "media" && (
              <ProductMedia
                productId={productId}
                onMediaComplete={handleMediaComplete}
              />
            )}
            {activeTab === "variants" && (
              <ProductVariant productId={productId} />
            )}
          </div>
        </div>
      </div>
      <div>
        {activeTab === "variants" && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={() => setActiveTab("form")}
              sx={{
                width: "200px",
                padding: "10px",
                fontSize: "12px",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#2e7d32",
                  transform: "scale(1.05)",
                },
                "&:active": {
                  backgroundColor: "#1b5e20",
                },
              }}
            >
              Create Product Again
            </Button>
            <Button
              variant="contained"
              color="error"
              href="/admin/product-list"
              sx={{
                width: "200px",
                padding: "10px",
                fontSize: "12px",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#d32f2f",
                  transform: "scale(1.05)",
                },
                "&:active": {
                  backgroundColor: "#c62828",
                },
              }}
            >
              Go to Product List
            </Button>
          </Box>
        )}
      </div>
    </>
  );
};

export default ProductCreate;
