import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import React from 'react';

interface DownloadButtonProps {
    fileName: string | any;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ fileName }) => {
    const handleDownloadFile = async () => {
        try {
            const response = await fetch(`/assets/${fileName}`);
            if (!response.ok) {
                throw new Error('File not found');
            }
            const fileData = await response.blob();
            const blob = new Blob([fileData], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const fileURL = window.URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = fileURL;
            downloadLink.download = fileName;
            downloadLink.click();
            window.URL.revokeObjectURL(fileURL);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="small"
                className="text-white mb-2 mb-md-0 ml-md-3"
                style={{ backgroundColor: "green", padding: "0px 20px", borderRadius: "0px" }}
                onClick={handleDownloadFile}
                startIcon={<FontAwesomeIcon icon={faFileDownload} />}
            >
                Demo File
            </Button>
        </>

    );
};

export default DownloadButton;
