import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CreateDiscount } from "services/apiService";

const DiscountCreate: React.FC = () => {
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  const [DiscountForm, setDiscountForm] = useState({
    name: "",
    slug: "",
    percentage: "",
    from_date: "",
    to_date: "",
    status: "",
    is_active: 1,
    image_path: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    slug: "",
    percentage: "",
    from_date: "",
    to_date: "",
    image_path: "",
  });

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };
    setDiscountForm((prevData) => ({
      ...prevData,
      [id]: id === "name" ? value.toUpperCase() : value,
      slug: id === "name" ? generateSlug(value) : prevData.slug,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    setDiscountForm((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const handleImageSelected = (image: string) => {
    setDiscountForm((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
    setErrors((prevErrors) => ({
      ...prevErrors,
      image_path: "",
    }));
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!DiscountForm.name) newErrors.name = "Discount name is required.";
    if (!DiscountForm.percentage)
      newErrors.percentage = "Percentage is required.";
    if (!DiscountForm.from_date)
      newErrors.from_date = "Start date is required.";
    if (!DiscountForm.to_date) newErrors.to_date = "End date is required.";
    return newErrors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const payload = {
        name: DiscountForm.name,
        slug: DiscountForm.slug,
        percentage: Number(DiscountForm.percentage),
        from_date: DiscountForm.from_date,
        to_date: DiscountForm.to_date,
        status: DiscountForm.status,
        is_active: DiscountForm.is_active,
        image_path: DiscountForm.image_path.toString(),
      };
      const DiscountCreateResponse = await CreateDiscount(payload);
      if (DiscountCreateResponse.status === "success") {
        toast.success("Discount created successfully!");
        setDiscountForm({
          name: "",
          slug: "",
          percentage: "",
          from_date: "",
          to_date: "",
          status: "",
          is_active: 1,
          image_path: "",
        });

        navigate("/admin/discount-list");
      } else {
        toast.error("Discount creation failed. Please try again.");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleDeleteImage = () => {
    setDiscountForm((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Discount Name
              </Typography>
              <TextField
                variant="filled"
                id="name"
                fullWidth
                value={DiscountForm.name}
                onChange={handleTextFieldChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Slug
              </Typography>
              <TextField
                variant="filled"
                id="slug"
                fullWidth
                value={DiscountForm.slug}
                onChange={handleTextFieldChange}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Percentage
              </Typography>
              <TextField
                variant="filled"
                id="percentage"
                fullWidth
                type="number"
                value={DiscountForm.percentage}
                onChange={handleTextFieldChange}
                error={!!errors.percentage}
                helperText={errors.percentage}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Start Date
              </Typography>
              <input
                id="from_date"
                type="date"
                value={DiscountForm.from_date}
                onChange={handleTextFieldChange}
                className="form-control"
                min={today}
                style={{ width: "100%", padding: "8px" }}
              />
              {errors.from_date && (
                <Typography color="error">{errors.from_date}</Typography>
              )}
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                End Date
              </Typography>
              <input
                id="to_date"
                type="date"
                value={DiscountForm.to_date}
                onChange={handleTextFieldChange}
                className="form-control"
                min={today}
                style={{ width: "100%", padding: "8px" }}
              />
              {errors.to_date && (
                <Typography color="error">{errors.to_date}</Typography>
              )}
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Coupon Image
              </Typography>

              <div
                style={{
                  width: "100%",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    border: "1px dashed #ccc",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "10px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {DiscountForm.image_path ? (
                    <>
                      <img
                        src={`${AppUrl}${DiscountForm.image_path}`}
                        alt="Selected"
                        style={{
                          width: "300px",
                          transition: "transform 0.2s",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      />
                      <button
                        style={{
                          backgroundColor: "rgba(255, 0, 0, 0.7)",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          fontSize: "16px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                          transition: "background-color 0.2s",
                        }}
                        onClick={handleDeleteImage}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </>
                  ) : (
                    <p style={{ color: "orange" }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      &nbsp; &nbsp; No image selected. Please upload an image.
                    </p>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleOpenModal}
                    style={{
                      padding: "5px 20px",
                      fontSize: "13px",
                      borderRadius: "0px",
                      border: "none",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      cursor: "pointer",
                      marginTop: "10px",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                    Thumbnail Image
                  </Button>
                </div>
              </div>
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Discount Status
              </Typography>
              <Toggle
                checked={DiscountForm.is_active === 1}
                onChange={(checked) => handleToggleChange(checked, "is_active")}
              />
            </Box>
            <Box textAlign="end" mt={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp; Create Discount
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default DiscountCreate;
