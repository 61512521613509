import React, { useEffect, useState } from "react";
import Toggle from "../../../common/Toggle";
import { TextField, Button } from "@mui/material";
import Select from "react-select";
import {
  BrandData,
  CategoriesData,
  DiscountData,
  ProductCreateformProp,
  ProductFormData,
  TaxData,
} from "types";
import {
  BrandDropDown,
  CategoriesDropDown,
  DiscountDropDown,
  ProductCreate,
  TaxDropDown,
} from "services/apiService";
import ImageUploaderModal from "common/ImageUploaderModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faCircleExclamation,
  faCircleXmark,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import ReusableEditor from "common/ReusableCKEditor";

const ProductCreateForm: React.FC<ProductCreateformProp> = ({ ProductKey }) => {
  const [productFormData, setProductFormData] = useState<ProductFormData>({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    price: "",
    discount: "",
    tax: "",
    category: "",
    brand: "",
    slug: "",
    is_have_variant: 1,
    unit: 1,
    weight: 1,
    returning_date: "",
    is_active: 1,
    is_pre_order: 0,
  });
  const [showInput, setShowInput] = useState(false);
  const [selectedImages, setselectedImages] = useState(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [BrandList, setBrandList] = useState<BrandData[]>([]);
  const [CategoryList, setCategoryList] = useState<CategoriesData[]>([]);
  const [taxList, setTaxList] = useState<TaxData[]>([]);
  const [discountList, setDiscountList] = useState<DiscountData[]>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = async (): Promise<void> => {
    try {
      const [brandResponse, categoryResponse, TaxResponse, DiscountResponse] =
        await Promise.all([
          BrandDropDown(),
          CategoriesDropDown(),
          TaxDropDown(),
          DiscountDropDown(),
        ]);
      setBrandList(brandResponse.data as BrandData[]);
      setCategoryList(categoryResponse.data as CategoriesData[]);
      setDiscountList(DiscountResponse.data as DiscountData[]);
      setTaxList(TaxResponse.data as TaxData[]);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };

  const handleImageSelected = (image: string) => {
    setselectedImages(image);
    setModalOpen(false);
  };
  const handleButtonClick = () => {
    setShowInput(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | any,
    field?: keyof ProductFormData | string
  ) => {
    if (e?.target) {
      if (e.target.type === "checkbox") {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;

        const fieldToUpdate =
          e.target.id === "toggle-active" ? "is_active" : "is_pre_order";

        setProductFormData((prevData) => ({
          ...prevData,
          [fieldToUpdate]: activeValue,
          quantity:
            fieldToUpdate === "is_pre_order" && isChecked
              ? "1"
              : prevData.quantity,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldToUpdate]: "",
        }));

        return;
      }
      const { id, value } = e.target;
      let updatedValue = value;
      if (id === "product_name") {
        const generateSlug = (name: string) => {
          return name
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, "")
            .replace(/\s+/g, "-");
        };
        const capitalizeWords = (text: string) => {
          return text
            .replace(/([^\w\s])/gi, (match) => match.replace(/\s+/g, ""))
            .replace(
              /\b\w+\b/g,
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            );
        };
        const transformedValue = capitalizeWords(value);
        updatedValue = transformedValue;
        const slug = generateSlug(transformedValue);
        setProductFormData((prevData) => ({
          ...prevData,
          [id]: updatedValue,
          slug,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        const numberFields = [
          "quantity",
          "minimum_stock_warning",
          "price",
          "tax",
          "unit",
          "weight",
          "returning_date",
        ];

        if (numberFields.includes(id)) {
          const parsedValue = parseFloat(value);
          if (!isNaN(parsedValue) && value.trim() !== "") {
            updatedValue = parsedValue.toString();
          } else {
            updatedValue = "";
          }
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
        setProductFormData((prevData) => ({
          ...prevData,
          [id]: updatedValue,
        }));
      }
    } else if (field) {
      const value = e ? e.value : "";
      setProductFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }
  };

  const HandleSubmit = async (event: any) => {
    event.preventDefault();
    setErrors({});
    try {
      const requiredFields = [
        "product_name",
        "quantity",
        "price",
        "category",
        "brand",
        "tax",
        "returning_date",
        "minimum_stock_warning",
      ];
      const newErrors: Record<string, string> = {};
      const emptyFields = requiredFields?.filter(
        (field) => !productFormData[field]
      );
      emptyFields.forEach((field) => {
        newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
      });
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
      const payload = {
        product_name: productFormData.product_name,
        description: productFormData.description,
        specification: productFormData.specification,
        sku: productFormData.sku,
        quantity: Number(productFormData.quantity),
        minimum_stock_warning: Number(productFormData.minimum_stock_warning),
        price: Number(String(productFormData.price).replace(/[.,]/g, "")),
        discount: Number(productFormData.discount),
        tax: productFormData.tax,
        category: Number(productFormData.category),
        brand: productFormData.brand,
        slug: productFormData.slug,
        is_have_variant: productFormData.is_have_variant,
        unit: productFormData.unit,
        weight: productFormData.weight,
        returning_date: Number(productFormData.returning_date),
        is_active: productFormData.is_active,
        is_pre_order: productFormData.is_pre_order,
        image_path: selectedImages.length > 0 ? selectedImages[0] : "",
      };
      const ProductCreateResponse = await ProductCreate(payload);
      if (ProductCreateResponse.status_code === 200) {
        ProductKey(ProductCreateResponse.data);
        toast.success("Product created successfully!");
      } else {
        toast.error(" Creation Failed");
      }
    } catch (error) {
      toast.error("Product Creation Failed !");
    }
  };

  const handleDeleteImage = () => {
    setselectedImages(null);
  };
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <form onSubmit={HandleSubmit}>
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }}>
            <TextField
              id="product_name"
              label="Product Name*"
              variant="outlined"
              placeholder="Type here"
              value={productFormData.product_name}
              onChange={handleChange}
              error={Boolean(errors.product_name)}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
            {errors.product_name && (
              <div
                className="error-text"
                style={{ color: "red", marginTop: "4px" }}
              >
                {errors.product_name}
              </div>
            )}
          </div>
          <div
            style={{ width: "200px", display: "flex", alignItems: "center" }}
          >
            {!showInput ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleButtonClick}
                  fullWidth
                  style={{
                    height: "56px",
                  }}
                >
                  Add SKU
                </Button>
              </>
            ) : (
              <TextField
                id="sku"
                label="SKU"
                variant="outlined"
                placeholder="Type here"
                value={productFormData.sku}
                onChange={handleChange}
                fullWidth
                helperText="Please provide a unique name"
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
                style={{
                  height: "56px",
                }}
              />
            )}
          </div>
        </div>

        <div className="mt-4">
          <h6 style={{ color: "rgb(0, 123, 255)" }}>
            <strong>Description</strong>
          </h6>
          <ReusableEditor
            value={productFormData.description}
            onChange={(value) => {
              setProductFormData((prevData) => ({
                ...prevData,
                description: value,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                description: "",
              }));
            }}
          />
          <br />
          <h6 style={{ color: "rgb(0, 123, 255)" }}>
            <strong>Specification</strong>
          </h6>
          <ReusableEditor
            value={productFormData.specification}
            onChange={(value) => {
              setProductFormData((prevData) => ({
                ...prevData,
                specification: value,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                specification: "",
              }));
            }}
          />
        </div>

        <div className="row g-3 mt-2">
          <div className="col-md-3">
            <label htmlFor="brand" className="form-label">
              Brand
            </label>
            <Select
              id="brand"
              options={BrandList?.map((brand) => ({
                value: brand.id,
                label: brand.brand_name.toUpperCase(),
              }))}
              value={
                BrandList?.find((brand) => brand.id === productFormData.brand)
                  ? {
                      value: productFormData.brand,
                      label: BrandList?.find(
                        (brand) => brand.id === productFormData.brand
                      )?.brand_name.toUpperCase(),
                    }
                  : null
              }
              onChange={(selectedOption) =>
                handleChange(selectedOption, "brand")
              }
              placeholder="Select brand"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />
            {errors.brand && (
              <div className="error-text" style={{ color: "red" }}>
                {errors.brand}
              </div>
            )}
          </div>

          <div className="col-md-3">
            <label htmlFor="category" className="form-label">
              Category
            </label>
            <Select
              id="category"
              options={CategoryList?.filter(
                (category) => category.parent_id === 0
              )?.map((parent) => ({
                label: parent.category_name.toUpperCase(),
                options: CategoryList?.filter(
                  (subCategory) => subCategory.parent_id === parent.id
                )?.map((subCategory) => ({
                  value: subCategory.id.toString(),
                  label: subCategory.category_name.toUpperCase(),
                })),
              }))}
              value={
                CategoryList?.find(
                  (category) =>
                    category.id.toString() ===
                    productFormData.category?.toString()
                )
                  ? {
                      value: productFormData.category.toString(),
                      label: CategoryList?.find(
                        (category) =>
                          category.id.toString() ===
                          productFormData.category.toString()
                      )?.category_name.toUpperCase(),
                    }
                  : null
              }
              onChange={(selectedOption) =>
                handleChange(selectedOption, "category")
              }
              placeholder="Select category"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />
            {errors.category && (
              <div className="error-text" style={{ color: "red" }}>
                {errors.category}
              </div>
            )}
          </div>

          <div className="col-md-3">
            <label htmlFor="discount" className="form-label">
              Discount
            </label>
            <Select
              id="discount"
              options={discountList?.map((discount) => ({
                value: discount.id.toString(),
                label: discount.name,
              }))}
              value={
                discountList?.find(
                  (discount) => discount.id === Number(productFormData.discount)
                )
                  ? {
                      value: productFormData.discount,
                      label: discountList?.find(
                        (discount) =>
                          discount.id === Number(productFormData.discount)
                      )?.name,
                    }
                  : null
              }
              onChange={(selectedOption) =>
                handleChange(selectedOption, "discount")
              }
              placeholder="Select discount"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="tax" className="form-label">
              Tax
            </label>
            <Select
              id="tax"
              options={taxList?.map((tax) => ({
                value: tax.id,
                label: tax.name,
              }))}
              value={
                taxList?.find((tax) => tax.id === Number(productFormData.tax))
                  ? {
                      value: Number(productFormData.tax),
                      label: taxList?.find(
                        (tax) => tax.id === Number(productFormData.tax)
                      )?.name,
                    }
                  : null
              }
              onChange={(selectedOption) => handleChange(selectedOption, "tax")}
              placeholder="Select tax"
              isClearable
              className="react-select-container"
              classNamePrefix="react-select"
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />
            {errors.tax && (
              <div className="error-text" style={{ color: "red" }}>
                {errors.tax}
              </div>
            )}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-3">
            <TextField
              id="minimum_stock_warning"
              label="Minimum Stock Warning*"
              variant="outlined"
              helperText="Set the stock quantity at which the system should notify you when the product is running low."
              placeholder="Type here"
              value={productFormData.minimum_stock_warning}
              onChange={(e) => {
                let value = e.target.value;
                value = value.replace(/[^0-9]/g, "");
                const numericValue = parseInt(value, 10);
                if (numericValue > 50) {
                  value = "50";
                } else if (numericValue < 1 && value !== "") {
                  value = "1";
                }
                setProductFormData((prev) => ({
                  ...prev,
                  minimum_stock_warning: value,
                }));
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  minimum_stock_warning: "",
                }));
              }}
              error={Boolean(errors.minimum_stock_warning)}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />

            {errors.minimum_stock_warning && (
              <div className="error-text">{errors.minimum_stock_warning}</div>
            )}
          </div>
          <div className="col-md-3">
            <TextField
              id="price"
              label="Price"
              variant="outlined"
              placeholder="Type here"
              error={Boolean(errors.price)}
              value={productFormData.price}
              onChange={(e) => {
                let value = e.target.value;
                value = value.replace(/[^0-9]/g, "");
                const maxAmount = 1000000;
                const numericValue = parseInt(value, 10);
                if (numericValue > maxAmount) {
                  value = maxAmount.toString();
                }
                value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                setProductFormData((prev) => ({
                  ...prev,
                  price: value,
                }));
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  price: "",
                }));
              }}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />

            {errors.price && <div className="error-text">{errors.price}</div>}
          </div>

          <div className="col-md-3">
            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              placeholder="Type here"
              value={productFormData.quantity}
              onChange={handleChange}
              error={Boolean(errors.quantity)}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
            {errors.quantity && (
              <div className="error-text">{errors.quantity}</div>
            )}
          </div>
          <div className="col-md-3">
            <TextField
              id="returning_date"
              label="Max Returning date"
              variant="outlined"
              placeholder="Type here"
              value={productFormData.returning_date}
              onChange={handleChange}
              error={Boolean(errors.returning_date)}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
              inputProps={{
                maxLength: 2,
              }}
            />
            {errors.quantity && (
              <div className="error-text">{errors.returning_date}</div>
            )}
          </div>
        </div>
        <div className="row container-fluid mt-5">
          <div
            style={{
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                border: "1px dashed #ccc",
                display: "flex",
                justifyContent: "center",
                padding: "20px",
                backgroundColor: "#f9f9f9",
                position: "relative",
              }}
            >
              {selectedImages ? (
                <>
                  <img
                    src={`${AppUrl}${selectedImages}`}
                    alt="Selected"
                    style={{
                      borderRadius: "10px",
                      transition: "transform 0.2s",
                      cursor: "pointer",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      border: "none",
                      fontSize: "30px",
                    }}
                    onClick={handleDeleteImage}
                  >
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </Button>
                </>
              ) : (
                <p style={{ color: "orange" }}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  &nbsp; &nbsp; No image selected. Please upload an image.
                </p>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleOpenModal}
                variant="contained"
                color="primary"
              >
                <FontAwesomeIcon
                  style={{ marginRight: "10px" }}
                  icon={faImage}
                />
                Thumnail Image
              </Button>
            </div>
          </div>
        </div>

        <div
          className="row justify-content-center  mt-4"
          style={{ marginBottom: "20px" }}
        >
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="Status" className="form-label text-muted">
                Status
              </label>
              <Toggle
                checked={productFormData.is_active === 1}
                onChange={(newValue) =>
                  handleChange({ target: { value: newValue } }, "is_active")
                }
              />
              <small className="form-text text-muted">
                Enable or disable for product status
              </small>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="Pre-Order" className="form-label text-muted">
                Pre-Order
              </label>
              <Toggle
                checked={productFormData.is_pre_order === 1}
                onChange={handleChange}
              />
              <small className="form-text text-muted">
                Enable or disable for pre-order
              </small>
            </div>
          </div>
        </div>

        <div className="row mt-4 ">
          <div className=" d-flex justify-content-center">
            <Button
              variant="contained"
              color="primary"
              onClick={HandleSubmit}
              sx={{ borderRadius: "0px", padding: "10px", width: "100%" }}
            >
              Create Product
              <FontAwesomeIcon
                icon={faAnglesRight}
                style={{ marginLeft: "5px" }}
              />
            </Button>
          </div>
        </div>
      </form>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default ProductCreateForm;
