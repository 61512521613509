import React, { useEffect, useState, useCallback } from "react";
import "../styles/NewModel.css";
import { ImageUploaderModalProps } from "types";
import { getMediaList } from "services/apiService";
import ImageUpload from "./ImageUpload";

const ResponsiveImageUploader: React.FC<ImageUploaderModalProps> = ({
    isOpen,
    onClose,
    onImageSelect,
    allowMultiple = false,
}) => {
    const [imageGallery, setImageGallery] = useState([]);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);
    const baseUrl = process.env.REACT_APP_IMAGE_URL || "";

    const fetchAlImages = useCallback(async () => {
        const response = await getMediaList();
        setImageGallery(response?.data);
    }, []);

    useEffect(() => {
        if (isOpen) {
            fetchAlImages();
        }
    }, [isOpen, fetchAlImages]);


    const handleImageUploadSuccess = useCallback(() => {
        fetchAlImages();
    }, [fetchAlImages]);

    const handleCheckboxChange = (imgName: string) => {
        setSelectedImages((prevSelected) => {
            if (allowMultiple) {
                if (prevSelected.includes(imgName)) {
                    return prevSelected.filter((name) => name !== imgName);
                } else {
                    return [...prevSelected, imgName];
                }
            } else {
                if (prevSelected.includes(imgName)) {
                    return prevSelected;
                } else {
                    onImageSelect([imgName]);
                    onClose();
                    return [imgName];
                }
            }
        });
    };

    const handleTabChange = (index: number) => {
        setActiveTab(index);
    };

    const handleConfirmSelection = () => {
        onImageSelect(selectedImages);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="modal-tabs">
                    <button
                        className={`tab ${activeTab === 0 ? "active" : ""}`}
                        onClick={() => handleTabChange(0)}
                    >
                        Gallery
                    </button>
                    <button
                        className={`tab ${activeTab === 1 ? "active" : ""}`}
                        onClick={() => handleTabChange(1)}
                    >
                        Uploader
                    </button>
                </div>
                <div className="modal-content">
                    {activeTab === 0 && (
                        <div className="tab-content">
                            <div className="image-grid">
                                {imageGallery.map((img, index) => (
                                    <div key={index} className="image-item">
                                        <img
                                            src={`${baseUrl}${img}`}
                                            alt={`image-${index}`}
                                            className="image-thumbnail"
                                        />
                                        <label className="checkbox-label">
                                            <input
                                                type="checkbox"
                                                onChange={() => handleCheckboxChange(img)}
                                                checked={selectedImages.includes(img)}
                                            />
                                        </label>
                                    </div>
                                ))}
                            </div>
                            {allowMultiple && (
                                <div className="confirm-btn d-flex justify-content-center">
                                    <button
                                        onClick={handleConfirmSelection}
                                        style={{
                                            backgroundColor: '#28a745',
                                            color: '#ffffff',
                                            border: 'none',
                                            borderRadius: '5px',
                                            padding: '2px',
                                            fontSize: '16px',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s',
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#218838'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#28a745'}
                                    >
                                        Confirm Selection
                                    </button>
                                </div>

                            )}
                        </div>
                    )}
                    {activeTab === 1 && (
                        <div className="tab-content">
                            <ImageUpload onUploadSuccess={handleImageUploadSuccess} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResponsiveImageUploader;
