import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCompaginDropDownValues } from "../../services/apiService";

export const fetchCampaignDropDown = createAsyncThunk(
  "jsondata/fetchCampaignDropDown",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCompaginDropDownValues();
      return response.data;
    } catch (error) {}
  }
);
const initialState = {
  campaignDropDownData: [],
  error: null,
  loading: false,
};

export const campaignDropDownDataList = createSlice({
  name: "campaignDropDownData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignDropDown.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaignDropDown.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignDropDownData = action.payload;
      })
      .addCase(fetchCampaignDropDown.rejected, (state) => {
        state.loading = false;
        state.error = "Error From Slice";
      });
  },
});
export default campaignDropDownDataList.reducer
