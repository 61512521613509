import React, { FC } from "react";
import "../styles/product.css";
import {
  BrandsListData,
  CategoriesListData,
  CouponList,
  Page,
  ProductData,
  TaxListData,
} from "types";
import NoDataFound from "../Images/9264885-removebg.png";

interface CommonTableType {
  data:
    | ProductData[]
    | Page[]
    | BrandsListData[]
    | CategoriesListData[]
    | TaxListData[]
    | CouponList[];
  isLoading: boolean;
  headers: String[];
  renderRow: (product: any, index: number) => JSX.Element;
}

const CommonTable: FC<CommonTableType> = ({
  data,
  isLoading,
  headers,
  renderRow,
}) => {
  if (!data || data?.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
        <img src={NoDataFound} alt="" width={400} height={300} />
      </div>
    );
  }

  return (
    <>
      <div className="product_main_third ">
        <div className="product_main_third_table">
          <table className="table">
            <thead>
              <tr>
                {headers?.map((header, index) => (
                  <th className="justify-content-center" key={index}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{data?.map((item, index) => renderRow(item, index))}</tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CommonTable;
