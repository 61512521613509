import React from "react";

interface ExcelCloneInterface {
  heading: string;
  SubHeading: string[];
}


const ExcelClone: React.FC<ExcelCloneInterface> = ({ heading, SubHeading }) => {
  const additionalInputs = new Array(210).fill("");
  const allInputs = [...SubHeading, ...additionalInputs];

  return (
    <div className="">
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>MS Excel Clone</title>
        <style>
          {`
            * {
              box-sizing: border-box;
            }

            body {
              background: #fff;
              font-family: "Noto Sans", sans-serif;
              color: #444;
              font-size: 14px;
            }

            aside.context {
              text-align: center;
              color: #333;
              line-height: 1.7;
            }

            aside.context a {
             text-decoration: none;
             color: #333;
             padding: 3px 0;
              border-bottom: 1px dashed;
             }
aside.context a:hover {
  border-bottom: 1px solid;
}
aside.context .explanation {
  max-width: 700px;
  margin: 6em auto 0;
}

footer {
  text-align: center;
  margin: 4em auto;
  width: 100%;
}
footer a {
  text-decoration: none;
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: transparent;
  border: 1px dashed #333;
  color: #333;
  margin: 5px;
}
footer a:hover {
  background: rgba(255, 255, 255, 0.1);
}
footer a .icons {
  margin-top: 12px;
  display: inline-block;
  font-size: 20px;
}

.main-content {
  position: relative; /* Needed to position the overlay */
  max-height: 700px; /* Set a max height for the content div, adjust as needed */
  overflow: hidden;  /* Hide content that goes beyond the max height */
}

/* Fading effect at the bottom */
.main-content::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px; /* Height of the fade effect, adjust as needed */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff); /* Adjust colors */
  pointer-events: none; /* Ensures the overlay doesn’t interfere with content interaction */
}


.title {
  background: #217346;
  text-align: center;
  display: grid;
  place-content: center;
  color: #fff;
}

.menu-bar {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(10, max-content);
  padding: 10px 10px;
  grid-gap: 30px;
  background: #f3f2f1;
  color : black
}
.menu-bar div:nth-child(2) span {
  display: inline-block;
  position: relative;
  font-weight: 700;
}

.cell-content {
  border: 1px solid #e6e6e6;
  background: #e6e6e6;
  display: grid;
  padding: 10px;
  height : 49px;
  grid-template-columns: 50px auto;
}
.cell-content div {
  border: 1px solid #cdcdcd;
  background: #fff;
  display: flex;
  align-items: center;
}
.cell-content div:nth-child(1) {
  justify-content: center;
  color: #999;
  font: italic 700 18px "Merriweather", serif;
  border-right: none;
}

.cells {
  position: relative;
  display: grid;
  grid-template-columns: 40px repeat(14, calc((100% - 50px) / 14));
  grid-gap: 1px;
  background: #cdcdcd;
  grid-auto-flow: dense;
  max-width: 100%;
  overflow: hidden;
}
.cells__spacer {
  background: #e6e6e6;
  position: relative;
}
.cells__spacer:after {
  content: "";
  position: absolute;
  right: 4px;
  bottom: 4px;
  height: 80%;
  width: 100%;
  background: linear-gradient(135deg, transparent 30px, #bbb 30px, #bbb 55px, transparent 55px);
}
.cells__alphabet {
  background: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cells__number {
  background: #e6e6e6;
  grid-column: 1/span 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cells__input {
  border: none;
  padding: 0 6px;
}
.cells input, .cells button {
  border: none;
  background: #fff;
  padding: 0 6px;
  font-family: "Noto Sans", sans-serif;
}

.input__explanation {
  grid-column: 3/span 2;
  grid-row: 15;
}
.input__see-more {
  grid-column: 5;
  grid-row: 15;
  text-align: left;
  padding: 6px;
  background: #fff;
}
.input__sm-1, .input__sm-2, .input__sm-3 {
  text-align: center;
  padding: 6px;
  grid-row: 15;
  background: #fff;
}
.input__sm-1 {
  grid-column: 8;
}
.input__sm-2 {
  grid-column: 9;
}
.input__sm-3 {
  grid-column: 10;
}

.icon-bar {
  background: #f3f2f1;
  position: relative;
  display: grid;
  padding: 10px 15px;
  grid-template-columns: repeat(7, max-content);
  grid-auto-flow: dense;
}
.icon-bar > div {
  display: grid;
  grid-template-rows: repeat(2, 30px) 30px;
  border-right: 1px solid #cdcdcd;
 grid-gap: 12px;
}
.icon-bar__name {
  font-size: 12px;
  text-align: center;
  align-self: end;
  margin-bottom: 3px;
}
.icon-bar .icon-desc {
  margin-top: 5px;
  line-height: 1.15;
  font-size: 13px;
}
.icon-bar .icon {
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/567707/spriteExcel.png);
}

.icon-bar__clipboard {
  grid-template-columns: 50px 30px;
  padding-right: 10px;
}
.icon-bar__clipboard .icon-bar__name {
  grid-column: 1/span 2;
}
.icon-bar__clipboard .icon-paste {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-row: 1/span 2;
}
.icon-bar__clipboard .icon-paste .icon {
  background-position: -30px -60px;
  height: 45px;
  width: 100%;
}
.icon-bar__clipboard .icon-paste span {
  margin-top: 5px;
  display: block;
}
.icon-bar__clipboard .icon-cut {
  background-position: 0 0;
}
.icon-bar__clipboard .icon-copy {
  background-position: -30px 0;
}

.icon-bar__font {
  padding: 0 10px;
  grid-template-columns: repeat(3, 30px) 40px repeat(2, 30px);
  justify-content: space-around;
}
.icon-bar__font .icon-bar__name {
  grid-column: 1/span 5;
}
.icon-bar__font select {
  height: 25px;
}
.icon-bar__font select:nth-child(1) {
  grid-column: 1/span 4;
}
.icon-bar__font select:nth-child(1) option {
  font-family: var(--font);
}
.icon-bar__font select:nth-child(2) {
  margin-left: -6px;
  grid-column: 5/span 2;
}
.icon-bar__font .icon-bold {
  background-position: -30px -150px;
}
.icon-bar__font .icon-italic {
  background-position: -60px -150px;
}
.icon-bar__font .icon-underline {
  background-position: -90px -150px;
  border-right: 1px solid #cdcdcd;
  margin-right: -2px;
}
.icon-bar__font .icon-border {
  background-position: -60px 0;
  margin: 0 5px;
}
.icon-bar__font .icon-fill {
  background-position: -90px 0;
  border-left: 1px solid #cdcdcd;
  margin-left: -2px;
}
.icon-bar__font .icon-color {
  background-position: -120px 0;
}

.icon-bar__alignment {
  padding: 0 10px;
  grid-template-columns: repeat(5, 30px) 160px;
}
.icon-bar__alignment .icon-bar__name {
  grid-column: 1/span 6;
}
.icon-bar__alignment .icon-alignt {
  background-position: -150px 0;
}
.icon-bar__alignment .icon-alignm {
  background-position: -180px 0;
}
.icon-bar__alignment .icon-alignb {
  background-position: -210px 0;
}
.icon-bar__alignment .icon-orientation {
  background-position: -240px 0;
  border-left: 1px solid #cdcdcd;
}
.icon-bar__alignment .icon-alignl {
  background-position: 0 -30px;
  grid-column: 1;
}
.icon-bar__alignment .icon-alignc {
  background-position: -30px -30px;
}
.icon-bar__alignment .icon-alignr {
  background-position: -60px -30px;
}
.icon-bar__alignment .icon-indentinc {
  background-position: -90px -30px;
  border-left: 1px solid #cdcdcd;
}
.icon-bar__alignment .icon-indentdec {
  background-position: -120px -30px;
}
.icon-bar__alignment .wrap-text, .icon-bar__alignment .merge-center {
  grid-column: 6;
  border-left: 1px solid #cdcdcd;
  padding-left: 5px;
  display: flex;
  align-items: center;
}
.icon-bar__alignment .wrap-text .icon, .icon-bar__alignment .merge-center .icon {
  width: 30px;
  height: 30px;
}
.icon-bar__alignment .wrap-text span, .icon-bar__alignment .merge-center span {
  display: block;
  padding-left: 5px;
}
.icon-bar__alignment .wrap-text {
  grid-row: 1;
}
.icon-bar__alignment .wrap-text .icon {
  background-position: -270px 0;
}
.icon-bar__alignment .merge-center .icon {
  background-position: -150px -30px;
}

.icon-bar__number {
  grid-template-columns: repeat(5, 30px);
  padding: 0 10px;
}
.icon-bar__number select {
  grid-column: span 5;
  height: 25px;
}
.icon-bar__number .icon-acc {
  background-position: -180px -30px;
}
.icon-bar__number .icon-percent {
  background-position: -210px -30px;
}
.icon-bar__number .icon-comma {
  background-position: -240px -30px;
}
.icon-bar__number .icon-decimalinc {
  background-position: -270px -30px;
  border-left: 1px solid #cdcdcd;
}
.icon-bar__number .icon-decimaldec {
  background-position: 0 -60px;
}
.icon-bar__number .icon-bar__name {
  grid-column: span 5;
}

.icon-bar__styles {
  grid-template-columns: 80px 70px 60px;
  padding: 0 10px;
  text-align: center;
}
.icon-bar__styles .icon-bar__name {
  grid-column: span 3;
}
.icon-bar__styles .icon {
  width: 45px;
  height: 45px;
  margin: -8px auto 5px;
}
.icon-bar__styles .conditional .icon {
  background-position: -75px -60px;
}
.icon-bar__styles .format-table .icon {
  background-position: -120px -60px;
}
.icon-bar__styles .cell-style .icon {
  background-position: -165px -60px;
}

.icon-bar__cells {
  grid-template-columns: repeat(3, 50px);
  padding: 0 10px;
  text-align: center;
}
.icon-bar__cells .icon-bar__name {
  grid-column: span 3;
}
.icon-bar__cells .icon {
  width: 45px;
  height: 45px;
  margin: -8px auto 5px;
}
.icon-bar__cells .cell-insert .icon {
  background-position: -210px -60px;
}
.icon-bar__cells .cell-delete .icon {
  background-position: -255px -60px;
}
.icon-bar__cells .cell-format .icon {
  background-position: -30px -105px;
}  `}
        </style>
      </head>
      < main>
        <div className="main-content">
          <div className="title">{heading}  - Excel sheet</div>
          <div className="menu-bar">
            <div><span>File</span></div>
            <div><span>Home</span></div>
            <div><span>Insert</span></div>
            <div><span>Page Layout</span></div>
            <div><span>Formulas</span></div>
            <div><span>Data</span></div>
            <div><span>Review</span></div>
            <div><span>View</span></div>
            <div><span>Help</span></div>
          </div>

          <div className="icon-bar">
            <div className="icon-bar__clipboard">
              <div className="icon-paste">
                <div className="icon"></div><span>Paste</span>
              </div>
              <div className="icon icon-cut"></div>
              <div className="icon icon-copy"></div>
              <div className="icon-bar__name">Clipboard</div>
            </div>

            <div className="icon-bar__font">
              <select className="font-name" defaultValue="Noto Sans" style={{ border: "1px solid grey" }}>
                <option value="Noto Sans">Noto Sans</option>
                <option value="Arial" style={{ font: "Arial" }}>Arial</option>
                <option value="Calibri" style={{ font: "Calibri" }}>Calibri</option>
                <option value="Comic Sans MS" style={{ font: "Comic Sans MS" }}>Comic Sans MS</option>
                <option value="Courier New" style={{ font: "Courier New" }}>Courier New</option>
                <option value="Impact" style={{ font: "Impact" }}>Impact</option>
                <option value="Georgia" style={{ font: "Georgia" }}>Georgia</option>
                <option value="Garamond" style={{ font: "Garamond" }}>Garamond</option>
                <option value="Lato" style={{ font: "Lato" }}>Lato</option>
                <option value="Open Sans" style={{ font: "Open Sans" }}>Open Sans</option>
                <option value="Palatino" style={{ font: "Palatino" }}>Palatino</option>
                <option value="Verdana" style={{ font: "Verdana" }}>Verdana</option>
              </select>
              <select className="font-size" defaultValue="14" style={{ border: "1px solid grey" }}>
                <option value="14">14</option>
                <option value="16">16</option>
                <option value="18">18</option>
                <option value="20">20</option>
                <option value="22">22</option>
                <option value="24">24</option>
                <option value="26">26</option>
                <option value="28">28</option>
                <option value="36">36</option>
                <option value="48">48</option>
                <option value="72">72</option>
              </select>
              <div className="icon icon-bold"></div>
              <div className="icon icon-italic"></div>
              <div className="icon icon-underline"></div>
              <div className="icon icon-border"></div>
              <div className="icon icon-fill"></div>
              <div className="icon icon-color"></div>
              <div className="icon-bar__name">Font</div>
            </div>
            <div className="icon-bar__alignment">
              <div className="icon icon-alignt"></div>
              <div className="icon icon-alignm"></div>
              <div className="icon icon-alignb"></div>
              <div className="icon icon-orientation"></div>
              <div className="icon icon-alignl"></div>
              <div className="icon icon-alignc"></div>
              <div className="icon icon-alignr"></div>
              <div className="icon icon-indentinc"></div>
              <div className="icon icon-indentdec"></div>
              <div className="wrap-text">
                <div className="icon"></div><span>Wrap Text</span>
              </div>
              <div className="merge-center">
                <div className="icon"></div><span>Merge & Center</span>
              </div>
              <div className="icon-bar__name">Alignment</div>
            </div>
            <div className="icon-bar__number">
              <select className="number-format">
                <option value="General">General</option>
                <option value="Number">Number</option>
                <option value="Currency">Currency</option>
                <option value="Accounting">Accounting</option>
                <option value="Short Date">Short Date</option>
                <option value="Long Date">Long Date</option>
                <option value="Time">Time</option>
                <option value="Percentage">Percentage</option>
              </select>
              <div className="icon icon-acc"></div>
              <div className="icon icon-percent"></div>
              <div className="icon icon-comma"></div>
              <div className="icon icon-decimalinc"></div>
              <div className="icon icon-decimaldec"></div>
              <div className="icon-bar__name">Number</div>
            </div>
            <div className="icon-bar__styles">
              <div className="conditional">
                <div className="icon"></div>
              </div>
              <div className="format-table">
                <div className="icon"></div>
              </div>
              <div className="cell-style">
                <div className="icon"></div>
              </div>
              <div className="icon-desc">Conditional Formatting</div>
              <div className="icon-desc">Format as Table</div>
              <div className="icon-desc">Cell Styles</div>
              <div className="icon-bar__name">Styles</div>
            </div>
            <div className="icon-bar__cells">
              <div className="cell-insert">
                <div className="icon"></div>
              </div>
              <div className="cell-delete">
                <div className="icon"></div>
              </div>
              <div className="cell-format">
                <div className="icon"></div>
              </div>
              <div className="icon-desc">Insert</div>
              <div className="icon-desc">Delete</div>
              <div className="icon-desc">Format</div>
              <div className="icon-bar__name">Cells</div>
            </div>
          </div>
          <div className="cell-content">
            <div>fx</div>
            <div></div>
          </div>
          <div className="cells">
            <div className="cells__spacer"></div>
            <div className="cells__alphabet">A</div>
            <div className="cells__alphabet">B</div>
            <div className="cells__alphabet">C</div>
            <div className="cells__alphabet">D</div>
            <div className="cells__alphabet">E</div>
            <div className="cells__alphabet">F</div>
            <div className="cells__alphabet">G</div>
            <div className="cells__alphabet">H</div>
            <div className="cells__alphabet">I</div>
            <div className="cells__alphabet">J</div>
            <div className="cells__alphabet">K</div>
            <div className="cells__alphabet">L</div>
            <div className="cells__alphabet">M</div>
            <div className="cells__alphabet">N</div>
            <div className="cells__number">1</div>
            <div className="cells__number">2</div>
            <div className="cells__number">3</div>
            <div className="cells__number">4</div>
            <div className="cells__number">5</div>
            <div className="cells__number">6</div>
            <div className="cells__number">7</div>
            <div className="cells__number">8</div>
            <div className="cells__number">9</div>
            <div className="cells__number">10</div>
            <div className="cells__number">11</div>
            <div className="cells__number">12</div>
            <div className="cells__number">13</div>
            <div className="cells__number">14</div>
            <div className="cells__number">15</div>
            {allInputs.map((input, index) => (
              <input
                key={index}
                className="cells__input"
                value={input}
                style={input ? { fontWeight: "800", fontSize: "10px" } : {}}
                readOnly={input !== ""}
              />
            ))}
          </div>
        </div>
      </main >

    </div >


  )
};

export default ExcelClone;
