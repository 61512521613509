import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getClassficationValues } from "../../../services/apiService";
import "../../../styles/DropdownClassification.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, Skeleton } from "@mui/material";
import {
  faCirclePlus,
  faFolderOpen,
  faFolderPlus,
} from "@fortawesome/free-solid-svg-icons";
import no_Image from "../../../Images/No_Image_Available-Table.png";
import { Link } from "react-router-dom";
import ProductClassificationVeiw from "./ProductClassificationVeiw";
import ProductClassificationAdd from "./ProductClassificationAdd";

const ProductClassification: React.FC = () => {
  const [Classificationlist, setClassificationlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [open, setOpen] = useState(false);
  const [openAddProductMap, setOpenAddProductMap] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleModelOpen = (id: number) => {
    setOpen(true);
    setSelectedId(id);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedId(null);
  };

  const handleModelOpenproductMap = (id: number) => {
    setOpenAddProductMap(true);
    setSelectedId(id);
  };

  const handleCloseproductMap = () => {
    setOpenAddProductMap(false);
    setSelectedId(null);
  };

  const [searchClassficationQuery, setSearchClassficationQuery] = useState("");

  useEffect(() => {
    fetchDropdowns();
  }, []);

  const fetchDropdowns = async () => {
    setLoading(true);
    try {
      const campaignResponse = await getClassficationValues();
      setClassificationlist(campaignResponse.data);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      toast.error("Failed to load dropdown data.");
    } finally {
      setLoading(false);
    }
  };

  const filterCampaign = Classificationlist?.filter((classification) =>
    classification.classification_name
      .toLowerCase()
      .includes(searchClassficationQuery.toLowerCase())
  );

  return (
    <>
      <main id="main" className="main p-3">
        <section className="section">
          <div className="row">
            <div>
              <div className="table-container">
                <div className="table-header">
                  <div>
                    <Button
                      component={Link}
                      to="/admin/campaign-create"
                      variant="contained"
                      color="primary"
                      sx={{ borderRadius: "0px" }}
                    >
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{ marginRight: "10px" }}
                      />
                      Create classification
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <span className="product-count" style={{ color: "grey" }}>
                      {loading ? (
                        <Skeleton width={150} height={50} />
                      ) : (
                        `  ${filterCampaign?.length}  Classification `
                      )}
                    </span>
                  </div>

                  <input
                    type="text"
                    className="search-barr"
                    placeholder="Search Campaign..."
                    value={searchClassficationQuery}
                    onChange={(e) =>
                      setSearchClassficationQuery(e.target.value)
                    }
                    disabled={loading}
                  />
                </div>
                <div className="table-scrollable-wrapper">
                  {loading ? (
                    Array.from({ length: 5 }).map((_, index) => (
                      <div key={index} className="skeleton-row">
                        <Skeleton
                          variant="text"
                          width="100%"
                          height={80}
                          style={{ marginBottom: 10 }}
                        />
                      </div>
                    ))
                  ) : (
                    <table className="table-classfiction">
                      <thead>
                        <tr>
                          <th>S.no</th>
                          <th>Image</th>
                          <th>classification Name</th>
                          <th>Mapped Products</th>
                          <th>Add Products</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterCampaign?.map((classification, index) => (
                          <tr key={classification.classification_id}>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                src={`${
                                  classification.image_path
                                    ? AppUrl + classification.image_path
                                    : no_Image
                                }`}
                                width={70}
                                alt={classification.image_path}
                                className="classification-image"
                              />
                            </td>
                            <td>{classification.classification_name}</td>
                            <td>
                              <Button
                                onClick={() =>
                                  handleModelOpen(
                                    classification.classification_id
                                  )
                                }
                              >
                                <Tooltip title="Open Folder">
                                  <FontAwesomeIcon icon={faFolderOpen} />
                                </Tooltip>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() =>
                                  handleModelOpenproductMap(
                                    classification.classification_id
                                  )
                                }
                              >
                                <Tooltip title="Add products">
                                  <FontAwesomeIcon icon={faFolderPlus} />
                                </Tooltip>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ProductClassificationVeiw
        open={open}
        handleClose={handleCloseModal}
        id={selectedId}
      />
      <ProductClassificationAdd
        openAddProductMap={openAddProductMap}
        handleClose={handleCloseproductMap}
        id={selectedId}
      />
    </>
  );
};

export default ProductClassification;
