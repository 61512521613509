import { faCirclePlus, faPen } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { getSellerListData } from "services/apiService";
import DynamicButton from "../../common/DynamicButton";
import CommonTable from "common/CommonTable";
import { Button, Pagination } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SellerEdit from "./SellerEdit";
import loadingImage from "../../assect/loadingSvg/loading.svg";

const SellersList = () => {
  const [sellerSearch, setSellerSearch] = useState<string>("");
  const [loader, setloader] = useState(false);
  const [isOpenBrandEdit, setIsOpenBrandEdit] = useState<boolean>(false);
  const [selectedBrandId, setSelectedBrandId] = useState<number | null>(null);
  const [allSellers, setAllSellers] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(10);
  const headers = [
    "S.no",
    "Seller Name",
    "Email",
    "Phone No",
    "Status",
    "Action",
  ];

  useEffect(() => {
    fetchSellerData();
  }, []);

  const fetchSellerData = async () => {
    setloader(true);
    try {
      const response = await getSellerListData();
      setAllSellers(response.data);
    } catch (error) {}
    setloader(false);
  };

  const handleModelOpen = (id: number) => {
    setSelectedBrandId(id);
    setIsOpenBrandEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenBrandEdit(false);
  };

  const renderRow = (discount: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td className="table_product_name">
          <div className="d-flex flex-column product_name_td">
            <span>{discount?.name}</span>
          </div>
        </td>
        <td>{discount?.email}</td>
        <td>{discount?.phone_no}</td>
        <td className="align-content-center">
          <span
            style={{
              color: discount.is_active === 1 ? "green" : "red",
              backgroundColor: discount.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {discount?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>
        <td className="align-content-center">
          <DynamicButton
            icon={faPen}
            onClick={() => handleModelOpen(discount.seller_id)}
            iconStyle={{ color: "#63E6BE" }}
            style={{
              padding: "0",
              border: "none",
              background: "none",
              cursor: "pointer",
              margin: "2px",
            }}
          />
        </td>
      </tr>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSellerSearch(value);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const filteredSellers = allSellers
    ?.filter((seller: any) =>
      seller.name.toLowerCase().includes(sellerSearch.toLowerCase())
    )
    .slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(allSellers?.length / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  const endIndex = Math.min(startIndex + itemsPerPage, allSellers?.length);

  return (
    <>
      <div className="d-flex justify-content-between  ">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/seller-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Seller
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            placeholder="Search Seller...."
            className="form-control"
            style={{ boxShadow: "none" }}
            value={sellerSearch}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="product-table-content">
        {loader ? (
          <div className="loadingsvg-Image text-center">
            <img src={loadingImage} width={150} height={150} alt="Loading..." />
          </div>
        ) : (
          <CommonTable
            data={filteredSellers}
            isLoading={loader}
            headers={headers}
            renderRow={renderRow}
          />
        )}
      </div>
      <div className="pagination-container">
        <div className="data-info" style={{ color: "grey" }}>
          <span>
            Showing {startIndex + 1}-{endIndex} of {allSellers?.length}
          </span>
        </div>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </div>
      <SellerEdit
        onActionComplete={() => {
          fetchSellerData();
        }}
        open={isOpenBrandEdit}
        handleClose={handleModelClose}
        id={selectedBrandId}
      />
    </>
  );
};

export default SellersList;
