import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPen } from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import DynamicButton from "../../common/DynamicButton";
import { getSettingsGroupList } from "services/apiService";
import SettingsGroupEdit from "./SettingsGroupEdit";

const SettingsGroupList: React.FC = () => {
  const [settingsGroupList, setSettingsGroupList] = useState([]);
  const [isOpenSettingsEdit, setIsOpenSettingsEdit] = useState<boolean>(false);
  const [selectedSettingsId, setSelectedSettingsId] = useState<number | null>(
    null
  );
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const headers = ["Sno", "Settings Group Name", "Status", "Action"];

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await getSettingsGroupList();
      setSettingsGroupList(response.data);
    } catch (error) {
      console.error("Error fetching settings groups:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredSettingsGroupList = settingsGroupList?.filter((group: any) =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderRow = (settingsGroup: any, index: number) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td title={settingsGroup.name}>
        {settingsGroup.name.length > 30
          ? `${settingsGroup.name.slice(0, 30)}...`
          : settingsGroup.name}
      </td>
      <td>
        <span
          style={{
            color: settingsGroup.is_active === 1 ? "green" : "red",
            backgroundColor:
              settingsGroup.is_active === 1 ? "#e0f7e9" : "#f8d7da",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        >
          {settingsGroup.is_active === 1 ? "Active" : "Inactive"}
        </span>
      </td>
      <td>
        <div className="actions-buttons">
          <DynamicButton
            icon={faPen}
            iconStyle={{ color: "#63E6BE" }}
            style={{
              padding: "0",
              border: "none",
              background: "none",
              cursor: "pointer",
              margin: "2px",
            }}
            onClick={() => handleEdit(settingsGroup.id)}
          />
        </div>
      </td>
    </tr>
  );

  const handleEdit = (id: number) => {
    setSelectedSettingsId(id);
    setIsOpenSettingsEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenSettingsEdit(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <Button
          component={Link}
          to="/admin/setting-create"
          variant="contained"
          color="primary"
          sx={{ borderRadius: "0px" }}
        >
          <FontAwesomeIcon
            icon={faCirclePlus}
            style={{ marginRight: "10px" }}
          />
          Create Settings Group
        </Button>
        <div className="col-lg-5 d-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search settings group..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ boxShadow: "none" }}
          />
          <div className="input-group-append"></div>
        </div>
      </div>
      <CommonTable
        data={filteredSettingsGroupList}
        isLoading={loader}
        headers={headers}
        renderRow={renderRow}
      />

      <SettingsGroupEdit
        onActionComplete={() => {
          fetchData();
        }}
        open={isOpenSettingsEdit}
        handleClose={handleModelClose}
        id={selectedSettingsId}
      />
    </>
  );
};

export default SettingsGroupList;
