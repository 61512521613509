import React, { useEffect, useState } from "react";
import {
  MediaImageUpload,
  ProductDeleteImage,
  productEdit,
} from "services/apiService";
import { ProductEditImageProps } from "types";
import NoData from "../../../Images/9264885-removebg.png";
import ImageUploaderModal from "common/ImageUploaderModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
  faTrash,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import toast from "react-hot-toast";
import "../../../styles/index.css";

const ProductEditMedia: React.FC<ProductEditImageProps> = ({ productId }) => {
  const [ProductImage, setProductImage] = useState<any[]>([]);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [listOrder, setListOrder] = useState<number[]>([]);
  const [isOpenModel, setIsOpenModle] = useState<boolean>(false);
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  const fetchProductImage = async () => {
    try {
      const response = await productEdit(productId);
      setProductImage(response.data.ProductMedia);
    } catch (error) {
      console.error("Error fetching product images:");
    }
  };
  useEffect(() => {
    fetchProductImage();
  }, [productId]);

  const handleImageSelected = (images: string[]) => {
    setSelectedImages(images);
  };

  const handleRemoveImage = (image: string) => {
    const updatedImages = selectedImages.filter((img) => img !== image);
    const updatedOrder = listOrder.filter(
      (_, index) => index !== selectedImages.indexOf(image)
    );
    setSelectedImages(updatedImages);
    setListOrder(updatedOrder);
  };

  const handleDragStart = (
    index: number,
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  const handleDrop = (
    index: number,
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    const draggedIndex = Number(event.dataTransfer.getData("text/plain"));
    const updatedImages = [...selectedImages];
    const [draggedImage] = updatedImages.splice(draggedIndex, 1);
    updatedImages.splice(index, 0, draggedImage);
    const newOrder = updatedImages.map((_, idx) => idx + 1);
    setSelectedImages(updatedImages);
    setListOrder(newOrder);
  };
  const handleUploadImage = async () => {
    if (selectedImages.length === 0) {
      toast("Please select a file!", {
        icon: "⚠️",
        style: {
          fontWeight: "bold",
          background: "red",
          color: "white",
        },
      });
      return;
    }
    const payload = {
      product_images: selectedImages,
      product_id: productId,
      list_order: listOrder,
      is_active: 1,
      is_video: 0,
    };
    try {
      const MediaUploadApi = await MediaImageUpload(payload);
      if (MediaUploadApi && MediaUploadApi.status_code === 200) {
        toast.success("Successfully uploaded!");
        setProductImage(selectedImages);
        fetchProductImage();
        setSelectedImages([]);
      } else {
        toast.error("Failed to upload images. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Please arrange your images and  then upload  .");
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await ProductDeleteImage(id);
      if (response.status_code === 200) {
        fetchProductImage();
        toast.success("Image deleted sucessfully");
      }
    } catch (error) {
      toast.error("Image deletion failed");
    }
  };

  const handleOpenModal = () => setIsOpenModle(true);
  const handleCloseModal = () => setIsOpenModle(false);

  return (
    <>
      <div className="product_media p-2">
        <div className="product_media_main">
          <div className="d-flex justify-content-between">
            <h6 className="font-bold pb-2 border-b">Media Images</h6>
            <Button
              onClick={handleOpenModal}
              style={{
                padding: "5px  50px",
                fontSize: "15px",
                borderRadius: "0px",
                backgroundColor: "#007bff",
                color: "#fff",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              &nbsp;&nbsp; Add Image
            </Button>
          </div>

          <hr />
          <div className="row">
            {ProductImage?.length === 0 ? (
              <div className="d-flex justify-content-center">
                <img
                  src={NoData}
                  alt="No images"
                  height={50}
                  className="styled-image"
                />
              </div>
            ) : (
              <div
                className="product-image-list"
                style={{
                  margin: "10px 0",
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {ProductImage?.map((img, index) => (
                  <div
                    key={index}
                    className="product-image-item"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    <div
                      className="image-container"
                      style={{
                        position: "relative",
                        width: "250px",
                        overflow: "hidden",
                        height: "250px",
                        borderRadius: "10px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <img
                        src={`${AppUrl}${img.product_image}`}
                        alt="Product-Image"
                        className="image-item img-fluid"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          minWidth: "100%",
                          minHeight: "100%",
                          objectFit: "cover",
                        }}
                      />

                      <button
                        onClick={() => handleDelete(img.product_asset_id)}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "red",
                          color: "white",
                          border: "none",
                          padding: "8px",
                          cursor: "pointer",
                        }}
                        className="delete-icon"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <br />
          <div className="alert alert-info d-flex align-items-center">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="me-2"
              style={{ fontSize: "1.5rem", color: "#1864a4" }}
            />
            <p className="mb-0" style={{ color: "#1864a4", fontSize: "1rem" }}>
              Note: you can drag the images as you need.
            </p>
          </div>
          <div className="product_media_img">
            {selectedImages.length > 0 ? (
              selectedImages.map((image, index) => (
                <div
                  key={image}
                  draggable
                  onDragStart={(event) => handleDragStart(index, event)}
                  onDragOver={(event) => handleDragOver(event)}
                  onDrop={(event) => handleDrop(index, event)}
                  style={{
                    position: "relative",
                    display: "inline-block",
                    margin: "10px",
                    border: "1px dashed #ccc",
                    borderRadius: "10px",
                    padding: "5px",
                    background: "#f9f9f9",
                    cursor: "move",
                  }}
                >
                  <img
                    src={`${AppUrl}${image}`}
                    alt="Selected"
                    style={{
                      width: "200px",
                      height: "150px",
                      borderRadius: "10px",
                    }}
                  />
                  <button
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemoveImage(image)}
                  >
                    <FontAwesomeIcon icon={faTrash} color="red" />
                  </button>
                </div>
              ))
            ) : (
              <p style={{ color: "orange" }}>
                <FontAwesomeIcon icon={faCircleExclamation} /> No image
                selected. Please upload images.
              </p>
            )}
          </div>

          <div
            className="container-fluid d-flex justify-content-center"
            style={{ padding: "20px", textAlign: "center" }}
          >
            {selectedImages.length > 0 && (
              <button
                onClick={handleUploadImage}
                style={{
                  padding: "10px 150px",
                  fontSize: "12px",
                  borderRadius: "0px",
                  backgroundColor: selectedImages.length ? "green" : "grey",
                  color: "#fff",
                  cursor: selectedImages.length ? "pointer" : "not-allowed",
                }}
                disabled={selectedImages.length === 0}
              >
                <FontAwesomeIcon icon={faFileArrowUp} />
                &nbsp;&nbsp; Upload Image
              </button>
            )}
          </div>
        </div>
      </div>

      <ImageUploaderModal
        isOpen={isOpenModel}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={true}
      />
    </>
  );
};

export default ProductEditMedia;
