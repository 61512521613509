import React, { useEffect, useState } from "react";
import adminLogo from "../../Images/adminlogonew.png";
import bgImage from "../../Images/rm301-nunny-19.jpg";
import slideImage1 from "../../Images/Ecommerce web page-pana.png";
import slideImage2 from "../../Images/Business Plan-amico.svg";
import slideImage3 from "../../Images/Ecommerce web page-amico.svg";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import "../../styles/login.css";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AdminLoginProps, UserData, ValidationErrors } from "types";
import { AdminSignIn } from "services/apiService";
import {
  validateEmail,
  validatePassword,
} from "../../validation/CustomValidation.jsx";

const LoginPage: React.FC<AdminLoginProps> = ({
  setIsAdminAuthenticated,
  setProductKeyState,
}) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const slides = [slideImage1, slideImage2, slideImage3];

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [slides.length]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailError = validateEmail(userData.email);
    const passwordError = validatePassword(userData.password);

    setValidationErrors({
      email: emailError,
      password: passwordError,
    });

    if (!passwordError && !emailError) {
      setLoading(true);
      setTimeout(async () => {
        try {
          const response = await AdminSignIn(userData, navigate);
          if (response.status_code === 200) {
            localStorage.setItem("admin_token", response.token.token);
            setIsAdminAuthenticated(true);
            setProductKeyState(false);
            toast.success("Logged in successfully");
            navigate("/admin/dashboard", { replace: true });
          } else {
            setErrorMessage(response.message);
          }
        } catch (error: any) {
          console.error(error.response?.data?.status_code, "error");
          if (error.response?.data?.status_code === 403) {
            setProductKeyState(true);
            navigate("/product-key");
            toast.success("!Oops , Please enter product-key");
          }
          setErrorMessage(error.response?.data?.message || "Login Failed");
        } finally {
          setLoading(false);
        }
      }, 1000);
    }
  };

  const handleDotClick = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="admin-login-container">
      <div className="admin-login-form">
        <div
          className="login-container"
          style={{
            marginTop: "5rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={adminLogo} alt="Admin Logo" className="admin-logo" />
          <p className="welcome-text">Welcome to ReactAdmin</p>
          {/* Description */}
          <p className="description-text">
            Manage your e-commerce platform with ease. Log in to access your
            dashboard, oversee operations, and keep your store running smoothly.
            We're here to support your success—let's get started!
          </p>

          {/* Login Form */}
          <h2 className="form-title">Sign In</h2>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="error-message">{errorMessage}</div>

            {/* Email Field */}
            <TextField
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              onChange={handleChange}
              value={userData.email}
              variant="outlined"
              fullWidth
              margin="normal"
              className="custom-input"
              error={Boolean(validationErrors.email)}
              helperText={validationErrors.email}
            />

            {/* Password Field */}
            <FormControl variant="outlined" className="custom-input">
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={userData.password}
                onChange={handleChange}
                placeholder="Password"
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {validationErrors.password && (
                <FormHelperText error>
                  {validationErrors.password}
                </FormHelperText>
              )}
            </FormControl>

            {/* Login Button */}
            <div className="button-wrapper">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </form>
        </div>

        {/* Footer */}
        <p className="footer-text">
          © 2024 ReactAdmin. All Rights Reserved. Powered by Folcon Engine.
        </p>
      </div>

      <div
        className="admin-login-slider"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="slider-container">
          {slides.map((slide, index) => (
            <img
              key={index}
              src={slide}
              alt={`Slide ${index}`}
              className={`slide ${index === currentSlide ? "active" : ""}`}
            />
          ))}
        </div>
        <div className="dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
        <div className="slider-text">
          <h2>Unlock Your Sales Introducing Our </h2>
          <h2>Dynamic Sales Dashboard</h2>
          <p>
            Elevate Sales Performance: Streamline Insights with Our Dynamic
            Dashboard <br />
            Your Gateway to Revenue Growth
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
