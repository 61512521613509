import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdsDropDownValues } from "../../services/apiService";

export const fetchAdsDropDown = createAsyncThunk(
  "jsondata/fetchAdsDropDown",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAdsDropDownValues();
      return response.data
    } catch (error) {}
  }
);
const initialState = {
  adsDropDownDatas: [],
  loading: false,
  error: null,
};
const adsDropDownData = createSlice({
    name:'adsDropDownDataList',
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(fetchAdsDropDown.pending, (state) => {
            state.loading = true; 
            state.error = null;    
          })
          .addCase(fetchAdsDropDown.fulfilled, (state, action) => {
            state.loading = false;
            state.adsDropDownDatas = action.payload;  
          })
          .addCase(fetchAdsDropDown.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || action.error.message || "Unknown error";
          });
    }
})

export default adsDropDownData.reducer