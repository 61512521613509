import React, { useEffect, useState } from "react";
import DynamicButton from "../../common/DynamicButton";
import {
  faCirclePlus,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import { DeleteCampaingn, getAllCampaigneList } from "services/apiService";
import { CampaignEdit } from "./CampaignEdit";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Pagination } from "@mui/material";
import Swal from "sweetalert2";
import loadingImage from "../../assect/loadingSvg/loading.svg";
import No_Image_Available from "../../Images/No_Image_Available-Table.png";

const CampaignList = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [campaignSearch, setCampaignSearch] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [allCampaign, setAllCampaign] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [isOpenCampaignEdit, setIsOpenCampaignEdit] = useState<boolean>(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const headers = [
    "S.no",
    "Campaign Name",
    "Image",
    "Expire_date",
    "Status",
    "Action",
  ];

  useEffect(() => {
    getCampaigne();
  }, []);

  useEffect(() => {
    const filtered = allCampaign.filter((campaign: any) =>
      campaign.campaign_name
        .toLowerCase()
        .includes(campaignSearch.toLowerCase())
    );
    setFilteredCampaigns(filtered);
  }, [campaignSearch, allCampaign]);

  const getCampaigne = async () => {
    setLoader(true);
    try {
      const response = await getAllCampaigneList();
      setAllCampaign(response.data);
    } catch (error) {
      Swal.fire(
        "Error!",
        "An error occurred while fetching campaigns.",
        "error"
      );
    }
    setLoader(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteCampaingn(id);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The Campaign has been deleted.", "success");
            getCampaigne();
          } else {
            Swal.fire("Error!", "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Campaign is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const renderRow = (discount: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td title={discount.campaign_name}>
          {discount.campaign_name?.length > 30
            ? discount.campaign_name?.slice(0, 30) + "..."
            : discount.campaign_name}
        </td>
        <td className=" align-content-center" style={{ color: "grey" }}>
          <div className="d-flex justify-content-center">
            {discount.image_path ? (
              <img
                src={`${AppUrl}${discount.image_path}`}
                alt="ProductImage"
                width={100}
              />
            ) : (
              <img
                src={No_Image_Available}
                alt="No_Image_Available"
                width={100}
              />
            )}
          </div>
        </td>
        <td className=" align-content-center">{discount.expire_date}</td>
        <td className="align-content-center">
          <span
            style={{
              color: discount.is_active === 1 ? "green" : "red",
              backgroundColor: discount.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {discount?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>
        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleModelOpen(discount.campaign_id)}
            />
            <DynamicButton
              icon={faTrashCan}
              iconStyle={{ color: "#f54242" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() =>
                handleDelete(discount.campaign_id, discount.campaign_name)
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCampaignSearch(value);
  };

  const handleModelOpen = (id: number) => {
    setSelectedCampaignId(id);
    setIsOpenCampaignEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenCampaignEdit(false);
  };

  const indexOfLastCampaign = currentPage * itemsPerPage;
  const indexOfFirstCampaign = indexOfLastCampaign - itemsPerPage;
  const currentCampaigns = filteredCampaigns.slice(
    indexOfFirstCampaign,
    indexOfLastCampaign
  );

  const paginate = (e: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/campaign-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create campaign
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            style={{ boxShadow: "none" }}
            value={campaignSearch}
            onChange={handleChange}
            placeholder="Search Campaign ..."
          />
        </div>
      </div>
      <div className="product-table-content">
        {loader ? (
          <div className="loadingsvg-Image text-center">
            <img src={loadingImage} width={150} height={150} alt="Loading..." />
          </div>
        ) : (
          <CommonTable
            data={currentCampaigns}
            isLoading={loader}
            headers={headers}
            renderRow={renderRow}
          />
        )}
      </div>

      <div className="pagination-container">
        <div className="data-info" style={{ color: "grey" }}>
          <span>
            Showing {indexOfFirstCampaign + 1}-
            {Math.min(indexOfLastCampaign, filteredCampaigns.length)} of{" "}
            {filteredCampaigns.length}
          </span>
        </div>
        <Pagination
          count={Math.ceil(filteredCampaigns.length / itemsPerPage)}
          page={currentPage}
          onChange={paginate}
          variant="outlined"
          color="primary"
        />
      </div>
      <CampaignEdit
        onActionComplete={() => {
          getCampaigne();
        }}
        open={isOpenCampaignEdit}
        handleClose={handleModelClose}
        id={selectedCampaignId}
      />
    </>
  );
};

export default CampaignList;
