export const validationByEdit = (fields, value) => {
  const errors = {};
  const { key_name, validations, is_multi_select, type } = fields;
  if (!validations) {
    return errors;
  }
  if (
    validations.required &&
    (value === undefined ||
      value === null ||
      (typeof value === "string" && value.trim() === ""))
  ) {
    errors[key_name] = `${key_name.toLowerCase()} is required`;
    return errors;
  }
  switch (type) {
    case "text_input":
      if (typeof value === "string") {
        const trimmedValue = value.trim();
        if (validations.min && trimmedValue.length < validations.min) {
          errors[
            key_name
          ] = `The input must be at least ${validations.min} characters long`;
        } else if (validations.max && trimmedValue.length > validations.max) {
          errors[
            key_name
          ] = `The input must be no more than ${validations.max} characters long`;
        }
      }
      break;
    case "drop_down":
      if (is_multi_select === 1) {
        const valuesArray =
          typeof value === "string" ? value.split(",") : value;
        if (!Array.isArray(valuesArray)) {
          errors[key_name] = `${key_name} must be an array`;
        } else if (valuesArray.length === 0) {
          errors[key_name] = "Please select at least one option";
        } else if (validations.min && valuesArray.length < validations.min) {
          errors[
            key_name
          ] = `You must select at least ${validations.min} options`;
        } else if (validations.max && valuesArray.length > validations.max) {
          errors[key_name] = `You can select up to ${validations.max} options`;
        }
      } else if (
        is_multi_select === 0 &&
        (value === undefined || value.trim() === "")
      ) {
        errors[key_name] = "This field cannot be empty";
      }
      break;
    case "FILE":
      if (
        !value ||
        (typeof value === "string" && value.trim() === "") ||
        (Array.isArray(value) && value.length === 0)
      ) {
        errors[key_name] = "File is required";
      } else if (
        validations.allowedTypes &&
        !validations.allowedTypes.includes(value.type)
      ) {
        errors[
          key_name
        ] = `Invalid file type. Allowed types are: ${validations.allowedTypes.join(
          ", "
        )}`;
      }
      break;
    case "toggle_input":
      if (typeof value !== "boolean") {
        errors[key_name] = `${key_name.toLowerCase()} must be true or false`;
      }
      break;
    default:
      break;
  }
  return errors;
};
