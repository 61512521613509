import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WidthFull } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CreateCampaignResponse } from "services/apiService";

const CampaignCreate: React.FC = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [CampaignCreate, setCampaingCreate] = useState({
    campaign_name: "",
    expire_date: "",
    title: "",
    description: "",
    image_path: "",
    is_active: 1,
    slug: "",
  });

  const [errors, setErrors] = useState({
    campaign_name: "",
    expire_date: "",
    title: "",
    description: "",
  });

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };
    setCampaingCreate((prevData) => ({
      ...prevData,
      [id]: value,
      slug: id === "campaign_name" ? generateSlug(value) : prevData.slug,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleImageSelected = (image: string) => {
    setCampaingCreate((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };

  const handleToggleChange = (checked: number, field: string) => {
    setCampaingCreate((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const validate = () => {
    let isValid = true;
    const newErrors: any = {};

    if (!CampaignCreate.campaign_name.trim()) {
      newErrors.campaign_name = "Campaign Name is required.";
      isValid = false;
    }

    if (!CampaignCreate.title.trim()) {
      newErrors.title = "Campaign Title is required.";
      isValid = false;
    }

    if (!CampaignCreate.description.trim()) {
      newErrors.description = "Description is required.";
      isValid = false;
    }

    if (!CampaignCreate.expire_date) {
      newErrors.expire_date = "Expiry Date is required.";
      isValid = false;
    } else if (new Date(CampaignCreate.expire_date) < new Date(today)) {
      newErrors.expire_date = "Expiry Date cannot be in the past.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      try {
        const payload = {
          campaign_name: CampaignCreate.campaign_name,
          expire_date: CampaignCreate.expire_date,
          title: CampaignCreate.title,
          description: CampaignCreate.description,
          image_path: CampaignCreate.image_path.toString(),
          is_active: CampaignCreate.is_active,
          slug: CampaignCreate.slug,
        };

        const response = await CreateCampaignResponse(payload);
        if (response.status === "success") {
          toast.success("Campaign created successfully!");
          setCampaingCreate({
            campaign_name: "",
            expire_date: "",
            title: "",
            description: "",
            image_path: "",
            is_active: 1,
            slug: "",
          });
          navigate("/admin/campaign-list");
        } else {
          toast.error("Campaign creation failed. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Campaign Name
              </Typography>
              <TextField
                variant="filled"
                id="campaign_name"
                fullWidth
                value={CampaignCreate.campaign_name}
                onChange={handleTextFieldChange}
                error={!!errors.campaign_name}
                helperText={errors.campaign_name}
                inputProps={{
                  maxLength: 40,
                }}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Campaign Title
              </Typography>
              <TextField
                variant="filled"
                id="title"
                fullWidth
                value={CampaignCreate.title}
                onChange={handleTextFieldChange}
                error={!!errors.title}
                helperText={errors.title}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Description
              </Typography>
              <TextField
                variant="filled"
                id="description"
                fullWidth
                multiline
                rows={4}
                value={CampaignCreate.description}
                onChange={handleTextFieldChange}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Expiry Date
              </Typography>
              <input
                id="expire_date"
                type="date"
                value={CampaignCreate.expire_date}
                onChange={handleTextFieldChange}
                className="form-control"
                min={today}
                style={{ width: "100%", padding: "8px" }}
              />
              {errors.expire_date && (
                <Typography color="error">{errors.expire_date}</Typography>
              )}
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Coupon Image
              </Typography>
              <div
                style={{
                  textAlign: "center",
                  border: "1px dashed #ccc",
                  padding: "20px",
                  backgroundColor: "#f9f9f9",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {CampaignCreate.image_path ? (
                  <img
                    src={`${AppUrl}${CampaignCreate.image_path}`}
                    alt="Selected"
                    style={{ width: "200px" }}
                  />
                ) : (
                  <p style={{ color: "orange" }}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    &nbsp;No image selected. Please upload an image.
                  </p>
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleOpenModal}
                    style={{
                      fontSize: "10px",
                      borderRadius: "5px",
                      border: "none",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      cursor: "pointer",
                      marginTop: "10px",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp;&nbsp;Upload
                    Image
                  </Button>
                </div>
              </div>
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Status
              </Typography>
              <Toggle
                checked={CampaignCreate.is_active === 1}
                onChange={(checked) => handleToggleChange(checked, "is_active")}
              />
            </Box>
            <Box textAlign="end" mt={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp; Create Campaign
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default CampaignCreate;
