import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import React, { useState } from "react";
import NoData from "../../../Images/9264885-removebg.png";
import { MediaImageUpload } from "services/apiService";
import toast from "react-hot-toast";
import { ProductMediaProp } from "types";

const ProductMedia: React.FC<ProductMediaProp> = ({
  productId,
  onMediaComplete,
}) => {
  const [isOpenModel, setIsOpenModle] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const [listOrder, setListOrder] = useState<number[]>([]);
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [ProductImage, setProductImage] = useState([]);

  const handleOpenModal = () => setIsOpenModle(true);
  const handleCloseModal = () => setIsOpenModle(false);

  const handleImageSelected = (images: string[]) => {
    setSelectedImages(images);
    setListOrder(images.map((_, idx) => idx + 1));
  };

  const handleRemoveImage = (image: string) => {
    const updatedImages = selectedImages?.filter((img) => img !== image);
    const updatedOrder = listOrder?.filter(
      (_, index) => index !== selectedImages.indexOf(image)
    );
    setSelectedImages(updatedImages);
    setListOrder(updatedOrder);
  };

  const handleDragStart = (
    index: number,
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (
    index: number,
    event: React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    const draggedIndex = Number(event.dataTransfer.getData("text/plain"));
    const updatedImages = [...selectedImages];
    const [draggedImage] = updatedImages.splice(draggedIndex, 1);
    updatedImages.splice(index, 0, draggedImage);

    setSelectedImages(updatedImages);
    setListOrder(updatedImages.map((_, idx) => idx + 1));
  };

  const handleUploadImage = async () => {
    if (selectedImages.length === 0) {
      toast("Please select a file!", {
        icon: "⚠️",
        style: {
          fontWeight: "bold",
          background: "red",
          color: "white",
        },
      });
      return;
    }
    const payload = {
      product_images: selectedImages,
      product_id: productId,
      list_order: listOrder,
      is_active: 1,
      is_video: 0,
    };

    try {
      const MediaUploadApi = await MediaImageUpload(payload);
      if (MediaUploadApi && MediaUploadApi.status_code === 200) {
        toast.success("Successfully uploaded!");
        setProductImage(selectedImages);
        setSelectedImages([]);
        onMediaComplete();
      } else {
        toast.error("Failed to upload images. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error(
        "An error occurred while uploading images. Please try again."
      );
    }
  };

  return (
    <>
      <div className="product_media p-2">
        <div className="product_media_main">
          <h6 className="font-bold pb-2 border-b">Media Images</h6>
          <hr />
          <div className="row">
            {ProductImage.length === 0 ? (
              <div className="d-flex justify-content-center">
                <img
                  src={NoData}
                  alt="No images"
                  height={50}
                  className="styled-image"
                />
              </div>
            ) : (
              <div className="row g-3">
                {ProductImage.map((img, index) => (
                  <div key={index} className="col-6 col-md-4 col-lg-3">
                    <div className="card">
                      <img
                        src={`${AppUrl}${img}`}
                        alt="Product-Image"
                        className="card-img-top img-fluid"
                        style={{ height: "200px", objectFit: "cover" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="alert alert-info d-flex align-items-center">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="me-2"
              style={{ fontSize: "1.5rem", color: "#1864a4" }}
            />
            <p className="mb-0" style={{ color: "#1864a4", fontSize: "1rem" }}>
              Note: you can drag the images as you need.
            </p>
          </div>
          <div className="product_media_img">
            {/* Display selected images */}
            {selectedImages.length > 0 ? (
              selectedImages.map((image, index) => (
                <div
                  key={image}
                  draggable
                  onDragStart={(event) => handleDragStart(index, event)}
                  onDragOver={(event) => handleDragOver(event)}
                  onDrop={(event) => handleDrop(index, event)}
                  style={{
                    position: "relative",
                    display: "inline-block",
                    margin: "10px",
                    border: "1px dashed #ccc",
                    borderRadius: "10px",
                    padding: "5px",
                    background: "#f9f9f9",
                    cursor: "move",
                  }}
                >
                  <img
                    src={`${AppUrl}${image}`}
                    alt="Selected"
                    style={{
                      width: "200px",
                      height: "150px",
                      borderRadius: "10px",
                    }}
                  />
                  <button
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemoveImage(image)}
                  >
                    <FontAwesomeIcon icon={faTrash} color="red" />
                  </button>
                </div>
              ))
            ) : (
              <p style={{ color: "orange" }}>
                <FontAwesomeIcon icon={faCircleExclamation} /> No image
                selected. Please upload images.
              </p>
            )}
          </div>

          <div
            className="container"
            style={{ maxWidth: "600px", padding: "20px" }}
          >
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={handleOpenModal}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faFileArrowUp} />
                &nbsp;&nbsp; Add Image
              </Button>
              &nbsp;&nbsp;
              <Button
                onClick={handleUploadImage}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faFileArrowUp} />
                &nbsp;&nbsp; Upload Image
              </Button>
            </div>
          </div>
        </div>
      </div>

      <ImageUploaderModal
        isOpen={isOpenModel}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={true}
      />
    </>
  );
};

export default ProductMedia;
