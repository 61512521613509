import React from "react";
import { clearImageSelections } from "../../../../redux/Slice/mediaSlice.js";
import { useDispatch } from "react-redux";

const ImageDisplay = ({ field, imagesData, single,isEditing }) => {
  const dispatch = useDispatch();
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const media = imagesData[field];
  const mediaArray = single ? [media] : media.split(",").filter((item) => item);
  console.log(isEditing,"isEditing");
  
  return (
    <div className="media-container">
      {mediaArray.length > 0 ? (
        mediaArray?.map((item, index) => (
          <div key={`${field}-${index}`} className="media-item">
            {item && (
              <>
                <img src={`${AppUrl}${item}`} alt={`Media for ${field}`} />
                {isEditing && (
                  <button
                    onClick={() => {
                      single
                        ? dispatch(
                            clearImageSelections({
                              keyName: field,
                              isMulti: false,
                              mediaUrl: item,
                            })
                          )
                        : dispatch(
                            clearImageSelections({
                              keyName: field,
                              isMulti: true,
                              mediaUrl: item,
                            })
                          );
                    }}
                    className="delete-button"
                  >
                    X
                  </button>
                )}
              </>
            )}
          </div>
        ))
      ) : (
        <p>No media available.</p>
      )}
    </div>
  );
};

export default ImageDisplay;
