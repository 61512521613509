import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CreateBrand } from "services/apiService";

const BrandCreate: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    brand_name: "",
  });
  const [BrandCreateForm, setBrandCreateForm] = useState({
    slug: "",
    is_active: 1,
    brand_image: "",
    brand_name: "",
  });
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const generateSlug = (name: string) => {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  };

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === "brand_name") {
      const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
      let error = "";
      if (sanitizedValue.length < 3) {
        error = "Brand name must be at least 3 characters.";
      } else if (sanitizedValue.length > 25) {
        error = "Brand name must not exceed 25 characters.";
      }
      setBrandCreateForm((prevData) => ({
        ...prevData,
        [id]: sanitizedValue,
        slug:
          id === "brand_name" ? generateSlug(sanitizedValue) : prevData.slug,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: error,
      }));

      return;
    }
    setBrandCreateForm((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setBrandCreateForm((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleImageSelected = (image: string) => {
    setBrandCreateForm((prevState) => ({
      ...prevState,
      brand_image: image,
    }));
    setModalOpen(false);
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!BrandCreateForm.brand_name.trim()) {
      newErrors.brand_name = "Brand name is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        if (BrandCreateForm.is_active === 0) {
          toast.error("Please make the status active");
          return;
        }
        const createPayload = {
          ...BrandCreateForm,
          name: BrandCreateForm.brand_name,
          slug: BrandCreateForm.slug,
          is_active: BrandCreateForm.is_active,
          brand_image: BrandCreateForm.brand_image.toString(),
          brand_name: BrandCreateForm.brand_name,
        };
        const BrandCreate = await CreateBrand(createPayload);
        if (BrandCreate.status_code === 200) {
          toast.success("successfully submitted the form");
          setBrandCreateForm({
            slug: "",
            is_active: 1,
            brand_image: "",
            brand_name: "",
          });
          navigate("/admin/brand-list");
        } else {
          toast.error("Brand Creation Failed");
        }
      } catch (error) {
        toast.error("An error occurred during brand creation");
      }
    } else {
      toast.error("Please Fill the form");
    }
  };

  const handleDeleteImage = () => {
    setBrandCreateForm((prevState) => ({
      ...prevState,
      brand_image: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Name
            </Typography>
            <TextField
              variant="filled"
              id="brand_name"
              fullWidth
              error={!!errors.brand_name}
              value={BrandCreateForm.brand_name}
              helperText={errors.brand_name}
              onChange={handleTextFieldChange}
              inputProps={{
                maxLength: 25,
              }}
            />
          </Box>

          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Slug
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              value={BrandCreateForm.slug}
              disabled
            />
          </Box>

          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Status
            </Typography>
            <Toggle
              checked={BrandCreateForm.is_active === 1}
              onChange={(checked) => handleToggleChange(checked, "is_active")}
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Brand Image
            </Typography>
            <div
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  border: "1px dashed #ccc",
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "10px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                {BrandCreateForm.brand_image ? (
                  <>
                    <img
                      src={`${AppUrl}${BrandCreateForm.brand_image}`}
                      alt="Selected"
                      style={{
                        width: "300px",
                        transition: "transform 0.2s",
                        cursor: "pointer",
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    />
                    <button
                      style={{
                        backgroundColor: "rgba(255, 0, 0, 0.7)",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        fontSize: "16px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                        transition: "background-color 0.2s",
                      }}
                      onClick={handleDeleteImage}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </>
                ) : (
                  <p style={{ color: "orange" }}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    &nbsp; &nbsp; No image selected. Please upload an image.
                  </p>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleOpenModal}
                  variant="contained"
                  color="primary"
                >
                  <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp; Upload
                  Image
                </Button>
              </div>
            </div>
          </Box>
          <Box textAlign="end" mt={4}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              &nbsp;&nbsp; Create Brand
            </Button>
          </Box>
        </Box>
      </form>

      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default BrandCreate;
