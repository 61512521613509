import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControlLabel,
  TextField,
  Checkbox,
} from "@mui/material";
import { BrandEditProps } from "types";
import { BrandDetails, BrandDetailsUpdate } from "services/apiService";
import ImageUploaderModal from "common/ImageUploaderModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

const BrandEdit: React.FC<BrandEditProps> = ({
  open,
  handleClose,
  id,
  onActionComplete,
}) => {
  const [BrandForm, setBrandForm] = useState({
    name: "",
    slug: "",
    is_active: 0,
    brand_id: "",
    brand_image: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    slug: "",
  });

  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  useEffect(() => {
    if (id) {
      fetchBrandDetails(id);
    }
  }, [open]);

  const fetchBrandDetails = async (id: number) => {
    try {
      const response = await BrandDetails(id);
      const {
        id: brand_id,
        brand_name: name,
        slug,
        is_active,
        brand_image,
      } = response.data;
      setBrandForm({ brand_id, name, slug, is_active, brand_image });
    } catch (error) {
      console.error("Error fetching brand details:", error);
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: "", slug: "" };

    if (!BrandForm.name.trim()) {
      newErrors.name = "Brand name is required.";
      valid = false;
    } else if (BrandForm.name.length < 3) {
      newErrors.name = "Brand name must be at least 3 characters long.";
      valid = false;
    }

    if (!BrandForm.slug.trim()) {
      newErrors.slug = "Slug is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleImageSelected = (image: string) => {
    setBrandForm((prevState) => ({
      ...prevState,
      brand_image: image,
    }));
    setModalOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedFormData = { ...BrandForm, [name]: value };
    const generateSlug = (name: string) => {
      return name.trim().toLowerCase().replace(/\s+/g, "-");
    };
    if (name === "name") {
      updatedFormData.slug = generateSlug(value);
      if (errors.name) {
        setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
      }
    }

    setBrandForm(updatedFormData);
  };

  const handleDeleteImage = () => {
    setBrandForm((prevState) => ({
      ...prevState,
      brand_image: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const createPayload = {
        ...BrandForm,
        brand_image: BrandForm.brand_image.toString() || "",
        brand_name: BrandForm.name,
        brand_id: BrandForm.brand_id,
      };
      const response = await BrandDetailsUpdate(createPayload);
      if (response.status_code === 200) {
        toast.success("Brand Updated Successfully");
        setBrandForm({
          name: "",
          slug: "",
          is_active: 0,
          brand_id: "",
          brand_image: "",
        });
        onActionComplete();
        handleClose();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  if (!id) return null;

  return (
    <Modal open={open} onClose={undefined}>
      <Box sx={style}>
        <Typography
          variant="h6"
          className="text-center mb-4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
            backgroundColor: "#007bff",
            padding: "5px",
            color: "white",
          }}
        >
          Edit Brand
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <TextField
                  id="name"
                  name="name"
                  label="Brand Name"
                  variant="outlined"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name}
                  value={BrandForm.name}
                  onChange={handleInputChange}
                />
              </div>
              <br />
              <div className="form-group">
                <TextField
                  id="slug"
                  name="slug"
                  label="Slug"
                  variant="outlined"
                  fullWidth
                  value={BrandForm.slug}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
              <br />
              <div className="form-group">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="is_active"
                      name="is_active"
                      checked={BrandForm.is_active === 1}
                      onChange={(e) => {
                        setBrandForm({
                          ...BrandForm,
                          is_active: e.target.checked ? 1 : 0,
                        });
                      }}
                    />
                  }
                  label="Is Active"
                />
              </div>
            </div>
            <div
              style={{
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "20px",
                  border: "1px dashed #ccc",
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  borderRadius: "10px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                {BrandForm.brand_image ? (
                  <>
                    <img
                      src={`${AppUrl}${BrandForm.brand_image}`}
                      alt="Selected"
                      style={{
                        width: "200px",
                        height: "150px",
                        borderRadius: "10px",
                        transition: "transform 0.2s",
                        cursor: "pointer",
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    />
                    <button
                      style={{
                        backgroundColor: "rgba(255, 0, 0, 0.7)",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        fontSize: "16px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                        transition: "background-color 0.2s",
                      }}
                      onClick={handleDeleteImage}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </>
                ) : (
                  <p style={{ color: "orange" }}>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    &nbsp; &nbsp; No image selected. Please upload an image.
                  </p>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleOpenModal}
                  variant="contained"
                  color="primary"
                >
                  <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp; Upload
                  Image
                </Button>
              </div>
            </div>
            <br />
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </form>
          <ImageUploaderModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onImageSelect={handleImageSelected}
            allowMultiple={false}
          />
        </Typography>
      </Box>
    </Modal>
  );
};

export default BrandEdit;
