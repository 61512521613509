import { Modal, Box } from "@mui/material";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  SettingGroupdropdown,
  SettingsChildEdit,
  updateSettingsvalue,
} from "services/apiService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

interface FormValues {
  key_name: string;
  key_value: string;
  group_id: string;
  display_name: string;
  is_active: number;
}
interface SettingsSingleEditProp {
  open: boolean;
  handleClose: () => void;
  id: number | null;
  onActionComplete: () => void;
}

const SettingsSingleEdit: React.FC<SettingsSingleEditProp> = ({
  open,
  handleClose,
  id,
  onActionComplete,
}) => {
  const [editSingleList, setEditSingleList] = useState({
    key_name: "",
    key_value: "",
    group_id: "",
    display_name: "",
    is_active: 1,
  });

  const [groups, setGroups] = useState<Array<{ id: string; name: string }>>([]);
  const [errors, setErrors] = useState<Partial<FormValues>>({});

  const fetchSettingsSingleEdit = async (id: number) => {
    try {
      const response = await SettingsChildEdit(id);
      if (response.status_code === 200) {
        setEditSingleList({
          key_name: response.data.key_name,
          key_value: response.data.key_value,
          group_id: response.data.group_id,
          display_name: response.data.display_name,
          is_active: response.data.is_active,
        });
      } else {
        console.warn("Data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSettingsSingleEdit(id);
    }
  }, [id]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await SettingGroupdropdown();
        setGroups(response.data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setEditSingleList((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: value ? "" : "This field is required",
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditSingleList((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const payload = {
        id: id,
        key_name: editSingleList.key_name,
        key_value: editSingleList.key_value,
        is_active: editSingleList.is_active,
        group_id: editSingleList.group_id,
        display_name: editSingleList.display_name,
        is_viewed: 1,
      };
      const response = await updateSettingsvalue(payload);
      if (response.status_code === 200) {
        toast.success("Settings Updated Successfully");
        handleClose();
        onActionComplete();
      }
    } catch (error) {
      toast.error("Settings Update failed");
    }
  };

  return (
    <>
      <Modal open={open} onClose={undefined}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Edit Settings List
          </Typography>
          <Typography sx={{ mt: 3 }}>
            <form onSubmit={handleSubmit}>
              <div className="w-100">
                <Box className="create_category_fields" mb={2}>
                  <Typography
                    variant="subtitle1"
                    className="text-capitalize label"
                  >
                    Key Name
                  </Typography>
                  <TextField
                    variant="filled"
                    name="key_name"
                    id="key_name"
                    value={editSingleList.key_name}
                    onChange={handleInputChange}
                    placeholder="Enter Key Name"
                    fullWidth
                    className="common_input"
                    error={!!errors.key_name}
                    helperText={errors.key_name}
                  />
                </Box>

                <Box className="create_category_fields" mb={2}>
                  <Typography
                    variant="subtitle1"
                    className="text-capitalize label"
                  >
                    Key Value
                  </Typography>
                  <TextField
                    variant="filled"
                    name="key_value"
                    id="key_value"
                    value={editSingleList.key_value}
                    onChange={handleInputChange}
                    placeholder="Enter Key Value"
                    fullWidth
                    className="common_input"
                    error={!!errors.key_value}
                    helperText={errors.key_value}
                  />
                </Box>
                <Box className="create_category_fields" mb={2}>
                  <Typography
                    variant="subtitle1"
                    className="text-capitalize label"
                  >
                    Display Name
                  </Typography>
                  <TextField
                    variant="filled"
                    name="display_name"
                    id="display_name"
                    value={editSingleList.display_name}
                    onChange={handleInputChange}
                    placeholder="Enter Display Name"
                    fullWidth
                    className="common_input"
                    error={!!errors.display_name}
                    helperText={errors.display_name}
                  />
                </Box>
                <Box
                  className="create_category_fields"
                  mb={2}
                  sx={{ maxWidth: "100%" }}
                >
                  <Typography
                    variant="subtitle1"
                    className="text-capitalize label"
                  >
                    Group
                  </Typography>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={!!errors.group_id}
                  >
                    <InputLabel id="group-select-label">
                      Select Group
                    </InputLabel>
                    <Select
                      labelId="group-select-label"
                      id="group_id"
                      name="group_id"
                      value={editSingleList.group_id}
                      onChange={handleSelectChange}
                      sx={{
                        borderRadius: "8px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <MenuItem value="">Select Group</MenuItem>
                      {groups?.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.group_id || "Please select a Group"}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box className="create_category_fields" mb={2}>
                  <Typography
                    variant="subtitle1"
                    className="text-capitalize label"
                  >
                    Status
                  </Typography>
                  <Toggle
                    checked={editSingleList.is_active === 1}
                    onChange={(checked) =>
                      setEditSingleList((prevData) => ({
                        ...prevData,
                        is_active: checked ? 1 : 0,
                      }))
                    }
                  />
                </Box>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                    style={{ marginLeft: "10px" }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default SettingsSingleEdit;
