import React, { useEffect, useState } from "react";
import {
  faCirclePlus,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { DeleteCoupon, getAllCouponList } from "services/apiService";
import DynamicButton from "../../common/DynamicButton";
import CommonTable from "common/CommonTable";
import { CouponList } from "types";
import CouponEdit from "./CouponEdit";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { Button, Pagination } from "@mui/material";
import loadingImage from "../../assect/loadingSvg/loading.svg";
import No_Image_Available from "../../Images/No_Image_Available-Table.png";

const CouponLIst = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [loader, setLoader] = useState(false);
  const [allCouponList, setAllCouponList] = useState<CouponList[]>([]);
  const [filteredCoupons, setFilteredCoupons] = useState<CouponList[]>([]);
  const [couponSearch, setCouponSearch] = useState("");
  const [isOpenCouponEdit, setIsOpenCouponEdit] = useState<boolean>(false);
  const [selectedCouponId, setSelectedCouponId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const headers = [
    "S.no",
    "Coupon Code",
    "Name",
    "Expire On",
    "Image",
    "Status",
    "Action",
  ];

  useEffect(() => {
    fetchAllCouponList();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [couponSearch, allCouponList]);

  const fetchAllCouponList = async () => {
    setLoader(true);
    try {
      const response = await getAllCouponList();
      setAllCouponList(response.data);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
    setLoader(false);
  };

  const handleSearch = () => {
    const lowercasedSearch = couponSearch.toLowerCase();
    const filtered = allCouponList.filter(
      (coupon) =>
        coupon.coupon_code.toLowerCase().includes(lowercasedSearch) ||
        coupon.name.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredCoupons(filtered);
    setCurrentPage(1);
  };

  const handleModelOpen = (id: number) => {
    setSelectedCouponId(id);
    setIsOpenCouponEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenCouponEdit(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteCoupon(id);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The Coupon has been deleted.", "success");
            fetchAllCouponList();
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Coupon is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  const renderRow = (coupon: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td className="table_product_name" title={coupon.coupon_code}>
          {coupon.coupon_code.length > 30
            ? coupon.coupon_code.slice(0, 30) + "..."
            : coupon.coupon_code}
        </td>

        <td className="align-content-center" title={coupon.name}>
          {coupon.name.length > 30
            ? coupon.name.slice(0, 30) + "..."
            : coupon.name}
        </td>
        <td
          className="table_product_name align-content-center"
          style={{ color: "grey" }}
        >
          {new Date(coupon?.expire_date).toLocaleDateString()}
        </td>
        <td className="align-content-center">
          <div className="d-flex justify-content-center">
            {coupon.image_path ? (
              <img
                src={`${AppUrl}${coupon.image_path}`}
                alt="ProductImage"
                width={100}
              />
            ) : (
              <img
                src={No_Image_Available}
                alt="No_Image_Available"
                width={100}
              />
            )}
          </div>
        </td>
        <td className="align-content-center">
          <span
            style={{
              color: coupon.is_active === 1 ? "green" : "red",
              backgroundColor: coupon.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {coupon?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>

        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleModelOpen(coupon.id)}
            />

            <DynamicButton
              icon={faTrashCan}
              iconStyle={{ color: "#f54242" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleDelete(coupon.id, coupon.name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  const paginatedData = filteredCoupons.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const startIndex = (currentPage - 1) * itemsPerPage;

  return (
    <>
      <div className="d-flex justify-content-between  ">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/coupon-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Coupon
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            placeholder="Search Coupon name...."
            className="form-control"
            style={{ boxShadow: "none" }}
            value={couponSearch}
            onChange={(e) => setCouponSearch(e.target.value)}
          />
        </div>
      </div>

      <div className="product-table-content">
        {loader ? (
          <div className="loadingsvg-Image text-center">
            <img src={loadingImage} width={150} height={150} alt="Loading..." />
          </div>
        ) : (
          <CommonTable
            data={paginatedData}
            isLoading={loader}
            headers={headers}
            renderRow={renderRow}
          />
        )}
      </div>

      <div className="pagination-container">
        <div className="data-info" style={{ color: "grey" }}>
          <span>
            Showing {startIndex + 1}-
            {Math.min(startIndex + itemsPerPage, filteredCoupons.length)} of{" "}
            {filteredCoupons.length}
          </span>
        </div>
        <Pagination
          count={Math.ceil(filteredCoupons.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </div>

      <CouponEdit
        onActionComplete={() => {
          fetchAllCouponList();
        }}
        open={isOpenCouponEdit}
        handleClose={handleModelClose}
        id={selectedCouponId}
      />
    </>
  );
};

export default CouponLIst;
