import React, { useState, useEffect } from "react";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { createSingleSetting, SettingGroupdropdown } from "services/apiService";
import toast from "react-hot-toast";
import Toggle from "common/Toggle";
import { useNavigate } from "react-router";

interface FormValues {
  key_name: string;
  key_value: string;
  group_id: string;
  display_name: string;
  is_active: number;
}

const SettingSingleCreate = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<FormValues>({
    key_name: "",
    key_value: "",
    group_id: "",
    display_name: "",
    is_active: 1,
  });

  const [groups, setGroups] = useState<Array<{ id: string; name: string }>>([]);
  const [errors, setErrors] = useState<Partial<FormValues>>({});

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await SettingGroupdropdown();
        setGroups(response.data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: value ? "" : "This field is required",
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: value ? "" : "This field is required",
    }));
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<FormValues> = {};
    if (!formValues.key_name) newErrors.key_name = "Key Name is required";
    if (!formValues.key_value) newErrors.key_value = "Key Value is required";
    if (!formValues.group_id) newErrors.group_id = "Group is required";
    if (!formValues.display_name)
      newErrors.display_name = "Display Name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fix validation errors before submitting.");
      return;
    }

    try {
      const payload = {
        key_name: formValues.key_name,
        key_value: formValues.key_value,
        group_id: formValues.group_id,
        display_name: formValues.display_name,
        is_viewed: 1,
        is_active: formValues.is_active,
      };
      const response = await createSingleSetting(payload);
      if (response.status_code === 200) {
        toast.success("Setting is created successfully");
        navigate("/admin/setting-single-list");
      }
    } catch (error) {
      toast.error("Error creating setting:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="w-100">
        <Box className="create_category_fields" mb={2}>
          <Typography variant="subtitle1" className="text-capitalize label">
            Key Name
          </Typography>
          <TextField
            variant="filled"
            name="key_name"
            id="key_name"
            value={formValues.key_name}
            onChange={handleInputChange}
            placeholder="Enter Key Name"
            fullWidth
            className="common_input"
            error={!!errors.key_name}
            helperText={errors.key_name}
          />
        </Box>

        <Box className="create_category_fields" mb={2}>
          <Typography variant="subtitle1" className="text-capitalize label">
            Key Value
          </Typography>
          <TextField
            variant="filled"
            name="key_value"
            id="key_value"
            value={formValues.key_value}
            onChange={handleInputChange}
            placeholder="Enter Key Value"
            fullWidth
            className="common_input"
            error={!!errors.key_value}
            helperText={errors.key_value}
          />
        </Box>
        <Box className="create_category_fields" mb={2}>
          <Typography variant="subtitle1" className="text-capitalize label">
            Display Name
          </Typography>
          <TextField
            variant="filled"
            name="display_name"
            id="display_name"
            value={formValues.display_name}
            onChange={handleInputChange}
            placeholder="Enter Display Name"
            fullWidth
            className="common_input"
            error={!!errors.display_name}
            helperText={errors.display_name}
          />
        </Box>
        <Box
          className="create_category_fields"
          mb={2}
          sx={{ maxWidth: "100%" }}
        >
          <Typography variant="subtitle1" className="text-capitalize label">
            Group
          </Typography>
          <FormControl fullWidth variant="filled" error={!!errors.group_id}>
            <InputLabel id="group-select-label">Select Group</InputLabel>
            <Select
              labelId="group-select-label"
              id="group_id"
              name="group_id"
              value={formValues.group_id}
              onChange={handleSelectChange}
              sx={{
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <MenuItem value="">Select Group</MenuItem>
              {groups?.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {errors.group_id || "Please select a Group"}
            </FormHelperText>
          </FormControl>
        </Box>

        {/* Status */}
        <Box className="create_category_fields" mb={2}>
          <Typography variant="subtitle1" className="text-capitalize label">
            Status
          </Typography>
          <Toggle
            checked={formValues.is_active === 1}
            onChange={(checked) =>
              setFormValues((prevData) => ({
                ...prevData,
                is_active: checked ? 1 : 0,
              }))
            }
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" pb={2}>
          <Button
            variant="contained"
            color="primary"
            className="mt-5"
            type="submit"
            style={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              icon={faCirclePlus}
            />
            Create Setting
          </Button>
        </Box>
      </div>
    </form>
  );
};

export default SettingSingleCreate;
