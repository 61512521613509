import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSectionData } from "../../services/apiService";

export const fetchSectionsDatas = createAsyncThunk(
  "jsondata/fetchSectionsDatas",
  async ({ sectionGroupId, pageId }, { rejectWithValue }) => {
    try {
      const response = await getSectionData(sectionGroupId, pageId);
      return response.data;
    } catch (error) {
        return rejectWithValue("Failed to fetch data");
    }
  }
);
const initialState = {
  getSectionDataList: [],
  error: null,
  loading: false,
};

export const getSectionDataSlice = createSlice({
  name: "getEditSections",
  initialState,
  reducers: {
    clearData: (state) => {
      state.getSectionDataList = [];
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSectionsDatas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSectionsDatas.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.getSectionDataList = action.payload; 
        } else {
          state.getSectionDataList = []; 
        }
      })
      .addCase(fetchSectionsDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = "Error from Rediucer";
      });
  },
});

export const { clearData } = getSectionDataSlice.actions;
export default getSectionDataSlice.reducer;
