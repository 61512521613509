import React, { useEffect, useState } from "react";
import DynamicButton from "../../common/DynamicButton.jsx";
import {
  faCirclePlus,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { DeleteTax, getAllTakesList } from "services/apiService";
import CommonTable from "../../common/CommonTable";
import { TaxListData } from "types/index.js";
import TaxEdit from "./TaxEdit";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Pagination } from "@mui/material";
import Swal from "sweetalert2";
import loadingImage from "../../assect/loadingSvg/loading.svg";

const TaxList = () => {
  const [taxSearch, setTaxSearch] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [allTaxes, setAllTaxes] = useState<TaxListData[]>([]);
  const [filteredTaxes, setFilteredTaxes] = useState<TaxListData[]>([]);
  const [isOpenTaxEdit, setIsOpenTaxEdit] = useState<boolean>(false);
  const [selectedTaxId, setSelectedTaxId] = useState<number | null>(null);
  const [itemsPerPage] = useState<number>(10);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    console.log("Current Page:", page);
  };

  const headers = [
    "S.no",
    "Tax Name",
    "Percentage",
    "Label",
    "Status",
    "Action",
  ];

  useEffect(() => {
    fetchAllTaxes();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [taxSearch, allTaxes]);

  const fetchAllTaxes = async () => {
    setLoader(true);
    try {
      const response = await getAllTakesList();
      setAllTaxes(response.data);
    } catch (error) {
      console.error("Error fetching taxes", error);
    }
    setLoader(false);
  };

  const handleSearch = () => {
    if (taxSearch.trim() === "") {
      setFilteredTaxes(allTaxes);
    } else {
      const searchQuery = taxSearch.toLowerCase();
      const filtered = allTaxes.filter((tax) =>
        tax.name.toLowerCase().includes(searchQuery)
      );
      setFilteredTaxes(filtered);
    }
    setCurrentPage(1);
  };

  const handleModelOpen = (id: number) => {
    setSelectedTaxId(id);
    setIsOpenTaxEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenTaxEdit(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteTax(id);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The Tax has been deleted.", "success");
            fetchAllTaxes();
          } else {
            Swal.fire("Error!", "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Tax is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const renderRow = (tax: TaxListData, index: number) => {
    const serialNumber = (currentPage - 1) * itemsPerPage + index + 1;
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{serialNumber}</td>
        <td className="table_product_name">
          <div className="d-flex flex-column product_name_td" title={tax.name}>
            {tax.name.length > 30 ? tax.name.slice(0, 30) + "..." : tax.name}
          </div>
        </td>
        <td className="align-content-center">{tax?.percentage}</td>
        <td
          className="table_product_name align-content-center"
          style={{ color: "grey" }}
        >
          {tax?.label}
        </td>
        <td className="align-content-center">
          <span
            style={{
              color: tax.is_active === 1 ? "green" : "red",
              backgroundColor: tax.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {tax?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>
        <td className="align-content-center ">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleModelOpen(tax.id)}
            />

            <DynamicButton
              icon={faTrashCan}
              iconStyle={{ color: "#f54242" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleDelete(tax.id, tax.name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPageData = filteredTaxes.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const totalPages = Math.ceil(filteredTaxes.length / itemsPerPage);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/tax-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Tax
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            placeholder="Search Tax Name ..."
            className="form-control"
            style={{ boxShadow: "none" }}
            value={taxSearch}
            onChange={(e) => setTaxSearch(e.target.value)}
          />
        </div>
      </div>

      <div className="product-table-content">
        {loader ? (
          <div className="loadingsvg-Image text-center">
            <img src={loadingImage} width={150} height={150} alt="Loading..." />
          </div>
        ) : (
          <CommonTable
            data={currentPageData}
            isLoading={loader}
            headers={headers}
            renderRow={renderRow}
          />
        )}
      </div>
      <div className="pagination-container">
        <div className="data-info" style={{ color: "grey" }}>
          <span>
            Showing {startIndex + 1}-
            {Math.min(startIndex + itemsPerPage, filteredTaxes.length)} of{" "}
            {filteredTaxes.length}
          </span>
        </div>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </div>

      <TaxEdit
        onActionComplete={fetchAllTaxes}
        open={isOpenTaxEdit}
        handleClose={handleModelClose}
        id={selectedTaxId}
      />
    </>
  );
};

export default TaxList;
