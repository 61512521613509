import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import Toggle from 'common/Toggle';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { SubpagesEdit, SubpagesUpdate } from 'services/apiService';
import { CoupnEditProps } from 'types';

const SubPagesEdit: React.FC<CoupnEditProps> = ({ open, id, handleClose, onActionComplete }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid white',
        boxShadow: 24,
        p: 4,
    };

    const [EditSubpagesform, setEditSubpagesForm] = useState({
        page_name: "",
        page_content: "",
        is_active: 0
    });
    const [errors, setErrors] = useState({
        page_name: "",
        page_content: ""
    });

    const handleTextFieldChange = (e) => {
        const { id, value } = e.target;
        setEditSubpagesForm((prev) => ({
            ...prev,
            [id]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: '',
        }));
    };

    const handleToggleChange = (checked: number, field: string) => {
        setEditSubpagesForm((prevData) => ({
            ...prevData,
            [field]: checked ? 1 : 0
        }));
    };

    const validateForm = () => {
        const newErrors: any = {};
        if (!EditSubpagesform.page_name) newErrors.page_name = 'Title is required.';
        if (!EditSubpagesform.page_content) newErrors.page_content = 'Description is required.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const fetchSubPages = async (id: number) => {
        try {
            const response = await SubpagesEdit(id);
            if (response.status_code === 200) {
                setEditSubpagesForm({
                    page_name: response.data.page_name,
                    page_content: response.data.page_content,
                    is_active: response.data.is_active,
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchSubPages(id);
        }
    }, [open, id]);

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                const createPayload = {
                    ...EditSubpagesform,
                    id: id,
                };

                const response = await SubpagesUpdate(createPayload);
                if (response.status_code === 200) {
                    toast.success("Successfully Updated");
                    handleClose()
                    if (onActionComplete) onActionComplete();
                } else {
                    toast.error("Update Failed");
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    };

    return (
        <Modal open={open} onClose={undefined}>
            <Box sx={style}>
                <Typography variant="h6" component="h2">
                    Edit Sub-Pages
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <form>
                        <div className="modal-body" style={{ display: 'grid', gap: '20px' }}>
                            <div className="form-group">
                                <TextField
                                    variant="outlined"
                                    label="Title"
                                    id="page_name"
                                    fullWidth
                                    value={EditSubpagesform.page_name}
                                    onChange={handleTextFieldChange}
                                    error={!!errors.page_name}
                                    helperText={errors.page_name}
                                />
                            </div>
                            <div className="form-group">
                                <TextField
                                    variant="outlined"
                                    id="page_content"
                                    fullWidth
                                    multiline
                                    label="Description"
                                    rows={10}
                                    value={EditSubpagesform.page_content}
                                    onChange={handleTextFieldChange}
                                    error={!!errors.page_content}
                                    helperText={errors.page_content}
                                />
                            </div>
                            <Box className="create_category_fields" mb={2}>
                                <Typography variant="subtitle1" className="text-capitalize label">
                                    STATUS
                                </Typography>
                                <Toggle
                                    checked={EditSubpagesform.is_active === 1}
                                    onChange={(checked) => handleToggleChange(checked, 'is_active')}
                                />
                            </Box>
                        </div>
                        <br />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </Button>
                    </form>
                </Typography>
            </Box>
        </Modal>
    );
};

export default SubPagesEdit;
