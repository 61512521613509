import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getSellerEdit, updateSeller } from "services/apiService";
import { BrandEditProps } from "types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

const SellerEdit: React.FC<BrandEditProps> = ({
  open,
  handleClose,
  id,
  onActionComplete,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    seller_id: "",
    address: "",
    phone_no: "",
    email: "",
    is_active: 1,
  });
  const [errors, setErrors] = useState({
    name: "",
    address: "",
    phone_no: "",
    email: "",
  });

  useEffect(() => {
    if (id) {
      fetchDetails(id);
    }
  }, [open]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setFormData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const validateFields = () => {
    const newErrors: typeof errors = {
      name: "",
      address: "",
      phone_no: "",
      email: "",
    };

    if (!formData.name.trim()) {
      newErrors.name = "Seller name is required.";
    } else if (formData.name.length > 30) {
      newErrors.name = "Seller name must not exceed 30 characters.";
    }

    if (!formData.address.trim()) {
      newErrors.address = "Seller address is required.";
    }

    if (!formData.phone_no.trim()) {
      newErrors.phone_no = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone_no)) {
      newErrors.phone_no = "Phone number must be 10 digits.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };
  const fetchDetails = async (id: number) => {
    try {
      const response = await getSellerEdit(id);
      const { seller_id, name, address, email, phone_no, is_active } =
        response.data;
      setFormData({
        seller_id,
        name,
        address,
        email,
        phone_no: String(phone_no),
        is_active,
      });
    } catch (error) {
      console.error("Error fetching seller details:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateFields()) {
      try {
        const payload = {
          seller_id: Number(formData.seller_id),
          seller_name: formData.name,
          address: formData.address,
          email: formData.email,
          phone_no: Number(formData.phone_no),
          is_active: Number(formData.is_active),
        };
        const response = await updateSeller(payload);
        if (response.status_code === 200) {
          onActionComplete();
          handleClose();
          toast.success(" Seller Updated  successfully");
        }
      } catch (error) {
        toast.error(" Seller Update failed");
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Box sx={style}>
          <Typography
            variant="h6"
            className="text-center mb-4"
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              backgroundColor: "#007bff",
              padding: "5px",
              color: "white",
            }}
          >
            Edit SELLER
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              variant="filled"
              id="name"
              label="Seller Name"
              fullWidth
              placeholder="Enter seller's name"
              value={formData.name}
              onChange={handleInputChange}
              error={!!errors.name}
              helperText={errors.name}
              sx={{ mb: 2 }}
            />
            <TextField
              variant="filled"
              id="address"
              label="Address"
              fullWidth
              placeholder="Enter your address, e.g., Building No, Plot No, City"
              value={formData.address}
              onChange={handleInputChange}
              error={!!errors.address}
              helperText={
                errors.address || "Address should not exceed 100 characters.."
              }
              sx={{ mb: 2 }}
              inputProps={{ maxLength: 100 }}
            />
            <TextField
              variant="filled"
              id="phone_no"
              label="Phone Number"
              fullWidth
              placeholder="Enter 10-digit phone number"
              value={formData.phone_no}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setFormData({ ...formData, phone_no: value });
                }
              }}
              error={!!errors.phone_no}
              helperText={errors.phone_no}
              sx={{ mb: 2 }}
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                style: { textAlign: "start" },
              }}
              type="tel"
            />
            <TextField
              variant="filled"
              id="email"
              label="Email"
              fullWidth
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              sx={{ mb: 2 }}
            />
            <Toggle
              checked={formData.is_active === 1}
              onChange={(checked) => handleToggleChange(checked, "is_active")}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mr: 2 }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </>
    </Modal>
  );
};

export default SellerEdit;
