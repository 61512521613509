import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { SubpagesCreate } from "services/apiService";

const SubPagesCreate = () => {
  const [Subpagesform, setSubpagesForm] = useState({
    page_name: "",
    description: "",
    is_active: 0,
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    page_name: "",
    description: "",
  });
  const handleTextFieldChange = (e) => {
    const { id, value } = e.target;
    setSubpagesForm((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    setSubpagesForm((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { page_name: "", description: "" };

    if (!Subpagesform.page_name.trim()) {
      newErrors.page_name = "Page name is required.";
      isValid = false;
    }

    if (!Subpagesform.description.trim()) {
      newErrors.description = "Description is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          page_name: Subpagesform.page_name,
          page_content: Subpagesform.description,
          is_active: Subpagesform.is_active,
        };
        const response = await SubpagesCreate(payload);
        if (response.status_code === 200) {
          toast.success("SubPage created successfully");
          navigate("/admin/sub-pages-list");
        } else if (response === 400) {
          toast.error("SubPage creation Failed , Duplication occured");
        } else {
          toast.error("SubPage creation Failed");
        }
      } catch (error) {
        toast.error("SubPage creation Failed , Duplication occured");
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                TITTLE
              </Typography>
              <TextField
                variant="filled"
                id="page_name"
                fullWidth
                value={Subpagesform.page_name}
                onChange={handleTextFieldChange}
                error={!!errors.page_name}
                helperText={errors.page_name}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                HTML CODE
              </Typography>
              <TextField
                variant="filled"
                id="description"
                fullWidth
                multiline
                rows={4}
                value={Subpagesform.description}
                onChange={handleTextFieldChange}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                STATUS
              </Typography>
              <Toggle
                checked={Subpagesform.is_active === 1}
                onChange={(checked) => handleToggleChange(checked, "is_active")}
              />
            </Box>
            <Box textAlign="end" mt={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp; Create Page
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default SubPagesCreate;
