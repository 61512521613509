import React from "react";

const Header = ({
  heading = "Product Page",
  mainPage = "Dashboard / Product /",
  innerPage = "Create",
  extClass = "mb-2",
}) => {
  return (
    <div className={`product_main_top ${extClass} poppins`}>
      <h6 className="text-uppercase fw-bold">{heading}</h6>
      <div>
        <span className="blue-color">{mainPage}</span>
        <span className="text-capitalize inner_page"> {innerPage}</span>
      </div>
    </div>
  );
};

export default Header;
