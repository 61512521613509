import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
// import { getAllSectionList } from "services/apiService";
import { SectionLists } from "types";
import JsonSectionCreate from "./JsonSectionCreate";
import "../../../styles/sectionc.css";
import "../../../styles/sectionList.css";
import KanbanBoard from "./KanbanBoard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import sectionData from "./sectionCreateData/sectionCreateData.json";
import PreviewImageModal from "./PreviewImageModal";
import { useDispatch } from "react-redux";
import { fetchSectionData } from "../../../redux/Slice/jsonDataSlice.js";
import { fetchAdsDropDown } from "../../../redux/Slice/adsDropDownSlice.js";
import { fetchClassificationDropDown } from "../../../redux/Slice/classificationDropdownSlice.js";
import { fetchCampaignDropDown } from "../../../redux/Slice/campaignSlice.js";
import { clearSelections } from "../../../redux/Slice/mediaSlice.js";
import sectionCreateData from "../SectionsList/sectionCreateData/sectionCreateData.json";

Modal.setAppElement("#root");

const SectionsList = () => {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [sections, setSections] = useState<any[]>([]);
  const [currentSection, setCurrentSection] = useState<string | null>(null);
  const [sectionId, setSectionId] = useState<number | null>(null);
  const [previewImage, setPreviewImage] = useState("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const pageId = localStorage.getItem("pageid");
  // const getSectionListData = useCallback(async () => {
  //   try {
  //     const response = await getAllSectionList();
  //     console.log(response.data, "response");

  // setSections(response.data);

  //   } catch (error) {
  //     console.error("Error fetching sections:", error);
  //   }
  // }, []);

  useEffect(() => {
    dispatch(fetchSectionData());
    dispatch(fetchAdsDropDown());
    dispatch(fetchClassificationDropDown());
    dispatch(fetchCampaignDropDown());
  }, [sectionCreateData]);
  useEffect(() => {
    const foundSection = sectionCreateData?.filter((section: any) => {
      return section.page_id === parseInt(pageId);
    });
    setSections(foundSection);
  }, [pageId]);

  useEffect(() => {
    if (currentSection) {
      const dataFromJson = sectionData?.find(
        (section) => section.section_name === currentSection
      );

      if (dataFromJson) {
        setPreviewImage(dataFromJson.preview_section);
      }
    }
  }, [currentSection]);

  const openCreateModal = (section: SectionLists) => {
    setSectionId(section.section_id);
    setCurrentSection(section.section_name);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setIsPreviewModalOpen(false);
    setModalIsOpen(false);
    setCurrentSection(null);
  };
  const openPreviewModal = (section: SectionLists, previewImage: string) => {
    setIsPreviewModalOpen(true);
    setCurrentSection(section.section_name);
  };
  const PreviweCloseModal = () => {
    setIsPreviewModalOpen(false);
    setCurrentSection(null);
  };
  return (
    <>
      <section className="d-flex justify-content-evenly">
        <div className="section-left col-lg-6">
          <div className="section-left-body d-flex flex-wrap justify-content-start">
            {sections?.map((section, index) => {
              return (
                <div className="box" key={index}>
                  <div className="box-content">
                    <h5 className="box-title">{section.section_name}</h5>
                  </div>
                  <div className="box-overlay">
                    <FontAwesomeIcon
                      icon={faEye}
                      className="box-icon"
                      onClick={() => {
                        openPreviewModal(section, previewImage);
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faFileCirclePlus}
                      className="box-icon"
                      onClick={() => {
                        openCreateModal(section);
                        dispatch(clearSelections());
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="section-right col-lg-5">
          <h4>Section Details</h4>
          <DndProvider backend={HTML5Backend}>
            <KanbanBoard pageid={pageId} modalIsOpen={modalIsOpen} />
          </DndProvider>
        </div>
      </section>

      {modalIsOpen ? (
        <JsonSectionCreate
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          sectionName={currentSection}
          section_id={sectionId}
        />
      ) : null}
      <PreviewImageModal
        isOpen={isPreviewModalOpen}
        onRequestClose={PreviweCloseModal}
        previewImagePath={previewImage}
        setPreviewImagePath={setPreviewImage}
      />
    </>
  );
};

export default SectionsList;
