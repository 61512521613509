import Select from "react-select";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { createSection } from "services/apiService";
import { validateField } from "validation/SectionUpdateValidation";
import ImageGallery from "./Upload/ImageGallery";
import VideoGallery from "./Upload/VideoGallery";
import toast from "react-hot-toast";
import "../../../styles/ModalStyles.css";
import sectionCreateData from "./sectionCreateData/sectionCreateData.json";
import { useDispatch, useSelector } from "react-redux";
import { clearSelections } from "../../../redux/Slice/mediaSlice.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import VideoDisplay from "./Upload/VideoDisplay";
import ImageDisplay from "./Upload/ImageDisplay";
const JsonSectionCreate = ({
  isOpen,
  onRequestClose,
  sectionName,
  section_id,
}) => {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const [isVideoGalleryOpen, setIsVideoGalleryOpen] = useState(false);
  const [getId, setGetId] = useState();
  const [isMulti, setIsMulti] = useState(false);
  const pageid = localStorage.getItem("pageid");
  const [errors, setErrors] = useState({});
  const resetState = () => {
    setFormData(null);
  };
  const sectionDatasss = useSelector(
    (state: any) => state?.jsondata?.sectionCreateData
  );
  const adsDropdown = useSelector((state: any) =>
    state.adsDropDownData.adsDropDownDatas?.map((option) => ({
      value: option?.id,
      label: option?.name?.toUpperCase(),
    }))
  );
  const sectionDropdownData = useSelector((state: any) =>
    state.classificationData.classificationDropDownData?.map((option) => ({
      value: option?.classification_id,
      label: option?.classification_name?.toUpperCase(),
    }))
  );
  const campaignData = useSelector((state: any) =>
    state.campaignData.campaignDropDownData?.map((option) => ({
      value: option?.campaign_id,
      label: option?.campaign_name?.toUpperCase(),
    }))
  );
  const multiImagesSelect = useSelector(
    (state: any) => state.media.selectedImagesByKey
  );
  const singleImage = useSelector(
    (state: any) => state.media.singleImageSelections
  );
  const multiVideo = useSelector(
    (state: any) => state.media.selectedVideosByKey
  );
  const singleVideo = useSelector(
    (state: any) => state.media.singleVideoSelections
  );
  useEffect(() => {
    if (multiImagesSelect) {
      Object.keys(multiImagesSelect).forEach((key) => {
        const remvoeQuets = multiImagesSelect[key]
          ?.split(",")
          .filter((item) => item);
        setFormData((prevData) => ({
          ...prevData,
          [key]: remvoeQuets.join(","),
        }));
      });
    }
    if (singleImage) {
      Object.keys(singleImage).forEach((key) => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: singleImage[key],
        }));
      });
    }
    if (multiVideo) {
      Object.keys(multiVideo).forEach((key) => {
        const remvoeQue = multiVideo[key]?.split(",").filter((item) => item);
        setFormData((prevData) => ({
          ...prevData,
          [key]: remvoeQue.join(","),
        }));
      });
    }
    if (singleVideo) {
      Object.keys(singleVideo).forEach((key) => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: singleVideo[key],
        }));
      });
    }
  }, [multiImagesSelect, singleImage, multiVideo, singleVideo]);
  useEffect(() => {
    const foundSection = sectionCreateData?.find((section: any) => {
      return (
        section.section_name === sectionName &&
        section.page_id === parseInt(pageid) &&
        section.section_id === section_id
      );
    });

    if (foundSection) {
      setSectionData(foundSection.data);
    } else {
      setSectionData(null);
    }
  }, [sectionName, pageid, section_id, sectionCreateData]);
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      section_id: section_id,
    }));
  }, [section_id]);
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData: {}) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData: {}) => ({
      ...prevFormData,
      [name]: checked ? 1 : 0,
    }));
  };
  const handleSelectChange = (name, selectedOption) => {
    let newValue;
    if (Array.isArray(selectedOption)) {
      newValue = selectedOption.map((option) => option.value);
    } else if (selectedOption) {
      newValue = selectedOption.value;
    } else {
      newValue = "";
    }
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    const payload = {
      page_id: parseInt(pageid),
      section_id: formData.section_id,
      section_value: Object.keys(formData)
        ?.filter((key) => key !== "section_id")
        ?.map((key) => {
          let keyValue = formData[key];
          if (key) {
            keyValue = String(keyValue);
          }
          return {
            key_name: key,
            key_value: keyValue,
          };
        }),
    };
    try {
      const validationSchema = validateField(sectionData, payload);
      setErrors(validationSchema);
      if (Object.keys(validationSchema).length === 0) {
        const response = await createSection(payload);
        if (response.status_code === 200) {
          onRequestClose();
          dispatch(clearSelections());
          toast.success("Section Created Successfully!");
        }
      }
    } catch (error) {}
  };
  const renderField = (field) => {
    const Multi = field.is_multi_select === 1;
    switch (field.type) {
      case "drop_down":
        return (
          <Select
            key={field.key_name}
            name={field.key_name}
            options={
              field.key_name === "ADS"
                ? adsDropdown
                : field.key_name === "CAMP"
                ? campaignData
                : sectionDropdownData
            }
            value={
              field.key_name === "ADS"
                ? adsDropdown?.filter((option) =>
                    Array.isArray(formData?.ADS)
                      ? formData?.ADS?.includes(option.value)
                      : option.value === formData?.ADS
                  )
                : field.key_name === "CAMP"
                ? campaignData?.filter((option) =>
                    Array.isArray(formData?.CAMP)
                      ? formData.CAMP.includes(option.value)
                      : option.value === formData?.CAMP
                  )
                : sectionDropdownData?.filter((option) =>
                    Array.isArray(formData?.CLASSIFI)
                      ? formData?.CLASSIFI?.includes(option.value)
                      : option.value === formData?.CLASSIFI
                  )
            }
            onChange={(selectedOption) => {
              handleSelectChange(field.key_name, selectedOption);
              setErrors((prevErrors) => ({
                ...prevErrors,
                [field.key_name]: "",
              }));
            }}
            isMulti={Multi}
          />
        );
      case "text_input":
        return (
          <input
            key={field.key_name}
            name={field.key_name}
            type="text"
            onChange={handleUpdateChange}
            onBlur={handleBlur}
            className="form-control"
            placeholder={`Enter ${field.key_name} here`}
          />
        );
      case "FILE":
        return (
          <>
            {field.is_video === 1 ||
            field.is_video === 0 ||
            field.is_multi_select === null ? (
              <button
                className="cls btn--video"
                onClick={() => {
                  setIsVideoGalleryOpen(true);
                  setGetId(field.key_name);
                  setIsMulti(field.is_video === 1 ? true : false);
                }}
              >
                <span className="btn-icon">
                  <FontAwesomeIcon icon={faVideo} />
                </span>
                Add Video
              </button>
            ) : (
              (field.is_multi_select === 1 ||
                field.is_multi_select === 0 ||
                field.is_video === null) && (
                <button
                  className="cls btn--image"
                  onClick={() => {
                    setIsImageGalleryOpen(true);
                    setGetId(field.key_name);
                    setIsMulti(field.is_multi_select === 1 ? true : false);
                  }}
                >
                  <span className="btn-icon">
                    <FontAwesomeIcon icon={faImage} />
                  </span>
                  Add Image
                </button>
              )
            )}
            <ImageGallery
              isOpen={isImageGalleryOpen}
              onRequestClose={() => setIsImageGalleryOpen(false)}
              isMulti={isMulti}
              keyName={getId}
              handleBlur={handleBlur}
            />
            <VideoGallery
              isOpen={isVideoGalleryOpen}
              onRequestClose={() => setIsVideoGalleryOpen(false)}
              keyName={getId}
              isMulti={isMulti}
              handleBlur={handleBlur}
            />

            {Object.keys(singleImage).includes(field.key_name) && (
              <>
                <ImageDisplay
                  field={field.key_name}
                  imagesData={singleImage}
                  single={true}
                  isEditing={true}
                />
              </>
            )}
            {Object.keys(multiImagesSelect).includes(field.key_name) && (
              <>
                <ImageDisplay
                  field={field.key_name}
                  imagesData={multiImagesSelect}
                  single={false}
                  isEditing={true}
                />
              </>
            )}

            {Object.keys(multiVideo).includes(field.key_name) && (
              <>
                <VideoDisplay
                  field={field.key_name}
                  videoUrl={multiVideo}
                  single={false}
                  isEditing={true}
                />
              </>
            )}

            {Object.keys(singleVideo).includes(field.key_name) && (
              <>
                <VideoDisplay
                  field={field.key_name}
                  videoUrl={singleVideo}
                  single={true}
                  isEditing={true}
                />
              </>
            )}
          </>
        );
      case "toggle_input":
        return (
          <div>
            <label className="toggle-switch">
              <input
                type="checkbox"
                name={field.key_name}
                onChange={(e) => {
                  handleToggleChange(e);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    [field.key_name]: "",
                  }));
                }}
              />
              <span className="toggle-slider"></span>
            </label>
          </div>
        );
      case "desc_input":
        return (
          <textarea
            name={field?.key_name}
            onChange={handleUpdateChange}
            onBlur={() => handleBlur}
            className="form-control"
            placeholder={`Enter ${field.key_name.toLowerCase()} here`}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Section"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "9999",
        },
        content: {
          width: "1020px",
          height: "auto",
          maxHeight: "80vh",
          overflow: "auto",
          borderRadius: "10px",
          padding: "20px",
          position: "relative",
          margin: "auto",
          backgroundColor: "#f8f9fa",
          scrollbarWidth: "none",
        },
      }}
    >
      <div className="modal-header-section d-flex justify-content-between align-items-center mb-3">
        <span
          className="m-0 p-0"
          style={{ fontSize: "24px", color: "#ffffff", margin: 0 }}
        >
          Add Section
        </span>
        <button
          onClick={() => {
            resetState();
            onRequestClose();
            setErrors({});
            dispatch(clearSelections());
          }}
          style={{
            backgroundColor: "#1467a5",
            border: "none",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          X
        </button>
      </div>
      <div >
        {sectionData ? (
          <div className="form-container">
            {sectionData?.map((field) => (
              <div key={field.key_name} className="form-group mb-3">
                <label className="me-4" htmlFor={field.key_name}>
                  {field.key_name}
                </label>
                {renderField(field)}

                {errors[field.key_name] ? (
                  <div className="error-message text-danger">
                    {errors[field.key_name]}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <p>No section data found for "{sectionName}".</p>
        )}
      </div>
      <div className="d-flex justify-content-end mt-5">
        <button
          type="button"
          onClick={(e) => handleSubmit(e)}
          className="save-button btn border rounded w-100 text-white"
        >
          Save
        </button>
      </div>
    </Modal>
  );
};
export default JsonSectionCreate;
