import {
  faCirclePlus,
  faMagnifyingGlass,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import React, { useEffect, useState } from "react";
import {
  DeleteFaqCategories,
  getAllFaqCategoriesList,
} from "services/apiService";
import loadingImage from "../../../assect/loadingSvg/loading.svg";
import DynamicButton from "../../../common/DynamicButton.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import FaqCategoriesEdit from "./FaqCategoriesEdit";
import Swal from "sweetalert2";

const FaqCategoriesList = () => {
  const [faqCatergoriesListData, SetfaqCatergoriesListData] = useState([]);
  const [filteredFaqCategories, setFilteredFaqCategories] = useState([]);
  const [loader, setloader] = useState(false);
  const [isOpenSubPageEdit, setIsOpenSubpagesEdit] = useState<boolean>(false);
  const [selectedSubPagesId, setSelectedSubpagesId] = useState<number | null>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");

  const headers = ["S.no", "Name", "Description", "Status", "Action"];

  useEffect(() => {
    getAllFaqCategories();
  }, []);

  const getAllFaqCategories = async () => {
    setloader(true);
    try {
      const response = await getAllFaqCategoriesList();
      SetfaqCatergoriesListData(response.data);
      setFilteredFaqCategories(response.data); // Initialize filtered data
    } catch (error) {
      console.error("Error fetching FAQ categories:", error);
    } finally {
      setloader(false);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);

    const filteredData = faqCatergoriesListData.filter((faq: any) =>
      faq.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredFaqCategories(filteredData);
  };

  const handleModelOpen = (id: number) => {
    setSelectedSubpagesId(id);
    setIsOpenSubpagesEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenSubpagesEdit(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteFaqCategories(id);
          if (response.status_code === 200) {
            Swal.fire(
              "Deleted!",
              "The Faq Categories has been deleted.",
              "success"
            );
            getAllFaqCategories();
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Faq Categories is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const renderRow = (faq: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td>{faq.name}</td>
        <td title={faq.description}>
          {faq.description?.length > 30
            ? faq.description?.slice(0, 30) + "..."
            : faq.description}
        </td>
        <td>
          <span
            style={{
              color: faq.is_active === 1 ? "green" : "red",
              backgroundColor: faq.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {faq.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>
        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              onClick={() => handleModelOpen(faq.id)}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
            />

            <DynamicButton
              icon={faTrash}
              iconStyle={{ color: "red" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleDelete(faq.id, faq.name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/faq-category-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Faq Category
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search by name"
            style={{ boxShadow: "none" }}
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
            </span>
          </div>
        </div>
      </div>

      <div className="product-table-container">
        <div className="product-table-content">
          {loader ? (
            <div className="loadingsvg-Image text-center">
              <img
                src={loadingImage}
                width={150}
                height={150}
                alt="Loading..."
              />
            </div>
          ) : (
            <CommonTable
              data={filteredFaqCategories}
              isLoading={loader}
              headers={headers}
              renderRow={renderRow}
            />
          )}
        </div>
      </div>

      <FaqCategoriesEdit
        onActionComplete={() => {
          getAllFaqCategories();
        }}
        open={isOpenSubPageEdit}
        handleClose={handleModelClose}
        id={selectedSubPagesId}
      />
    </>
  );
};

export default FaqCategoriesList;
