import React from "react";
import { NavLink } from "react-router-dom";

const Dropdown = ({ item, activeItem, setActiveItem, currentRoute }) => {
  const handleMainListClick = () => {
    setActiveItem(item !== activeItem ? item : null);
  };

  return (
    <div className="sidebar_dropdown" key={item.name}>
      {/* Main Item */}
      <div
        role="button"
        onClick={handleMainListClick}
        className="d-flex justify-content-between align-items-center"
      >
        <div className="sidebar_dropdown_select d-flex gap-3 align-items-center sidebar_main_tab">
          <i className={`bi ${item.icon} mt-1`}></i>
          <h5 className="m-0 p-0 text-capitalize fw-bold fs-16">{item.name}</h5>
        </div>
        <i
          className={`bi ${
            activeItem === item ? "bi-chevron-up" : "bi-chevron-down"
          } mt-1`}
        ></i>
      </div>
      <div
        className={`sidebar_dropdown_list transition-all duration-900 ease-in-out mt-2 overflow-hidden ${
          activeItem === item ? "max-h-100" : "max-h-0"
        }`}
      >
        {/* Sub Items */}
        {item?.sub_options?.length && (
          <>
            {item?.sub_options?.map((subItem, i) => {
              const subItemRoute = `/admin/${item.route.toLowerCase()}-${subItem.name.toLowerCase()}`;
              return (
                <div
                  className="sidebar_sublist mt-1"
                  key={i}
                  style={{
                    backgroundColor:
                      currentRoute === subItemRoute ? "#ffba0a" : "transparent",
                    color: currentRoute === subItemRoute ? "#1467a5" : "black",
                    textAlign: "center",
                  }}
                >
                  <NavLink
                    to={subItemRoute}
                    className="d-flex gap-2 align-items-center text-decoration-none w-100"
                  >
                    <div className="d-flex justify-content-between align-items-center subListSidebar">
                      <h6 className="text-capitalize font-semibold d-flex gap-2 align-items-center fs-14 p-1">
                        <i
                          className={`bi-dot fs-16`}
                          style={{ color: "white" }}
                        ></i>
                        <span className="custom-span">
                          {subItem.name.replace("-", " ")}
                        </span>
                      </h6>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
