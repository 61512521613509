import React, { useEffect, useState } from "react";
import { getAppAboutUsData } from "services/apiService";
import Gif_Image from "../../assect/construction.gif";

const AboutUsList = () => {
  const [appAboutUsData, setAppAboutus] = useState([]);
  useEffect(() => {
    getAppAboutus();
  }, []);
  const getAppAboutus = async () => {
    try {
      const response = await getAppAboutUsData();
      setAppAboutus(response.data);
    } catch (error) {}
  };
  console.log(appAboutUsData, "appAboutUsData");

  return (
    <>
      <div className="container d-flex justify-content-center">
        <img src={Gif_Image} />
      </div>
    </>
  );
};

export default AboutUsList;
