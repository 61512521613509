import React from "react";
import { Bar, Doughnut, Line, Radar } from "react-chartjs-2";
import Dhashboard from "../../Images/Dhashboard.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxesPacking,
  faBoxesStacked,
  faCartShopping,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const lineData = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "This Week",
        data: [60, 80, 70, 90, 110, 130, 120],
        borderColor: "#5a55ea",
        backgroundColor: "rgba(90, 85, 234, 0.1)",
        fill: true,
      },
      {
        label: "Previous Week",
        data: [40, 60, 55, 70, 80, 100, 90],
        borderColor: "#8bd1f0",
        backgroundColor: "rgba(139, 209, 240, 0.1)",
        fill: true,
      },
    ],
  };

  const radarData = {
    labels: ["Admin", "Social", "Support", "Tech", "Dev", "Sales", "Marketing"],
    datasets: [
      {
        label: "Allocated Budget",
        data: [20, 15, 30, 35, 45, 25, 40],
        backgroundColor: "rgba(66, 135, 245, 0.2)",
        borderColor: "#4287f5",
        borderWidth: 1,
      },
      {
        label: "Actual Spending",
        data: [18, 10, 25, 28, 40, 20, 30],
        backgroundColor: "rgba(47, 203, 113, 0.2)",
        borderColor: "#2fcb71",
        borderWidth: 1,
      },
    ],
  };

  const barData = {
    labels: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    datasets: [
      {
        label: "Visitors",
        data: [50, 70, 90, 150, 30, 60, 100, 160],
        backgroundColor: "#4b7bec",
      },
    ],
  };
  const data = {
    labels: ["Groceries", "Electronics", "Others"],
    datasets: [
      {
        data: [9500, 11500, 11000],
        backgroundColor: ["#4CAF50", "#03A9F4", "#9C27B0"],
        hoverBackgroundColor: ["#66BB6A", "#29B6F6", "#AB47BC"],
      },
    ],
  };

  // Options for the chart
  const options = {
    cutout: "70%", // Adjusts the size of the center cutout
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
    },
  };

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div className="col-md-6 mb-3">
          <div
            className="card p-5"
            style={{
              backgroundColor: "rgba(183,193,244,.6)",
              borderRadius: "0px",
              border: "none",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 style={{ fontWeight: "bold" }}>Good Morning, Victor!</h5>
                <p>Here's what's happening with your store today</p>
                <div className="d-flex justify-content-between mt-5">
                  <div>
                    <h6 style={{ color: "grey", fontSize: "14px" }}>
                      TODAY'S VISIT
                    </h6>
                    <h3 style={{ fontWeight: "900" }}>15,209</h3>
                  </div>
                  <div style={{ marginLeft: "1.5rem" }}>
                    <h6 style={{ color: "grey", fontSize: "14px" }}>
                      TODAY'S TOTAL SALES
                    </h6>
                    <h3 style={{ fontWeight: "900" }}>$29,115.50</h3>
                  </div>
                </div>
              </div>
              {/* Right Section - Image */}
              <div>
                <img
                  src={Dhashboard}
                  alt="dashboard-image"
                  width={250}
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Right Card - Metrics Overview */}
        <div className="col-md-6 mb-3">
          <div className="row d-flex align-items-stretch">
            {/* Orders Card */}
            <div className="col-6 mb-3">
              <div className="card p-2 d-flex justify-content-center align-items-center text-center">
                <div className="me-3">
                  <FontAwesomeIcon
                    icon={faBoxesPacking}
                    style={{
                      color: "#4CAF50",
                      fontSize: "1.5rem",
                      marginBottom: "0.5rem",
                    }}
                  />
                </div>
                <div>
                  <h6 className="text-muted">ORDERS</h6>
                  <h3 className="mb-1">15,209</h3>
                  <p className="text-success">5.5% ↑</p>
                </div>
              </div>
            </div>

            {/* Gross Sale Card */}
            <div className="col-6 mb-3">
              <div className="card p-2 d-flex justify-content-center align-items-center text-center">
                <FontAwesomeIcon
                  icon={faBoxesStacked}
                  style={{
                    color: "#FF9800",
                    fontSize: "1.5rem",
                    marginBottom: "0.5rem",
                  }}
                />

                <h6 className="text-muted">GROSS SALE</h6>
                <h3 className="mb-1">$12,345</h3>
                <p className="text-success">11.5% ↑</p>
              </div>
            </div>

            {/* Shipping Card */}
            <div className="col-6 mb-3">
              <div className="card p-2 d-flex justify-content-center align-items-center text-center">
                <FontAwesomeIcon
                  icon={faTruckFast}
                  style={{
                    color: "#03A9F4",
                    fontSize: "1.5rem",
                    marginBottom: "0.5rem",
                  }}
                />
                <h6 className="text-muted">SHIPPING</h6>
                <h3 className="mb-1">$360</h3>
                <p className="text-danger">1.5% ↓</p>
              </div>
            </div>

            {/* Item Sold Card */}
            <div className="col-6 mb-3">
              <div className="card p-2 d-flex justify-content-center align-items-center text-center">
                <FontAwesomeIcon
                  icon={faCartShopping}
                  style={{
                    color: "#9C27B0",
                    fontSize: "1.5rem",
                    marginBottom: "0.5rem",
                  }}
                />
                <h6 className="text-muted">ITEM SOLD</h6>
                <h3 className="mb-1">7,360</h3>
                <p className="text-success">11.5% ↑</p>
              </div>
            </div>
          </div>
        </div>

        {/* Second Row - Weekly Sales and Expected Earnings */}
        <div className="col-md-6 mb-3">
          <div className="card p-4">
            <h6 style={{ fontWeight: "bold" }}>Weekly Sales</h6>
            <Line data={lineData} />
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <div className="card p-4">
            <h6>Website Visitors</h6>
            <Bar data={barData} />
          </div>
        </div>

        {/* Radar Chart Card */}
        <div className="col-md-6 mb-3">
          <div className="card p-4 d-flex flex-column align-items-center">
            <h6 className="card-title text-center">Sales By POS Location</h6>
            <div
              style={{ width: "400px", height: "400px" }}
              className="d-flex justify-content-center"
            >
              <Radar data={radarData} />
            </div>
            <div className="text-center d-flex justify-content-between mt-3">
              <p>
                Allocated Budget - $29,500.00{" "}
                <span className="text-success">5.5% ↑</span>
              </p>
              <p>
                Actual Spending - $19,500.00{" "}
                <span className="text-danger">1.4% ↓</span>
              </p>
            </div>
          </div>
        </div>

        {/* Bar Chart Card */}
        <div className="col-md-6 mb-3">
          <div className="card  mb-3">
            <div className="card-body  d-flex justify-content-evenly">
              {/* Earnings Information */}
              <div className="pe-4">
                <h6 className="text-muted">EXPECTED EARNINGS</h6>
                <h3>$32,000</h3>
                <p className="text-success">5.5% ↑</p>
                <div>
                  <p className="mb-1">
                    <span
                      className="dot"
                      style={{ backgroundColor: "#4CAF50" }}
                    ></span>{" "}
                    Groceries - $9,500
                  </p>
                  <p className="mb-1">
                    <span
                      className="dot"
                      style={{ backgroundColor: "#03A9F4" }}
                    ></span>{" "}
                    Electronics - $11,500
                  </p>
                  <p>
                    <span
                      className="dot"
                      style={{ backgroundColor: "#9C27B0" }}
                    ></span>{" "}
                    Others - $11,000
                  </p>
                </div>
              </div>

              {/* Doughnut Chart */}
              <div style={{ width: "200px", height: "200px" }}>
                <Doughnut data={data} options={options} />
              </div>
            </div>
          </div>

          <div className="card ">
            <div className="card-body">
              <h6 className="card-title">Top Selling Products</h6>
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Macbook Air - 8gb <span>321</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  iPad Pro 14 - With Pencil <span>250</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  iPad Pro 14 - With Pencil <span>250</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  iPad Pro 14 - With Pencil <span>250</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Clay Camera For Showcase <span>121</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Products Orders Table */}
        <div className="col-lg-6 mb-4">
          <div className="card h-100">
            <div className="card-body">
              <h6 className="card-title">Products Orders</h6>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ORDER ID</th>
                    <th>CREATED</th>
                    <th>CUSTOMER</th>
                    <th>TOTAL</th>
                    <th>PROFIT</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#XTD-4629</td>
                    <td>2 mins ago</td>
                    <td>J. Ronan</td>
                    <td>$650</td>
                    <td>$150</td>
                    <td>
                      <span className="badge bg-warning text-dark">
                        Pending
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>#SPI-2123</td>
                    <td>5 mins ago</td>
                    <td>V. James</td>
                    <td>$720</td>
                    <td>$220</td>
                    <td>
                      <span className="badge bg-success">Confirmed</span>
                    </td>
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Stock Report Table */}
        <div className="col-lg-6 mb-4">
          <div className="card h-100">
            <div className="card-body">
              <h6 className="card-title">Stock Report</h6>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>PRODUCT ID</th>
                    <th>PRICE</th>
                    <th>STATUS</th>
                    <th>QTY</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Macbook Air - 8gb</td>
                    <td>#SPI-2123</td>
                    <td>$1650</td>
                    <td>
                      <span className="badge bg-success">In Stock</span>
                    </td>
                    <td>50 Pcs</td>
                  </tr>
                  <tr>
                    <td>Macbook Air - 8gb</td>
                    <td>#SPI-2123</td>
                    <td>$1650</td>
                    <td>
                      <span className="badge bg-success">In Stock</span>
                    </td>
                    <td>50 Pcs</td>
                  </tr>
                  <tr>
                    <td>Macbook Air - 8gb</td>
                    <td>#SPI-2123</td>
                    <td>$1650</td>
                    <td>
                      <span className="badge bg-success">In Stock</span>
                    </td>
                    <td>50 Pcs</td>
                  </tr>
                  <tr>
                    <td>Samsung S23 Ultra</td>
                    <td>#TDZ-1123</td>
                    <td>$1850</td>
                    <td>
                      <span className="badge bg-danger">Out Of Stock</span>
                    </td>
                    <td>0 Pcs</td>
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
