import {
  faCirclePlus,
  faMagnifyingGlass,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import React, { useEffect, useState } from "react";
import { DeleteSubPages, getAllSubPagesList } from "services/apiService";
import loadingImage from "../../../assect/loadingSvg/loading.svg";
import DynamicButton from "../../../common/DynamicButton.jsx";
import SubPagesEdit from "./SubPagesEdit";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubPageList = () => {
  const [subPagesListData, setSubPagesListData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setloader] = useState(false);
  const [isOpenSubPageEdit, setIsOpenSubpagesEdit] = useState<boolean>(false);
  const [selectedSubPagesId, setSelectedSubpagesId] = useState<number | null>(
    null
  );
  const [subPageSearch, setSubPageSearch] = useState("");

  useEffect(() => {
    getsubPagesList();
  }, []);

  const getsubPagesList = async () => {
    setloader(true);
    try {
      const response = await getAllSubPagesList();
      setSubPagesListData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      setloader(false);
    }
    setloader(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSubPageSearch(searchValue);
    const filtered = subPagesListData.filter((subpage) =>
      subpage.page_name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const headers = ["S.no", "Sub Page Name", "Action"];

  const handleModelOpen = (id: number) => {
    setSelectedSubpagesId(id);
    setIsOpenSubpagesEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenSubpagesEdit(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteSubPages(id);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The SubPage has been deleted.", "success");
            getsubPagesList();
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The SubPage is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const renderRow = (subpage: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td>{subpage.page_name}</td>
        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleModelOpen(subpage.page_id)}
            />
            <DynamicButton
              icon={faTrash}
              iconStyle={{ color: "red" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleDelete(subpage.page_id, subpage.page_name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between  ">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/sub-pages-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create SUB PAGES
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            value={subPageSearch}
            placeholder="Search Sub Pages..."
            style={{ boxShadow: "none" }}
            onChange={handleSearch}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
            </span>
          </div>
        </div>
      </div>

      <div className="product-table-container">
        <div className="product-table-content">
          {loader ? (
            <div className="loadingsvg-Image text-center">
              <img
                src={loadingImage}
                width={150}
                height={150}
                alt="Loading..."
              />
            </div>
          ) : (
            <CommonTable
              data={filteredData}
              isLoading={loader}
              headers={headers}
              renderRow={renderRow}
            />
          )}
        </div>
      </div>
      <SubPagesEdit
        onActionComplete={() => {
          getsubPagesList();
        }}
        open={isOpenSubPageEdit}
        handleClose={handleModelClose}
        id={selectedSubPagesId}
      />
    </>
  );
};

export default SubPageList;
