import React from "react";
import Modal from "react-modal";

const PreviewImageModal = ({
  isOpen,
  onRequestClose,
  previewImagePath,
  setPreviewImagePath,
}) => {
  let previewImage;
  try {
    previewImage = require(`../../../assect/SectionImages/${previewImagePath}`);
  } catch (err) {
    console.error("Image not found, using fallback", err);
    previewImage = require(`../../../assect/NoPreview/noprview.png`);
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Section"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: "15px",
          padding: "20px",
          width: "1000px",
          height: "650px",
          overflowY: "hidden",
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
          border: "2px solid #63E6BE",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        },
      }}
    >
      <div
        className="modal-header-section"
        style={{ width: "100%", alignItems: "center" }}
      >
        <span className="m-0 p-0 fs-4">PreviewImage</span>

        <button
          onClick={() => {
            onRequestClose();
            setPreviewImagePath("");
          }}
          style={{
            backgroundColor: "#1467a5",
            border: "none",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          X
        </button>
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <img
          src={previewImage}
          alt="Preview"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </div>
    </Modal>
  );
};

export default PreviewImageModal;
