import { Box, Button, Checkbox, FormControlLabel, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { TaxEditdata, TaxUpdate } from 'services/apiService';
import { TaxEditProps } from 'types';

const TaxEdit: React.FC<TaxEditProps> = ({ id, open, handleClose, onActionComplete }) => {
    const [taxList, setTaxlist] = useState({
        name: "",
        percentage: "",
        label: "",
        is_active: 1
    })
    const [errors, setErrors] = useState({
        name: "",
        percentage: "",
        label: "",
    });
    const validateForm = () => {
        const newErrors: any = {};
        if (!taxList.name) newErrors.name = 'Name is required.';
        if (!taxList.percentage) newErrors.percentage = 'percentage is required.';
        if (!taxList.label) newErrors.label = 'label is required.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid white',
        boxShadow: 24,
        p: 4,
    };



    const fetchTaxDetails = async (id: number) => {
        const response = await TaxEditdata(id)
        if (response.status_code === 200) {
            setTaxlist({
                name: response.data.name,
                percentage: response.data.percentage,
                label: response.data.label,
                is_active: response.data.is_active
            });
        }

    }
    useEffect(() => {
        if (id) {
            fetchTaxDetails(id)
        }
    }, [open])
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setTaxlist((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const payload = {
                    ...taxList,
                    name: taxList.name,
                    percentage: Number(taxList.percentage),
                    label: taxList.label,
                    is_active: taxList.is_active,
                    id: id
                }
                const responseUpload = await TaxUpdate(payload)
                if (responseUpload.status_code === 200) {
                    toast.success("Tax Updated Successfully")
                    handleClose()
                    onActionComplete()
                } else {
                    toast.error("Failed to updated")
                }
            } catch (error) {
                console.log("error")
            }
        }
    }

    return (
        <Modal
            open={open}
            onClose={undefined}
        >
            <Box sx={style}>
                <Typography variant="h6" component="h2">
                    Edit Tax
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="form-group">
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Tax Name"
                                    variant="outlined"
                                    fullWidth
                                    value={taxList.name}
                                    onChange={handleInputChange}
                                    error={!!errors.name}
                                    helperText={errors.name}

                                />
                            </div>
                            <br />
                            <div className="form-group">
                                <TextField
                                    id="percentage"
                                    name="Percentage"
                                    label="Percentage"
                                    variant="outlined"
                                    fullWidth
                                    type='number'
                                    value={taxList.percentage}
                                    onChange={handleInputChange}
                                    error={!!errors.percentage}
                                    helperText={errors.percentage}
                                />
                            </div>
                            <br />
                            <div className="form-group">
                                <TextField
                                    id="label"
                                    name="label"
                                    label="Tax Label"
                                    variant="outlined"
                                    fullWidth
                                    value={taxList.label}
                                    onChange={handleInputChange}
                                    error={!!errors.label}
                                    helperText={errors.label}
                                />
                            </div>


                            <div className="form-group">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="is_active"
                                            name="is_active"
                                            checked={taxList.is_active === 1}
                                            onChange={(e) => {
                                                setTaxlist({ ...taxList, is_active: e.target.checked ? 1 : 0 });
                                            }}
                                        />
                                    }
                                    label="Is Active"
                                />
                            </div>
                        </div>
                        <br />
                        <Button
                            variant="contained"
                            color="primary"
                            type='submit'
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClose}
                            style={{ marginLeft: '10px' }}
                        >
                            Cancel
                        </Button>
                    </form>
                </Typography>
            </Box>
        </Modal>
    );
};

export default TaxEdit;
