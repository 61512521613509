import React, { useState } from "react";
import "../styles/sidebar.css";
import Dropdown from "./Dropdown";
import db from "../JSON/db.json";
import { useNavigate, useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);

  return (
    <div className="sidebar">
      <div className="sidebar_main dm-sans">
        <div
          className="sidebar_heading d-flex gap-2 align-items-center pointer"
          onClick={() => navigate("/admin/dashboard")}
        >
          <i className="bi bi-send fs-14 fw-bolder light-white"></i>
          <h4 className="m-0 p-0 fs-16 fw-bolder light-white">Dashboard</h4>
        </div>
        <div className="sidebar_subheading">
          <h6 className="text-uppercase font-bold m-0 p-0 light-white">
            ecommerce pannel
          </h6>
        </div>
        <div className="sidebar_dropdown_main">
          {db.sidebar_dashboard?.map((item) => (
            <div key={item.name}>
              <Dropdown
                item={item}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                currentRoute={location.pathname}
              />
            </div>
          ))}
        </div>
        <div className="sidebar_subheading">
          <h6 className="text-uppercase font-bold m-0 p-0 light-white">
            APPEARANCE
          </h6>
        </div>

        <div className="sidebar_dropdown_main">
          {db.appearance?.map((item) => (
            <div key={item.name}>
              <Dropdown
                item={item}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                currentRoute={location.pathname}
              />
            </div>
          ))}
        </div>
        <div className="sidebar_subheading">
          <h6 className="text-uppercase font-bold m-0 p-0 light-white">
            Settings
          </h6>
        </div>
        <div className="sidebar_dropdown_main">
          {db.settings.map((item) => (
            <div key={item.name}>
              <Dropdown
                item={item}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            </div>
          ))}
        </div>

        <div
          className="sidebar_heading d-flex justify-content-between align-items-center pointer "
          style={{ marginLeft: "30px" }}
          onClick={() => navigate("/admin/settings-menu")}
        >
          <h6 className="m-0 p-0 fs-13 fw-bolder light-white">Settings Menu</h6>
          <FontAwesomeIcon
            icon={faArrowRightLong}
            style={{ fontSize: "10px" }}
          />
        </div>

        <div className="sidebar_subheading">
          <h6 className="text-uppercase font-bold m-0 p-0 light-white">
            About Us
          </h6>
        </div>
        <div className="sidebar_dropdown_main">
          {db.aboutus.map((item) => (
            <div key={item.name}>
              <Dropdown
                item={item}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
