import "animate.css";
import "./styles/app.css";
import "./styles/index.css";
import "./styles/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./styles/home.css";
import "./styles/product.css";
import "./styles/product-form.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import BrandCreate from "./pages/Brand/BrandCreate.tsx";
import Layout from "./components/Layout.jsx";
import TaxCreate from "./pages/Tax/TaxCreate.tsx";
import CategoriesCreate from "./pages/Categorie/CategoriesCreate.tsx";
import CategoryInfo from "./components/CategoryInfo.jsx";
import ProductClassification from "./pages/Product/Mapping/ProductClassification.tsx";
import MediaLibrary from "./pages/Media/MediaLibrary.tsx";
import SettingsList from "./components/SettingsList.jsx";
import MediaLibraryCreate from "./pages/Media/MediaLibraryCreate.tsx";
import NotFound from "./components/NotFound.jsx";
import ProductList from "./pages/Product/ProductList.tsx";
import ProductView from "./pages/Product/Create/ProductView.tsx";
import ProductkeyPage from "./pages/ProductKey/ProductkeyPage.tsx";
import LoginPage from "./pages/Login/LoginPage.tsx";
import PagesList from "./components/Pages/PagesList.tsx";
import SectionsList from "./components/Pages/SectionsList/SectionsList.tsx";
import ProductCreate from "./pages/Product/Create/ProductCreate.tsx";
import BrandsBulk from "./components/Bulk/Brands/BrandsBulk.tsx";
import CategoriesBulk from "./components/Bulk/Categories/CategoriesBulk.tsx";
import ProductBulkUpload from "./components/Bulk/Products/ProductBulkUpload.tsx";
import BrandList from "./pages/Brand/BrandList.tsx";
import CategoriesList from "./pages/Categorie/CategoriesList.tsx";
import TaxList from "./pages/Tax/TaxList.tsx";
import CouponList from "./pages/Coupon/CouponLIst.tsx";
import DiscountList from "./pages/Discount/DiscountList.tsx";
import SellerList from "./pages/Seller/SellersList.tsx";
import ClassificationList from "./pages/Classification/ClassificationsList.tsx";
import CampaignList from "./pages/Campaign/CampaignList.tsx";
import OrderList from "./pages/Orders/OrdersList.tsx";
import CouponCreate from "./pages/Coupon/CouponCreate.tsx";
import DiscountCreate from "./pages/Discount/DiscountCreate.tsx";
import ClassificationCreate from "./pages/Classification/ClassificationCreate.tsx";
import CampaignCreate from "./pages/Campaign/CampaignCreate.tsx";
import ProductCampaign from "./pages/Product/Mapping/ProductCampaign.tsx";
import ProductEdit from "./pages/Product/Edit/ProductEdit.tsx";
import Dashboard from "./pages/Dashboard/Dashboard.tsx";
import SubPageList from "./components/Appearance/SubPage/SubPageList.tsx";
import AdsList from "./components/Appearance/Ads/AdsList.tsx";
import FaqCategoriesList from "./components/Appearance/FaqCategorys/FaqCategoriesList.tsx";
import FaqLIst from "./components/Appearance/Faq/FaqLIst.tsx";
import AboutUsList from "./components/AboutUs/AboutUsList.tsx";
import SettingsGourpCreate from "./pages/Settings/SettingsGourpCreate.tsx";
import BlogsList from "./components/Appearance/Blogs/BlogsList.tsx";
import BlogCategories from "./components/Appearance/BlogCategory/BlogCategory.tsx";
import BlogTags from "./components/Appearance/BlogTags/BlogTags.tsx";
import FaqCreate from "./components/Appearance/Faq/FaqCreate.tsx";
import FaqCategoryCreate from "./components/Appearance/FaqCategorys/FaqCategoryCreate.tsx";
import SubpagesCreate from "./components/Appearance/SubPage/SubPagesCreate.tsx";
import AdsCreate from "./components/Appearance/Ads/AdsCreate.tsx";
import BlogsCreate from "./components/Appearance/Blogs/BlogCreate.tsx";
import SellerCreate from "./pages/Seller/SellerCreate.tsx";
import CommonSettings from "./pages/Settings/CommonSettings.tsx";
import BlogCategoryCreate from "./components/Appearance/BlogCategory/BlogCategoryCreate.tsx";
import SettingsGroupList from "../src/pages/Settings/SettingsGroupList.tsx";
import BlogTagesCreate from "./components/Appearance/BlogTags/BlogTagesCreate.tsx";
import SettingSingleCreate from "./pages/Settings/SettingSingleCreate.tsx";
import SettingSingleList from "./pages/Settings/SettingSingleList.tsx";
import OrderCreate from "../src/pages/Orders/OrderCreate.tsx";

import { useEffect, useState } from "react";
import SellersList from "./pages/Seller/SellersList.tsx";
import OrderVeiw from "./pages/Orders/OrderVeiw.tsx";
function App() {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(
    localStorage.getItem("isAdminAuthenticated") === "true"
  );
  const [isRestricted, setIsRestricted] = useState(false);
  const [productKeyState, setProductKeyState] = useState(
    localStorage.getItem("productKeyState") === "false"
  );
  useEffect(() => {
    localStorage.setItem("isAdminAuthenticated", isAdminAuthenticated);
  }, [isAdminAuthenticated]);
  localStorage.setItem("productKeyState", productKeyState);
  useEffect(() => {
    const adminToken = localStorage.getItem("admin_token");
    setIsAdminAuthenticated(!!adminToken);
  }, []);

  useEffect(() => {
    const checkDeviceWidth = () => {
      const isSmallDevice = window.innerWidth <= 1024;
      setIsRestricted(isSmallDevice);
    };

    checkDeviceWidth();
    window.addEventListener("resize", checkDeviceWidth);

    return () => {
      window.removeEventListener("resize", checkDeviceWidth);
    };
  }, []);

  if (isRestricted) {
    return (
      <div className="device-restricted">
        This application is only available on laptops and tablets.
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Toaster />

      <Routes>
        admin
        <Route path="/" element={<Navigate to="/admin" replace />} />
        <Route
          path="/admin"
          element={
            <LoginPage
              setIsAdminAuthenticated={setIsAdminAuthenticated}
              setProductKeyState={setProductKeyState}
            />
          }
        />
        <Route
          path="/admin/*"
          element={
            isAdminAuthenticated ? <MainRoutes /> : <Navigate to="/" replace />
          }
        />
        {productKeyState && (
          <Route
            path="/product-key"
            element={<ProductkeyPage setProductKeyState={setProductKeyState} />}
          />
        )}
      </Routes>
    </BrowserRouter>
  );
}
function MainRoutes() {
  return (
    <Routes>
      <Route
        path="/product-veiw"
        element={<Layout children={<ProductView />} />}
      />
      <Route
        path="product-list"
        element={<Layout children={<ProductList />} />}
      />
      <Route
        path="product-create"
        element={<Layout children={<ProductCreate />} />}
      />
      <Route
        path="classification-map"
        element={<Layout children={<ProductClassification />} />}
      />
      <Route
        path="brand-create"
        element={<Layout children={<BrandCreate />} />}
      />
      <Route
        path="/categories-create"
        element={<Layout children={<CategoriesCreate />} />}
      />
      <Route
        path="/category-information"
        element={<Layout children={<CategoryInfo />} />}
      />
      <Route
        path="/media-list"
        element={<Layout children={<MediaLibrary />} />}
      />
      <Route
        path="/settings-list"
        element={<Layout children={<SettingsList />} />}
      />
      <Route
        path="/media-create"
        element={<Layout children={<MediaLibraryCreate />} />}
      />
      <Route path="*" element={<NotFound />} />
      <Route path="/pages-list" element={<Layout children={<PagesList />} />} />
      <Route
        path="/pages-list/sections"
        element={<Layout children={<SectionsList />} />}
      />
      <Route
        path="/bulk-brands"
        element={<Layout children={<BrandsBulk />} />}
      />
      <Route
        path="/bulk-categories"
        element={<Layout children={<CategoriesBulk />} />}
      />
      <Route
        path="/bulk-products"
        element={<Layout children={<ProductBulkUpload />} />}
      />
      <Route path="brand-list" element={<Layout children={<BrandList />} />} />
      <Route
        path="/categories-list"
        element={<Layout children={<CategoriesList />} />}
      />
      <Route path="/tax-list" element={<Layout children={<TaxList />} />} />
      <Route path="/tax-create" element={<Layout children={<TaxCreate />} />} />
      {/*  */}
      <Route
        path="/coupon-list"
        element={<Layout children={<CouponList />} />}
      />
      <Route
        path="/discount-list"
        element={<Layout children={<DiscountList />} />}
      />
      <Route
        path="/classification-list"
        element={<Layout children={<ClassificationList />} />}
      />
      <Route
        path="/sellers-list"
        element={<Layout children={<SellerList />} />}
      />
      <Route
        path="/classifications-list"
        element={<Layout children={<ClassificationList />} />}
      />
      <Route
        path="/campaign-list"
        element={<Layout children={<CampaignList />} />}
      />{" "}
      <Route
        path="/orders-list"
        element={<Layout children={<OrderList />} />}
      />
      <Route
        path="/coupon-create"
        element={<Layout children={<CouponCreate />} />}
      />
      <Route
        path="/discount-create"
        element={<Layout children={<DiscountCreate />} />}
      />
      <Route
        path="/classification-create"
        element={<Layout children={<ClassificationCreate />} />}
      />
      <Route
        path="/campaign-create"
        element={<Layout children={<CampaignCreate />} />}
      />
      <Route
        path="campaign-map"
        element={<Layout children={<ProductCampaign />} />}
      />
      <Route
        path="/product-edit"
        element={<Layout children={<ProductEdit />} />}
      />
      <Route
        path="/sub-pages-list"
        element={<Layout children={<SubPageList />} />}
      />
      <Route path="ads-list" element={<Layout children={<AdsList />} />} />
      <Route
        path="faq-category-list"
        element={<Layout children={<FaqCategoriesList />} />}
      />
      <Route path="faq-list" element={<Layout children={<FaqLIst />} />} />
      <Route
        path="setting-create"
        element={<Layout children={<SettingsGourpCreate />} />}
      />
      <Route
        path="aboutus-list"
        element={<Layout children={<AboutUsList />} />}
      />
      <Route path="blog-list" element={<Layout children={<BlogsList />} />} />
      <Route
        path="blog-category-list"
        element={<Layout children={<BlogCategories />} />}
      />
      <Route
        path="blog-tags-list"
        element={<Layout children={<BlogTags />} />}
      />
      <Route path="/faq-create" element={<Layout children={<FaqCreate />} />} />
      <Route
        path="/faq-category-create"
        element={<Layout children={<FaqCategoryCreate />} />}
      />
      <Route
        path="sub-pages-create"
        element={<Layout children={<SubpagesCreate />} />}
      />
      <Route path="ads-create" element={<Layout children={<AdsCreate />} />} />
      <Route
        path="blog-create"
        element={<Layout children={<BlogsCreate />} />}
      />
      <Route
        path="seller-list"
        element={<Layout children={<SellersList />} />}
      />
      <Route
        path="seller-create"
        element={<Layout children={<SellerCreate />} />}
      />
      <Route path="/dashboard" element={<Layout children={<Dashboard />} />} />
      <Route
        path="/settings-menu"
        element={<Layout children={<CommonSettings />} />}
      />
      <Route
        path="/blog-category-create"
        element={<Layout children={<BlogCategoryCreate />} />}
      />
      <Route
        path="/setting-list"
        element={<Layout children={<SettingsGroupList />} />}
      />
      <Route path="/order-veiw" element={<Layout children={<OrderVeiw />} />} />
      <Route
        path="/blog-tags-create"
        element={<Layout children={<BlogTagesCreate />} />}
      />
      <Route
        path="/setting-single-create"
        element={<Layout children={<SettingSingleCreate />} />}
      />
      <Route
        path="/setting-single-list"
        element={<Layout children={<SettingSingleList />} />}
      />
      <Route
        path="/orders-create"
        element={<Layout children={<OrderCreate />} />}
      />
    </Routes>
  );
}
export default App;
