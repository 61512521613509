import React, { useEffect, useState, useMemo } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  Skeleton,
} from "@mui/material";
import { FixedSizeList } from "react-window";
import debounce from "lodash.debounce";
import {
  getcampaignMappingProduct,
  productCampaignSubmit,
} from "services/apiService";
import toast from "react-hot-toast";

const ProductCampaignAdd = ({ openAddProductMap, handleClose, id }) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    display: "flex",
    flexDirection: "column",
  };

  const [productList, setProductList] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    if (id) fetchProductMapping();
  }, [id]);

  const fetchProductMapping = async () => {
    setLoading(true);
    try {
      const payload = {
        campaign_id: id,
        classification_id: 0,
      };
      const response = await getcampaignMappingProduct(payload);
      if (response.status_code === 200) {
        const filteredData = response.data.filter(
          (item) => item.is_selected !== 1
        );
        setProductList(filteredData);
        setFilteredProducts(filteredData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setFilteredProducts(
          productList.filter((product) =>
            product.product_name.toLowerCase().includes(query.toLowerCase())
          )
        );
      }, 300),
    [productList]
  );

  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);
  const handleSelect = (product) => {
    const productId = product.product_id;
    setSelectedProducts((prev) => {
      if (prev.includes(productId)) {
        return prev.filter((id) => id !== productId);
      } else {
        return [...prev, productId];
      }
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await productCampaignSubmit({
        campaign_id: id,
        product_id: selectedProducts,
      });
      if (response.status_code === 200) {
        toast.success(response.message || "Mapping successful!");
        setSelectedProducts([]);
        handleClose();
      } else {
        toast.error(response.message || "Mapping failed.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Submission failed");
    }
  };

  const handleModalClose = () => {
    setSelectedProducts([]);
    handleClose();
  };

  const renderRow = ({ index, style }) => {
    const product = filteredProducts[index];
    return (
      <ListItem key={product.product_id} style={style}>
        <Checkbox
          checked={selectedProducts.includes(product.product_id)}
          onChange={() => handleSelect(product)}
        />
        <ListItemAvatar>
          <Avatar
            src={`${
              product.thumnail_image
                ? AppUrl + product.thumnail_image
                : "/placeholder.png"
            }`}
            alt={product.product_name}
          />
        </ListItemAvatar>
        <ListItemText
          primary={product.product_name}
          secondary={`Brand: ${product.brand_name} | Category : ${product.category_name}`}
        />
      </ListItem>
    );
  };

  return (
    <Modal
      open={openAddProductMap}
      onClose={(e, reason) => reason !== "backdropClick" && handleModalClose()}
    >
      <Box sx={modalStyle}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography
            variant="h6"
            style={{ textTransform: "uppercase", color: "#3975b1" }}
          >
            Add Products
          </Typography>
          <Box>
            {selectedProducts.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mr: 2 }}
              >
                Map items ({selectedProducts.length})
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleModalClose}
            >
              Close
            </Button>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            placeholder="Search by Product Name...."
            variant="outlined"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              handleSearch(e.target.value);
            }}
            sx={{ flex: 1, mr: 2 }}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            border: "1px solid #ccc",
            borderRadius: 2,
            p: 2,
          }}
        >
          {loading ? (
            Array.from({ length: 5 }).map((_, idx) => (
              <Box key={idx} display="flex" alignItems="center" mb={2}>
                <Skeleton variant="circular" width={50} height={50} />
                <Box sx={{ flex: 1, ml: 2 }}>
                  <Skeleton variant="text" width="70%" height={20} />
                  <Skeleton variant="text" width="50%" height={15} />
                </Box>
              </Box>
            ))
          ) : filteredProducts.length > 0 ? (
            <FixedSizeList
              height={400}
              width="100%"
              itemSize={60}
              itemCount={filteredProducts.length}
            >
              {renderRow}
            </FixedSizeList>
          ) : (
            <Typography>No data available.</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductCampaignAdd;
