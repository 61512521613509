import React from "react";
import Nav from "../common/Nav";
import Footer from "../common/Footer";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header";
import { useLocation } from "react-router";

import FloatingButton from "../common/FloatingButton";

const Layout = ({ children }) => {
  const router = useLocation();
  const isDashboard = router.pathname === "/admin/dashboard";
  const isCommonPage = router.pathname === "/admin/classification-map";
  const isCommon2Page = router.pathname === "/admin/campaign-map";
  const isCommmon3page = router.pathname === "/admin/settings-menu";

  return (
    <div className="home">
      <div className="home_main d-flex">
        <Sidebar />
        <div className="flex w-100 flex-column home_main_cont">
          <FloatingButton />
          <Nav />
          <Header
            extClass="px-20px mt-3 mb-3"
            heading={router.pathname
              .replace("/admin/", "")
              .replace("-", " ")
              .trim()}
            mainPage=" /"
            innerPage={router.pathname.replace("/", "").replace("-", " ")}
          />
          <div
            className={`${
              isDashboard || isCommonPage || isCommon2Page || isCommmon3page
                ? "layout_comp_no_bg"
                : "layout_comp shadow dm-sans"
            }`}
          >
            {children}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
