import React, { useEffect, useState } from "react";
import DynamicButton from "../../common/DynamicButton";
import CommonTable from "common/CommonTable";
import {
  faEye,
  faMagnifyingGlass,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { getAllOrdersList, pageNationData } from "services/apiService";
import Pagenation from "../../common/Pagenation";
import loadingImage from "../../assect/loadingSvg/loading.svg";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderEdit from "./OrderEdit";

const OrdersList = () => {
  const navigate = useNavigate();
  const [ordersSearch, setOrdersSearch] = useState<string>("");
  const [loader, setloader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [allOrders, setAllOrders] = useState([]);
  const [pagenationLength, setPagenationLength] = useState(null);
  const [currentPageNationPage, setCurrentPageNationPage] = useState(1);
  const [page_numbers] = useState<number | null>(0);
  const numberofItems: number | null = 10;
  const headers = [
    "S.no",
    "Order code",
    "Customer name",
    "Total products",
    "Total amount",
    "Order status",
    "Payment status",
    "Action",
  ];
  useEffect(() => {
    getOrders(ordersSearch, page_numbers, numberofItems);
    fetchOrderCount();
  }, []);
  const getOrders = async (
    search_text: string,
    page_number: number,
    num_of_items: number
  ) => {
    setloader(true);
    try {
      const response = await getAllOrdersList(
        search_text,
        page_number,
        num_of_items
      );
      setAllOrders(response.data);
    } catch (error) {}
    setloader(false);
  };
  const fetchOrderCount = async () => {
    try {
      const response = await pageNationData();
      setPagenationLength(response.data.order_count);
    } catch (error) {}
  };

  const handleVeiw = (id: number) => {
    navigate(`/admin/order-veiw`, { state: { id } });
  };

  const handleOpenModal = (id: number) => {
    setOrderId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderRow = (discount: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td className="table_product_name">
          <div className="d-flex flex-column product_name_td">
            <span>{discount?.order_code}</span>
          </div>
        </td>
        <td
          className="table_product_name align-content-center"
          style={{ color: "grey" }}
        >
          {discount?.customer_name}
        </td>
        <td className="table_product_name align-content-center">
          {discount?.total_products}
        </td>
        <td>{discount?.total_amount}</td>
        <td>
          <span
            style={{
              color:
                discount?.order_status === "delivered"
                  ? "green"
                  : discount?.order_status === "cancelled" ||
                    discount?.order_status === "returned"
                  ? "red"
                  : discount?.order_status === "shipping" ||
                    discount?.order_status === "processing"
                  ? "#007bff"
                  : "black",
              backgroundColor:
                discount?.order_status === "delivered"
                  ? "#e0f7e9"
                  : discount?.order_status === "cancelled" ||
                    discount?.order_status === "returned"
                  ? "#f8d7da"
                  : discount?.order_status === "shipping" ||
                    discount?.order_status === "processing"
                  ? "#e6f7ff"
                  : "#f5f5f5",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {discount?.order_status}
          </span>
        </td>
        <td>
          <span
            style={{
              color:
                discount?.payment_status === "paid"
                  ? "green"
                  : discount?.payment_status === "unpaid"
                  ? "red"
                  : discount?.payment_status === "refund"
                  ? "#007bff"
                  : "black",
              backgroundColor:
                discount?.payment_status === "paid"
                  ? "#e0f7e9"
                  : discount?.payment_status === "unpaid"
                  ? "#f8d7da"
                  : discount?.payment_status === "refund"
                  ? "#e6f7ff"
                  : "#f5f5f5",
              padding: "5px 10px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            {" "}
            {discount?.payment_status}{" "}
          </span>
        </td>

        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleOpenModal(discount.order_id)}
            />
            <DynamicButton
              icon={faEye}
              iconStyle={{ color: "blue" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleVeiw(discount.order_id)}
            />
          </div>
        </td>
      </tr>
    );
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOrdersSearch(value);
    if (value) {
      getOrders(value, null, null);
    } else {
      getOrders("", page_numbers, numberofItems);
    }
  };
  const handlePageChange = (page: number) => {
    const dataIndex = (page - 1) * numberofItems;
    if (page) {
      getOrders("", dataIndex, numberofItems);
    }
    setCurrentPageNationPage(page);
  };
  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            style={{ boxShadow: "none" }}
            placeholder="Search Orders..."
            value={ordersSearch}
            onChange={handleChange}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
            </span>
          </div>
        </div>
      </div>
      <div className="product-table-container">
        <div className="product-table-content">
          {loader ? (
            <div className="loadingsvg-Image text-center">
              <img
                src={loadingImage}
                width={150}
                height={150}
                alt="Loading..."
              />
            </div>
          ) : (
            <CommonTable
              data={allOrders}
              isLoading={loader}
              headers={headers}
              renderRow={renderRow}
            />
          )}
        </div>
        <div className="pagination-container d-flex justify-content-end">
          <Pagenation
            pagenationLength={pagenationLength}
            numberofItems={numberofItems}
            currentPageNationPage={currentPageNationPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      <OrderEdit
        onActionComplete={() => {
          getOrders(ordersSearch, page_numbers, numberofItems);
          fetchOrderCount();
        }}
        open={isModalOpen}
        onClose={handleCloseModal}
        id={orderId}
      />
    </>
  );
};

export default OrdersList;
