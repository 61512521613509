import React, { useEffect, useState } from "react";
import {
  VariantDropDown,
  productVariantSubmit,
} from "../../../services/apiService";
import { toast } from "react-hot-toast";
// import { CustomToast } from "../../Helper/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

interface VariantField {
  dropdownValue: string;
  textboxValue: string;
}
interface ProductVariantsFormProps {
  productId: number;
}

const ProductVariant: React.FC<ProductVariantsFormProps> = ({ productId }) => {
  const [variantList, setVariantList] = useState<
    { id: number; name: string }[]
  >([]);
  const [variantFields, setVariantFields] = useState<VariantField[]>([
    { dropdownValue: "", textboxValue: "" },
  ]);

  useEffect(() => {
    const fetchVariantList = async () => {
      try {
        const response = await VariantDropDown();
        setVariantList(response.data);
      } catch (error) {
        console.error("Error fetching variant list:", error);
      }
    };

    fetchVariantList();
  }, []);

  const handleAddVariant = () => {
    setVariantFields([
      ...variantFields,
      { dropdownValue: "", textboxValue: "" },
    ]);
  };

  const handleDeleteVariant = (index: number) => {
    setVariantFields(variantFields?.filter((_, i) => i !== index));
  };

  const handleChangeDropdown = (index: number, value: string) => {
    const updatedFields = [...variantFields];
    updatedFields[index].dropdownValue = value;
    setVariantFields(updatedFields);
  };

  const handleChangeTextbox = (index: number, value: string) => {
    const updatedFields = [...variantFields];
    updatedFields[index].textboxValue = value;
    setVariantFields(updatedFields);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      variantFields.some(
        (variant) => variant.dropdownValue !== "" && variant.textboxValue !== ""
      )
    ) {
      const payload = {
        product_id: productId,
        variants: variantFields.map((variant) => ({
          variant_label_id: parseInt(variant.dropdownValue),
          value: variant.textboxValue,
          is_active: 1,
        })),
      };

      const response = await productVariantSubmit(payload);
      if (response.status_code === 200) {
        toast.success(response.message);
        toast.success(response.message);
        setVariantFields([{ dropdownValue: "", textboxValue: "" }]);
      }
    } else {
      toast.error("Please select variant or enter value");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <button
          type="button"
          className="btn btn-add me-2"
          onClick={handleAddVariant}
          style={{ color: "white" }}
        >
          <FontAwesomeIcon icon={faPlus} /> &nbsp;Add
        </button>
        <button
          type="submit"
          className="btn btn-submitt"
          onClick={handleSubmit}
          style={{ color: "white" }}
        >
          <FontAwesomeIcon icon={faArrowUpFromBracket} /> &nbsp;Submit
        </button>
      </div>

      <div className="col-lg-12" style={{ height: "650px" }}>
        <form
          className="form-container"
          onSubmit={handleSubmit}
          style={{ overflow: "auto", height: "600px" }}
        >
          {variantFields.map((variant, index) => (
            <div key={index} className="form-row mb-3 d-flex align-items-end">
              <div className="form-group me-2" style={{ flex: 1 }}>
                <label className="form-label">Variant</label>
                <select
                  id={`variant-dropdown-${index}`}
                  className="form-select"
                  value={variant.dropdownValue}
                  onChange={(e) => handleChangeDropdown(index, e.target.value)}
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  <option value="">Select Variant</option>
                  {variantList.length === 0 ? (
                    <option value="">No Variant found</option>
                  ) : (
                    variantList.map((variantItem) => (
                      <option key={variantItem.id} value={variantItem.id}>
                        {variantItem.name.toUpperCase()}
                      </option>
                    ))
                  )}
                </select>
              </div>
              <div className="form-group me-2" style={{ flex: 1 }}>
                <label className="form-label">Variant Value</label>
                <input
                  type="text"
                  className="form-input"
                  value={variant.textboxValue}
                  onChange={(e) => {
                    const value = e.target.value
                      .toUpperCase()
                      .replace(/[^A-Z0-9]/g, "");
                    if (value.length <= 4) {
                      handleChangeTextbox(index, value);
                    }
                  }}
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <button
                type="button"
                className="btn-delete"
                onClick={() => handleDeleteVariant(index)}
                style={{ background: "transparent", color: "red" }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ))}
        </form>
      </div>
    </>
  );
};

export default ProductVariant;
