import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCampaignEdit, updatedCampaign } from "services/apiService";
import { CoupnEditProps } from "types";

export const CampaignEdit: React.FC<CoupnEditProps> = ({
  open,
  id,
  handleClose,
  onActionComplete,
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };
  const today = new Date().toISOString().split("T")[0];
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const [CampaignEditCreate, setCampaingEditCreate] = useState({
    campaign_name: "",
    expire_date: "",
    title: "",
    description: "",
    image_path: "",
    is_active: 1,
    slug: "",
  });

  const [errors, setErrors] = useState({
    campaign_name: "",
    expire_date: "",
    title: "",
    description: "",
  });

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };

    // Validation for campaign_name and title
    let error = "";
    if (id === "campaign_name" || id === "title") {
      const regex = /^[a-zA-Z0-9-]*$/; // Only alphanumeric and hyphen are allowed
      if (!regex.test(value)) {
        error = "Only alphanumeric characters and hyphen (-) are allowed.";
      }
      if (value.length > 30) {
        error = "Max length is 30 characters.";
      }
    }

    if (id === "title" && value.length > 25) {
      error = "Max length is 25 characters.";
    }

    if (id === "description" && value.length > 50) {
      error = "Max length is 50 characters.";
    }

    setCampaingEditCreate((prevData) => ({
      ...prevData,
      [id]: value,
      slug: id === "campaign_name" ? generateSlug(value) : prevData.slug,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: error,
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    setCampaingEditCreate((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const handleImageSelected = (image: string) => {
    setCampaingEditCreate((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };

  useEffect(() => {
    if (id) {
      fetchCampaignData(id);
    }
  }, [open]);

  const fetchCampaignData = async (id: number) => {
    const response = await getCampaignEdit(id);
    if (response.status_code === 200) {
      setCampaingEditCreate({
        campaign_name: response.data.campaign_name,
        expire_date: response.data.expire_date,
        title: response.data.title,
        description: response.data.description,
        image_path: response.data.image_path,
        is_active: response.data.is_active,
        slug: response.data.slug || "",
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check if any required field is empty
    const requiredFields = [
      "campaign_name",
      "title",
      "description",
      "expire_date",
    ];
    let formErrors = { ...errors };

    // Check each field
    requiredFields.forEach((field) => {
      if (!CampaignEditCreate[field as keyof typeof CampaignEditCreate]) {
        formErrors = { ...formErrors, [field]: "This field is required." };
      }
    });

    // If there are any errors, stop the submission
    if (Object.values(formErrors).some((error) => error !== "")) {
      setErrors(formErrors);
      toast.error("Please fill all required fields before submitting.");
      return;
    }

    try {
      const payload = {
        ...CampaignEditCreate,
        image_path: CampaignEditCreate.image_path.toString(),
        campaign_id: id,
      };
      const response = await updatedCampaign(payload);
      if (response.status_code === 200) {
        toast.success("Campaign updated successfully!");
        handleClose();
        onActionComplete();
      }
    } catch (error) {
      console.error("Error updating Campaign:", error);
      toast.error("Campaign update failed. Please try again.");
    }
  };

  const handleDeleteImage = () => {
    setCampaingEditCreate((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <Modal open={open} onClose={undefined} style={{ zIndex: "1299" }}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Edit Campaign
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <div
                className="modal-body"
                style={{ display: "grid", gap: "20px" }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <TextField
                    id="campaign_name"
                    name="name"
                    label="Campaign Name"
                    variant="outlined"
                    fullWidth
                    value={CampaignEditCreate.campaign_name}
                    onChange={handleTextFieldChange}
                    error={!!errors.campaign_name}
                    inputProps={{
                      maxLength: 25,
                    }}
                    helperText={errors.campaign_name}
                  />
                  <TextField
                    variant="outlined"
                    id="title"
                    fullWidth
                    label="Campaign Title"
                    value={CampaignEditCreate.title}
                    onChange={handleTextFieldChange}
                    error={!!errors.title}
                    helperText={errors.title}
                    inputProps={{
                      maxLength: 25,
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    variant="outlined"
                    id="description"
                    fullWidth
                    multiline
                    label="Description"
                    rows={4}
                    value={CampaignEditCreate.description}
                    onChange={handleTextFieldChange}
                    error={!!errors.description}
                    helperText={errors.description}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "100px",
                  }}
                >
                  <input
                    id="expire_date"
                    type="date"
                    className="form-control"
                    style={{ width: "100%", padding: "8px" }}
                    min={today}
                    value={CampaignEditCreate.expire_date}
                    onChange={handleTextFieldChange}
                  />
                  {errors.expire_date && (
                    <Typography color="error">{errors.expire_date}</Typography>
                  )}
                  <Toggle
                    checked={CampaignEditCreate.is_active === 1}
                    onChange={(checked) =>
                      handleToggleChange(checked, "is_active")
                    }
                  />
                </div>

                <div className="form-group">
                  <div
                    style={{
                      width: "100%",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "20px",
                        border: "1px dashed #ccc",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "10px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {CampaignEditCreate.image_path ? (
                        <>
                          <img
                            src={`${AppUrl}${CampaignEditCreate.image_path}`}
                            alt="Selected"
                            style={{
                              width: "100px",
                              transition: "transform 0.2s",
                              cursor: "pointer",
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.transform = "scale(1.05)";
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.transform = "scale(1)";
                            }}
                          />
                          <button
                            style={{
                              backgroundColor: "rgba(255, 0, 0, 0.7)",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              fontSize: "16px",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                              transition: "background-color 0.2s",
                            }}
                            onClick={handleDeleteImage}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </>
                      ) : (
                        <p style={{ color: "orange" }}>
                          <FontAwesomeIcon icon={faCircleExclamation} />
                          &nbsp; &nbsp; No image selected. Please upload an
                          image.
                        </p>
                      )}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={handleOpenModal}
                        style={{
                          padding: "5px 20px",
                          fontSize: "13px",
                          borderRadius: "0px",
                          border: "none",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          cursor: "pointer",
                          marginTop: "10px",
                          transition: "background-color 0.2s",
                        }}
                      >
                        <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                        Thumbnail Image
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <Button type="submit" variant="contained" color="primary">
                  Save Campaign
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  style={{ marginLeft: "10px" }}
                >
                  Close
                </Button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
      />
    </>
  );
};
