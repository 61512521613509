// store.js
import { configureStore } from "@reduxjs/toolkit";
import jsonDataReducer from "./Slice/jsonDataSlice.js";
import adsDropDownDataReducer from "./Slice/adsDropDownSlice.js";
import classificatiponDropDownDataReducer from "./Slice/classificationDropdownSlice.js";
import campaignDropDownReducer from "./Slice/campaignSlice.js";
import getSectionDataReducer from "./Slice/getSectionDataSlice.js";
import mediaReducer from "./Slice/mediaSlice.js";

const store = configureStore({
  reducer: {
    jsondata: jsonDataReducer,
    adsDropDownData: adsDropDownDataReducer,
    classificationData: classificatiponDropDownDataReducer,
    campaignData: campaignDropDownReducer,
    getSectionData: getSectionDataReducer,
    media: mediaReducer,
  },
});

export default store;
