import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getMediaList } from "services/apiService";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toggleVideoSelection } from "../../../../redux/Slice/mediaSlice.js";

const VideoGallery = ({ isOpen, onRequestClose, isMulti, keyName, handleBlur }) => {
  const dispatch = useDispatch();
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const { data: video } = useQuery("video", getMediaList);

  const handleImageToggle = ({ keyName, mediaUrl, isMulti }) => {
    dispatch(toggleVideoSelection({ keyName, mediaUrl, isMulti }));
  };
  const multiVideo = useSelector(
    (state: any) => state.media.selectedVideosByKey
  );
  const singleVideo = useSelector(
    (state: any) => state.media.singleVideoSelections
  );
  const selectedVideos = multiVideo ? multiVideo[keyName] : undefined;
  const removeQuets = selectedVideos ? selectedVideos.split(",") : [];
  const singleSelect = singleVideo ? singleVideo[keyName] : "";
  const filteredFiles = removeQuets.filter((file) => file !== "");
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Video Gallery"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "9999",
        },
        content: {
          width: "90%",
          maxWidth: "1020px",
          height: "auto",
          maxHeight: "80vh",
          overflow: "auto",
          borderRadius: "10px",
          padding: "20px",
          position: "relative",
          margin: "auto",
          // backgroundColor: "#f8f9fa",
          scrollbarWidth: "none",
        },
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Video Gallery</h2>
          </div>
          <div className="col-auto">
            <button
              onClick={onRequestClose}
              style={{
                fontSize: "30px",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faTimes} style={{ color: "#63E6BE" }} />
            </button>
          </div>
        </div>
        <div className="row">
          {video?.data
            ?.filter(
              (url) =>
                url.endsWith(".mp4") ||
                url.endsWith(".mov") ||
                url.endsWith(".avi") ||
                url.endsWith(".gif")
            )
            .map((mediaUrl, index) => (
              <div key={index} className="col-md-4 mb-4">
                <div
                  style={{
                    width: "200px",
                    height: "auto",
                    border: "1px solid #ddd",
                    padding: "5px",
                    borderRadius: "5px",
                    position: "relative",
                  }}
                >
                  <video
                    src={AppUrl + mediaUrl}
                    className="img-fluid"
                    controls
                  />
                  <input
                    name={keyName}
                    key={keyName}
                    type="checkbox"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      zIndex: "10",
                    }}
                    checked={
                      isMulti
                        ? filteredFiles?.includes(mediaUrl)
                        : singleSelect === mediaUrl
                    }
                    onChange={(e) =>
                      handleImageToggle({ keyName, mediaUrl, isMulti })
                    }
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default VideoGallery;
