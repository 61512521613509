import React from "react";
import ProductDropdown from "../common/ProductDropdown";

const CategoryInfo = () => {
  return (
    <div className="w-100">
      {["category name", "category image", "select category icon"].map((i) => (
        <div className="create_category_fields">
          <span className="text-capitalize label">{i}</span>
          <div className="w-100%">
            {i === "category name" ? (
              <input
                type="text"
                name={i}
                id={i}
                placeholder="Type Here"
                className="outline-none w-100 p-1 border-1 common_input"
              />
            ) : i === "category image" ? (
              <label
                htmlFor="upload_file"
                className="d-flex align-items-center justify-content-center flex-column border py-5"
              >
                <input
                  type="file"
                  className="d-none"
                  name="upload_file"
                  id="upload_file"
                />
                <i className="bi bi-cloud-upload font-large"></i>
                <span>Drag file here or click to upload</span>
              </label>
            ) : (
              <ProductDropdown showLabel={false} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryInfo;
