// src/components/InputField.tsx

import React from 'react';

interface InputFieldProps {
    label: string;
    value: string;
    readOnly: boolean;
    isTextarea?: boolean;
}

const DynamicInputField: React.FC<InputFieldProps> = ({ label, value, readOnly, isTextarea }) => {
    return (
        <div className="input-field-container">
            <label className="input-label">{label}</label>
            {isTextarea ? (
                <textarea
                    value={value}
                    readOnly={readOnly}
                    className={`input-field textarea ${readOnly ? 'readonly' : ''}`}
                    style={{ overflowY: 'auto', maxHeight: '100px', width: '100%' }} // Set a max height and enable scrolling
                />
            ) : (
                <input
                    type="text"
                    value={value}
                    readOnly={readOnly}
                    className={`input-field ${readOnly ? 'readonly' : ''}`}
                />
            )}
        </div>
    );
};

export default DynamicInputField;
