import React, { useEffect, useState } from "react";
import { CategoriesEditProp } from "types";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Toggle from "common/Toggle";
import {
  CategoriesCreateDropDown,
  categoriesEdit,
  categoriesUpdate,
} from "services/apiService";
import toast from "react-hot-toast";

const CategoriesEdit: React.FC<CategoriesEditProp> = ({
  open,
  handleClose,
  id,
  onActionComplete,
}) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [EditCategiresForm, setEditCategiresForm] = useState({
    category_name: "",
    slug: "",
    description: "",
    color_code: "#000000",
    is_active: 1,
    parent_id: "",
    category_image: "",
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [categoryDropdownList, setCategoryDropdown] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [errors, setErrors] = useState({
    category_name: "",
    description: "",
  });

  const validateForm = () => {
    const newErrors: any = {};
    if (!EditCategiresForm.category_name)
      newErrors.category_name = "Name is required.";
    if (!EditCategiresForm.description)
      newErrors.description = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };
  const fetchCategoriesCreateDropDown = async () => {
    try {
      const response = await CategoriesCreateDropDown();
      setCategoryDropdown(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchCategoriesData = async (id: number) => {
    try {
      const response = await categoriesEdit(id);
      if (response.status_code === 200) {
        setEditCategiresForm({
          category_name: response.data.category_name,
          slug: response.data.slug,
          description: response.data.description,
          category_image: response.data.category_image,
          is_active: response.data.is_active,
          parent_id: response.data.parent_id,
          color_code: response.data.color_code,
        });
      } else {
        toast.error("failed to fetch the data ");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (open) {
      fetchCategoriesCreateDropDown();
      fetchCategoriesData(id);
    }
  }, [open]);

  const handleSelectChange = (event) => {
    const parentId = event.target.value as number;
    setSelectedCategory(parentId);

    setEditCategiresForm((prevData) => ({
      ...prevData,
      parent_id: parentId.toString(),
      color_code: parentId !== 0 ? "" : prevData.color_code,
    }));
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    let sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
    let error = "";
    if (sanitizedValue.length < 3) {
      error = "Input must be at least 3 characters.";
    }
    if (id === "category_name" && sanitizedValue.length > 40) {
      error = "Input must not exceed 40 characters.";
    }
    if (id === "category_name") {
      const generateSlug = (name: string) => {
        return name.trim().toLowerCase().replace(/\s+/g, "-");
      };
      setEditCategiresForm((prevData) => ({
        ...prevData,
        [id]: sanitizedValue,
        slug: generateSlug(sanitizedValue),
      }));
    } else {
      setEditCategiresForm((prevData) => ({
        ...prevData,
        [id]: sanitizedValue,
      }));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: error,
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setEditCategiresForm((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleImageSelected = (image: string) => {
    setEditCategiresForm((prevState) => ({
      ...prevState,
      category_image: image,
    }));
    setModalOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        if (!EditCategiresForm.category_name) {
          toast.error("Please Fill The Name");
          return;
        }

        const payload = {
          ...EditCategiresForm,
          category_name: EditCategiresForm.category_name,
          slug: EditCategiresForm.slug,
          description: EditCategiresForm.description,
          category_image: EditCategiresForm.category_image.toString(),
          is_active: EditCategiresForm.is_active,
          parent_id: Number(EditCategiresForm.parent_id),
          color_code: EditCategiresForm.color_code.toString(),
          category_id: id,
        };
        const UpdateCatogires = await categoriesUpdate(payload);
        if (UpdateCatogires.status_code === 200) {
          toast.success("Updated Successfully");
          onActionComplete();
          handleClose();
        } else {
          toast.error("Edit Failed ");
          handleClose();
        }
      } catch (error) {
        console.error("Error updating Category:", error);
      }
    }
  };

  const handleColorPickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEditCategiresForm((prev) => ({
      ...prev,
      color_code: value,
    }));
  };

  const handleDeleteImage = () => {
    setEditCategiresForm((prevState) => ({
      ...prevState,
      category_image: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  return (
    <Modal open={open} onClose={undefined}>
      <Box sx={{ ...style, maxWidth: "800px", padding: "20px" }}>
        <Typography
          variant="h6"
          component="h2"
          sx={{ mb: 2, fontSize: "18px" }}
        >
          Edit Category
        </Typography>
        <form>
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  name="category_name"
                  id="category_name"
                  label="Category Name"
                  value={EditCategiresForm.category_name}
                  onChange={handleInputChange}
                  placeholder="Type Here"
                  fullWidth
                  className="common_input"
                  size="small"
                  inputProps={{
                    maxLength: 40,
                  }}
                  error={!!errors.category_name}
                  helperText={errors.category_name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  name="slug"
                  label="Category Slug"
                  id="slug"
                  value={EditCategiresForm.slug}
                  onChange={handleInputChange}
                  placeholder="Type Here"
                  fullWidth
                  className="common_input"
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name="description"
                label="Description"
                id="description"
                value={EditCategiresForm.description}
                onChange={handleInputChange}
                placeholder="Type description here"
                fullWidth
                multiline
                rows={3}
                size="small"
                className="common_input"
                sx={{ backgroundColor: "#f9f9f9" }}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Grid>
            <Grid container spacing={1} sx={{ mt: 2, ml: 2 }}>
              <Grid item xs={5}>
                <FormControl fullWidth variant="outlined">
                  <Select
                    labelId="category-select-label"
                    id="parent_id"
                    name="parent_id"
                    value={EditCategiresForm.parent_id}
                    onChange={handleSelectChange}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <MenuItem value={0}>No Parent</MenuItem>
                    {categoryDropdownList && categoryDropdownList.length > 0 ? (
                      categoryDropdownList.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.category_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">No categories available</MenuItem>
                    )}
                  </Select>
                  <FormHelperText>
                    Please select a category or choose 'No Parent'
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={3}
                >
                  <TextField
                    variant="outlined"
                    name="color"
                    label="Color"
                    id="color_code"
                    value={EditCategiresForm.color_code}
                    onChange={handleColorPickerChange}
                    placeholder="#000000"
                    className="common_input"
                    size="small"
                    sx={{ width: "40%" }}
                  />
                  <input
                    type="color"
                    id="color_code"
                    value={EditCategiresForm.color_code}
                    onChange={handleColorPickerChange}
                    style={{
                      width: "100px",
                      height: "50px",
                      border: "none",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Toggle
                  checked={EditCategiresForm.is_active === 1}
                  onChange={(checked) =>
                    handleToggleChange(checked, "is_active")
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div
                className="container-fluid"
                style={{
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    border: "1px dashed #ccc",
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: "#f9f9f9",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {EditCategiresForm.category_image ? (
                    <>
                      <img
                        src={`${AppUrl}${EditCategiresForm.category_image}`}
                        alt="Selected"
                        style={{
                          width: "200px",
                          height: "150px",
                          borderRadius: "10px",
                          transition: "transform 0.2s",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      />
                      <button
                        style={{
                          backgroundColor: "rgba(255, 0, 0, 0.7)",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          fontSize: "16px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                          transition: "background-color 0.2s",
                        }}
                        onClick={handleDeleteImage}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </>
                  ) : (
                    <p style={{ color: "orange" }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      &nbsp; &nbsp; No image selected. Please upload an image.
                    </p>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleOpenModal}
                    style={{
                      padding: "5px 20px",
                      fontSize: "15px",
                      borderRadius: "0px",
                      border: "none",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      cursor: "pointer",
                      marginTop: "10px",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                    Thumnail Image
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end" pb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleClose}
                  sx={{ ml: 2 }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>

        <ImageUploaderModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onImageSelect={handleImageSelected}
          allowMultiple={false}
        />
      </Box>
    </Modal>
  );
};

export default CategoriesEdit;
