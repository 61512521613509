import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BlogCategoryEditApi, UpadateBlogsCategory } from "services/apiService";
import { CoupnEditProps } from "types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

const BlogCategoryEdit: React.FC<CoupnEditProps> = ({
  open,
  id,
  handleClose,
  onActionComplete,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    is_active: 0,
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
  });

  useEffect(() => {
    if (id) {
      fetchBlogsTags(id);
    }
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const fetchBlogsTags = async (id: number) => {
    try {
      const response = await BlogCategoryEditApi(id);
      if (response.status_code === 200) {
        setFormData({
          name: response.data.name,
          is_active: response.data.is_active,
        });
      }
    } catch (error) {
      console.log("error");
    }
  };

  const validateForm = () => {
    const errors: any = {};
    if (!formData.name.trim()) {
      errors.name = "Blog tag title is required.";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const payload = {
        id: id,
        name: formData.name,
        is_active: formData.is_active,
      };
      const response = await UpadateBlogsCategory(payload);
      if (response.status_code === 200) {
        toast.success("Blog Category Updated Successfully");
        handleClose();
        onActionComplete();
      }
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ padding: 1 }}>
          <form onSubmit={handleSubmit}>
            <Box className="create_category_fields" mb={2}>
              <Typography
                sx={{ mt: 2 }}
                variant="subtitle1"
                className="text-capitalize label"
              >
                Blogs Tag Title
              </Typography>
              <TextField
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                variant="filled"
                placeholder="Type Here"
                fullWidth
                className="common_input"
                error={!!formErrors.name}
                helperText={formErrors.name}
              />
            </Box>
            <Box
              className="create_category_fields"
              display="flex"
              alignItems="center"
              gap={2}
              mb={3}
            >
              <Typography
                variant="body1"
                className="text-capitalize label"
                sx={{ width: "20%" }}
              >
                Status
              </Typography>
              <Toggle
                checked={formData.is_active === 1}
                onChange={(checked) => handleToggleChange(checked, "is_active")}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default BlogCategoryEdit;
