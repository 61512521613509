import React, { useState } from "react";

const ProductDropdown = ({
  label = "Category",
  selected = "Selected",
  options = ["One", "Two", "Three"],
  extClass = "",
  showLabel = true,
}) => {
  const [value, setValue] = useState(selected);
  return (
    <div className={`${extClass}`}>
      {showLabel && (
        <label htmlFor="blog_tag" className="text-capitalize">
          {label}
        </label>
      )}

      <div className="dropdown w-100 mt-1">
        <button
          className="btn btn-secondary dropdown-toggle w-100 d-flex justify-content-between bg-none align-items-center bg-white text-black"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {value}
        </button>
        <ul className="dropdown-menu w-100">
          {options.map((i) => (
            <li onClick={() => setValue(i)} key={i}>
              <a className="dropdown-item" href="/">
                {i}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductDropdown;
