import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { createFaqCategory } from "services/apiService";

const FaqCategoryCreate = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    is_active: 1,
  });
  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });
  const handleInputChange = ({ target: { id, value } }) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setFormData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors: any = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.description)
      newErrors.description = "Description is required";
    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return;
    }
    try {
      const response = await createFaqCategory(formData);
      if (response.status_code === 200) {
        navigate("/admin/faq-category-list");
      }
    } catch (error) {
      toast.error("Error creating FAQ category. Please try again.");
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Name
            </Typography>
            <TextField
              variant="filled"
              id="name"
              fullWidth
              placeholder="Type here"
              value={formData.name}
              onChange={(e) => {
                const value = e.target.value;
                const filteredValue = value
                  .replace(/[^a-zA-Z\s]/g, "")
                  .slice(0, 30);
                setFormData((prevData) => ({
                  ...prevData,
                  name: filteredValue,
                }));
              }}
              error={!!errors.name}
              helperText={
                errors.name ||
                "Only letters and spaces are allowed, max 30 characters"
              }
            />
          </Box>

          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Description
            </Typography>
            <TextField
              variant="filled"
              id="description"
              fullWidth
              placeholder="Type here"
              value={formData.description}
              onChange={handleInputChange}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Status
            </Typography>
            <Toggle
              checked={formData.is_active === 1}
              onChange={(checked) => handleToggleChange(checked, "is_active")}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" pb={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              &nbsp;&nbsp; Create Faq Category
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FaqCategoryCreate;
