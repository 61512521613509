import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "../../../common/ImageUploaderModal";
import ResponsiveImageUploader from "../../../common/ResponsiveImageUploader";
import Toggle from "../../../common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AdsResonseEdit, AdsUpadate } from "../../../services/apiService";
import { TaxEditProps } from "../../../types";

const AdsEdit: React.FC<TaxEditProps> = ({
  id,
  open,
  handleClose,
  onActionComplete,
}) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isResponsiveModelopen, setisResponsiveModelopen] =
    useState<boolean>(false);
  const [EditAdsformData, setEditAdsFormData] = useState({
    name: "",
    slug: "",
    background_color: "",
    title_one: "",
    title_two: "",
    description_one: "",
    description_two: "",
    link_one: "",
    link_two: "",
    is_active: 1,
    image_path: "",
    responsive_image_path: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    slug: "",
    background_color: "",
    title_one: "",
    title_two: "",
    description_one: "",
    description_two: "",
    link_one: "",
    link_two: "",
    image_path: "",
    responsive_image_path: "",
  });
  const fetchAdsData = async (id: number) => {
    try {
      const response = await AdsResonseEdit(id);
      if (response.status_code === 200) {
        setEditAdsFormData({
          name: response.data.name,
          slug: response.data.slug,
          background_color: response.data.background_color,
          title_one: response.data.title_one,
          title_two: response.data.title_two,
          description_one: response.data.description_one,
          description_two: response.data.description_two,
          link_one: response.data.link_one,
          link_two: response.data.link_two,
          is_active: response.data.is_active,
          image_path: response.data.image_path,
          responsive_image_path: response.data.responsive_image_path,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAdsData(id);
    }
  }, [open]);
  const validateForm = () => {
    const newErrors: any = {};
    if (!EditAdsformData.name) newErrors.name = "Name is required.";
    if (!EditAdsformData.title_one)
      newErrors.title_one = "title one is required.";
    if (!EditAdsformData.link_one) newErrors.link_one = "link one is required.";
    if (!EditAdsformData.description_one)
      newErrors.description_one = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleImageSelected = (image: string) => {
    setEditAdsFormData((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };
  const handleResponsiveImageSelected = (image: string) => {
    setEditAdsFormData((prevState) => ({
      ...prevState,
      responsive_image_path: image,
    }));
    setModalOpen(false);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === "name") {
      const generateSlug = (name: string) => {
        return name.trim().toLowerCase().replace(/\s+/g, "-");
      };
      setEditAdsFormData((prevData) => ({
        ...prevData,
        [id]: value,
        slug: id === "name" ? generateSlug(value) : prevData.slug,
      }));
    } else if (id === "background_color") {
      setEditAdsFormData((prevData) => ({
        ...prevData,
        background_color: value,
      }));
    } else {
      setEditAdsFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };
  const handleToggleChange = (checked: number, field: string) => {
    setEditAdsFormData((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          ...EditAdsformData,
          ad_id: id,
          image_path: EditAdsformData.image_path.toString(),
          responsive_image_path:
            EditAdsformData.responsive_image_path.toString(),
        };
        const updateAds = await AdsUpadate(payload);
        if (updateAds.status_code === 200) {
          toast.success("Ads updated Successfully");
          onActionComplete();
          handleClose();
        } else {
          toast.error(" Failed to update");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleDeleteImage = () => {
    setEditAdsFormData({ ...EditAdsformData, responsive_image_path: "" });
  };

  const handleOpenResponsiveModal = () => setisResponsiveModelopen(true);
  const handleCloseResponsiveModal = () => setisResponsiveModelopen(false);

  return (
    <>
      <Modal open={open} onClose={undefined} style={{ zIndex: "1299" }}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Edit Ads
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2} mt={2}>
              <Box display="flex" gap={2}>
                <TextField
                  id="name"
                  name="name"
                  label="Ad Name"
                  variant="outlined"
                  fullWidth
                  value={EditAdsformData.name}
                  onChange={handleInputChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  id="slug"
                  name="slug"
                  label="Slug"
                  variant="outlined"
                  fullWidth
                  value={EditAdsformData.slug}
                  disabled
                />
              </Box>
              <Box display="flex" gap={2}>
                <TextField
                  id="title_one"
                  name="title_one"
                  label="Title One"
                  variant="outlined"
                  fullWidth
                  value={EditAdsformData.title_one}
                  onChange={handleInputChange}
                  error={!!errors.title_one}
                  helperText={errors.title_one}
                />
                <TextField
                  id="title_two"
                  name="title_two"
                  label="Title Two"
                  variant="outlined"
                  fullWidth
                  value={EditAdsformData.title_two}
                  onChange={handleInputChange}
                  error={!!errors.title_two}
                  helperText={errors.title_two}
                />
                <TextField
                  id="link_one"
                  name="link_one"
                  label="Link One"
                  variant="outlined"
                  fullWidth
                  value={EditAdsformData.link_one}
                  onChange={handleInputChange}
                  error={!!errors.link_one}
                  helperText={errors.link_one}
                />
                <TextField
                  id="link_two"
                  name="link_two"
                  label="Link Two"
                  variant="outlined"
                  fullWidth
                  value={EditAdsformData.link_two}
                  onChange={handleInputChange}
                  error={!!errors.link_two}
                  helperText={errors.link_two}
                />
              </Box>
              <Box display="flex" gap={2}>
                <TextField
                  id="description_one"
                  name="description_one"
                  label="Description One"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={EditAdsformData.description_one}
                  onChange={handleInputChange}
                  error={!!errors.description_one}
                  helperText={errors.description_one}
                />
                <TextField
                  id="description_two"
                  name="description_two"
                  label="Description Two"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={EditAdsformData.description_two}
                  onChange={handleInputChange}
                  error={!!errors.description_two}
                  helperText={errors.description_two}
                />
              </Box>
              <Box display="flex" gap={2} alignItems="center" width="100%">
                <Typography
                  variant="subtitle1"
                  className="text-capitalize label"
                >
                  Is Active
                </Typography>
                <Toggle
                  checked={EditAdsformData.is_active === 1}
                  onChange={(checked) =>
                    handleToggleChange(checked, "is_active")
                  }
                />
                <Box className="create_category_fields" sx={{ flex: 2 }}>
                  <Typography
                    variant="subtitle1"
                    className="text-capitalize label"
                  >
                    Background Color
                  </Typography>
                  <Box display="flex" alignItems="center" gap={2}>
                    <TextField
                      variant="filled"
                      name="background_color"
                      id="background_color"
                      value={EditAdsformData.background_color}
                      onChange={handleInputChange}
                      placeholder="#000000"
                      className="common_input"
                      sx={{ width: "40%" }}
                    />
                    <input
                      type="color"
                      id="background_color"
                      value={EditAdsformData.background_color}
                      onChange={handleInputChange}
                      style={{
                        width: "50px",
                        height: "50px",
                        border: "none",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                width="100%"
              >
                <Box
                  flex={1}
                  textAlign="center"
                  border="1px dashed #ccc"
                  padding="20px"
                  bgcolor="#f9f9f9"
                >
                  {EditAdsformData.image_path ? (
                    <img
                      src={`${AppUrl}${EditAdsformData.image_path}`}
                      alt="Selected"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200px",
                        height: "150px",
                      }}
                    />
                  ) : (
                    <p style={{ color: "orange" }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      &nbsp;No image selected. Please upload an image.
                    </p>
                  )}
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                      onClick={handleOpenModal}
                      sx={{
                        fontSize: "10px",
                        borderRadius: "5px",
                        border: "none",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        cursor: "pointer",
                        transition: "background-color 0.2s",
                        "&:hover": { backgroundColor: "#0056b3" },
                      }}
                    >
                      <FontAwesomeIcon icon={faFileArrowUp} />{" "}
                      &nbsp;&nbsp;Upload Image
                    </Button>
                  </Box>
                </Box>

                <Box
                  flex={1}
                  textAlign="center"
                  border="1px dashed #ccc"
                  padding="20px"
                  bgcolor="#f9f9f9"
                  position="relative"
                >
                  {EditAdsformData.responsive_image_path ? (
                    <>
                      <img
                        src={`${AppUrl}${EditAdsformData.responsive_image_path}`}
                        alt="Selected"
                        style={{ width: "200px", height: "150px" }}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          color: "red",
                          fontSize: "20px",
                          cursor: "pointer",
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          borderRadius: "50%",
                          padding: "5px",
                        }}
                        onClick={handleDeleteImage} // Call the delete function
                      />
                    </>
                  ) : (
                    <p style={{ color: "orange" }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      &nbsp;No image selected. Please upload an image.
                    </p>
                  )}
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                      onClick={handleOpenResponsiveModal}
                      sx={{
                        fontSize: "10px",
                        borderRadius: "5px",
                        border: "none",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        cursor: "pointer",
                        transition: "background-color 0.2s",
                        "&:hover": { backgroundColor: "#0056b3" },
                      }}
                    >
                      <FontAwesomeIcon icon={faFileArrowUp} />{" "}
                      &nbsp;&nbsp;Upload Image
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end" gap={2}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>

      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
      <ResponsiveImageUploader
        isOpen={isResponsiveModelopen}
        onClose={handleCloseResponsiveModal}
        onImageSelect={handleResponsiveImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default AdsEdit;
