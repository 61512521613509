import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSectionDataFromdb } from "../../services/apiService";

export const fetchSectionData = createAsyncThunk(
  "jsondata/fetchSectionData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSectionDataFromdb();
      const parsedData = JSON.parse(response.data[0].cassette);
      return parsedData.map((section) => ({
        section_name: section.section_name,
        page_id: section.page_id,
        data: section.data,
        previewSection: section.preview_section,
      }));
    } catch (error) {
      console.error(error);
      return rejectWithValue("Failed to fetch section data");
    }
  }
);

const initialState = {
  sectionCreateData: [],
  loading: false,
  error: null,
};

const jsonDataReducer = createSlice({
  name: "jsondata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSectionData.pending, (state) => {
        state.loading = true; 
        state.error = null;    
      })
      .addCase(fetchSectionData.fulfilled, (state, action) => {
        state.loading = false;
        state.sectionCreateData = action.payload;  
      })
      .addCase(fetchSectionData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message || "Unknown error";
      });
  },
});

export default jsonDataReducer.reducer;
