import React, { useState, useCallback, useRef } from "react";
import "../styles/ImageUpload.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Box, Typography, Card, IconButton, Grid } from "@mui/material";
import { MultipleMediaUpload } from "services/apiService";
import { ImageUploadProps } from "types";

const ImageUpload: React.FC<ImageUploadProps> = ({ onUploadSuccess }) => {
  const [images, setImages] = useState<File[]>([]);
  const [message, setMessage] = useState<{
    type: "success" | "error" | "";
    text: string;
  }>({ type: "", text: "" });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadFiles = async () => {
    if (images.length === 0) {
      setMessage({ type: "error", text: "Please upload at least one image." });
      return;
    }

    const formData = new FormData();
    images.forEach((image) => formData.append("file_name", image));

    try {
      const response = await MultipleMediaUpload(formData);
      if (response.status_code === 200) {
        setMessage({ type: "success", text: "Images uploaded successfully!" });
        onUploadSuccess();
        setImages([]);
      }
    } catch (error) {
      setMessage({ type: "error", text: "Error uploading images." });
    }
  };

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files); // Handle multiple files
    setImages((prevImages) => [...prevImages, ...droppedFiles]);
  }, []);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setMessage({ type: "", text: "" });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files
      ? Array.from(event.target.files)
      : [];
    setImages((prevImages) => [...prevImages, ...selectedFiles]);
    setMessage({ type: "", text: "" });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleBrowseFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        gap: 2,
        padding: 3,
        maxWidth: "800px",
        maxHeight: "600px",
        height: "500px",
        overflow: "auto",
        margin: "auto",
        backgroundColor: "#f9f9f9",
        borderRadius: 4,
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{
          width: "100%",
          minHeight: "150px",
          border: "2px dashed #007bff",
          borderRadius: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: 2,
          backgroundColor: "#eef6fd",
          "&:hover": { backgroundColor: "#d7e9fa" },
          cursor: "pointer",
        }}
      >
        <Typography variant="body1" color="primary">
          Drag & drop your images here
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
              }}
            >
              <img
                src={URL.createObjectURL(image)}
                alt={`Preview ${index}`}
                style={{
                  width: "100%",
                  maxHeight: "100px",
                  objectFit: "cover",
                  borderRadius: 4,
                  marginBottom: "8px",
                }}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                noWrap
                sx={{ width: "100%", textAlign: "center" }}
              >
                {image.name}
              </Typography>
              <IconButton
                color="error"
                onClick={() => handleRemoveImage(index)}
                size="small"
                sx={{ marginTop: 1 }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" gap={2} mt={2}>
        <Button
          variant="outlined"
          onClick={handleBrowseFiles}
          sx={{ textTransform: "none" }}
        >
          Browse Files
        </Button>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple
          style={{ display: "none" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleUploadFiles}
          disabled={images.length === 0}
          sx={{ textTransform: "none" }}
        >
          <FontAwesomeIcon icon={faCloudArrowUp} />
          &nbsp; Upload to Gallery
        </Button>
      </Box>
      {message.text && (
        <Typography
          variant="body2"
          color={message.type === "success" ? "green" : "error"}
        >
          {message.text}
        </Typography>
      )}
    </Box>
  );
};

export default ImageUpload;
