import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DynamicButton = ({ icon, text, onClick, style, iconStyle }) => {
    return (
        <button
            style={{
                border: 'none',
                ...style
            }}
            onClick={onClick}
        >
            {icon && <FontAwesomeIcon icon={icon} style={{
                ...iconStyle
            }} />}
            {text}
        </button>
    );
}

export default DynamicButton;
