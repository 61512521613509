import React, { useEffect, useState } from "react";
import { DeleteDiscount, getDiscountListData } from "services/apiService";
import DynamicButton from "../../common/DynamicButton";
import {
  faCirclePlus,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import imageNotFound from "../../Images/imageNotFound.png";
import DiscountEdit from "./DiscountEdit";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Pagination } from "@mui/material";
import loadingImage from "../../assect/loadingSvg/loading.svg";
import Swal from "sweetalert2";

const DiscountList = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [discountSearch, setDiscountSearch] = useState<string>("");
  const [loader, setloader] = useState(false);
  const [isOpenDiscountEdit, setIsOpenDiscountEdit] = useState<boolean>(false);
  const [selectedDiscountId, setSelectedDiscountId] = useState<number | null>(
    null
  );
  const [allDiscount, setAllDiscount] = useState([]);

  console.log(allDiscount, "*****************");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;
  const headers = [
    "S.no",
    "Discount Name",
    "Discount",
    "Image",
    "Start Date",
    "End Date",
    "Status",
    "Action",
  ];

  useEffect(() => {
    getDiscountList();
  }, []);

  const getDiscountList = async () => {
    setloader(true);
    try {
      const response = await getDiscountListData();
      setAllDiscount(response.data);
    } catch (error) {}
    setloader(false);
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteDiscount(id);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The discount has been deleted.", "success");
            getDiscountList();
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The discount is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const handleModelOpen = (id: number) => {
    setSelectedDiscountId(id);
    setIsOpenDiscountEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenDiscountEdit(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDiscountSearch(value);
    setCurrentPage(1);
  };
  const filteredDiscounts = allDiscount?.filter((discount: any) =>
    discount.name.toLowerCase().includes(discountSearch.toLowerCase())
  );

  const paginatedDiscounts = filteredDiscounts?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const renderRow = (discount: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td className="table_product_name" title={discount.name}>
          {discount.name?.length > 30
            ? discount.name.slice(0, 30) + "..."
            : discount.name}
        </td>

        <td className="align-content-center">{discount?.percentage}%</td>
        <td
          className="table_product_name align-content-center"
          style={{ color: "grey" }}
        >
          <div className="d-flex justify-content-center">
            <img
              src={
                discount.image_path
                  ? AppUrl + discount.image_path
                  : imageNotFound
              }
              alt={discount?.slug.toLowerCase()}
              style={{ width: "200px" }}
            />
          </div>
        </td>

        <td className="table_product_name align-content-center">
          {discount.from_date}
        </td>
        <td className="table_product_name align-content-center">
          {discount.to_date}
        </td>

        <td className="align-content-center">
          <span
            style={{
              color: discount.is_active === 1 ? "green" : "red",
              backgroundColor: discount.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {discount?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>

        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleModelOpen(discount.id)}
            />

            <DynamicButton
              icon={faTrashCan}
              iconStyle={{ color: "#f54242" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleDelete(discount.id, discount.name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between  ">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/discount-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Discount
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            placeholder="Search Discount ...."
            className="form-control"
            style={{ boxShadow: "none" }}
            value={discountSearch}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="product-table-content">
        {loader ? (
          <div className="loadingsvg-Image text-center">
            <img src={loadingImage} width={150} height={150} alt="Loading..." />
          </div>
        ) : (
          <CommonTable
            data={paginatedDiscounts}
            isLoading={loader}
            headers={headers}
            renderRow={renderRow}
          />
        )}
      </div>

      <div className="pagination-container">
        <div className="data-info" style={{ color: "grey" }}>
          <span>
            Showing {(currentPage - 1) * itemsPerPage + 1}-
            {Math.min(currentPage * itemsPerPage, filteredDiscounts?.length)} of{" "}
            {filteredDiscounts?.length}
          </span>
        </div>
        <Pagination
          count={Math.ceil(filteredDiscounts?.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>

      <DiscountEdit
        onActionComplete={() => {
          getDiscountList();
        }}
        open={isOpenDiscountEdit}
        handleClose={handleModelClose}
        id={selectedDiscountId}
      />
    </>
  );
};

export default DiscountList;
