import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  FaqCategory,
  PagesListResponse,
  ProductKey,
  SectionPageList,
  SignInResponse,
  UserData,
  ProductlistResponse,
  AdsDropDownValuesApi,
  CompaginDropDownApi,
  ClassfiDropDownApi,
  MediaListData,
  SectionDataList,
  GetEditSections,
  SectionPayload,
  SectionOrderChangePayload,
  SectionRemovePayload,
  BrandResponse,
  CategoriesResponse,
  TaxResponse,
  DiscountResponse,
  AllBrandsList,
  AllCategoriesList,
  AllTaxList,
  AllCouponList,
  PagenationPayload,
  OrdersResponseTyeps,
  MediaImageUploadPayload,
  ProductDropdownResponse,
  TotalPagenationCount,
  BrandCreatePayload,
  BrandCreateResponse,
  CategoryCreatePayload,
  CategoryCreateResponse,
  CreateTaxPayload,
} from "../types";
import toast from "react-hot-toast";

const API_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL as string;

const apiService: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL as any;

apiService.interceptors.request.use((config) => {
  const token = localStorage.getItem("admin_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

//Product key Api
export const verifyProductKey = async (
  productKeyToken: string
): Promise<ProductKey | null> => {
  try {
    const productKey = {
      product_key: productKeyToken,
    };

    const response: AxiosResponse<ProductKey> = await apiService.post(
      "/app-key-verify",
      productKey
    );

    return response.data;
  } catch (error) {
    toast.error("Error verifying product key");
    return null;
  }
};

//Login Api
export const AdminSignIn = async (
  userData: UserData,
  navigate: Function
): Promise<SignInResponse> => {
  try {
    const response: AxiosResponse<SignInResponse> = await apiService.post(
      "/login",
      userData
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 403) {
        navigate("/Product-key");
      }
    } else {
      console.error("Network or unexpected error:", error);
      localStorage.setItem("productKeyState", "true");
      navigate("/login");
    }
    throw error;
  }
};

///faqCategorieFilter Api
export const faqCategorieFilter = async (
  id: number
): Promise<FaqCategory | null> => {
  try {
    const response: AxiosResponse<FaqCategory> = await apiService.get(
      `/get-category-faqs/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching FAQ categories", error);
    return null;
  }
};

// All products
export const allProducts = async (
  search_text: string,
  page_number: number,
  numberofItems: number
): Promise<ProductlistResponse | null> => {
  try {
    const payload: PagenationPayload = {
      search_text: search_text,
      page_number: page_number,
      num_of_items: numberofItems,
    };
    const response: AxiosResponse<ProductlistResponse> = await apiService.post(
      "/all-products",
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching all products", error);
    return null;
  }
};

export const productViewData = async (
  id: number
): Promise<ProductlistResponse | null> => {
  try {
    const response = await apiService.get(`/get-product/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all products", error);
  }
};
// All Pages
export const getAllPagesList = async (): Promise<PagesListResponse | null> => {
  try {
    const response: AxiosResponse<PagesListResponse> = await apiService.get(
      "/all-pages"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
//  All Section List
export const getAllSectionList = async (): Promise<SectionPageList | null> => {
  try {
    const response: AxiosResponse<SectionPageList> = await apiService.get(
      "/all-sections"
    );
    return response.data;
  } catch (error) {}
};
// get all ads dropdown values
export const getAdsDropDownValues = async (): Promise<AdsDropDownValuesApi> => {
  try {
    const response: AxiosResponse<AdsDropDownValuesApi> = await apiService.get(
      "/ads-dropdown"
    );
    return response.data;
  } catch (error) {}
};
// get All compagin dropdown Values
export const getCompaginDropDownValues =
  async (): Promise<CompaginDropDownApi> => {
    try {
      const response: AxiosResponse<CompaginDropDownApi> = await apiService.get(
        "/campaigns-dropdown"
      );
      return response.data;
    } catch (error) {}
  };
// get classfication drop down values
export const getClassficationValues = async (): Promise<ClassfiDropDownApi> => {
  try {
    const response: AxiosResponse<ClassfiDropDownApi> = await apiService.get(
      "/classifications-dropdown"
    );
    return response.data;
  } catch (error) {}
};
// get Media List
export const getMediaList = async (): Promise<MediaListData> => {
  try {
    const response: AxiosResponse<MediaListData> = await apiService.get(
      "/media-list"
    );
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};
//  Create Section
export const createSection = async (payload: SectionPayload) => {
  try {
    const response = await apiService.post("/add-section", payload);
    return response.data;
  } catch (error) {}
};
// get Section Data
export const getSectionData = async (
  section_group: number,
  pageId: number
): Promise<SectionDataList> => {
  try {
    const response: AxiosResponse<SectionDataList> = await apiService.get(
      `/view-section-data/${section_group}/${pageId}`
    );
    return response.data;
  } catch (error) {}
};
// get Edit Page  Section
export const getEditPageSection = async (
  page_id: number
): Promise<GetEditSections> => {
  try {
    const response: AxiosResponse<GetEditSections> = await apiService.get(
      `/page-sections/${page_id}`
    );
    return response.data;
  } catch (error) {}
};
// Edit Section
export const editSection = async (payload: any) => {
  try {
    const response = await apiService.post("/update-page-section", payload);
    return response.data;
  } catch (error) {}
};
// Edit section Place
export const getEditSectionPlace = async (
  payload: SectionOrderChangePayload
) => {
  try {
    const response = await apiService.post("/edit-section-place", payload);
    return response.data;
  } catch (error) {}
};
// Remove Section
export const removeSection = async (payload: SectionRemovePayload) => {
  try {
    const response = await apiService.post("/remove-section", payload);
    return response.data;
  } catch (error) {}
};

// Bulk Brands Upload
export const bulkBrandsUpload = async (payload: File) => {
  try {
    const formData = new FormData();
    formData.append("file_name", payload);
    const response = await apiService.post("/bulk-upload-brands", formData);
    return response.data;
  } catch (error) {}
};
// Bulk Categories Upload
export const bulkCategoriesUpload = async (payload: File) => {
  try {
    const formData = new FormData();
    formData.append("file_name", payload);
    const response = await apiService.post("/bulk-upload-categories", formData);
    return response.data;
  } catch (error) {}
};
// Bulk Product Upload
export const bulkProductUpload = async (payload: File) => {
  try {
    const formData = new FormData();
    formData.append("file_name", payload);
    const response = await apiService.post("/bulk-upload-products", formData);
    return response.data;
  } catch (error) {}
};

// Function to fetch brands dropdown
export const BrandDropDown = async (): Promise<BrandResponse | null> => {
  try {
    const response: AxiosResponse<BrandResponse> = await apiService.get(
      "/get-brands-dropdown"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CategoriesDropDown =
  async (): Promise<CategoriesResponse | null> => {
    try {
      const response: AxiosResponse<CategoriesResponse> = await apiService.get(
        "/get-categories-dropdown"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const TaxDropDown = async (): Promise<TaxResponse | null> => {
  try {
    const response: AxiosResponse<TaxResponse> = await apiService.get(
      "/taxes-dropdown"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DiscountDropDown = async (): Promise<DiscountResponse | null> => {
  try {
    const response: AxiosResponse<DiscountResponse> = await apiService.get(
      "/discounts-dropdown"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// GET ALL BRANDS LIST
export const getAllBrands = async (
  search_text: string,
  page_number: number,
  num_of_items: number
): Promise<AllBrandsList> => {
  try {
    const payload: PagenationPayload = {
      search_text: search_text,
      page_number: page_number,
      num_of_items: num_of_items,
    };
    const response: AxiosResponse<AllBrandsList> = await apiService.post(
      "/all-brands",
      payload
    );
    return response.data;
  } catch (error) {}
};
// GET ALL CATEGORIES LIST
export const getAllCategories = async (
  search_text: string,
  page_number: number,
  num_of_items: number
): Promise<AllCategoriesList> => {
  try {
    const payload: PagenationPayload = {
      search_text: search_text,
      page_number: page_number,
      num_of_items: num_of_items,
    };
    const response: AxiosResponse<AllCategoriesList> = await apiService.post(
      "/all-categories",
      payload
    );
    return response.data;
  } catch (error) {}
};
// GET ALL TAXES LIST
export const getAllTakesList = async (): Promise<AllTaxList> => {
  try {
    const response: AxiosResponse<AllTaxList> = await apiService.get(
      "/all-taxes"
    );
    return response.data;
  } catch (error) {}
};
//  GET ALL COUPON LIST
export const getAllCouponList = async (): Promise<AllCouponList> => {
  try {
    const response: AxiosResponse<AllCouponList> = await apiService.get(
      "/all-coupons"
    );
    return response.data;
  } catch (error) {}
};
// GET ALL DISCOUNT LIST
export const getDiscountListData = async () => {
  try {
    const response = await apiService.get("/all-discounts");
    return response.data;
  } catch (error) {}
};
// GET ALL SELLER LIST
export const getSellerListData = async () => {
  try {
    const response = await apiService.get("/all-sellers");
    return response.data;
  } catch (error) {}
};
// GET ALL CLASSIFICATIONS LIST

export const getAllClassificationList = async () => {
  try {
    const response = await apiService.get("/all-classifications");
    return response.data;
  } catch (error) {}
};
// GET ALL CAMPAIGNE LIST

export const getAllCampaigneList = async () => {
  try {
    const response = await apiService.get("/all-campaigns");
    return response.data;
  } catch (error) {}
};
// GET ALL ORDERS LIST

export const getAllOrdersList = async (
  search_text: string,
  page_number: number,
  num_of_items: number
): Promise<OrdersResponseTyeps> => {
  try {
    const payload: PagenationPayload = {
      search_text: search_text,
      page_number: page_number,
      num_of_items: num_of_items,
    };
    const response: AxiosResponse<OrdersResponseTyeps> = await apiService.post(
      "/all-orders",
      payload
    );
    return response.data;
  } catch (error) {}
};

// PAGENATION DATA FOR PRODUCTS
export const pageNationData = async (): Promise<TotalPagenationCount> => {
  try {
    const response: AxiosResponse<TotalPagenationCount> = await apiService.get(
      "/get-range-data"
    );
    return response.data;
  } catch (error) {}
};

export const MultipleMediaUpload = async (payload) => {
  try {
    const response = await apiService.post("/multiple-media-upload", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ProductCreate = async (payload) => {
  try {
    const response = await apiService.post("/create-product", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const MediaImageUpload = async (payload: MediaImageUploadPayload) => {
  try {
    const response = await apiService.post("/product-image-upload", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AllProducts = async () => {
  try {
    const response: AxiosResponse<ProductDropdownResponse> =
      await apiService.get("/products-dropdown");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getcampaignMappedProduct = async (id: number) => {
  try {
    const response = await apiService.get(`get-campaign-products/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getclassificationMappedProduct = async (id: number) => {
  try {
    const response = await apiService.get(`get-classification-products/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getcampaignMappingProduct = async (payload) => {
  try {
    const response = await apiService.post("admin-products-list", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ClassificationDropDown = async () => {
  try {
    const response: AxiosResponse = await apiService.get(
      "/classifications-dropdown"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const BrandDetails = async (brandId: number) => {
  try {
    const response = await apiService.get(`/get-brand/${brandId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const BrandDetailsUpdate = async (payload) => {
  try {
    const response = await apiService.post("/update-brand", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreateBrand = async (
  payload: BrandCreatePayload
): Promise<BrandCreateResponse> => {
  try {
    const response = await apiService.post("/create-brand", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CategoryCreateApi = async (
  payload: CategoryCreatePayload
): Promise<CategoryCreateResponse> => {
  try {
    const response = await apiService.post("/create-category", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CategoriesCreateDropDown = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/dropdown-main-categories`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching dropdown categories:", error);
    throw error;
  }
};

export const CreateTax = async (payload: CreateTaxPayload) => {
  try {
    const response = await apiService.post("/create-tax", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreateCoupon = async (payload: any) => {
  try {
    const response = await apiService.post("/create-coupon", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreateDiscount = async (payload: any) => {
  try {
    const response = await apiService.post("/create-discount", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreateClassification = async (payload: any) => {
  try {
    const response = await apiService.post("/create-classification", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreateCampaignResponse = async (payload: any) => {
  try {
    const response = await apiService.post("/create-campaign", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AllClassification = async (productId: number) => {
  try {
    const response = await apiService.get(
      `/product-classifications-map/${productId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const productClassificationSubmit = async (payload) => {
  try {
    const response = await apiService.post(
      "/create-classification-map",
      payload
    );
    return response.data;
  } catch (error) {
    return 400;
  }
};

export const productCampaignSubmit = async (payload) => {
  try {
    const response = await apiService.post("/create-campaign-map", payload);

    return response.data;
  } catch (error) {
    return 400;
  }
};

export const VariantDropDown = async () => {
  try {
    const response = await apiService.get("/variant-dropdown");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const productVariantSubmit = async (payload) => {
  try {
    const response = await apiService.post("/create-product-variant", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ProductDelete = async (payload) => {
  try {
    const response = await apiService.post("/delete-product", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CatogorieDelete = async (payload) => {
  try {
    const response = await apiService.post("/delete-category", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const BrandDelete = async (payload) => {
  try {
    const response = await apiService.post("/delete-brand", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const productEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-product/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const productUpdate = async (payload) => {
  try {
    const response = await apiService.post("/update-product", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const productVariantEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-product-variant/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const productVariantUpdate = async (payload: any) => {
  try {
    const response = await apiService.post("/update-product-variant", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// GET ALL SUB PAGES LIST
export const getAllSubPagesList = async () => {
  try {
    const response = await apiService.get("/get-all-sub-pages");
    return response.data;
  } catch (error) {}
};
// GET ALL ADS PAGES LIST
export const getAllAdsPageList = async () => {
  try {
    const response = await apiService.get("/all-ads");
    return response.data;
  } catch (error) {}
};
//GET ALL  FAQ CATEGORIES PAGES LSIT
export const getAllFaqCategoriesList = async () => {
  try {
    const response = await apiService.get("/all-faq-categories");
    return response.data;
  } catch (error) {}
};
// GET ALL FAQ LIST
export const getAllFaqPagesList = async () => {
  try {
    const response = await apiService.get("/get-all-faqs");
    return response.data;
  } catch (error) {}
};
//  GET ALL SETTINGS DATA
export const getSettingsDataList = async () => {
  try {
    const response = await apiService.get("/get-settings-data");
    return response.data;
  } catch (error) {}
};
// GET APP ABOUT US
export const getAppAboutUsData = async () => {
  try {
    const response = await apiService.get("/test");
    return response.data;
  } catch (error) {}
};

export const productCampaignUpdate = async (payload) => {
  try {
    const response = await apiService.post("/update-campaign-map", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AllCampaign = async (productId) => {
  try {
    const response = await apiService.get(`/product-campaign-map/${productId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const productClasssificationUpdate = async (payload) => {
  try {
    const response = await apiService.post(
      "/update-classification-map",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// UPDATE THE SETTINGS VALUES
export const updateSettingsvalue = async (payload: any) => {
  try {
    const response = await apiService.post("/update-setting", payload);
    return response.data;
  } catch (error) {}
};
// GET BLOGS LIST DATA
export const getAllBlogsList = async () => {
  try {
    const response = await apiService.get("/get-all-blogs");
    return response.data;
  } catch (error) {}
};
// GET ALL BLOG CATEGORIES
export const getAllBlogCategoriesList = async () => {
  try {
    const response = await apiService.get("/all-blog-categories");
    return response.data;
  } catch (error) {}
};
// GET ALL BLOG TAGES
export const getAllBlogTages = async () => {
  try {
    const response = await apiService.get("/all-blog-tags");
    return response.data;
  } catch (error) {}
};

export const categoriesEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-category/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const categoriesUpdate = async (payload) => {
  try {
    const response = await apiService.post("/update-category", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const TaxEditdata = async (id: number) => {
  try {
    const response = await apiService.get(`/get-tax/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBlogApi = async (id: number) => {
  try {
    const response = await apiService.get(`/get-single-blog/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const BlogUpdate = async (payload: any) => {
  try {
    const response = await apiService.post("/update-blog", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const TaxUpdate = async (payload) => {
  try {
    const response = await apiService.post("/update-tax", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const couponEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-coupon/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SubpagesCreate = async (payload) => {
  try {
    const response = await apiService.post("/create-sub-page", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SubpagesEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-single-sub-page/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SubpagesUpdate = async (payload) => {
  try {
    const response = await apiService.post("/update-sub-page", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreateAds = async (payload) => {
  try {
    const response = await apiService.post("/create-ad", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AdsResonseEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-ad/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFaqCategoriesEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-faq-category/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getFaqEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-single-faq/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const AdsUpadate = async (payload) => {
  try {
    const response = await apiService.post("/update-ad", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpadateFaqCatogries = async (payload) => {
  try {
    const response = await apiService.post("/update-faq-category", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpadateBlogsTags = async (payload) => {
  try {
    const response = await apiService.post("/update-blog-tag", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpadateBlogsCategory = async (payload) => {
  try {
    const response = await apiService.post("/update-blog-category", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpadateFaq = async (payload) => {
  try {
    const response = await apiService.post("/update-faq", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllBlogTagsAPICall = async () => {
  try {
    const response = await apiService.get("/all-blog-tags");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getDiscountEdit = async (DiscountId: number) => {
  try {
    const response = await apiService.get(`/get-discount/${DiscountId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCampaignEdit = async (categoryId: number) => {
  try {
    const response = await apiService.get(`/get-campaign/${categoryId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getClassificationEdit = async (Id: number) => {
  try {
    const response = await apiService.get(`/get-classification/${Id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createBlogAPICall = async (payload) => {
  try {
    const response = await apiService.post("/create-blog", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatedCampaign = async (payload) => {
  try {
    const response = await apiService.post("/update-campaign", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatedClassification = async (payload) => {
  try {
    const response = await apiService.post("/update-classification", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatedCoupon = async (payload) => {
  try {
    const response = await apiService.post("/update-coupon", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatedDiscount = async (payload) => {
  try {
    const response = await apiService.post("/update-discount", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const orderCreateMyAdmin = async (payload) => {
  try {
    const response = await apiService.post("/add-to-cart", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserCardData = async (id) => {
  try {
    const response = await apiService.get(`/get-user-cart/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// FAQ CATEGORY DROPDOWN
export const faqCategoryDropDowndata = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}faq-categories-dropdown`);
    return response.data;
  } catch (error) {}
};

// FAQ CATEGORY CREATE
export const createFaqCategory = async (payload) => {
  try {
    const formData = {
      ...payload,
      is_active: payload.is_active ? 1 : 0,
    };
    const response = await apiService.post("/create-faq-category", formData);
    return response.data;
  } catch (error) {}
};
//  FAQ CREATE
export const createFaq = async (payload) => {
  try {
    const formData = {
      ...payload,
      is_active: payload.is_active ? 1 : 0,
    };
    const response = await apiService.post("/create-faq", formData);
    return response.data;
  } catch (error) {}
};

export const createSeller = async (payload: any) => {
  try {
    const response = await apiService.post("/create-seller", payload);
    return response.data;
  } catch (error) {}
};

// ADMIN LOGOUT
export const adminLogout = async () => {
  try {
    const response = await apiService.get("/logout");
    return response.data;
  } catch (error) {}
};

// GET SECTOION  DATA FROM DB
export const getSectionDataFromdb = async () => {
  try {
    const response = await apiService.get("/get-themes-list");
    return response?.data;
  } catch (error) {}
};

export const getSellerEdit = async (id: number) => {
  try {
    const response = await apiService.get(`/get-seller/${id}`);
    return response?.data;
  } catch (error) {}
};

export const updateSeller = async (payload: any) => {
  try {
    const response = await apiService.post("/update-seller", payload);
    return response.data;
  } catch (error) {}
};

export const CreateSettingsGroupApi = async (payload: any) => {
  try {
    const response = await apiService.post("/create-setting-group", payload);
    return response.data;
  } catch (error) {}
};

export const createBlogCategories = async (payload) => {
  try {
    const response = await apiService.post("/create-blog-category", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteTax = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-tax/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteCoupon = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-coupon/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteDiscount = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-discount/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteClassification = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-classification/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteCampaingn = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-campaign/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteSubPages = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-sub-page/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteAds = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-ads/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteFaqCategories = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-faq-category/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteFaq = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-faq/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteBlogs = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-blog/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteBlogsCatorgeries = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-blog-category/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteBlogsTags = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-blog-tag/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createBlogTags = async (payload) => {
  try {
    const response = await apiService.post("/create-blog-tag", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSettingsGroupList = async () => {
  try {
    const response = await apiService.get("/setting-group-list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ProductDeleteImage = async (id: number) => {
  try {
    const response = await apiService.get(`/delete-product-asset/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const OrderVeiwApi = async (id: number) => {
  try {
    const response = await apiService.get(`/get-order-detail/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateOrder = async (payload) => {
  try {
    const response = await apiService.post("/update-order", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SettingGroupdropdown = async () => {
  try {
    const response = await apiService.get("/setting-group-dropdown");
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const createSingleSetting = async (payload) => {
  try {
    const response = await apiService.post("/create-setting", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateSettingsGroupEditApi = async (payload) => {
  try {
    const response = await apiService.post("/update-setting-group", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateCommonSettings = async (payload) => {
  try {
    const response = await apiService.post("/change-setting-value", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SettingSingledropdown = async () => {
  try {
    const response = await apiService.get("/settings-list");
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const SettingsChild = async (id: number) => {
  try {
    const response = await apiService.get(`get-settings-data/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const SettingsChildEdit = async (id: number) => {
  try {
    const response = await apiService.get(`get-setting/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const SettingsGroupEditApi = async (id: number) => {
  try {
    const response = await apiService.get(`get-setting-group/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const BlogTagsEditApi = async (id: number) => {
  try {
    const response = await apiService.get(`get-blog-tag/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const BlogCategoryEditApi = async (id: number) => {
  try {
    const response = await apiService.get(`get-blog-category/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserDataOderCreate = async () => {
  try {
    const response = await apiService.get("users-dropdown");
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserCartTotal = async (id) => {
  try {
    const response = await apiService.get(`get-cart-total/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserBillingAddress = async (id) => {
  try {
    const response = await apiService.get(`get-billing-addresses/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserShippingAddress = async (id) => {
  try {
    const response = await apiService.get(`get-shipping-addresses/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteCartItems = async (payload) => {
  try {
    const response = await apiService.post(
      "/remove-add-to-cart-product",
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateCartItems = async (payload) => {
  try {
    const response = await apiService.post("/update-to-cart", payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteMappedProductCampign = async (id) => {
  try {
    const response = await apiService.get(`delete-mapped-campaign/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const DeleteMappedProductClassification = async (id) => {
  try {
    const response = await apiService.get(`delete-mapped-classification/${id}`);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export default apiService;
