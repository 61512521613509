import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";

const FloatingButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Create a ref for Draggable's node
  const draggableRef = useRef<HTMLDivElement>(null);

  const toggleContainer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Draggable nodeRef={draggableRef}>
      <div ref={draggableRef} style={styles.buttonContainer}>
        <button style={styles.button} onClick={toggleContainer}>
          <FontAwesomeIcon icon={faCalculator} />
        </button>
        {isOpen && (
          <div style={styles.popup}>
            <Calculator />
          </div>
        )}
      </div>
    </Draggable>
  );
};

const Calculator: React.FC = () => {
  const [input, setInput] = useState("");

  const handleClick = (value: string) => {
    setInput((prev) => prev + value);
  };

  const calculate = () => {
    try {
      setInput(eval(input)); // Unsafe in production; replace with a safe parser.
    } catch {
      setInput("Error");
    }
  };

  const clear = () => setInput("");

  return (
    <div style={styles.calculator}>
      <input type="text" value={input} readOnly style={styles.display} />
      <div style={styles.buttons}>
        {[...Array(10).keys()].map((num) => (
          <button
            key={num}
            style={styles.buttonItem}
            onClick={() => handleClick(num.toString())}
          >
            {num}
          </button>
        ))}
        <button style={styles.buttonItem} onClick={clear}>
          C
        </button>
        <button style={styles.buttonItem} onClick={calculate}>
          =
        </button>
        {["+", "-", "*", "/"].map((op) => (
          <button
            key={op}
            style={styles.buttonItem}
            onClick={() => handleClick(op)}
          >
            {op}
          </button>
        ))}
      </div>
    </div>
  );
};

const styles = {
  buttonContainer: {
    position: "fixed" as const,
    bottom: "20px",
    right: "20px",
    zIndex: 1000,
  },
  button: {
    background: "#007bff",
    color: "#fff",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    border: "none",
    cursor: "pointer",
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  popup: {
    position: "absolute" as const,
    bottom: "70px",
    right: "0",
    background: "#f9f9f9",
    boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
    borderRadius: "12px",
    padding: "20px",
    zIndex: 1000,
    width: "250px",
  },
  calculator: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    fontFamily: "'Roboto', sans-serif",
  },
  display: {
    width: "100%",
    padding: "15px",
    marginBottom: "20px",
    textAlign: "right" as const,
    fontSize: "22px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    background: "#fff",
    boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  buttons: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "10px",
    width: "100%",
  },
  buttonItem: {
    background: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    padding: "15px",
    fontSize: "18px",
    cursor: "pointer",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    transition: "background 0.3s ease, transform 0.2s ease",
    fontWeight: "bold" as const,
  },
};

export default FloatingButton;
