import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getMediaList } from "services/apiService";
import { useQuery } from "react-query";
import { toggleImageSelection } from "../../../../redux/Slice/mediaSlice.js";

import { useDispatch, useSelector } from "react-redux";

const ImageGallery = ({ isOpen, onRequestClose, isMulti, keyName, handleBlur }) => {
  const dispatch = useDispatch();
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const { data: images } = useQuery("image", getMediaList);
  const imageeee = useSelector((state:any) => state.media.selectedImagesByKey);
  const single = useSelector((state: any) => state.media.singleImageSelections);
  const selectedImage = imageeee ? imageeee[keyName] : undefined;
  const removeQuets = selectedImage ? selectedImage.split(",") : [];
  const singleSelect = single ? single[keyName] : "";
  const filteredFiles = removeQuets.filter((file) => file !== "");
  const handleImageToggle = ({ keyName, mediaUrl, isMulti }) => {
    dispatch(toggleImageSelection({ keyName, mediaUrl, isMulti }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Gallery"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "9999",
        },
        content: {
          width: "90%",
          maxWidth: "1020px",
          height: "auto",
          maxHeight: "80vh",
          overflow: "auto",
          borderRadius: "10px",
          padding: "20px",
          position: "relative",
          margin: "auto",
          // backgroundColor: "#f8f9fa",
          scrollbarWidth: "none",
        },
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Image Gallery</h2>
          </div>
          <div className="col-auto">
            <button
              onClick={onRequestClose}
              style={{
                fontSize: "30px",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faTimes} style={{ color: "#63E6BE" }} />
            </button>
          </div>
        </div>
        <div className="row">
          {images?.data
            ?.filter(
              (url) =>
                url.endsWith(".jpg") ||
                url.endsWith(".jpeg") ||
                url.endsWith(".png") ||
                url.endsWith(".webp")
            )
            .map((mediaUrl, index) => {
              return (
                <div key={index} className="col-md-4 mb-4">
                  <div
                    style={{
                      width: "200px",
                      height: "auto",
                      border: "1px solid #ddd",
                      padding: "5px",
                      borderRadius: "5px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={AppUrl + mediaUrl}
                      alt="media"
                      className="img-fluid"
                    />
                    <input
                      key={keyName}
                      name={keyName}
                      type="checkbox"
                      onBlur={handleBlur}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        zIndex: "10",
                      }}
                      checked={
                        isMulti
                          ? filteredFiles?.includes(mediaUrl)
                          : singleSelect === mediaUrl
                      }
                      onChange={() =>
                        handleImageToggle({ keyName, mediaUrl, isMulti })
                      }
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};

export default ImageGallery;
