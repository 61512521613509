import React, { useState } from "react";
import Pagination from "../common/Pagination";
import data from "../JSON/db.json";

const SettingsList = () => {
  const [value, setValue] = useState(data.settings_list);

  const handleValueClick = (id) => {
    value.map((i) => {
      if (i.id === id) i.isEdit = true;
      else i.isEdit = false;
      return null;
    });
    setValue([...value]);
  };

  const handleInputChange = (e, id) => {
    const keyValue = e.target.value;
    value.map((i) => {
      if (i.id === id) {
        i.value = keyValue;
      }
      return null;
    });
    setValue([...value]);
  };

  return (
    <div className="w-100">
      <div className=" dm-sans">
        <div className="product_main_second my-3">
          <h6>Settings</h6>
          <div className="product_search bg-white shadow-sm">
            <i className="bi bi-search"></i>
            <input type="text" placeholder="Search..." />
          </div>
        </div>
        <div className="product_main_third">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Value</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.settings_list?.map((i) => (
                <tr className="mt-2 pt-4" key={i.id}>
                  <td>{i.id}</td>
                  <td>
                    <div className="d-flex flex-column">
                      <span>{i.name}</span>
                    </div>
                  </td>
                  <td onClick={() => handleValueClick(i.id)}>
                    {i.isEdit ? (
                      <input
                        type="text"
                        value={i.value}
                        className="borded-none outline-none bg-transparent border-0"
                        onChange={(e) => handleInputChange(e, i.id)}
                      />
                    ) : (
                      <span>{i.value}</span>
                    )}
                  </td>
                  <td>
                    <div className="active_status p-1 ">{i.status}</div>
                  </td>
                  <td>
                    <button className="bg-white p-2  border-0 outline-none">
                      <i className="bi bi-download"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination extClasses="py-3" />
      </div>
    </div>
  );
};

export default SettingsList;
