import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { productViewData } from "services/apiService";
import { ProductData } from "types";
import "../../../styles/ProductView.css";
import DynamicInputField from "../../../common/DynamicInputField";

const ProductView: React.FC = () => {
  const [productViewdata, setProductViewdata] = useState<ProductData[] | any>(
    null
  );
  const location = useLocation();
  const { product_id } = location.state || {};
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    getProductViewData();
  }, [product_id]);

  const getProductViewData = async () => {
    const response = await productViewData(product_id);
    setProductViewdata(response?.data);
  };

  if (!productViewdata) {
    return <CircularProgress />;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-md-6">
            <DynamicInputField
              label="Product Name"
              value={productViewdata.product_name || ""}
              readOnly
            />
          </div>
          <div className="col-md-6">
            <DynamicInputField
              label="Slug"
              value={productViewdata.slug || ""}
              readOnly
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <DynamicInputField
              label="Description"
              value={productViewdata.description || ""}
              readOnly
              isTextarea
            />
          </div>
          <div className="col-md-6">
            <DynamicInputField
              label="Specifications"
              value={productViewdata.specification || ""}
              readOnly
              isTextarea
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <DynamicInputField
              label="Brand"
              value={productViewdata.brand_name || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <DynamicInputField
              label="Category"
              value={productViewdata.category_name || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <DynamicInputField
              label="SKU"
              value={productViewdata.sku || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <DynamicInputField
              label="Quantity"
              value={productViewdata.quantity || ""}
              readOnly
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <DynamicInputField
              label="Unit"
              value={productViewdata.unit_id || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <DynamicInputField
              label="Minimum Stock Warning"
              value={productViewdata.minimum_stock_warning || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <DynamicInputField
              label="Weight"
              value={productViewdata.weight || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <DynamicInputField
              label="Price"
              value={productViewdata.original_price || ""}
              readOnly
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
            <DynamicInputField
              label="Returning Date"
              value={productViewdata.returning_date || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <DynamicInputField
              label="Discount"
              value={productViewdata.discount_amount || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <DynamicInputField
              label="Tax Name"
              value={productViewdata.tax_name || ""}
              readOnly
            />
          </div>
          <div className="col-md-3">
            <label className="input-label">Status</label>
            <span
              style={{
                color: productViewdata.is_active ? "green" : "red",
                backgroundColor: productViewdata.is_active
                  ? "#e0f7e9"
                  : "#f8d7da",
                padding: "5px 10px",
                borderRadius: "5px",
                display: "inline-block",
                marginLeft: "10px",
              }}
            >
              {productViewdata.is_active ? "Active" : "Inactive"}
            </span>
          </div>
        </div>
        <div className="image-uploader  d-flex justify-content-around mb-3">
          <div className="row">
            {productViewdata.thumnail_image && (
              <div className="col-md-6">
                <h6 style={{ color: "grey" }}>Thumbnail Image</h6>
                <img
                  src={`${AppUrl}${productViewdata.thumnail_image}`}
                  alt="Thumbnail"
                  className="image-preview"
                />
              </div>
            )}
            {productViewdata.brand_image && (
              <div className="col-md-6">
                <h5>Brand Image</h5>
                <img
                  src={`${AppUrl}${productViewdata.brand_image}`}
                  alt="Brand"
                  className="image-preview"
                />
              </div>
            )}
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default ProductView;
