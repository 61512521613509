import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getDiscountEdit, updatedDiscount } from "services/apiService";
import { CoupnEditProps } from "types";

const DiscountEdit: React.FC<CoupnEditProps> = ({
  open,
  id,
  handleClose,
  onActionComplete,
}) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const today = new Date().toISOString().split("T")[0];
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [DiscountEditForm, setDiscountEditForm] = useState({
    name: "",
    slug: "",
    percentage: "",
    from_date: "",
    to_date: "",
    status: "",
    is_active: 1,
    image_path: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    slug: "",
    percentage: "",
    from_date: "",
    to_date: "",
  });

  useEffect(() => {
    if (id) {
      fetchDiscount(id);
    }
  }, [open, id]);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };
    setDiscountEditForm((prevData) => ({
      ...prevData,
      [id]: id === "name" ? value.toUpperCase() : value,
      slug: id === "name" ? generateSlug(value) : prevData.slug,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const fetchDiscount = async (id) => {
    try {
      const response = await getDiscountEdit(id);
      if (response.status_code === 200) {
        setDiscountEditForm({
          name: response.data.name,
          slug: response.data.slug,
          percentage: response.data.percentage,
          from_date: response.data.from_date,
          to_date: response.data.to_date,
          status: response.data.status,
          is_active: response.data.is_active,
          image_path: response.data.image_path,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("failed to get the data");
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newErrors = {
      name: DiscountEditForm.name ? "" : "Discount name is required.",
      slug: DiscountEditForm.slug ? "" : "Slug is required.",
      percentage: DiscountEditForm.percentage ? "" : "Percentage is required.",
      from_date: DiscountEditForm.from_date ? "" : "From date is required.",
      to_date: DiscountEditForm.to_date ? "" : "To date is required.",
    };
    const hasErrors = Object.values(newErrors).some((error) => error !== "");

    if (hasErrors) {
      setErrors(newErrors);
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      const payload = {
        ...DiscountEditForm,
        from_date: DiscountEditForm.from_date.split("T")[0],
        to_date: DiscountEditForm.to_date.split("T")[0],
        id: id,
        image_path: DiscountEditForm.image_path.toString(),
        percentage: Number(DiscountEditForm.percentage),
      };
      const response = await updatedDiscount(payload);
      if (response.status_code === 200) {
        toast.success("Discount update successfully");
        handleClose();
        onActionComplete();
      }
    } catch (error) {
      toast.error("Discount update failed");
      console.log("error", error);
    }
  };

  const handleImageSelected = (image: string) => {
    setDiscountEditForm((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
    setErrors((prevErrors) => ({
      ...prevErrors,
      image_path: "",
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    setDiscountEditForm((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const handleDeleteImage = () => {
    setDiscountEditForm((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <Modal open={open} onClose={undefined} style={{ zIndex: 1299 }}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Edit Discount
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <div
                className="modal-body"
                style={{ display: "grid", gap: "20px" }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="name"
                    fullWidth
                    label="Discount Name"
                    value={DiscountEditForm.name}
                    onChange={handleTextFieldChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    inputProps={{ maxLength: 25 }}
                  />
                  <TextField
                    variant="outlined"
                    id="slug"
                    fullWidth
                    label="slug"
                    value={DiscountEditForm.slug}
                    onChange={handleTextFieldChange}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="percentage"
                    label="Percentage"
                    fullWidth
                    type="number"
                    value={DiscountEditForm.percentage}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => {
                      const value = e.target.value;
                      if (value.length <= 4 && Number(value) <= 100) {
                        handleTextFieldChange(
                          e as React.ChangeEvent<HTMLInputElement>
                        );
                      }
                    }}
                    error={!!errors.percentage}
                    helperText={errors.percentage}
                  />
                  <TextField
                    variant="outlined"
                    id="status"
                    fullWidth
                    label="Discount status"
                    value={DiscountEditForm.status}
                    disabled
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <div>
                    <label htmlFor="from_date">From Date</label>
                    <input
                      id="from_date"
                      onChange={handleTextFieldChange}
                      type="date"
                      className="form-control"
                      value={
                        DiscountEditForm.from_date
                          ? DiscountEditForm.from_date.slice(0, 10)
                          : ""
                      }
                      style={{ width: "100%", padding: "8px" }}
                      min={today}
                    />
                  </div>
                  <div>
                    <label htmlFor="to_date">To date</label>
                    <input
                      id="to_date"
                      onChange={handleTextFieldChange}
                      type="date"
                      className="form-control"
                      value={
                        DiscountEditForm.to_date
                          ? DiscountEditForm.to_date.slice(0, 10)
                          : ""
                      }
                      style={{ width: "100%", padding: "8px" }}
                      min={today}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div
                    style={{
                      width: "100%",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "20px",
                        border: "1px dashed #ccc",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "10px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {DiscountEditForm.image_path ? (
                        <>
                          <img
                            src={`${AppUrl}${DiscountEditForm.image_path}`}
                            alt="Selected"
                            style={{
                              width: "150px",
                              transition: "transform 0.2s",
                              cursor: "pointer",
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.transform = "scale(1.05)";
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.transform = "scale(1)";
                            }}
                          />
                          <button
                            style={{
                              backgroundColor: "rgba(255, 0, 0, 0.7)",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              fontSize: "16px",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                              transition: "background-color 0.2s",
                            }}
                            onClick={handleDeleteImage}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </>
                      ) : (
                        <p style={{ color: "orange" }}>
                          <FontAwesomeIcon icon={faCircleExclamation} />
                          &nbsp; &nbsp; No image selected. Please upload an
                          image.
                        </p>
                      )}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={handleOpenModal}
                        style={{
                          padding: "5px 20px",
                          fontSize: "13px",
                          borderRadius: "0px",
                          border: "none",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          cursor: "pointer",
                          marginTop: "10px",
                          transition: "background-color 0.2s",
                        }}
                      >
                        <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                        Thumbnail Image
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <Toggle
                    checked={DiscountEditForm.is_active === 1}
                    onChange={(checked) =>
                      handleToggleChange(checked, "is_active")
                    }
                  />
                </div>
              </div>
              <br />
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </form>
          </Typography>
        </Box>
      </Modal>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default DiscountEdit;
