import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import toast from "react-hot-toast";
import { OrderVeiwApi, UpdateOrder } from "services/apiService";

interface OrderEditProps {
  open: boolean;
  onClose: () => void;
  id: number | any;
  onActionComplete: () => void;
}

const OrderEdit: React.FC<OrderEditProps> = ({
  open,
  onClose,
  id,
  onActionComplete,
}) => {
  const [orderDetails, setOrderDetails] = useState<any>({});
  const [paymentStatus, setPaymentStatus] = useState<string | undefined>("");
  const [orderStatus, setOrderStatus] = useState<string | undefined>("");

  useEffect(() => {
    if (id) {
      fetchOrder(id);
    }
  }, [id]);

  const fetchOrder = async (id: number) => {
    try {
      const response = await OrderVeiwApi(id);
      console.log("Order details:", response);
      if (response.status_code === 200) {
        setOrderDetails(response.data);
        setPaymentStatus(response.data.payment_status);
        setOrderStatus(response.data.order_status);
      } else {
        console.error("Failed to fetch order details");
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const payload = {
        id: id,
        order_code: orderDetails.order_code,
        order_status: orderStatus,
        payment_status: paymentStatus,
      };
      const response = await UpdateOrder(payload);
      if (response.status_code === 200) {
        toast.success("Order updated successfully!");
        onClose();
        onActionComplete();
      } else {
        toast.error("Order update failed. Please try again.");
      }
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("Order update failed. Please try again.");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="order-edit-modal"
      aria-describedby="edit-order-details"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          id="order-edit-modal"
          variant="h6"
          component="h2"
          gutterBottom
        >
          Edit Order: {orderDetails.order_code || "Loading..."}{" "}
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
            <InputLabel id="payment-status-label">
              Update Payment Status
            </InputLabel>
            <Select
              labelId="payment-status-label"
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
            >
              <MenuItem value="" disabled>
                Select payment status
              </MenuItem>
              <MenuItem value="pending">pending</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="unpaid">Unpaid</MenuItem>
              <MenuItem value="refund">Refund</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="order-status-label">Update Order Status</InputLabel>
            <Select
              labelId="order-status-label"
              value={orderStatus}
              onChange={(e) => setOrderStatus(e.target.value)}
            >
              <MenuItem value="" disabled>
                Select order status
              </MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="shipping">Shipping</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="return_request">Return Request</MenuItem>
              <MenuItem value="returned">Returned</MenuItem>
            </Select>
          </FormControl>

          {/* Actions */}
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
          >
            <Button variant="outlined" color="error" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default OrderEdit;
