import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CreateCoupon } from "services/apiService";

const CouponCreate: React.FC = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [CouponCreate, setCouponCreate] = useState({
    name: "",
    coupon_code: "",
    description: "",
    expire_date: "",
    percentage: "",
    max_time_use: "",
    min_purchase_amount: "",
    is_active: 1,
    slug: "",
    image_path: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    coupon_code: "",
    description: "",
    expire_date: "",
    percentage: "",
    max_time_use: "",
    min_purchase_amount: "",
  });

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };
    const filterToNumbersAndDecimal = (text: string) => {
      return text.replace(/[^0-9.]/g, "");
    };

    setCouponCreate((prevData) => ({
      ...prevData,
      [id]:
        id === "coupon_code"
          ? value.toUpperCase()
          : id === "percentage" || id === "max_time_use"
          ? filterToNumbersAndDecimal(value)
          : value,
      slug: id === "name" ? generateSlug(value) : prevData.slug,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    setCouponCreate((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const handleImageSelected = (image: string) => {
    setCouponCreate((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };
  const validateForm = () => {
    const newErrors: any = {};
    if (!CouponCreate.name) newErrors.name = "Coupon name is required.";
    if (!CouponCreate.coupon_code)
      newErrors.coupon_code = "Coupon code is required.";
    if (!CouponCreate.description)
      newErrors.description = "Description is required.";
    if (!CouponCreate.expire_date)
      newErrors.expire_date = "Expiry date is required.";
    if (!CouponCreate.percentage || isNaN(Number(CouponCreate.percentage))) {
      newErrors.percentage = "Please enter a valid percentage.";
    }
    if (
      !CouponCreate.max_time_use ||
      isNaN(Number(CouponCreate.max_time_use))
    ) {
      newErrors.max_time_use = "Please enter a valid maximum time use.";
    }
    if (
      !CouponCreate.min_purchase_amount ||
      isNaN(Number(CouponCreate.min_purchase_amount))
    ) {
      newErrors.min_purchase_amount =
        "Please enter a valid minimum purchase amount.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const payload = {
        name: CouponCreate.name,
        coupon_code: CouponCreate.coupon_code,
        description: CouponCreate.description,
        expire_date: CouponCreate.expire_date,
        percentage: Number(CouponCreate.percentage),
        max_time_use: Number(CouponCreate.max_time_use),
        min_purchase_amount: Number(CouponCreate.min_purchase_amount),
        is_active: CouponCreate.is_active,
        slug: CouponCreate.slug,
        image_path: CouponCreate.image_path.toString(),
      };
      try {
        const CouponCreate = await CreateCoupon(payload);
        if (CouponCreate.status === "success") {
          toast.success("successfully submitted the form");
          setCouponCreate({
            name: "",
            coupon_code: "",
            description: "",
            expire_date: "",
            percentage: "",
            max_time_use: "",
            min_purchase_amount: "",
            is_active: 1,
            slug: "",
            image_path: "",
          });
        }
        navigate("/admin/coupon-list");
      } catch (error) {
        toast.error("Form Creation Failed");
      }
    } else {
      toast.error("Form has validation errors");
    }
  };

  const handleDeleteImage = () => {
    setCouponCreate((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Coupon Name
              </Typography>
              <TextField
                variant="filled"
                id="name"
                fullWidth
                value={CouponCreate.name}
                onChange={handleTextFieldChange}
                error={!!errors.name}
                helperText={errors.name || "Max lenght is 50."}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Coupon Code
              </Typography>
              <TextField
                variant="filled"
                id="coupon_code"
                fullWidth
                value={CouponCreate.coupon_code}
                onChange={handleTextFieldChange}
                error={!!errors.coupon_code}
                helperText={
                  errors.coupon_code || "Please enter the Coupon Code."
                }
                inputProps={{
                  maxLength: 15,
                }}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Description
              </Typography>
              <TextField
                variant="filled"
                id="description"
                fullWidth
                multiline
                rows={4}
                value={CouponCreate.description}
                onChange={handleTextFieldChange}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Coupon Percentage
              </Typography>
              <TextField
                variant="filled"
                id="percentage"
                fullWidth
                type="number"
                value={CouponCreate.percentage}
                onChange={handleTextFieldChange}
                error={!!errors.percentage}
                helperText={
                  errors.percentage || "Please enter Coupon percentage."
                }
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Expiry Date
              </Typography>
              <input
                id="expire_date"
                type="date"
                value={CouponCreate.expire_date}
                onChange={handleTextFieldChange}
                className="form-control"
                min={today}
                style={{ width: "100%", padding: "8px" }}
              />
              {errors.expire_date && (
                <Typography color="error">{errors.expire_date}</Typography>
              )}
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Max Time Use
              </Typography>
              <TextField
                variant="filled"
                id="max_time_use"
                fullWidth
                type="number"
                value={CouponCreate.max_time_use}
                onChange={handleTextFieldChange}
                error={!!errors.max_time_use}
                helperText={errors.max_time_use}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Minimum Purchase Amount
              </Typography>
              <TextField
                variant="filled"
                id="min_purchase_amount"
                fullWidth
                type="number"
                value={CouponCreate.min_purchase_amount}
                onChange={handleTextFieldChange}
                error={!!errors.min_purchase_amount}
                helperText={errors.min_purchase_amount}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Coupon Image
              </Typography>
              <div
                style={{
                  width: "100%",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    border: "1px dashed #ccc",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "10px",
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {CouponCreate.image_path ? (
                    <>
                      <img
                        src={`${AppUrl}${CouponCreate.image_path}`}
                        alt="Selected"
                        style={{
                          width: "300px",
                          transition: "transform 0.2s",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      />
                      <button
                        style={{
                          backgroundColor: "rgba(255, 0, 0, 0.7)",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          fontSize: "16px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                          transition: "background-color 0.2s",
                        }}
                        onClick={handleDeleteImage}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </>
                  ) : (
                    <p style={{ color: "orange" }}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      &nbsp; &nbsp; No image selected. Please upload an image.
                    </p>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={handleOpenModal}
                    style={{
                      padding: "5px 20px",
                      fontSize: "13px",
                      borderRadius: "0px",
                      border: "none",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      cursor: "pointer",
                      marginTop: "10px",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                    Thumbnail Image
                  </Button>
                </div>
              </div>
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Status
              </Typography>
              <Toggle
                checked={CouponCreate.is_active === 1}
                onChange={(checked) => handleToggleChange(checked, "is_active")}
              />
            </Box>
            <Box textAlign="end" mt={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp; Create Coupon
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default CouponCreate;
