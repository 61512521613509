import React, { useEffect, useState } from "react";
import { DeleteBlogsTags, getAllBlogTages } from "services/apiService";
import DynamicButton from "../../../common/DynamicButton.jsx";
import {
  faCirclePlus,
  faMagnifyingGlass,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import loadingImage from "../../../assect/loadingSvg/loading.svg";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import BlogTagsEdit from "./BlogTagsEdit";

const BlogTags = () => {
  const [blogsTagesData, setBlogsTagesData] = useState([]);
  const [blogsTagesSearch, setBlogsTagesSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [isOpenSubPageEdit, setIsOpenSubpagesEdit] = useState<boolean>(false);
  const [selectedSubPagesId, setSelectedSubpagesId] = useState<number | null>(
    null
  );
  const headers = ["S.no", "Blog Tag Name", "Status", "Action"];

  useEffect(() => {
    getAllBlogsTagesData();
  }, []);

  const getAllBlogsTagesData = async () => {
    setLoader(true);
    try {
      const response = await getAllBlogTages();
      setBlogsTagesData(response.data);
    } catch (error) {
      console.error("Error fetching blog tags:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setBlogsTagesSearch(value);
  };

  const filteredTags = blogsTagesData?.filter((tag) =>
    tag.name.toLowerCase().includes(blogsTagesSearch.toLowerCase())
  );

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteBlogsTags(id);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The Blogs Tag has been deleted.", "success");
            getAllBlogsTagesData();
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Blogs Tag is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const handleModelOpen = (id: number) => {
    setSelectedSubpagesId(id);
    setIsOpenSubpagesEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenSubpagesEdit(false);
  };

  const renderRow = (blog, index) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td title={blog.name}>
          {blog.name?.length > 30 ? blog.name?.slice(0, 30) + "..." : blog.name}
        </td>
        <td
          className={`fw-semibold ${
            blog.is_active === 1 ? "text-success" : "text-danger"
          }`}
        >
          {blog.is_active === 1 ? "Active" : "Inactive"}
        </td>
        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              onClick={() => handleModelOpen(blog.id)}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
            />

            <DynamicButton
              icon={faTrash}
              iconStyle={{ color: "red" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleDelete(blog.id, blog.name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/blog-tags-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Blog Tags
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            value={blogsTagesSearch}
            placeholder="Search Blog Tags Name......"
            style={{ boxShadow: "none" }}
            onChange={handleChange}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
            </span>
          </div>
        </div>
      </div>
      <div className="product-table-container">
        <div className="product-table-content">
          {loader ? (
            <div className="loadingsvg-Image text-center">
              <img
                src={loadingImage}
                width={150}
                height={150}
                alt="Loading..."
              />
            </div>
          ) : (
            <CommonTable
              data={filteredTags}
              isLoading={loader}
              headers={headers}
              renderRow={renderRow}
            />
          )}
        </div>
      </div>

      <BlogTagsEdit
        onActionComplete={() => {
          getAllBlogsTagesData();
        }}
        open={isOpenSubPageEdit}
        handleClose={handleModelClose}
        id={selectedSubPagesId}
      />
    </>
  );
};

export default BlogTags;
