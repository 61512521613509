import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  getClassificationEdit,
  updatedClassification,
} from "services/apiService";
import { CoupnEditProps } from "types";

export const ClassificationEdit: React.FC<CoupnEditProps> = ({
  open,
  id,
  handleClose,
  onActionComplete,
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [EditclassificationCreate, setEditClassificationCreate] = useState({
    classification_name: "",
    slug: "",
    image_path: "",
    is_active: 1,
  });
  const [errors, setErrors] = useState({
    classification_name: "",
    slug: "",
    image_path: "",
  });

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };
    setEditClassificationCreate((prevData) => ({
      ...prevData,
      [id]: value,
      slug: id === "classification_name" ? generateSlug(value) : prevData.slug,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };

  const handleImageSelected = (image: string) => {
    setEditClassificationCreate((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };

  const handleToggleChange = (checked: number, field: string) => {
    setEditClassificationCreate((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const validateForm = () => {
    let formErrors: any = {};
    if (!EditclassificationCreate.classification_name.trim()) {
      formErrors.classification_name = "Classification name is required.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [open]);

  const fetchData = async (id: number) => {
    try {
      const response = await getClassificationEdit(id);
      if (response.status_code === 200) {
        setEditClassificationCreate({
          classification_name: response.data.classification_name,
          slug: response.data.slug,
          image_path: response.data.image_path,
          is_active: response.data.is_active,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          ...EditclassificationCreate,
          image_path: EditclassificationCreate.image_path.toString(),
          classification_id: id,
        };
        const response = await updatedClassification(payload);
        if (response.status_code === 200) {
          toast.success("Classification updated successfully!");
          handleClose();
          onActionComplete();
        }
      } catch (error) {
        toast.error("Classification update failed. Please try again.");
      }
    }
  };

  const handleDeleteImage = () => {
    setEditClassificationCreate((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <>
        <Modal open={open} onClose={undefined} style={{ zIndex: "1299" }}>
          <Box sx={style}>
            <Typography
              variant="h6"
              className="text-center mb-4"
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                backgroundColor: "#007bff",
                padding: "5px",
                color: "white",
              }}
            >
              Edit Classification
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <form onSubmit={handleSubmit}>
                <div
                  className="modal-body"
                  style={{ display: "grid", gap: "20px" }}
                >
                  <div
                    style={{
                      display: "grid",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      id="classification_name"
                      fullWidth
                      label="Classification Name"
                      value={EditclassificationCreate.classification_name}
                      onChange={handleTextFieldChange}
                      error={!!errors.classification_name}
                      helperText={"Maximum length is 50 characters. "}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      variant="outlined"
                      id="slug"
                      fullWidth
                      label="slug"
                      value={EditclassificationCreate.slug}
                      onChange={handleTextFieldChange}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        padding: "20px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: "20px",
                          border: "1px dashed #ccc",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "10px",
                          backgroundColor: "#f9f9f9",
                        }}
                      >
                        {EditclassificationCreate.image_path ? (
                          <>
                            <img
                              src={`${AppUrl}${EditclassificationCreate.image_path}`}
                              alt="Selected"
                              style={{
                                width: "150px",
                                transition: "transform 0.2s",
                                cursor: "pointer",
                              }}
                              onMouseOver={(e) => {
                                e.currentTarget.style.transform = "scale(1.05)";
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.transform = "scale(1)";
                              }}
                            />
                            <button
                              style={{
                                backgroundColor: "rgba(255, 0, 0, 0.7)",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                fontSize: "16px",
                                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                                transition: "background-color 0.2s",
                              }}
                              onClick={handleDeleteImage}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </>
                        ) : (
                          <p style={{ color: "orange" }}>
                            <FontAwesomeIcon icon={faCircleExclamation} />
                            &nbsp; &nbsp; No image selected. Please upload an
                            image.
                          </p>
                        )}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          onClick={handleOpenModal}
                          variant="outlined"
                          color="secondary"
                        >
                          <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                          Thumbnail Image
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div style={{ gridTemplateColumns: "1fr auto" }}>
                    <label>Is active</label>
                    <Toggle
                      checked={EditclassificationCreate.is_active === 1}
                      onChange={(checked) =>
                        handleToggleChange(checked, "is_active")
                      }
                    />
                  </div>
                </div>
                <br />
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </form>
            </Typography>
          </Box>
        </Modal>
        <ImageUploaderModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onImageSelect={handleImageSelected}
          allowMultiple={false}
        />
      </>
    </>
  );
};
