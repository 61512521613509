import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { editSection } from "services/apiService";
import ImageGallery from "../Upload/ImageGallery";
import VideoGallery from "../Upload/VideoGallery";
import "../../../../styles/ModalStyles.css";
import { validationByEdit } from "validation/SectionEditValidations";
import "../../../../styles/SectionEdit.css";
import "./jsonedit.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faFloppyDisk,
  faImage,
  faPencil,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import sectionCreateData from "../sectionCreateData/sectionCreateData.json";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  toggleImageSelection,
  toggleVideoSelection,
  clearSelections,
} from "../../../../redux/Slice/mediaSlice.js";
import ImageDisplay from "../Upload/ImageDisplay";
import VideoDisplay from "../Upload/VideoDisplay";

const JsonSectionEdit = ({
  isOpen,
  onRequestClose,
  sectionName,
  sectionId,
  sectionGroupId,
}) => {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState([]);
  const [formData, setFormData] = useState(null);
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const [isVideoGalleryOpen, setIsVideoGalleryOpen] = useState(false);
  const [mapId, setMapId] = useState([]);
  const [getId, setGetId] = useState();
  const [isMulti, setIsMulti] = useState(false);
  const [errors, setErrors] = useState({});
  const pageid = localStorage.getItem("pageid");
  const [currentEditingField, setCurrentEditingField] = useState(null);

  const sectionDatasss = useSelector(
    (state: any) => state?.jsondata?.sectionCreateData
  );
  const adsDropdown = useSelector((state: any) =>
    state.adsDropDownData.adsDropDownDatas?.map((option) => ({
      value: option.id,
      label: option.name?.toUpperCase(),
    }))
  );
  const sectionDropdownData = useSelector((state: any) =>
    state.classificationData.classificationDropDownData?.map((option) => ({
      value: option?.classification_id,
      label: option?.classification_name?.toUpperCase(),
    }))
  );
  const campaignData = useSelector((state: any) =>
    state.campaignData.campaignDropDownData?.map((option) => ({
      value: option?.campaign_id,
      label: option?.campaign_name?.toUpperCase(),
    }))
  );
  const fetchGetSection = useSelector(
    (state: any) => state.getSectionData.getSectionDataList
  );
  const multiImagesSelect = useSelector(
    (state: any) => state.media.selectedImagesByKey
  );
  const singleImage = useSelector(
    (state: any) => state.media.singleImageSelections
  );
  const multiVideo = useSelector(
    (state: any) => state.media.selectedVideosByKey
  );
  const singleVideo = useSelector(
    (state: any) => state.media.singleVideoSelections
  );
  useEffect(() => {

    if (multiImagesSelect) {
      Object.keys(multiImagesSelect).forEach((key) => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: multiImagesSelect[key],
        }));
      });
    }
    if (singleImage) {
      Object.keys(singleImage).forEach((key) => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: singleImage[key],
        }));
      });
    }
    if (multiVideo) {
      Object.keys(multiVideo).forEach((key) => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: multiVideo[key],
        }));
      });
    }
    if (singleVideo) {
      Object.keys(singleVideo).forEach((key) => {
        setFormData((prevData) => ({
          ...prevData,
          [key]: singleVideo[key],
        }));
      });
    }
  }, [multiImagesSelect, singleImage, multiVideo, singleVideo]);
  useEffect(() => {
    if (fetchGetSection?.sections_data?.length > 0) {
      getSectionDatas();
    }
  }, [sectionGroupId, fetchGetSection?.sections_data]);
  
useEffect(() => {

  const parsedSectionId = sectionId.split(/section|_/)?.[1];
  const foundSection = sectionCreateData?.find((section: any) => {
    return (
      section.section_name === sectionName &&
      section.page_id === parseInt(pageid) &&
      section.section_id === parseInt(parsedSectionId)
    );
  });

  if (foundSection) {
    setSectionData(foundSection.data);
  } else {
    setSectionData(null);
  }
}, [sectionName, pageid, sectionId, sectionCreateData]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      section_id: sectionId,
    }));
  }, [sectionId]);

  const getSectionDatas = () => {
    try {
      const sectionValues = {};
      if (!fetchGetSection?.sections_data) {
        console.error("No section data available.");
        return;
      }
      fetchGetSection.sections_data.forEach((item) => {
        const { key_name, key_value } = item;
        const matchedSection = sectionData.find(
          (section) => section.key_name === key_name
        );
        if (matchedSection) {
          if (matchedSection.type === "drop_down") {
            sectionValues[key_name] = key_value
              .split(",")
              .map((value) => parseInt(value.trim(), 10));
          } else if (key_name === "toggle_input") {
            sectionValues[key_name] = key_value
              .split(",")
              .map((value) => value.trim() === "1");
          } else if (matchedSection.type === "FILE") {
            const files = key_value.split(",").filter((value) => value.trim());
            const videoExtensions = [
              ".mp4",
              ".avi",
              ".mkv",
              ".mov",
              ".flv",
              ".wmv",
            ];
            const media = files.map((file) => {
              const is_video = videoExtensions.some((ext) =>
                file.toLowerCase().endsWith(ext)
              );
              return { mediaUrl: file, type: is_video ? "video" : "image" };
            });
            media.forEach((file) => {
              if (file.type === "video") {
                dispatch(
                  toggleVideoSelection({
                    keyName: key_name,
                    mediaUrl: file.mediaUrl,
                    isMulti:
                      matchedSection.is_video === 1 &&
                      matchedSection.is_multi_select === null
                        ? true
                        : false,
                  })
                );
              } else {
                dispatch(
                  toggleImageSelection({
                    keyName: key_name,
                    mediaUrl: file.mediaUrl,
                    isMulti:
                      matchedSection.is_multi_select === 1 &&
                      matchedSection.is_video === null
                        ? true
                        : false,
                  })
                );
              }
            });

            sectionValues[key_name] = media;
          } else {
            sectionValues[key_name] = key_value;
          }
        }
      });
      setFormData((prevData) => ({
        ...prevData,
        ...sectionValues,
      }));
      setMapId(fetchGetSection.sections_data);
    } catch (error) {
      console.error("Error processing sections data:", error);
    }
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOption) => {
    let newValue;
    if (Array.isArray(selectedOption)) {
      newValue = selectedOption?.map((option) => option.value);
    } else if (selectedOption) {
      newValue = selectedOption.value;
    } else {
      newValue = "";
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const resetState = () => {
    setFormData({});
  };
  const getSectionMapId = (key) => {
    let obj = mapId?.find((item) => {
      if (item?.key_name === key) return item;
    });
    return obj ? obj?.section_map_id : -1;
  };
  const handleEditToggle = async (e, fieldKey) => {
    if (currentEditingField !== fieldKey) {
      setCurrentEditingField(fieldKey);
      return;
    }
    const sectionKeyName = sectionData.find(
      (item) => item.key_name === fieldKey
    );
    const fieldValue = formData?.[fieldKey] || [];
    const payload = {
      section_group: sectionGroupId,
      section_map_id: getSectionMapId(fieldKey),
      key_value:
        fieldKey === "text_input" || "desc_input"
          ? fieldValue.toString()
          : fieldValue.join(","),
    };
    const errss = validationByEdit(sectionKeyName, payload.key_value);
    setErrors(errss);
    if (Object.keys(errss).length <= 0) {
      const response = await editSection(payload);
      if (response.status_code === 200) {
        onRequestClose();
        dispatch(clearSelections());
        setCurrentEditingField(null);
        toast.success(`${fieldKey} is updated successfully`);
      }
    }
  };
  const handleCancel = () => {
    setCurrentEditingField(null);
  };

  const renderField = (field) => {
    const Multi = field.is_multi_select === 1;
    const isEditing = currentEditingField === field.key_name;
    switch (field.type) {
      case "drop_down":
        return (
          <div
            key={field.key_name}
            className="field-container d-flex justify-content-between align-items-center w-100"
          >
            <div className="select-container" style={{ width: "80%" }}>
              <Select
                name={field.key_name}
                options={
                  field.key_name === "ADS"
                    ? adsDropdown
                    : field.key_name === "CAMP"
                    ? campaignData
                    : sectionDropdownData
                }
                value={
                  field?.key_name === "ADS"
                    ? adsDropdown?.filter((option) =>
                        Array.isArray(formData?.ADS)
                          ? formData?.ADS?.includes(option.value)
                          : option.value === formData?.ADS
                      )
                    : field?.key_name === "CAMP"
                    ? campaignData?.filter((option) =>
                        Array.isArray(formData?.CAMP)
                          ? formData.CAMP.includes(option.value)
                          : option.value === formData?.CAMP
                      )
                    : sectionDropdownData?.filter((option) =>
                        Array.isArray(formData?.CLASSIFI)
                          ? formData?.CLASSIFI?.includes(option.value)
                          : option.value === formData?.CLASSIFI
                      )
                }
                onChange={(selectedOption) => {
                  handleSelectChange(field.key_name, selectedOption);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    [field.key_name]: "",
                  }));
                }}
                isMulti={Multi}
                className="w-100"
                isDisabled={!isEditing}
              />
            </div>

            <div className="button-container d-flex justify-content-start gap-2">
              {isEditing ? (
                <>
                  <button
                    type="button"
                    className="btn-custom btn-save px-0 px-2"
                    onClick={(e) => handleEditToggle(e, field.key_name)}
                  >
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      style={{ marginRight: "5px" }}
                    />
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-custom btn-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn-custom btn-edit"
                  onClick={() => setCurrentEditingField(field.key_name)}
                >
                  <FontAwesomeIcon icon={faPencil}  style={{ marginRight: "5px" }} />
                  Edit
                </button>
              )}
            </div>
          </div>
        );
      case "text_input":
        return (
          <div
            key={field.key_name}
            className="field-container d-flex justify-content-between align-items-center w-100"
          >
            <div className="select-container" style={{ width: "80%" }}>
              <input
                name={field.key_name}
                type="text"
                value={formData?.[field.key_name] || ""}
                onChange={handleUpdateChange}
                onBlur={handleBlur}
                className="form-control"
                placeholder={`Enter ${field.key_name} here`}
                disabled={!isEditing}
              />
            </div>
            <div className="button-container d-flex justify-content-start gap-2">
              {isEditing ? (
                <>
                  <button
                    type="button"
                    className="btn-custom btn-save  px-0 px-2"
                    onClick={(e) => handleEditToggle(e, field.key_name)}
                  >
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      style={{ marginRight: "5px" }}
                    />
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-custom btn-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn-custom btn-edit"
                  onClick={() => setCurrentEditingField(field.key_name)}
                >
                  <FontAwesomeIcon icon={faPencil}  style={{ marginRight: "5px" }} />
                  Edit
                </button>
              )}
            </div>
          </div>
        );
      case "FILE":
        return (
          <div key={field?.key_name} className="field-containers ">
            {(field.is_video === 1 || field.is_video === 0) &&
            field.is_multi_select === null &&
            isEditing ? (
              <button
                className=" cls btn--video"
                onClick={() => {
                  setIsVideoGalleryOpen(true);
                  setGetId(field.key_name);
                  setIsMulti(field.is_video === 1 ? true : false);
                }}
              >
                <span className="btn-icon">
                  <FontAwesomeIcon icon={faVideo} />
                </span>
                Edit Video
              </button>
            ) : (field.is_multi_select === 1 || field.is_multi_select === 0) &&
              field.is_video === null &&
              isEditing ? (
              <button
                className=" cls btn--image"
                onClick={() => {
                  setIsImageGalleryOpen(true);
                  setGetId(field.key_name);
                  setIsMulti(field.is_multi_select === 1 ? true : false);
                }}
              >
                <span className="btn-icon">
                  <FontAwesomeIcon icon={faImage}  style={{ marginRight: "5px" }} />
                </span>
                Edit Image
              </button>
            ) : null}

            <ImageGallery
              isOpen={isImageGalleryOpen}
              onRequestClose={() => setIsImageGalleryOpen(false)}
              isMulti={isMulti}
              keyName={getId}
              handleBlur={handleBlur}
            />
            <VideoGallery
              isOpen={isVideoGalleryOpen}
              onRequestClose={() => setIsVideoGalleryOpen(false)}
              keyName={getId}
              isMulti={isMulti}
              handleBlur={handleBlur}
            />

            {Object.keys(singleImage).includes(field.key_name) && (
              <>
                <ImageDisplay
                  field={field.key_name}
                  imagesData={singleImage}
                  single={true}
                  isEditing={isEditing}
                />
              </>
            )}
            {Object.keys(multiImagesSelect).includes(field.key_name) && (
              <>
                <ImageDisplay
                  field={field.key_name}
                  imagesData={multiImagesSelect}
                  single={false}
                  isEditing={isEditing}
                />
              </>
            )}

            {Object.keys(multiVideo).includes(field.key_name) && (
              <>
                <VideoDisplay
                  field={field.key_name}
                  videoUrl={multiVideo}
                  single={false}
                  isEditing={isEditing}
                />
              </>
            )}

            {Object.keys(singleVideo).includes(field.key_name) && (
              <>
                <VideoDisplay
                  field={field.key_name}
                  videoUrl={singleVideo}
                  single={true}
                  isEditing={isEditing}
                />
              </>
            )}

            <div className="button-container d-flex justify-content-end gap-2">
              {isEditing ? (
                <>
                  <button
                    type="button"
                    className="btn-custom btn-save  px-0 px-2"
                    onClick={(e) => handleEditToggle(e, field.key_name)}
                  >
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      style={{ marginRight: "5px" }}
                    />
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-custom btn-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn-custom btn-edit"
                  onClick={() => setCurrentEditingField(field.key_name)}
                >
                  <FontAwesomeIcon icon={faPencil}  style={{ marginRight: "5px" }} />
                  Edit
                </button>
              )}
            </div>
          </div>
        );
      case "toggle_input":
        return (
          <div
            className="d-flex align-items-center  p-0"
            title={`${field.key_name} is not Editable `}
          >
            <label className="toggle-switch">
              <input type="checkbox" checked={true} name={field.key_name} />
              <span className="toggle-slider"></span>
            </label>
            <span className="ms-3" title={`${field.key_name} is not Editable `}>
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </div>
        );
      case "desc_input":
        return (
          <div
            key={field.key_name}
            className="field-container d-flex justify-content-between align-items-center w-100"
          >
            <div className="select-container" style={{ width: "80%" }}>
              <textarea
                name={field?.key_name}
                onChange={handleUpdateChange}
                value={formData?.[field.key_name] || ""}
                onBlur={() => handleBlur}
                className="form-control"
                placeholder={`Enter ${field.key_name.toLowerCase()} here`}
                disabled={!isEditing}
              />
            </div>
            <div className="button-container d-flex justify-content-start gap-2">
              {isEditing ? (
                <>
                  <button
                    type="button"
                    className="btn-custom btn-save  px-0 px-2"
                    onClick={(e) => handleEditToggle(e, field.key_name)}
                  >
                    <FontAwesomeIcon
                      icon={faFloppyDisk}
                      style={{ marginRight: "5px" }}
                    />
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn-custom btn-cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  className="btn-custom btn-edit"
                  onClick={() => setCurrentEditingField(field.key_name)}
                >
                  <FontAwesomeIcon icon={faPencil}  style={{ marginRight: "5px" }} />
                  Edit
                </button>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit Section"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1",
        },
        content: {
          width: "90%",
          maxWidth: "1020px",
          height: "auto",
          maxHeight: "80vh",
          overflow: "auto",
          borderRadius: "10px",
          padding: "20px",
          position: "relative",
          margin: "auto",
          backgroundColor: "#f8f9fa",
          scrollbarWidth: "none",
        },
      }}
    >
      <div className="modal-header-section d-flex justify-content-between align-items-center mb-3">
        <h2 style={{ fontSize: "24px", color: "#ffffff", margin: 0 }}>
          Edit Section
        </h2>
        <button
          onClick={() => {
            dispatch(clearSelections());
            resetState();
            onRequestClose();
            setErrors({});
            handleCancel()
          }}
          style={{
            backgroundColor: "#1467a5",
            border: "none",
            color: "white",
            padding: "5px 10px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "20px",
          }}
        >
          X
        </button>
      </div>
      <>
        {sectionData ? (
          <div className="form-container">
            {sectionData?.map((field) => (
              <div
                key={field?.key_name}
                className="form-group"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <label
                  htmlFor={field.key_name}
                  style={{ fontSize: "16px", color: "#555" }}
                  className="mt-3"
                >
                  {field?.key_name}
                </label>
                {renderField(field)}
                {errors[field.key_name] && (
                  <div
                    className="error-message"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    {errors[field.key_name]}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p style={{ color: "#999" }}>
            No section data found for "{sectionName}".
          </p>
        )}
      </>
    </Modal>
  );
};

export default JsonSectionEdit;
