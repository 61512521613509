import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getFaqCategoriesEdit, UpadateFaqCatogries } from "services/apiService";
import { CoupnEditProps } from "types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

const FaqCategoriesEdit: React.FC<CoupnEditProps> = ({
  open,
  id,
  handleClose,
  onActionComplete,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    is_active: 0,
  });

  const [errors, setErrors] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (id) {
      fetchFaqCategory(id);
    }
  }, [id]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", description: "" };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    } else if (formData.name.length > 30) {
      newErrors.name = "Name cannot exceed 30 characters.";
      isValid = false;
    }

    if (!formData.description.trim()) {
      newErrors.description = "Description is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setFormData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const fetchFaqCategory = async (id: number) => {
    try {
      const response = await getFaqCategoriesEdit(id);
      if (response.status_code === 200) {
        setFormData({
          name: response.data.name,
          description: response.data.description,
          is_active: response.data.is_active,
        });
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const payload = {
        id: id,
        name: formData.name,
        description: formData.description,
        is_active: formData.is_active,
      };
      const response = await UpadateFaqCatogries(payload);
      if (response.status_code === 200) {
        toast.success("Faq Updated Succeefully");
        handleClose();
        onActionComplete();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ padding: 4 }}>
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={3}>
              <Box className="create_category_fields" mb={2}>
                <Typography
                  variant="subtitle1"
                  className="text-capitalize label"
                >
                  Name
                </Typography>
                <TextField
                  variant="filled"
                  id="name"
                  fullWidth
                  placeholder="Type here"
                  value={formData.name}
                  onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value
                      .replace(/[^a-zA-Z\s]/g, "")
                      .slice(0, 30);
                    setFormData((prevData) => ({
                      ...prevData,
                      name: filteredValue,
                    }));
                  }}
                  error={!!errors.name}
                  helperText={
                    errors.name ||
                    "Only letters and spaces are allowed, max 30 characters"
                  }
                />
              </Box>

              {/* Description Field */}
              <Box className="create_category_fields" mb={2}>
                <Typography
                  variant="subtitle1"
                  className="text-capitalize label"
                >
                  Description
                </Typography>
                <TextField
                  variant="filled"
                  id="description"
                  fullWidth
                  multiline
                  minRows={2}
                  placeholder="Type here"
                  value={formData.description}
                  onChange={handleInputChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </Box>

              {/* Status Toggle */}
              <Box className="create_category_fields" mb={2}>
                <Typography
                  variant="subtitle1"
                  className="text-capitalize label"
                >
                  Status
                </Typography>

                <Toggle
                  checked={formData.is_active === 1}
                  onChange={(checked) =>
                    handleToggleChange(checked, "is_active")
                  }
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" pb={2}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default FaqCategoriesEdit;
