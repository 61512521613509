import {
  faCircleInfo,
  faCloudArrowUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { MultipleMediaUpload } from "services/apiService";

const MediaLibraryCreate: React.FC = () => {
  const [images, setImages] = useState<File[]>([]);
  const [message, setMessage] = useState<{
    type: "success" | "error" | "";
    text: string;
  }>({ type: "", text: "" });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const allowedExtensions = ["jpg", "jpeg", "png", "gif", "mp4"];

  const isValidFile = (file: File) => {
    const extension = file.name.split(".").pop()?.toLowerCase();
    return extension ? allowedExtensions.includes(extension) : false;
  };

  const handleUploadFile = async () => {
    if (images.length === 0) {
      toast.error("Please upload at least one image.");
      return;
    }

    const formData = new FormData();
    images.forEach((image) => {
      if (!isValidFile(image)) {
        setMessage({
          type: "error",
          text: "Invalid file type. Allowed types are: jpg, jpeg, png, gif, mp4.",
        });
        return;
      }
      formData.append("file_name", image);
    });

    try {
      const response = await MultipleMediaUpload(formData);
      if (response.status_code === 200) {
        toast.success("Uploaded successfully");
        setMessage({ type: "success", text: "Images uploaded successfully!" });
        navigate("/admin/media-list");
      } else {
        setMessage({
          type: "error",
          text: response.message || "Error uploading the images.",
        });
      }
    } catch (error) {
      setMessage({ type: "error", text: "Error uploading the images." });
    }
  };

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files); // Allow multiple files
    const validFiles = files.filter((file) => isValidFile(file));

    if (validFiles.length > 0) {
      setImages((prevImages) => [...prevImages, ...validFiles]);
      setMessage({ type: "", text: "" });
    } else {
      setMessage({
        type: "error",
        text: "Invalid file type. Allowed types are: jpg, jpeg, png, gif, mp4.",
      });
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, []);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      if (updatedImages.length === 0 && fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return updatedImages;
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    const validFiles = files.filter((file) => isValidFile(file));

    if (validFiles.length > 0) {
      setImages((prevImages) => [...prevImages, ...validFiles]);
      setMessage({ type: "", text: "" });
    } else {
      setMessage({
        type: "error",
        text: "Invalid file type. Allowed types are: jpg, jpeg, png, gif, mp4.",
      });
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleContainerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <div style={{ maxWidth: "100%", margin: "0 auto", padding: "20px" }}>
        <div
          style={{
            backgroundColor: "#f9f9c5",
            color: "#333",
            padding: "10px",
            border: "1px solid #e6e600",
            borderRadius: "5px",
            marginBottom: "20px",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <FontAwesomeIcon icon={faCircleInfo} />
          &nbsp; Note! Allowed File Extensions:{" "}
          <strong>jpg, jpeg, png, gif, mp4</strong>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              border: "2px dashed #ccc",
              borderRadius: "5px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              position: "relative",
              minHeight: "300px",
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={handleContainerClick}
          >
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              multiple
            />
            {images.length > 0 ? (
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {images.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "150px",
                      border: "1px solid white",
                      padding: "10px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Uploaded Preview ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          background: "rgba(255, 255, 255, 0.8)",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                    <p
                      style={{
                        wordWrap: "break-word",
                        marginTop: "10px",
                        textAlign: "center",
                      }}
                    >
                      {image.name}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img
                    src="https://img.freepik.com/free-vector/image-upload-concept-illustration_114360-798.jpg"
                    alt="Image Upload"
                    style={{ width: "350px", height: "auto" }}
                  />
                  <p>Drag & drop your images here or click to upload</p>
                </div>
              </>
            )}
          </div>

          <button
            style={{
              marginTop: "20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "10px 15px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onClick={handleUploadFile}
          >
            <FontAwesomeIcon icon={faCloudArrowUp} />
            &nbsp;&nbsp; Upload Images
          </button>

          {message.text && (
            <div
              style={{
                marginTop: "10px",
                padding: "10px",
                borderRadius: "5px",
                backgroundColor:
                  message.type === "success" ? "#d4edda" : "#f8d7da",
                color: message.type === "success" ? "#155724" : "#721c24",
              }}
            >
              {message.text}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MediaLibraryCreate;
