
import { bulkProductUpload } from '../../../services/apiService';
import React, { useState } from 'react';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faFileCirclePlus, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import ExcelClone from '../../../common/ExcelClone';
import toast from 'react-hot-toast';
import DownloadButton from 'common/DownloadButton';


const ProductBulkUpload: React.FC = () => {
  const [bulkFileUpload, setBulkFileUpload] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const heading = "Products"
  const SubHeading = [
    "PRODUCT_NAME",
    "DESCRIPTION",
    "SPECIFICATION",
    "SKU",
    "QUANTITY",
    "IS_PRE_ORDER",
    "MIN_STOCK_WARNING",
    "PRICE",
    "TAX",
    "CATEGORY",
    "BRAND",
    "WEIGHT",
    "RETURNING_DATE",
    "IS_ACTIVE"
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    if (file && file.name.endsWith('.xlsx')) {
      setBulkFileUpload(file);
    } else {
      toast.error("Only .xlsx files are allowed")
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file && file.name.endsWith('.xlsx')) {
      setBulkFileUpload(file);
    } else {
      toast.error("Only .xlsx files are allowed")
    }
  };

  const handleRemoveFile = () => {
    setBulkFileUpload(null);
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!bulkFileUpload) {
      alert("Please upload a file before submitting.");
      return;
    }
    try {
      await bulkProductUpload(bulkFileUpload);
      toast.success('File uploaded successfully');
    } catch (error) {
      console.error("Error uploading the file", error);
    }
  };

  const fileName = 'products_data.xlsx'
  return (
    <>
      <div className='d-flex flex-column flex-md-row justify-content-evenly mb-3'>
        <div
          className='col-12 col-md-10 col-lg-8'
          style={{
            backgroundColor: '#f9f9c5',
            color: '#333',
            padding: '10px',
            border: '1px solid #e6e600',
            borderRadius: '5px',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          <FontAwesomeIcon icon={faCircleInfo} />&nbsp;
          Note! Only files are supported : <strong>.xlsx </strong>
        </div>
        <div className='d-flex flex-column flex-md-row justify-content-start'>
          <Button
            variant="contained"
            color="primary"
            className="text-white mb-2 mb-md-0"
            onClick={handleSubmit}
            disabled={!bulkFileUpload}
            startIcon={<FontAwesomeIcon icon={faFileCirclePlus} />}
          >
            Upload File
          </Button>
          &nbsp;&nbsp;&nbsp;
          <DownloadButton fileName={fileName} />
        </div>
      </div>
      <div
        className={` p-4 text-center d-flex flex-column justify-content-center align-items-center position-relative ${dragActive ? 'bg-light' : ''}`}
        style={{
          width: '100%',
          borderColor: dragActive ? '#007bff' : '#cccccc',
          border: "2px dashed grey",
          backgroundColor: "#f4eeee",
          cursor: 'pointer',
          transition: 'border-color 0.3s ease',
          borderRadius: "0px"
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => document.getElementById('fileInput')?.click()}
      >
        <input
          id="fileInput"
          type="file"
          className="d-none"
          accept=".xlsx"
          onChange={handleChange}
        />
        {bulkFileUpload ? (
          <div className="d-flex flex-column align-items-center">
            <i className="bi bi-file-earmark-check font-large text-success"></i>
            <div className="mt-2">{bulkFileUpload.name}</div>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleRemoveFile}
              style={{ marginTop: '10px' }}
            >
              Remove File
            </Button>
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center">
            <i className="bi bi-cloud-upload font-large text-primary"></i>
            <div className="mt-2">Drag & drop your .xlsx file here or click to upload</div>
          </div>
        )}
      </div>
      <br />
      <ExcelClone heading={heading} SubHeading={SubHeading} />
    </>
  );
};

export default ProductBulkUpload




