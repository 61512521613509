import React, { useEffect, useState } from "react";
import DynamicButton from "../../../common/DynamicButton.jsx";
import {
  faCirclePlus,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CommonTable from "common/CommonTable";
import loadingImage from "../../../assect/loadingSvg/loading.svg";
import { DeleteAds, getAllAdsPageList } from "services/apiService";
import AdsEdit from "./AdsEdit";
import Swal from "sweetalert2";
import { Button, Pagination } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdsList = () => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const [adsPagesListData, setAdsPagesListData] = useState([]);
  const [filteredAdsPagesList, setFilteredAdsPagesList] = useState([]);
  const [isOpenAdsEdit, setIsOpenAdsEdit] = useState<boolean>(false);
  const [selectedAdsId, setSelectedAdsId] = useState<number | null>(null);
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  useEffect(() => {
    getAdsList();
  }, []);

  const getAdsList = async () => {
    setLoader(true);
    try {
      const response = await getAllAdsPageList();
      setAdsPagesListData(response.data);
      setFilteredAdsPagesList(response.data);
    } catch (error) {
      setLoader(false);
    }
    setLoader(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query === "") {
      setFilteredAdsPagesList(adsPagesListData);
    } else {
      const filteredData = adsPagesListData.filter((item: any) =>
        item.name.toLowerCase().includes(query)
      );
      setFilteredAdsPagesList(filteredData);
    }
  };

  const handleDelete = async (id: number, name: string) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${name}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const response = await DeleteAds(id);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The Ads has been deleted.", "success");
            getAdsList();
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The Ads is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const headers = ["S.No", "Name", "Image", "Status", "Action"];

  const renderRow = (subpage: any, index: number) => {
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{index + 1}</td>
        <td>{subpage.name}</td>
        <td>
          <div className="d-flex justify-content-center">
            <img src={AppUrl + subpage.image_path} alt="" />
          </div>
        </td>
        <td>
          <span
            style={{
              color: subpage.is_active === 1 ? "green" : "red",
              backgroundColor: subpage.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {subpage.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>

        <td className="align-content-center">
          <div className="actions-buttons">
            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleModelOpen(subpage.id)}
            />

            <DynamicButton
              icon={faTrash}
              iconStyle={{ color: "red" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleDelete(subpage.id, subpage.page_name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  const handleModelOpen = (id: number) => {
    setSelectedAdsId(id);
    setIsOpenAdsEdit(true);
  };

  const handleModelClose = () => {
    setIsOpenAdsEdit(false);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const paginateData = filteredAdsPagesList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/ads-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Ads
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search ads Name..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ boxShadow: "none" }}
          />
        </div>
      </div>

      <div className="product-table-content">
        {loader ? (
          <div className="loadingsvg-Image text-center">
            <img src={loadingImage} width={150} height={150} alt="Loading..." />
          </div>
        ) : (
          <CommonTable
            data={paginateData}
            isLoading={loader}
            headers={headers}
            renderRow={renderRow}
          />
        )}
      </div>

      <div className="pagination-container mt-4">
        <div className="data-info" style={{ color: "grey" }}>
          <span>
            Showing {(currentPage - 1) * itemsPerPage + 1}-
            {Math.min(currentPage * itemsPerPage, filteredAdsPagesList.length)}{" "}
            of {filteredAdsPagesList.length}
          </span>
        </div>
        <Pagination
          count={Math.ceil(filteredAdsPagesList.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          color="primary"
        />
      </div>
      <AdsEdit
        onActionComplete={() => {
          getAdsList();
        }}
        open={isOpenAdsEdit}
        handleClose={handleModelClose}
        id={selectedAdsId}
      />
    </>
  );
};

export default AdsList;
