import React, { useState } from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
  Box,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Cart = ({
  cartItems,
  cartTotalAmount,
  billingAddresses,
  shippingAddresses,
  handleDelete,
  handleUpdate,
}) => {
  // Initialize quantities state for individual items
  const [quantities, setQuantities] = useState(() =>
    cartItems.reduce((acc, item) => {
      acc[item.product_id] = 1; // Set default quantity to 1 for each product_id
      return acc;
    }, {})
  );

  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  const handleIncrease = (productId) => {
    const updatedQuantity = (quantities[productId] || 1) + 1; // Increase quantity
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: updatedQuantity,
    }));

    // Call handleUpdate API function with productId and updated quantity
    handleUpdate(productId, updatedQuantity);
  };

  const handleDecrease = (productId) => {
    const updatedQuantity = Math.max(1, (quantities[productId] || 1) - 1); // Decrease quantity, but ensure it doesn't go below 1
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: updatedQuantity,
    }));

    // Call handleUpdate API function with productId and updated quantity
    handleUpdate(productId, updatedQuantity);
  };

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" gutterBottom>
        Cart
      </Typography>
      <hr />
      <TableContainer component={Paper} elevation={3} sx={{ borderRadius: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.NO</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Original Price</TableCell>

              <TableCell>Sales Price</TableCell>
              <TableCell>Tax Amount</TableCell>
              <TableCell>Sub Total</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cartItems.map((item, index) => (
              <TableRow key={item.product_id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.product_name}</TableCell>
                <TableCell>
                  <Avatar
                    src={`${
                      item.thumnail_image
                        ? AppUrl + item.thumnail_image
                        : "/placeholder.png"
                    }`}
                    alt={item.product_name}
                    variant="square"
                    sx={{ width: 50, height: 50 }}
                  />
                </TableCell>
                <TableCell>₹ {item.original_price}</TableCell>

                <TableCell>₹ {item.sales_price}</TableCell>
                <TableCell>₹ {item.tax_amount}</TableCell>
                <TableCell>₹ {item.sub_total}</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      color="primary"
                      onClick={() => handleDecrease(item.product_id)}
                      disabled={quantities[item.product_id] <= 1}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        width: 30,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {quantities[item.product_id] || 1}
                    </Typography>
                    <IconButton
                      color="primary"
                      onClick={() => handleIncrease(item.product_id)}
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(item.product_id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            borderRadius: 2,
            border: "1px solid #e0e0e0",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              mt: 2,
              // backgroundColor: "#f5f5f5",
              padding: 3,
              width: "100%",
              maxWidth: "500px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                borderBottom: "2px solid #1976d2",
                paddingBottom: 1,
                color: "#333",
                textAlign: "left",
                width: "100%",
                mb: 2,
              }}
            >
              Invoice Summary
            </Typography>

            <Box sx={{ width: "100%" }}>
              {[
                {
                  label: "Total Products",
                  value: cartTotalAmount?.total_products,
                },
                { label: "Total Count", value: cartTotalAmount?.total_count },
                {
                  label: "Total Original Amount",
                  value: `₹${cartTotalAmount?.total_original_amount || "-"}`,
                },
                {
                  label: "Total Discount Amount",
                  value: `₹${cartTotalAmount?.total_discount_amount || "-"}`,
                },
                {
                  label: "Total Tax Amount",
                  value: `₹${cartTotalAmount?.total_tax_amount || "-"}`,
                },
                {
                  label: "Total Sales Amount",
                  value: `₹${cartTotalAmount?.total_sales_amount || "-"}`,
                },
                {
                  label: "Coupon Discount Price",
                  value: `₹${cartTotalAmount?.coupen_discount_price || "-"}`,
                },
                {
                  label: "Total Price",
                  value: `₹${cartTotalAmount?.total_price || "-"}`,
                  isHighlight: true,
                },
              ].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1.5,
                    color: item.isHighlight ? "#1976d2" : "#555",
                    fontWeight: item.isHighlight ? "bold" : "normal",
                  }}
                >
                  <Typography variant="subtitle2">{item.label}</Typography>
                  <Typography variant="subtitle2">
                    {item.value === null ||
                    item.value === undefined ||
                    item.value === ""
                      ? "-"
                      : item.value}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Button
              sx={{
                width: "100%",
              }}
              variant="contained"
            >
              PLACE ORDER
            </Button>
          </Box>
        </div>
      </TableContainer>
    </Grid>
  );
};

export default Cart;
