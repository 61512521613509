import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  faqCategoryDropDowndata,
  getFaqEdit,
  UpadateFaq,
} from "services/apiService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  p: 4,
};

interface FaqCategoryOption {
  id: string;
  name: string;
}

interface FaqEditProps {
  open: boolean;
  id: number | null;
  handleClose: () => void;
  onActionComplete: () => void;
}

const FaqEdit: React.FC<FaqEditProps> = ({
  open,
  id,
  handleClose,
  onActionComplete,
}) => {
  const [faqFormData, setFaqFormData] = useState({
    title: "",
    description: "",
    is_active: 1,
    faq_category_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [faqCategoryOptions, setFaqCategoryOptions] = useState<
    FaqCategoryOption[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    faq_category_id: "",
  });

  useEffect(() => {
    faqCategoriesDropDownList();
  }, []);

  useEffect(() => {
    if (id) {
      fetchFaq(id);
    }
  }, [id]);

  const faqCategoriesDropDownList = async () => {
    setIsLoading(true);
    try {
      const response = await faqCategoryDropDowndata();
      setFaqCategoryOptions(response.data || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFaqFormData((prev) => ({ ...prev, [id]: value }));
    if (formErrors[id as keyof typeof formErrors]) {
      setFormErrors((prev) => ({ ...prev, [id]: "" }));
    }
  };

  const handleCategoryChange = (e) => {
    setFaqFormData((prevData) => ({
      ...prevData,
      faq_category_id: e.target.value,
    }));
  };

  const handleToggleChange = (checked: number) => {
    setFaqFormData((prevData) => ({
      ...prevData,
      is_active: checked ? 1 : 0,
    }));
  };

  const validateForm = () => {
    const errors: typeof formErrors = {
      title: "",
      description: "",
      faq_category_id: "",
    };
    if (!faqFormData.title.trim()) {
      errors.title = "Title is required.";
    }
    if (!faqFormData.description.trim()) {
      errors.description = "Description is required.";
    }
    if (!faqFormData.faq_category_id) {
      errors.faq_category_id = "Category must be selected.";
    }
    setFormErrors(errors);
    return Object.values(errors).every((error) => !error);
  };

  const fetchFaq = async (id: number) => {
    try {
      const response = await getFaqEdit(id);
      if (response.status_code === 200) {
        setFaqFormData({
          title: response.data.title || "",
          description: response.data.description || "",
          is_active: response.data.is_active || 0,
          faq_category_id: response.data.faq_category_id || "",
        });
      }
    } catch (error) {
      console.error("Error fetching FAQ:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsSubmitting(true);
    try {
      const payload = {
        id: id,
        faq_category_id: faqFormData.faq_category_id,
        title: faqFormData.title,
        description: faqFormData.description,
        is_active: Number(faqFormData.is_active),
      };
      const response = await UpadateFaq(payload);
      if (response.status_code === 200) {
        toast.success("Updated Succesfully");
        onActionComplete();
        handleClose();
      }
    } catch (error) {
      toast.error("Error Updating FAQ:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ padding: 1 }}>
          <form onSubmit={handleSubmit} noValidate>
            <Box display="flex" flexDirection="column" gap={3}>
              <Box className="create_category_fields">
                <Typography variant="subtitle1">FAQ Title</Typography>
                <TextField
                  id="title"
                  variant="filled"
                  fullWidth
                  placeholder="Enter FAQ title"
                  value={faqFormData.title}
                  onChange={handleInputChange}
                  error={!!formErrors.title}
                  helperText={formErrors.title}
                />
              </Box>

              {/* Description Field */}
              <Box className="create_category_fields">
                <Typography variant="subtitle1">Description</Typography>
                <TextField
                  id="description"
                  variant="filled"
                  fullWidth
                  placeholder="Enter FAQ description"
                  value={faqFormData.description}
                  onChange={handleInputChange}
                  error={!!formErrors.description}
                  helperText={formErrors.description}
                  multiline
                  rows={3}
                />
              </Box>

              {/* Category Dropdown */}
              <Box className="create_category_fields">
                <Typography variant="subtitle1">Category</Typography>
                <FormControl fullWidth variant="filled">
                  <Select
                    id="category-select"
                    value={faqFormData.faq_category_id}
                    onChange={handleCategoryChange}
                    displayEmpty
                  >
                    {isLoading ? (
                      <MenuItem value="">
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : faqCategoryOptions.length > 0 ? (
                      faqCategoryOptions.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No Categories Available
                      </MenuItem>
                    )}
                  </Select>
                  {formErrors.faq_category_id && (
                    <FormHelperText>
                      {formErrors.faq_category_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>

              {/* Status Toggle */}
              <Box className="create_category_fields">
                <Typography variant="subtitle1">Status</Typography>
                <Toggle
                  checked={faqFormData.is_active === 1}
                  onChange={(checked) => handleToggleChange(checked)}
                />
              </Box>

              <Box display="flex" justifyContent="flex-end" pb={2}>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default FaqEdit;
