import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { createBlogTags } from "services/apiService";

interface BlogCategoryFormData {
  name: string;
  is_active: number;
}

interface Errors {
  name?: string;
}

const BlogTagesCreate: React.FC = () => {
  const [BlogCategoryformData, setBlogCategoryformData] =
    useState<BlogCategoryFormData>({
      name: "",
      is_active: 0,
    });
  const [errors, setErrors] = useState<Errors>({});
  const navigate = useNavigate();

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setBlogCategoryformData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (errors[id as keyof Errors]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }
  };
  const handleToggleChange = (checked: number, field: string) => {
    setBlogCategoryformData((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const validateForm = (): boolean => {
    const newErrors: Errors = {};

    if (!BlogCategoryformData.name.trim()) {
      newErrors.name = "Blog category title is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    const payload = {
      name: BlogCategoryformData.name,
      is_active: BlogCategoryformData.is_active,
    };
    try {
      const response = await createBlogTags(payload);
      if (response.status_code === 200) {
        toast.success("Form submitted");
        setBlogCategoryformData({
          name: "",
          is_active: 0,
        });
        navigate("/admin/blog-tags-list");
        setErrors({});
      }
    } catch (error) {
      console.error("Failed to create blog Tag:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Blogs Tag Title
            </Typography>
            <TextField
              variant="filled"
              name="name"
              id="name"
              value={BlogCategoryformData.name}
              onChange={handleTextFieldChange}
              placeholder="Type Here"
              fullWidth
              error={!!errors.name}
              className="common_input"
              helperText={errors.name || "Please enter Blog Tag title."}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography
              variant="body1"
              className="text-capitalize label"
              sx={{ width: "20%" }}
            >
              Status
            </Typography>
            <Toggle
              checked={BlogCategoryformData.is_active === 1}
              onChange={(checked) => handleToggleChange(checked, "is_active")}
            />
          </Box>
        </Box>
        <Box textAlign="center" mt={5}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            <FontAwesomeIcon icon={faCirclePlus} /> &nbsp;&nbsp; Create Blog Tag
          </Button>
        </Box>
      </form>
    </>
  );
};

export default BlogTagesCreate;
