import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import {
  ClassificationDropDown,
  AllClassification,
  productClasssificationUpdate,
} from "../../../services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { ProductClassificationEditFormProps } from "types";
import { Button } from "@mui/material";
import noClassificationImage from "../../../assect/No_data.png";
import { useNavigate } from "react-router";

const ProductEditClassification: React.FC<
  ProductClassificationEditFormProps
> = ({ productId }) => {
  const [classificationData, setClassificationData] = useState([]);
  const [ClassificationLists, setClassificationList] = useState([]);
  const [editingClassificationnId, setEditingClassificationId] = useState(null);
  const [newClassificationName, setNewClassificationName] = useState("");
  const [previousClassificationName, setPreviousClassificationName] =
    useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (productId) {
      fetchData(productId);
      ClassificationDropDownList();
    }
  }, [productId]);

  const ClassificationDropDownList = async () => {
    try {
      const response = await ClassificationDropDown();
      console.log(response);
      setClassificationData(response.data);
    } catch (error) {
      console.error("Error fetching Classification list:", error);
    }
  };

  const fetchData = async (productId: number) => {
    try {
      const response = await AllClassification(productId);
      if (response.status_code === 200) {
        setClassificationList(response?.data);
      }
    } catch (error) {
      console.error("Error fetching product classifications:", error);
    }
  };

  const handleEditClick = (classification_id, classification_name) => {
    setEditingClassificationId(classification_id);
    setNewClassificationName(classification_id.toString());
    setPreviousClassificationName(classification_name);
  };

  const handleDropdownChange = (e) => {
    setNewClassificationName(e.target.value);
  };

  const handleSaveClick = async (classification_map_id: number) => {
    try {
      const payload = {
        classification_id: parseInt(newClassificationName),
        classification_map_id: classification_map_id,
        product_id: productId,
      };

      const response = await productClasssificationUpdate(payload);
      if (response.status_code === 200) {
        toast.success(response.message);
        const updatedCampaigns = ClassificationLists.map((classification) =>
          classification.classification_id === editingClassificationnId
            ? { ...classification, classification_name: newClassificationName }
            : classification
        );
        setClassificationList(updatedCampaigns);
        setEditingClassificationId(null);
        setNewClassificationName("");
        fetchData(productId);
      } else if (response.status_code === 400) {
        toast.error(response.message);
      } else {
        console.error("Unexpected response:", response);
        toast.error("An unexpected error occurred");
      }
    } catch (error) {
      toast.error("Error updating Classsification name");
      console.error("Error updating Classsification name:", error);
    }
  };

  const handleCancelClick = () => {
    setNewClassificationName(previousClassificationName);
    setEditingClassificationId(null);
  };

  return (
    <div className="container-fluid ">
      <div className="card p-3 " style={{ border: "none" }}>
        <div
          className="classification-list-container"
          style={{ maxHeight: "700px", overflowY: "auto" }}
        >
          {ClassificationLists ? (
            <table className="table table-striped">
              <thead className="table-light">
                <tr>
                  <th>Classification Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {ClassificationLists?.map((classification) => (
                  <tr key={classification.classification_id}>
                    <td>
                      {editingClassificationnId ===
                      classification.classification_id ? (
                        <select
                          className="form-select"
                          value={newClassificationName}
                          onChange={handleDropdownChange}
                          style={{ width: "100%" }}
                        >
                          <option value="">Select campaign</option>
                          {classificationData.map((campaignItem) => (
                            <option
                              key={campaignItem.classification_id}
                              value={campaignItem.classification_id}
                            >
                              {campaignItem.classification_name.toUpperCase()}
                            </option>
                          ))}
                        </select>
                      ) : (
                        classification.classification_name.toUpperCase()
                      )}
                    </td>
                    <td className="text-center">
                      {editingClassificationnId ===
                      classification.classification_id ? (
                        <>
                          <Button
                            onClick={() =>
                              handleSaveClick(classification.map_id)
                            }
                            variant="contained"
                            color="success"
                            className="me-2"
                          >
                            Save
                          </Button>
                          <Button
                            onClick={handleCancelClick}
                            variant="outlined"
                            color="error"
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() =>
                            handleEditClick(
                              classification.classification_id,
                              classification.classification_name
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                          &nbsp;&nbsp;Edit
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="d-flex flex-column align-items-center text-center">
              <img
                src={noClassificationImage}
                alt="No Classification Found"
                style={{ maxWidth: "200px", marginBottom: "16px" }}
              />
              <p
                style={{
                  fontSize: "16px",
                  color: "#555",
                  marginBottom: "16px",
                }}
              >
                <strong>Oops!</strong> This product currently has no
                classification mapped. If you'd like to add a classification,
                click the button below.
              </p>
              <Button
                className="btn btn-primary mt-3"
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/admin/product-classification");
                }}
                style={{
                  borderRadius: "4px",
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#0056b3")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#007bff")
                }
              >
                Add Classification
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductEditClassification;
