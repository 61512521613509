import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CreateTax } from "services/apiService";

const TaxCreate: React.FC = () => {
  const navigate = useNavigate();

  const [TaxFormData, setTaxFormData] = useState({
    name: "",
    percentage: "",
    label: "",
    is_active: 1,
  });

  const [errors, setErrors] = useState({
    name: "",
    percentage: "",
    label: "",
  });

  const filterInputValue = (id: string, value: string) => {
    switch (id) {
      case "name":
        return value.replace(/[^a-zA-Z0-9-]/g, "").slice(0, 30);
      case "label":
        return value
          .toUpperCase()
          .replace(/[^A-Z0-9-]/g, "")
          .slice(0, 20);
      case "percentage":
        return value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
      default:
        return value;
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    const filteredValue = filterInputValue(id, value);

    if (
      id === "percentage" &&
      (Number(filteredValue) < 0 || Number(filteredValue) > 100)
    ) {
      return;
    }

    setTaxFormData((prevData) => ({
      ...prevData,
      [id]: filteredValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };
  const validateForm = () => {
    const newErrors: any = {};

    if (!TaxFormData.name.trim()) newErrors.name = "Tax name is required.";
    if (!TaxFormData.label.trim()) newErrors.label = "Tax label is required.";
    if (!TaxFormData.percentage.trim())
      newErrors.percentage = "Tax percentage is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const payload = {
        name: TaxFormData.name,
        percentage: Number(TaxFormData.percentage),
        label: TaxFormData.label,
        is_active: TaxFormData.is_active,
      };

      const response = await CreateTax(payload);

      if (response.status_code === 200) {
        toast.success("Tax created successfully!");
        setTaxFormData({ name: "", percentage: "", label: "", is_active: 1 });
        navigate("/admin/tax-list");
      } else {
        toast.error("Tax creation failed. Please try again.");
      }
    } catch (error) {
      console.error("Error creating tax:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="w-100">
          {[
            {
              id: "name",
              label: "Tax Name",
              helperText:
                "Only letters, numbers, and '-' allowed. Max 30 characters.",
              maxLength: 30,
            },
            {
              id: "label",
              label: "Tax Label",
              maxLength: 20,
            },
            {
              id: "percentage",
              label: "Tax Percentage",
              helperText: "Enter a value between 0% and 100%.",
              type: "number",
            },
          ].map((field) => (
            <Box className="create_category_fields" mb={3} key={field.id}>
              <Typography variant="subtitle1" className="text-capitalize label">
                {field.label}
              </Typography>
              <TextField
                variant="filled"
                name={field.id}
                id={field.id}
                required
                value={TaxFormData[field.id as keyof typeof TaxFormData]}
                onChange={handleInputChange}
                placeholder="Type Here"
                fullWidth
                error={!!errors[field.id]}
                className="common_input"
                helperText={field.helperText}
                type={field.type || "text"}
                inputProps={{ maxLength: field.maxLength }}
              />
            </Box>
          ))}

          <Box display="flex" alignItems="center" gap={2}>
            <Typography
              variant="body1"
              className="text-capitalize label"
              sx={{ width: "20%" }}
            >
              Status
            </Typography>
            <Toggle
              checked={TaxFormData.is_active === 1}
              onChange={(checked) =>
                setTaxFormData((prevData) => ({
                  ...prevData,
                  is_active: checked ? 1 : 0,
                }))
              }
            />
          </Box>
        </div>

        <Box textAlign="center" mt={5}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            &nbsp;&nbsp; Create Tax
          </Button>
        </Box>
      </form>
    </>
  );
};

export default TaxCreate;
