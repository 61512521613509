import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Toggle from "common/Toggle";
import ImageUploaderModal from "common/ImageUploaderModal";
import {
  BrandData,
  CategoriesData,
  DiscountData,
  ProductEditFormProps,
  ProductFormData,
  TaxData,
} from "types";
import {
  BrandDropDown,
  CategoriesDropDown,
  DiscountDropDown,
  productEdit,
  productUpdate,
  TaxDropDown,
} from "services/apiService";
import { useLocation } from "react-router";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import ReusableEditor from "common/ReusableCKEditor";

const ProductEditForm: React.FC<ProductEditFormProps> = ({ productId }) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const location = useLocation();
  const { product_id } = location.state || {};
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedImages, setselectedImages] = useState(null);
  const [ApiFormdata, setApiFormdata] = useState({});
  const [brandId, setBrandId] = useState({});
  const [TaxId, setTxId] = useState({});
  const [categoryId, setcategoryId] = useState(null);
  const [discountId, setdiscountId] = useState(null);
  const [productEditFormData, setProductEditFormData] = useState({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    original_price: "",
    discount_id: "",
    tax_id: "",
    category_id: "",
    brand_id: "",
    slug: "",
    is_have_variant: 1,
    unit: 1,
    weight: 1,
    returning_date: 5,
    is_active: 1,
    is_pre_order: 0,
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [BrandList, setBrandList] = useState<BrandData[]>([]);
  const [CategoryList, setCategoryList] = useState<CategoriesData[]>([]);
  const [taxList, setTaxList] = useState<TaxData[]>([]);
  const [discountList, setDiscountList] = useState<DiscountData[]>([]);
  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    setProductEditFormData({
      ...productEditFormData,
      ...ApiFormdata,
    });
  }, [ApiFormdata]);
  const fetchDropdownData = async (): Promise<void> => {
    try {
      const [brandResponse, categoryResponse, TaxResponse, DiscountResponse] =
        await Promise.all([
          BrandDropDown(),
          CategoriesDropDown(),
          TaxDropDown(),
          DiscountDropDown(),
          ProductData(),
        ]);
      setBrandList(brandResponse.data as BrandData[]);
      setCategoryList(categoryResponse.data as CategoriesData[]);
      setDiscountList(DiscountResponse.data as DiscountData[]);
      setTaxList(TaxResponse.data as TaxData[]);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };
  const ProductData = async () => {
    const response = await productEdit(product_id);
    productId(product_id);
    setApiFormdata(response.data);
    setTxId(response.data.tax_id);
    setBrandId(response.data.brand_id);
    setcategoryId(response.data.category_id);
    setdiscountId(response.data.discount_id);
    setselectedImages(response.data.thumnail_image);
  };
  const handleImageSelected = (image: string) => {
    setselectedImages(image);
    setModalOpen(false);
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | any,
    field?: keyof ProductFormData | string
  ) => {
    if (e?.target) {
      const { id, value } = e.target;
      let updatedValue = value;
      if (id === "product_name") {
        const generateSlug = (name: string) => {
          return name
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, "")
            .replace(/\s+/g, "-");
        };
        const capitalizeWords = (text: string) => {
          return text
            .replace(/([^\w\s])/gi, (match) => match.replace(/\s+/g, ""))
            .replace(
              /\b\w+\b/g,
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            );
        };
        const transformedValue = capitalizeWords(value);
        updatedValue = transformedValue;
        const slug = generateSlug(transformedValue);
        setProductEditFormData((prevData) => ({
          ...prevData,
          [id]: updatedValue,
          slug,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
      } else {
        const numberFields = [
          "quantity",
          "minimum_stock_warning",
          "price",
          "tax",
          "unit",
          "weight",
          "returning_date",
        ];
        if (numberFields.includes(id)) {
          const parsedValue = parseFloat(value);
          if (!isNaN(parsedValue) && value.trim() !== "") {
            updatedValue = parsedValue.toString();
          } else {
            updatedValue = "";
          }
        }
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: "",
        }));
        setProductEditFormData((prevData) => ({
          ...prevData,
          [id]: updatedValue,
        }));
      }
    } else if (field) {
      const value = e ? e.value : "";
      setProductEditFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    }
  };
  const handleToggleChange = (checked: number, field: string) => {
    const newValue = checked ? 1 : 0;
    setProductEditFormData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };
  const HandleEditSubmit = async (event) => {
    event.preventDefault();

    const requiredFields = [
      "product_name",
      "sku",
      "quantity",
      "original_price",
      "category_id",
      "brand_id",
      "slug",
      "tax_id",
      "minimum_stock_warning",
    ];
    const newErrors: Record<string, string> = {};
    const emptyFields = requiredFields?.filter(
      (field) => !productEditFormData[field]
    );
    emptyFields.forEach((field) => {
      newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
    });
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const payload = {
            ...productEditFormData,
            product_name: productEditFormData.product_name,
            slug: productEditFormData.slug,
            description: productEditFormData.description,
            specification: productEditFormData.specification,
            brand_id: Number(productEditFormData.brand_id),
            category: Number(productEditFormData.category_id),
            discount_id: Number(productEditFormData.discount_id),
            tax_id: Number(productEditFormData.tax_id),
            minimum_stock_warning: Number(
              productEditFormData.minimum_stock_warning
            ),
            price: Number(productEditFormData.original_price),
            sku: productEditFormData.sku,
            quantity: Number(productEditFormData.quantity),
            image_path: selectedImages ? selectedImages.toString() : "",
            is_active: productEditFormData.is_active,
            unit_id: 1,
            weight: 1.5,
            returning_date: 5,
          };
          const ProductEditResponse = await productUpdate(payload);
          if (ProductEditResponse.status_code === 200) {
            toast.success("Product Updated Successfully!");
          } else {
            toast.error("Failed to update the product.");
          }
        } catch (error) {
          console.error("Error updating product:", error);
          toast.error("Product update failed. Please try again.");
        }
      }
    });
  };

  const handleDeleteImage = () => {
    setselectedImages(null);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  return (
    <>
      <form onSubmit={HandleEditSubmit}>
        <div className="product_form_main_right_t">
          <div className="group position-relative">
            <TextField
              id="product_name"
              label="Product Name*"
              variant="outlined"
              placeholder="Type here"
              value={productEditFormData.product_name}
              onChange={handleChange}
              error={Boolean(errors.product_name)}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
            {errors.product_name && (
              <div className="error-text">{errors.product_name}</div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <h6 style={{ color: "rgb(0, 123, 255)" }}>
            <strong>Description</strong>
          </h6>
          <ReusableEditor
            value={productEditFormData.description}
            onChange={(value) => {
              setProductEditFormData((prevData) => ({
                ...prevData,
                description: value,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                description: "",
              }));
            }}
          />
          <br />
          <h6 style={{ color: "rgb(0, 123, 255)" }}>
            <strong>Specification</strong>
          </h6>
          <ReusableEditor
            value={productEditFormData.specification}
            onChange={(value) => {
              setProductEditFormData((prevData) => ({
                ...prevData,
                specification: value,
              }));
              setErrors((prevErrors) => ({
                ...prevErrors,
                specification: "",
              }));
            }}
          />
        </div>
        <div className="row g-3 mt-2">
          <div className="col-md-3">
            <label htmlFor="brand" className="custom-label">
              Brand
            </label>
            <select
              id="brand_id"
              className="custom-select"
              onChange={handleChange}
            >
              {!BrandList ? (
                <option value="">No Data...</option>
              ) : BrandList.length === 0 ? (
                <option value="">No brand found</option>
              ) : (
                <>
                  <option value="">Select brand</option>
                  {BrandList.map((brand) => (
                    <option
                      key={brand.id}
                      value={brand.id}
                      selected={brandId === brand.id}
                    >
                      {brand.brand_name.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
            {errors.brand_id && (
              <div className="error-message">{errors.brand_id}</div>
            )}
          </div>
          <div className="col-md-3">
            <label htmlFor="category" className="form-label">
              Category
            </label>
            <select
              id="category_id"
              className="custom-select"
              onChange={handleChange}
            >
              {!CategoryList ? (
                <option value="">No Data...</option>
              ) : CategoryList.length === 0 ? (
                <option value="">No category found</option>
              ) : (
                <>
                  <option value="">Select Category</option>
                  {CategoryList.map((category) => (
                    <option
                      key={category.id}
                      value={category.id}
                      selected={categoryId === category.id}
                    >
                      {category.category_name.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
            {errors.category && (
              <div className="error-text" style={{ color: "red" }}>
                {errors.category}
              </div>
            )}
          </div>
          <div className="col-md-3">
            <label htmlFor="discount" className="form-label">
              Discount
            </label>
            <select
              id="discount_id"
              onChange={handleChange}
              className="custom-select"
            >
              {!discountList ? (
                <option value="">No Data...</option>
              ) : discountList.length === 0 ? (
                <option value="">No Discount found</option>
              ) : (
                <>
                  <option value="">Select Discount</option>
                  {discountList.map((discount) => (
                    <option
                      key={discount.id}
                      value={discount.id}
                      selected={discountId === discount.id}
                    >
                      {discount.name.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="tax" className="form-label">
              Tax
            </label>
            <select
              id="tax_id"
              className="custom-select"
              onChange={handleChange}
            >
              {!taxList ? (
                <option value="">No Data...</option>
              ) : taxList.length === 0 ? (
                <option value="">No tax found</option>
              ) : (
                <>
                  <option value="">Select Tax</option>
                  {taxList.map((tax) => (
                    <option
                      key={tax.id}
                      value={tax.id}
                      selected={TaxId === tax.id}
                    >
                      {tax.label.toUpperCase()}
                    </option>
                  ))}
                </>
              )}
            </select>
            {errors.tax_id && (
              <div className="error-text" style={{ color: "red" }}>
                {errors.tax_id}
              </div>
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <TextField
              id="minimum_stock_warning"
              label="Minimum Stock Warning"
              variant="outlined"
              placeholder="Type here"
              value={productEditFormData.minimum_stock_warning}
              onChange={(e) => {
                if (e.target.value.length <= 2) {
                  handleChange(e);
                }
              }}
              error={Boolean(errors.minimum_stock_warning)}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />

            {errors.minimum_stock_warning && (
              <div className="error-text">{errors.minimum_stock_warning}</div>
            )}
          </div>
          <div className="col-md-3">
            <TextField
              id="original_price"
              label="Price"
              variant="outlined"
              placeholder="Type here"
              error={Boolean(errors.original_price)}
              value={productEditFormData.original_price}
              onChange={handleChange}
              type="number"
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
            {errors.original_price && (
              <div className="error-text">{errors.original_price}</div>
            )}
          </div>
          <div className="col-md-3">
            <TextField
              id="sku"
              label="SKU"
              variant="outlined"
              placeholder="Type here"
              value={productEditFormData.sku}
              onChange={handleChange}
              error={Boolean(errors.sku)}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
            {errors.sku && <div className="error-text">{errors.sku}</div>}
          </div>
          <div className="col-md-3">
            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              placeholder="Type here"
              value={productEditFormData.quantity}
              onChange={handleChange}
              error={Boolean(errors.quantity)}
              fullWidth
              InputProps={{
                style: { backgroundColor: "white" },
              }}
            />
            {errors.quantity && (
              <div className="error-text">{errors.quantity}</div>
            )}
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div
            style={{
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                textAlign: "center",
                marginBottom: "20px",
                border: "1px dashed #ccc",
                padding: "20px",
                borderRadius: "10px",
                backgroundColor: "#f9f9f9",
              }}
            >
              {selectedImages ? (
                <>
                  <img
                    src={`${AppUrl}${selectedImages}`}
                    alt="Selected"
                    style={{
                      width: "200px",
                      height: "150px",
                      borderRadius: "10px",
                      transition: "transform 0.2s",
                      cursor: "pointer",
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.transform = "scale(1.05)";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  />
                  <button
                    style={{
                      backgroundColor: "rgba(255, 0, 0, 0.7)",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      fontSize: "16px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                      transition: "background-color 0.2s",
                    }}
                    onClick={handleDeleteImage}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </>
              ) : (
                <p style={{ color: "orange" }}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  &nbsp; &nbsp; No image selected. Please upload an image.
                </p>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleOpenModal}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  cursor: "pointer",
                  marginTop: "10px",
                  transition: "background-color 0.2s",
                }}
              >
                <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp; Thumnail
                Image
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-evenly">
          <div>
            <label htmlFor="Status" className="form-label">
              Status
            </label>
            <Toggle
              checked={productEditFormData.is_active === 1}
              onChange={(checked) => handleToggleChange(checked, "is_active")}
            />
          </div>

          <div>
            <label htmlFor="Pre-Order" className="form-label">
              Pre-Order
            </label>
            <Toggle
              checked={productEditFormData.is_pre_order === 1}
              onChange={(checked) =>
                handleToggleChange(checked, "is_pre_order")
              }
            />
          </div>
        </div>
      </form>
      <div className="mt-3">
        <div className="d-flex justify-content-center">
          <Button
            variant="contained"
            color="primary"
            onClick={HandleEditSubmit}
            sx={{ borderRadius: "0px", padding: "10px", width: "100%" }}
          >
            Update Product
            <FontAwesomeIcon
              icon={faAnglesRight}
              style={{ marginLeft: "5px" }}
            />
          </Button>
        </div>
      </div>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default ProductEditForm;
