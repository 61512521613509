import React from "react";
import "../styles/pagenation.css";

interface PaginationProps {
  pagenationLength: number;
  numberofItems: number;
  currentPageNationPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  pagenationLength,
  numberofItems,
  currentPageNationPage,
  onPageChange,
}) => {
  const pageNumbers = [];
  const ellipsis = "...";
  const totalPages = Math.ceil(pagenationLength / numberofItems);

  if (totalPages <= numberofItems + 2) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    const leftSide = Math.max(1, currentPageNationPage - 2);
    const rightSide = Math.min(currentPageNationPage + 2, totalPages);

    if (leftSide > 2) {
      pageNumbers.push(1);
      pageNumbers.push(ellipsis);
    } else {
      for (let i = 1; i < leftSide; i++) {
        pageNumbers.push(i);
      }
    }

    for (let i = leftSide; i <= rightSide; i++) {
      pageNumbers.push(i);
    }

    if (rightSide < totalPages - 1) {
      pageNumbers.push(ellipsis);
      pageNumbers.push(totalPages);
    } else {
      for (let i = rightSide + 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }
  }
  return (
    <div className="custom-pagination">
    <button
      className="custom-pagination__prev"
      onClick={() => onPageChange(currentPageNationPage - 1)}
      disabled={currentPageNationPage === 1}
    >
      &lt;
    </button>
  
    {pageNumbers.map((number, index) => {
      return number === ellipsis ? (
        <span key={index} className="custom-pagination__ellipsis">
          {ellipsis}
        </span>
      ) : (
        <button
          key={index}
          className={`custom-pagination__button ${
            currentPageNationPage === number ? "custom-pagination__button--active" : ""
          }`}
          onClick={() => onPageChange(number)}
        >
          {number}
        </button>
      );
    })}
  
    <button
      className="custom-pagination__next"
      onClick={() => onPageChange(currentPageNationPage + 1)}
      disabled={currentPageNationPage === pageNumbers.length}
    >
      &gt;
    </button>
  </div>
  
  
  
  );
};

export default Pagination;
