import React, { useEffect, useState } from "react";
import CommonTable from "common/CommonTable";
import DynamicButton from "../../common/DynamicButton.jsx";
import {
  faCirclePlus,
  faMagnifyingGlass,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { SettingSingledropdown } from "services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Pagination, Box } from "@mui/material";
import { Link } from "react-router-dom";
import SettingsSingleEdit from "./SettingsSingleEdit";

const SettingSingleList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [settingsList, setSettingsList] = useState([]);
  const [filteredSettings, setFilteredSettings] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSettingId, setSelectedSettingId] = useState<number | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const headers = ["S.no", "Settings Name", "Status", "Action"];

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredSettings(settingsList);
    } else {
      const filtered = settingsList.filter((setting) =>
        setting.key_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredSettings(filtered);
    }
  }, [searchQuery, settingsList]);

  const fetchSettings = async () => {
    setIsLoading(true);
    try {
      const response = await SettingSingledropdown();
      setSettingsList(response.data);
      setFilteredSettings(response.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
    setIsLoading(false);
  };

  const openEditModal = (id: number) => {
    setSelectedSettingId(id);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Pagination logic
  const totalPages = Math.ceil(filteredSettings.length / itemsPerPage);
  const paginatedData = filteredSettings.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const renderRow = (setting: any, index: number) => {
    const serialNumber = (currentPage - 1) * itemsPerPage + index + 1;
    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{serialNumber}</td>
        <td className="table_product_name">{setting.key_name}</td>
        <td className="align-content-center">
          <span
            style={{
              color: setting.is_active === 1 ? "green" : "red",
              backgroundColor: setting.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {setting?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>

        <td className="align-content-center">
          <DynamicButton
            icon={faPen}
            iconStyle={{ color: "#63E6BE" }}
            style={{
              padding: "0",
              border: "none",
              background: "none",
              cursor: "pointer",
              margin: "2px",
            }}
            onClick={() => openEditModal(setting.id)}
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/setting-single-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Single Settings
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            className="form-control"
            style={{ boxShadow: "none" }}
            placeholder="Settings Name..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" />
            </span>
          </div>
        </div>
      </div>

      <CommonTable
        data={paginatedData}
        isLoading={isLoading}
        headers={headers}
        renderRow={renderRow}
      />
      {filteredSettings.length > itemsPerPage && (
        <Box display="flex" justifyContent="center" mt={3}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            shape="rounded"
          />
        </Box>
      )}

      <SettingsSingleEdit
        onActionComplete={() => {
          fetchSettings();
        }}
        open={isEditModalOpen}
        handleClose={closeEditModal}
        id={selectedSettingId}
      />
    </>
  );
};

export default SettingSingleList;
