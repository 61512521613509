import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedImagesByKey: {},
  singleImageSelections: {},
  selectedVideosByKey: {},
  singleVideoSelections: {},
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
   toggleImageSelection: (state, action) => {
  const { keyName, mediaUrl, isMulti } = action.payload;
  console.log(keyName, mediaUrl, isMulti, "payload");

  if (isMulti) {
    // Handle multi-selection logic
    if (!state.selectedImagesByKey[keyName]) {
      state.selectedImagesByKey[keyName] = "";
    }

    const imagesForKey = state.selectedImagesByKey[keyName]
      .split(",")
      .filter((item) => item !== "");

    if (imagesForKey.includes(mediaUrl)) {
      // Remove image from the list
      state.selectedImagesByKey[keyName] = imagesForKey
        .filter((url) => url !== mediaUrl)
        .join(",");
    } else {
      // Add new image to the list
      state.selectedImagesByKey[keyName] = [...imagesForKey, mediaUrl].join(",");
    }

    // Clean up empty string case
    if (state.selectedImagesByKey[keyName] === "") {
      delete state.selectedImagesByKey[keyName];
    }
  } else {
    // Handle single selection logic
    if (state.singleImageSelections[keyName] !== mediaUrl) {
      state.singleImageSelections[keyName] = mediaUrl;
    }
  }
},

    toggleVideoSelection: (state, action) => {
      const { keyName, mediaUrl, isMulti } = action.payload;
      if (isMulti) {
        if (!state.selectedVideosByKey[keyName]) {
          state.selectedVideosByKey[keyName] = "";
        }
        const videosForKey = state.selectedVideosByKey[keyName]
          .split(",")
          .filter((item) => item !== "");

        if (videosForKey.includes(mediaUrl)) {
          state.selectedVideosByKey[keyName] = videosForKey
            .filter((url) => url !== mediaUrl)
            .join(",");
        } else {
          state.selectedVideosByKey[keyName] = [...videosForKey, mediaUrl].join(
            ","
          );
        }
        if (state.selectedVideosByKey[keyName] === "") {
          state.selectedVideosByKey[keyName] = "";
        }
      } else {
        if (state.singleVideoSelections[keyName] !== mediaUrl) {
          state.singleVideoSelections[keyName] = mediaUrl;
        }
      }
    },
    clearSelections: (state, action) => {
      state.selectedImagesByKey = {};
      state.singleImageSelections = {};
      state.selectedVideosByKey = {};
      state.singleVideoSelections = {};
    },
    clearVideoSelections: (state, action) => {
      const { keyName, isMulti, mediaUrl } = action.payload;
      if (isMulti) {
        if (state.selectedVideosByKey[keyName]) {
          const videosForKey = state.selectedVideosByKey[keyName].split(",");
          state.selectedVideosByKey[keyName] = videosForKey
            .filter((url) => url !== mediaUrl)
            .join(",");
          if (state.selectedVideosByKey[keyName] === "") {
            state.selectedVideosByKey[keyName] = "";
          }
        }
      } else {
        if (state.singleVideoSelections[keyName] === mediaUrl) {
          state.singleVideoSelections[keyName] = "";
        }
      }
    },
    clearImageSelections: (state, action) => {
      const { keyName, isMulti, mediaUrl } = action.payload;
      if (isMulti) {
        if (state.selectedImagesByKey[keyName]) {
          const imagesForKey = state.selectedImagesByKey[keyName].split(",");
          state.selectedImagesByKey[keyName] = imagesForKey
            .filter((url) => url !== mediaUrl)
            .join(",");
          if (state.selectedImagesByKey[keyName] === "") {
            state.selectedImagesByKey[keyName] = "";
          }
        }
      } else {
        if (state.singleImageSelections[keyName] === mediaUrl) {
          state.singleImageSelections[keyName] = "";
        }
      }
    },
  },
});

export const {
  toggleImageSelection,
  toggleVideoSelection,
  clearSelections,
  clearVideoSelections,
  clearImageSelections,
} = mediaSlice.actions;
export default mediaSlice.reducer;
