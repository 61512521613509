import React, { useState, useEffect } from "react";
import "../styles/toggle.css";
import { ToggleProps } from "types";

const Toggle: React.FC<ToggleProps> = ({
  initialChecked = 1,
  onChange,
  checked,
}) => {
  const [isChecked, setIsChecked] = useState(
    checked !== undefined ? checked : initialChecked === 1
  );

  useEffect(() => {
    if (checked !== undefined) {
      setIsChecked(checked);
    } else {
      setIsChecked(initialChecked === 1);
    }
  }, [checked, initialChecked]);

  const handleToggle = () => {
    const newCheckedState = isChecked ? 0 : 1;
    setIsChecked(newCheckedState === 1);
    if (onChange) {
      onChange(newCheckedState);
    }
  };

  return (
    <div className="toggle-wrapper">
      <input
        className="toggle-checkbox"
        type="checkbox"
        checked={isChecked}
        onChange={handleToggle}
        id="toggle"
      />
      <div className="toggle-container" onClick={handleToggle}>
        <div className="toggle-button">
          <div className="toggle-button-circles-container"></div>
          <div className="toggle-button-circles-container"></div>
          <div className="toggle-button-circles-container"></div>
          <div className="toggle-button-circles-container"></div>
          <div className="toggle-button-circles-container"></div>
          <div className="toggle-button-circles-container"></div>
        </div>
      </div>
      <label htmlFor="toggle" className="toggle-label"></label>
    </div>
  );
};

export default Toggle;
