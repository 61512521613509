import React, { useState } from "react";
import ProductEditForm from "../Edit/ProductEditForm";
import ProductEditMedia from "../Edit/ProductEditMedia";
import ProductEditVariant from "../Edit/ProductEditVariant";
import ProductEditClassification from "../Edit/ProductEditClassification";
import ProductEditCampaign from "../Edit/ProductEditCampaign";

const ProductEdit: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    "form" | "media" | "variants" | "campaign" | "classification"
  >("form");
  const [productId, setProductId] = useState(null);
  const openTab = (
    tabId: "form" | "media" | "variants" | "campaign" | "classification"
  ) => {
    setActiveTab(tabId);
  };
  const handleProductFormSuccess = (productId: number) => {
    if (productId) {
      setProductId(productId);
      setActiveTab("form");
    }
  };
  return (
    <>
      <div className="product_form w-100 pb-5">
        <div className="w-100 d-flex dm-sans">
          <div className="product_form_main_left">
            <div
              role="button"
              className={`${
                activeTab === "form" ? "InnerSide-activeTab" : ""
              } m-0 d-flex gap-1 align-items-center product_form_tab`}
              onClick={() => openTab("form")}
            >
              <i className="bi bi-card-checklist"></i>
              <h6 className="font-bold text-capitalize m-0 px-2">form</h6>
            </div>
            <div
              role="button"
              className={`${
                activeTab === "media" ? "InnerSide-activeTab" : ""
              } m-0 d-flex gap-1 align-items-center product_form_tab`}
              onClick={() => openTab("media")}
            >
              <i className="bi bi-image"></i>
              <h6 className="font-bold text-capitalize m-0 px-2">media</h6>
            </div>
            <div
              role="button"
              className={`${
                activeTab === "variants" ? "InnerSide-activeTab" : ""
              } m-0 d-flex gap-1 align-items-center product_form_tab`}
              onClick={() => openTab("variants")}
            >
              <i className="bi bi-layout-three-columns"></i>
              <h6 className="font-bold text-capitalize m-0 px-2">variants</h6>
            </div>
            <div
              role="button"
              className={`${
                activeTab === "classification" ? "InnerSide-activeTab" : ""
              } m-0 d-flex gap-1 align-items-center product_form_tab`}
              onClick={() => openTab("classification")}
            >
              <i className="bi bi-hdd-stack-fill"></i>
              <h6 className="font-bold text-capitalize m-0 px-2">
                classification
              </h6>
            </div>
            <div
              role="button"
              className={`${
                activeTab === "campaign" ? "InnerSide-activeTab" : ""
              } m-0 d-flex gap-1 align-items-center product_form_tab`}
              onClick={() => openTab("campaign")}
            >
              <i className="bi bi-megaphone-fill"></i>
              <h6 className="font-bold text-capitalize m-0 px-2">campaign</h6>
            </div>
          </div>
          <div className="product_form_main_right w-100">
            {activeTab === "form" && (
              <ProductEditForm productId={handleProductFormSuccess} />
            )}
            {activeTab === "media" && (
              <ProductEditMedia productId={productId} />
            )}
            {activeTab === "variants" && (
              <ProductEditVariant productId={productId} />
            )}
            {activeTab === "classification" && (
              <ProductEditClassification productId={productId} />
            )}
            {activeTab === "campaign" && (
              <ProductEditCampaign productId={productId} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductEdit;
