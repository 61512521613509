import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FixedSizeList as VirtualizedList } from "react-window";
import debounce from "lodash.debounce";
import {
  DeleteMappedProductClassification,
  getclassificationMappedProduct,
} from "services/apiService";
import toast from "react-hot-toast";

const ProductClassificationVeiw = ({ open, handleClose, id }) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 580,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    display: "flex",
    flexDirection: "column",
  };

  const [mappedProductList, setMappedProductList] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [classifictionName, setClassifictionName] = useState("");
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    if (id) fetchMappedProducts();
  }, [id]);

  const fetchMappedProducts = async () => {
    setLoading(true);
    try {
      const response = await getclassificationMappedProduct(id);
      if (response.status_code === 200) {
        const data = response.data;
        setMappedProductList(data);
        setFilteredProducts(data);
        setClassifictionName(data?.[0]?.classification_name || "");
      } else {
        setMappedProductList([]);
        setFilteredProducts([]);
        setClassifictionName("");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      const filtered = mappedProductList.filter((product) =>
        product.product_name.toLowerCase().includes(query)
      );
      setFilteredProducts(filtered);
    }, 300),
    [mappedProductList]
  );

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleDelete = async (productId: number) => {
    try {
      const response = await DeleteMappedProductClassification(productId);
      if (response.status_code === 200) {
        toast.success(response.message);
        fetchMappedProducts();
      }
    } catch (error) {
      console.log("error");
    }
  };

  const RenderRow = useMemo(
    () =>
      ({ index, style }) => {
        const product = filteredProducts[index];
        return (
          <ListItem
            style={{
              ...style,
              borderBottom: "1px solid #ddd",
            }}
            key={product.product_id}
            secondaryAction={
              <IconButton
                edge="end"
                color="error"
                onClick={() => handleDelete(product.id)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar
                src={`${
                  product.thumnail_image
                    ? AppUrl + product.thumnail_image
                    : "/placeholder.png"
                }`}
                alt={product.product_name}
              />
            </ListItemAvatar>
            <ListItemText
              primary={product.product_name}
              secondary={`Price: ₹${product.original_price} | Quantity: ${product.quantity}`}
            />
          </ListItem>
        );
      },
    [filteredProducts]
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            component="h2"
            style={{ textTransform: "uppercase", color: "#3975b1" }}
          >
            {`Mapped items of ${classifictionName}`}
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Close
          </Button>
        </Box>
        <TextField
          placeholder="Search by Product Name"
          fullWidth
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          sx={{ mt: 2 }}
        />
        <Box
          sx={{
            flex: 1,
            mt: 2,
            border: "1px solid #ddd",
            borderRadius: "8px",
            p: 2,
          }}
        >
          {loading ? (
            <Box>
              {Array.from({ length: 6 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={40}
                  sx={{ mb: 2, borderRadius: "4px" }}
                />
              ))}
            </Box>
          ) : filteredProducts?.length > 0 ? (
            <VirtualizedList
              height={380}
              itemCount={filteredProducts?.length}
              itemSize={80}
              width="100%"
              style={{
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
            >
              {RenderRow}
            </VirtualizedList>
          ) : (
            <Typography>No data available.</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductClassificationVeiw;
