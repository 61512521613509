import React from "react";
import { useDispatch } from "react-redux";
import { clearVideoSelections } from "../../../../redux/Slice/mediaSlice.js";

const VideoDisplay = ({ field, videoUrl, single, isEditing }) => {
  const dispatch = useDispatch();
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const media = videoUrl[field];
  const mediaArray = single
    ? [media]
    : media?.split(",").filter((item) => item !== "");

  return (
    <div className="media-container">
      {mediaArray?.map((item) => {
        return (
          <>
            {item && (
              <div className="media-item" key={item}>
                <video controls autoPlay width="300">
                  <source src={`${AppUrl}${item}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {isEditing && (
                  <button
                    onClick={() =>
                      single
                        ? dispatch(
                            clearVideoSelections({
                              keyName: field,
                              isMulti: false,
                              mediaUrl: item,
                            })
                          )
                        : dispatch(
                            clearVideoSelections({
                              keyName: field,
                              isMulti: true,
                              mediaUrl: item,
                            })
                          )
                    }
                    className="delete-button"
                  >
                    X
                  </button>
                )}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default VideoDisplay;
