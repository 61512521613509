import CommonTable from "common/CommonTable";
import React, { useEffect, useState } from "react";
import {
  allProducts,
  pageNationData,
  ProductDelete,
} from "services/apiService";
import "../../styles/index.css";
import {
  faCirclePlus,
  faEye,
  faPen,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import DynamicButton from "../../common/DynamicButton";
import { ProductData } from "types";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagenation from "common/Pagenation";
import loadingImage from "../../assect/loadingSvg/loading.svg";
import imageNotFound from "../../Images/imageNotFound.png";
import "../../styles/commonTable.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const ProductList: React.FC = () => {
  const [productListData, setProductListData] = useState<ProductData[]>([]);
  const [productSearch, setProductSearch] = useState<string>("");
  const [loader, setloader] = useState<boolean>(false);
  const [pagenationLength, setPagenationLength] = useState(null);
  const [currentPageNationPage, setCurrentPageNationPage] = useState(1);
  const [page_numbers] = useState(0);
  const numberofItems = 10;
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const headers = [
    "Sr.No",
    "Product Name",
    "Price",
    "Qunatity",
    "Image",
    "status",
    "Action",
  ];
  const navigate = useNavigate();

  const handleView = (product_id: number) => {
    navigate(`/admin/product-veiw`, { state: { product_id } });
  };

  useEffect(() => {
    getProductListData("", page_numbers, numberofItems);
    fetchProductCount();
  }, []);
  const getProductListData = async (
    search: string,
    page_numbers: number,
    numberofItems: number
  ) => {
    setloader(true);
    try {
      const response = await allProducts(search, page_numbers, numberofItems);
      setProductListData(response?.data);
    } catch (error) {
      console.log("error");
    }
    setloader(false);
  };
  const fetchProductCount = async () => {
    try {
      const response = await pageNationData();
      setPagenationLength(response.data?.products_count);
    } catch (error) {}
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setProductSearch(value);
    getProductListData(value || "", page_numbers, numberofItems);
  };

  const handleButtonClick = (product_id: number) => {
    navigate("/admin/product-edit", { state: { product_id } });
  };

  const handledeleteProduct = async (
    productId: number,
    productName: string
  ) => {
    try {
      const firstConfirmation = await Swal.fire({
        title: "Are you sure?",
        html: `You wanted to delete <strong style="color: orange; padding: 3px;">⚠️ ${productName}</strong>`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
      if (firstConfirmation.isConfirmed) {
        const secondConfirmation = await Swal.fire({
          title: "Are you really sure?",
          text: "This action cannot be undone!",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes, I am sure!",
          cancelButtonText: "Cancel",
        });

        if (secondConfirmation.isConfirmed) {
          const productIdpayload = {
            product_id: productId,
          };
          const response = await ProductDelete(productIdpayload);
          if (response.status_code === 200) {
            Swal.fire("Deleted!", "The product has been deleted.", "success");
            getProductListData("", page_numbers, numberofItems);
          } else {
            Swal.fire("Error!" || "Something went wrong!", "error");
          }
        } else {
          Swal.fire("Cancelled", "The product is safe!", "info");
        }
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred.", "error");
    }
  };

  const renderRow = (product: any, index: number) => {
    const serialNumber =
      (currentPageNationPage - 1) * numberofItems + index + 1;

    return (
      <tr className="mt-2 pt-4" key={index}>
        <td>{serialNumber}</td>
        <td className="align-content-center" title={product.product_name}>
          {product.product_name.length > 30
            ? product.product_name.slice(0, 30) + "..."
            : product.product_name}
        </td>
        <td className="align-content-center" style={{ color: "grey" }}>
          ₹{product?.original_price}
        </td>
        <td className="align-content-center" style={{ color: "grey" }}>
          {product?.quantity}
        </td>
        <td className="align-content-center">
          <div className="d-flex justify-content-center">
            <img
              src={`${
                product.thumnail_image
                  ? AppUrl + product.thumnail_image
                  : imageNotFound
              }`}
              alt="ProductImage"
              width={80}
            />
          </div>
        </td>

        <td className="align-content-center">
          <span
            style={{
              color: product.is_active === 1 ? "green" : "red",
              backgroundColor: product.is_active === 1 ? "#e0f7e9" : "#f8d7da",
              padding: "5px 10px",
              borderRadius: "5px",
              display: "inline-block",
            }}
          >
            {product?.is_active === 1 ? "Active" : "Inactive"}
          </span>
        </td>
        <td className="align-content-center ">
          <div className="actions-buttons">
            <DynamicButton
              icon={faEye}
              iconStyle={{ color: "#1b63de" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleView(product.product_id)}
            />

            <DynamicButton
              icon={faPen}
              iconStyle={{ color: "#63E6BE" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() => handleButtonClick(product.product_id)}
            />

            <DynamicButton
              icon={faTrashCan}
              iconStyle={{ color: "#f54242" }}
              style={{
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
                margin: "2px",
              }}
              onClick={() =>
                handledeleteProduct(product.product_id, product.product_name)
              }
            />
          </div>
        </td>
      </tr>
    );
  };
  const handlePageChange = (page: number) => {
    const dataIndex = (page - 1) * numberofItems;
    if (page) {
      getProductListData("", dataIndex, numberofItems);
    }
    setCurrentPageNationPage(page);
  };

  return (
    <>
      <div className="d-flex justify-content-between  ">
        <div className="col-lg-6">
          <Button
            component={Link}
            to="/admin/product-create"
            variant="contained"
            color="primary"
            sx={{ borderRadius: "0px" }}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              style={{ marginRight: "10px" }}
            />
            Create Product
          </Button>
        </div>
        <div className="col-lg-5 d-flex mb-3">
          <input
            type="text"
            placeholder="Search Products Name ..."
            className="form-control"
            style={{ boxShadow: "none" }}
            value={productSearch}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="product-table-container">
        <div className="product-table-content">
          {loader ? (
            <div className="loadingsvg-Image text-center">
              <img
                src={loadingImage}
                width={150}
                height={150}
                alt="Loading..."
              />
            </div>
          ) : (
            <CommonTable
              data={productListData}
              isLoading={loader}
              headers={headers}
              renderRow={renderRow}
            />
          )}
        </div>
        <div className="pagination-container">
          <div className="data-info" style={{ color: "grey" }}>
            {pagenationLength > 0 && (
              <span>
                Showing{" "}
                <strong>
                  {Math.min(
                    (currentPageNationPage - 1) * numberofItems + 1,
                    pagenationLength
                  )}
                </strong>{" "}
                -{" "}
                <strong>
                  {Math.min(
                    currentPageNationPage * numberofItems,
                    pagenationLength
                  )}
                </strong>{" "}
                out of <strong>{pagenationLength}</strong> records
              </span>
            )}
          </div>
          <Pagenation
            pagenationLength={pagenationLength}
            numberofItems={numberofItems}
            currentPageNationPage={currentPageNationPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default ProductList;
