import React, { useEffect, useState } from "react";
import { OrderVeiwApi } from "services/apiService";
import { OrderVeiwEditProps } from "types";
import { useLocation } from "react-router";

const OrderVeiw: React.FC<OrderVeiwEditProps> = () => {
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    if (id) fetchOrderDetails(id);
  }, [id]);

  const fetchOrderDetails = async (id: number) => {
    try {
      const response = await OrderVeiwApi(id);
      if (response.status_code === 200) {
        setOrderDetails(response.data);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #ddd",
          paddingBottom: "10px",
          marginBottom: "20px",
        }}
      >
        <div>
          <p>
            <b>Order Date:</b> {orderDetails?.order_date}
          </p>
          <p>
            <b>Order Code:</b> {orderDetails?.order_code}
          </p>
        </div>
        <div style={{ textAlign: "right" }}>
          <p>
            <b>Customer Name:</b> {orderDetails?.customer_name}
          </p>
          <p>
            <b>Customer Email:</b> {orderDetails?.customer_email}
          </p>
        </div>
      </div>
      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div style={{ flex: "1" }}>
          <h4 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
            Billing Address
          </h4>
          {orderDetails?.billing_address ? (
            <p>
              {orderDetails.billing_address.billing_name}, <br />
              {orderDetails.billing_address.billing_email}, <br />
              {orderDetails.billing_address.billing_phone_no}, <br />
              {orderDetails.billing_address.billing_address_line_one},
              {orderDetails.billing_address.billing_address_line_two}, <br />
              {orderDetails.billing_address.billing_city},
              {orderDetails.billing_address.billing_state}
              {orderDetails.billing_address.billing_country}, <br />
              {orderDetails.billing_address.billing_pincode}
            </p>
          ) : (
            <p>No Billing Address Available</p>
          )}
        </div>
        <div style={{ flex: "1" }}>
          <h4 style={{ borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
            Shipping Address
          </h4>
          {orderDetails?.shipping_address ? (
            <p>
              {orderDetails.shipping_address.shipping_name}, <br />
              {orderDetails.shipping_address.shipping_email}, <br />
              {orderDetails.shipping_address.shipping_phone_no}, <br />
              {orderDetails.shipping_address.shipping_address_line_one}, <br />
              {orderDetails.shipping_address.shipping_address_line_two}
              {orderDetails.shipping_address.shipping_city},
              {orderDetails.shipping_address.shipping_state},
              {orderDetails.shipping_address.shipping_country},<br />
              {orderDetails.shipping_address.shipping_pincode}
            </p>
          ) : (
            <p>No Shipping Address Available</p>
          )}
        </div>
      </div>
      <hr />
      <div style={{ marginTop: "20px" }}>
        <h4 style={{ marginBottom: "10px", color: "#333" }}>Purchase List</h4>
        <div style={{ overflowX: "auto" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginBottom: "20px",
              fontSize: "14px",
            }}
          >
            <thead>
              <tr style={{ background: "#f4f4f4", textAlign: "left" }}>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>#</th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Product Name
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Variant Name
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Variant Value
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Price
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Discount Price
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Quantity
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Tax Name
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Tax Percentage
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Tax Amount
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Sales Amount
                </th>
                <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                  Sub Total
                </th>
              </tr>
            </thead>
            <tbody>
              {orderDetails?.purchase_list?.map((item, index) => (
                <tr key={index}>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {index + 1}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.product_name}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.variant_name}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.variant_value}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.price}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.discount_price}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.quantity}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.tax_name}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.tax_percentage}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.tax_amount}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.sales_amount}
                  </td>
                  <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                    {item.sub_total_amount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderVeiw;
