import React, { useEffect, useState } from "react";
import {
  getCompaginDropDownValues,
  productCampaignUpdate,
  AllCampaign,
} from "../../../services/apiService";
import { Button } from "@mui/material";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import noClassificationImage from "../../../assect/No_data.png";
import { useNavigate } from "react-router";

interface ProductCampaignEditFormProps {
  productId: number;
}

const ProductEditCampaign: React.FC<ProductCampaignEditFormProps> = ({
  productId,
}) => {
  const [campaignLists, setCampaignLists] = useState([]);
  const [campaignDropdownList, setCampaignDropdownList] = useState([]);
  const [editingCampaignId, setEditingCampaignId] = useState(null);
  const [newCampaignName, setNewCampaignName] = useState("");
  const [previousCampaignName, setPreviousCampaignName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (productId) {
      fetchData(productId);
      campaignDropDownList();
    }
  }, [productId]);
  const fetchData = async (productId: number) => {
    try {
      const responseData = await AllCampaign(productId);
      if (responseData.status_code === 200) {
        setCampaignLists(responseData?.data);
      }
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const campaignDropDownList = async () => {
    try {
      const response = await getCompaginDropDownValues();
      setCampaignDropdownList(response.data);
    } catch (error) {
      console.error("Error fetching campaign list:", error);
    }
  };
  const scrollContainerStyle: React.CSSProperties = {
    maxHeight: "400px",
    overflowY: "auto",
  };

  const handleEditClick = (campaignId, campaignName) => {
    setEditingCampaignId(campaignId);
    setNewCampaignName(campaignId.toString());
    setPreviousCampaignName(campaignName);
  };
  const handleDropdownChange = (e) => {
    setNewCampaignName(e.target.value);
  };
  const handleSaveClick = async (campaign_map_id) => {
    try {
      const payload = {
        campaign_id: parseInt(newCampaignName),
        campaign_map_id: campaign_map_id,
        product_id: productId,
      };
      const response = await productCampaignUpdate(payload);
      if (response.status_code === 200) {
        toast.success(response.message);
        const updatedCampaigns = campaignLists.map((campaign) =>
          campaign.campaign_id === editingCampaignId
            ? { ...campaign, campaign_name: newCampaignName }
            : campaign
        );
        setCampaignLists(updatedCampaigns);
        setEditingCampaignId(null);
        setNewCampaignName("");
        fetchData(productId);
      } else if (response.status_code === 400) {
        toast.error(response.message);
      } else {
        console.error("Unexpected response:", response);
        toast.error("An unexpected error occurred");
      }
    } catch (error) {
      toast.error("Error updating campaign name");
      console.error("Error updating campaign name:", error);
    }
  };
  const handleCancelClick = () => {
    setNewCampaignName(previousCampaignName);
    setEditingCampaignId(null);
  };
  return (
    <div className="container-fluid">
      <div className="card p-3" style={{ border: "none" }}>
        <div className="table-responsive" style={scrollContainerStyle}>
          {campaignLists ? (
            <table className="table table-striped">
              <thead className="table-light">
                <tr>
                  <th className="text-center">Campaign</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {campaignLists?.map((campaign) => (
                  <tr key={campaign.campaign_id}>
                    <td>
                      {editingCampaignId === campaign.campaign_id ? (
                        <select
                          className="form-select"
                          value={newCampaignName}
                          onChange={handleDropdownChange}
                          style={{ width: "100%" }}
                        >
                          <option value="">Select campaign</option>
                          {campaignDropdownList.map((campaignItem) => (
                            <option
                              key={campaignItem.campaign_id}
                              value={campaignItem.campaign_id}
                            >
                              {campaignItem.campaign_name.toUpperCase()}
                            </option>
                          ))}
                        </select>
                      ) : (
                        campaign.campaign_name.toUpperCase()
                      )}
                    </td>
                    <td className="text-center">
                      {editingCampaignId === campaign.campaign_id ? (
                        <>
                          <Button
                            onClick={() =>
                              handleSaveClick(campaign.campaign_map_id)
                            }
                            variant="contained"
                            color="success"
                            className="me-2"
                          >
                            Save
                          </Button>
                          <Button
                            onClick={handleCancelClick}
                            variant="outlined"
                            color="error"
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() =>
                            handleEditClick(
                              campaign.campaign_id,
                              campaign.campaign_name
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                          &nbsp;&nbsp;Edit
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>
              {" "}
              <div className="d-flex flex-column align-items-center text-center">
                <img
                  src={noClassificationImage}
                  alt="No Classification Found"
                  style={{ maxWidth: "200px", marginBottom: "16px" }}
                />
                <p
                  style={{
                    fontSize: "16px",
                    color: "#555",
                    marginBottom: "16px",
                  }}
                >
                  <strong>Oops!</strong> This product currently has no Campaign
                  mapped. If you'd like to add a Campaign, click the button
                  below.
                </p>
                <Button
                  className="btn btn-primary mt-3"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/admin/product-campaign");
                  }}
                  style={{
                    borderRadius: "4px",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#0056b3")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#007bff")
                  }
                >
                  Add Campaign
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductEditCampaign;
