import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  faCircleExclamation,
  faCirclePlus,
  faFileArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import {
  createBlogAPICall,
  getAllBlogCategoriesList,
  getAllBlogTagsAPICall,
} from "services/apiService";

const BlogCreate: React.FC = () => {
  const [blogForm, setBlogForm] = useState({
    title: "",
    blog_category_id: "",
    blog_tag_id: "",
    description: "",
    image_path: "",
    message: "",
    is_active: 0,
  });
  const navigate = useNavigate();
  const [blogCategories, setblogCategories] = useState([]);
  const [blogTagsCategories, setblogTagsCategories] = useState([]);

  const [errors, setErrors] = useState({
    title: "",
    blog_category_id: "",
    blog_tag_id: "",
    description: "",
    message: "",
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const AppUrl = process.env.REACT_APP_IMAGE_URL;

  useEffect(() => {
    getAllBlogCategories();
    getAllBlogTags();
  }, []);
  const getAllBlogCategories = async () => {
    try {
      const response = await getAllBlogCategoriesList();
      if (response.status_code === 200) {
        setblogCategories(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getAllBlogTags = async () => {
    try {
      const response = await getAllBlogTagsAPICall();
      if (response.status_code === 200) {
        console.log("getAllBlogCategoriesAPICall", response.data);
        setblogTagsCategories(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!blogForm.title) newErrors.title = " title is required.";
    if (!blogForm.blog_category_id)
      newErrors.blog_category_id = "blog category code is required.";
    if (!blogForm.blog_tag_id) newErrors.blog_tag_id = "blog tag is required.";
    if (!blogForm.description)
      newErrors.description = "description date is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleTextFieldChange = (e) => {
    const { id, value } = e.target;
    setBlogForm((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleToggleChange = (checked: number, field: string) => {
    setBlogForm((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const handleImageSelected = (image: string) => {
    setBlogForm((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const payload = {
          ...blogForm,
          image_path: blogForm.image_path.toString(),
          blog_category_id: Number(blogForm.blog_category_id),
          blog_tag_id: Number(blogForm.blog_tag_id),
        };

        const response = await createBlogAPICall(payload);

        if (response.status_code === 200) {
          toast.success("Blog created successfully !");
          navigate("/admin/blog-list");
        } else {
          toast.error("Blog created Failed");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Blog Title
            </Typography>
            <TextField
              variant="filled"
              name="title"
              id="title"
              value={blogForm.title}
              onChange={handleTextFieldChange}
              placeholder="Type Here"
              fullWidth
              error={!!errors.title}
              className="common_input"
              helperText="Please enter blog title."
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Blog Category
            </Typography>
            <div className="container">
              <select
                id="blog_category_id"
                className="form-select"
                name="blog_category_id"
                value={blogForm.blog_category_id}
                onChange={handleTextFieldChange}
              >
                {!blogCategories ? (
                  <option value="">No Data...</option>
                ) : blogCategories.length === 0 ? (
                  <option value="">No blog category found</option>
                ) : (
                  <>
                    <option value="">Select blog category</option>
                    {blogCategories.map((blogCategory) => (
                      <option key={blogCategory.id} value={blogCategory.id}>
                        {blogCategory.name.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {errors && blogForm.blog_category_id == "" ? (
                <div className="text-danger">Please select blog category</div>
              ) : null}
            </div>
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Blog Tag
            </Typography>
            <div className="container">
              <select
                id="blog_tag_id"
                className="form-select"
                value={blogForm.blog_tag_id}
                onChange={handleTextFieldChange}
                name="blog_tag_id"
              >
                {!blogTagsCategories ? (
                  <option value="">No Data...</option>
                ) : blogTagsCategories.length === 0 ? (
                  <option value="">No blog tag found</option>
                ) : (
                  <>
                    <option value="">Select blog tag</option>
                    {blogTagsCategories.map((blogCategory) => (
                      <option key={blogCategory.id} value={blogCategory.id}>
                        {blogCategory.name.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}
              </select>
              {errors && blogForm.blog_tag_id == "" ? (
                <div className="text-danger">Please select blog tag</div>
              ) : null}
            </div>
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Description
            </Typography>
            <TextField
              variant="filled"
              name="description"
              id="description"
              value={blogForm.description}
              onChange={handleTextFieldChange}
              placeholder="Type description here"
              multiline
              rows={4}
              fullWidth
              className="common_input"
              error={!!errors.description}
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Message
            </Typography>
            <CKEditor
              editor={ClassicEditor}
              data={blogForm.message || ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                setBlogForm((prevData) => ({
                  ...prevData,
                  message: data,
                }));
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  description: "",
                }));
              }}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "blockQuote",
                  "|",
                  "numberedList",
                  "bulletedList",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
            />
          </Box>
          <Box className="create_category_fields" mb={2}>
            <Typography variant="subtitle1" className="text-capitalize label">
              Image Path
            </Typography>
            <div
              style={{
                textAlign: "center",
                border: "1px dashed #ccc",
                padding: "20px",
                backgroundColor: "#f9f9f9",
              }}
            >
              {blogForm.image_path ? (
                <img
                  src={`${AppUrl}${blogForm.image_path}`}
                  alt="Selected"
                  style={{ width: "200px", height: "150px" }}
                />
              ) : (
                <p style={{ color: "orange" }}>
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  &nbsp;No image selected. Please upload an image.
                </p>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleOpenModal}
                  style={{
                    fontSize: "10px",
                    borderRadius: "5px",
                    border: "none",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    cursor: "pointer",
                    marginTop: "10px",
                    transition: "background-color 0.2s",
                  }}
                >
                  <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp;&nbsp;Upload
                  Image
                </Button>
              </div>
            </div>
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography
              variant="body1"
              className="text-capitalize label"
              sx={{ width: "20%" }}
            >
              Status
            </Typography>
            <Toggle
              checked={blogForm.is_active === 1}
              onChange={(checked) => handleToggleChange(checked, "is_active")}
            />
          </Box>
        </Box>
        <Box textAlign="center" mt={5}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            <FontAwesomeIcon icon={faCirclePlus} /> &nbsp;&nbsp; Create Blog
          </Button>
        </Box>
      </form>

      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default BlogCreate;
