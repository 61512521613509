import React, { useState } from "react";
import ProductKeyImage from "../../Images/ProductKeyImage.png";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ProductKeyPageProps } from "../../types";
import { verifyProductKey } from "../../services/apiService";

const ProductkeyPage: React.FC<ProductKeyPageProps> = ({
  setProductKeyState,
}) => {
  const [productKey, setProductKey] = useState<string>("");
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const validateProductKey = (): boolean => {
    if (!productKey) {
      setError("Product key is required");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async () => {
    localStorage.removeItem("productKeyState");
    if (!validateProductKey()) {
      return;
    }

    try {
      const response: any = await verifyProductKey(productKey);
      if (response?.status_code === 200) {
        setProductKeyState(false);
        setProductKey("");
        toast.success("Product key verified successfully!");
        navigate("/admin");
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
  };

  return (
    <div
      className="container d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="text-center mb-4">
        <h1 className="position-relative mb-7" style={{ marginBottom: "98px" }}>
          Enter your Product Key
        </h1>
        <img
          src={ProductKeyImage}
          width={600}
          height={300}
          alt="Product Key"
          className="mb-4"
        />
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <input
          type="text"
          required
          placeholder="Enter the Product key"
          className="form-control me-2" // Add right margin to input
          value={productKey}
          onChange={(e) => setProductKey(e.target.value)}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
      {error && <p className="text-danger">{error}</p>}{" "}
      {/* Display error message */}
    </div>
  );
};

export default ProductkeyPage;
