import {
  faCircleExclamation,
  faFileArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography, Modal } from "@mui/material";
import ImageUploaderModal from "common/ImageUploaderModal";
import Toggle from "common/Toggle";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { couponEdit, updatedCoupon } from "services/apiService";

import { CoupnEditProps } from "types";

const CouponEdit: React.FC<CoupnEditProps> = ({
  open,
  id,
  handleClose,
  onActionComplete,
}) => {
  const AppUrl = process.env.REACT_APP_IMAGE_URL;
  const today = new Date().toISOString().split("T")[0];
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    p: 4,
  };
  const [CouponEdit, setCouponEdit] = useState({
    name: "",
    coupon_code: "",
    description: "",
    expire_date: "",
    percentage: "",
    max_time_use: "",
    min_purchase_amount: "",
    is_active: 1,
    slug: "",
    image_path: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    coupon_code: "",
    description: "",
    expire_date: "",
    percentage: "",
    max_time_use: "",
    min_purchase_amount: "",
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    const generateSlug = (text: string) => {
      return text.toLowerCase().replace(/\s+/g, "-");
    };

    const filterToNumbersAndDecimal = (text: string) => {
      return text.replace(/[^0-9.]/g, "");
    };

    setCouponEdit((prevData) => ({
      ...prevData,
      [id]:
        id === "coupon_code"
          ? value.toUpperCase()
          : id === "percentage" || id === "max_time_use"
          ? filterToNumbersAndDecimal(value)
          : value,
      slug: id === "name" ? generateSlug(value) : prevData.slug,
    }));
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!CouponEdit.name) newErrors.name = "Coupon name is required.";
    if (!CouponEdit.coupon_code)
      newErrors.coupon_code = "Coupon code is required.";
    if (!CouponEdit.expire_date)
      newErrors.expire_date = "Expiry date is required.";
    if (!CouponEdit.percentage || isNaN(Number(CouponEdit.percentage))) {
      newErrors.percentage = "Please enter a valid percentage.";
    }
    if (!CouponEdit.max_time_use || isNaN(Number(CouponEdit.max_time_use))) {
      newErrors.max_time_use = "Please enter a valid maximum time use.";
    }
    if (
      !CouponEdit.min_purchase_amount ||
      isNaN(Number(CouponEdit.min_purchase_amount))
    ) {
      newErrors.min_purchase_amount =
        "Please enter a valid minimum purchase amount.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImageSelected = (image: string) => {
    setCouponEdit((prevState) => ({
      ...prevState,
      image_path: image,
    }));
    setModalOpen(false);
  };

  const handleToggleChange = (checked: number, field: string) => {
    setCouponEdit((prevData) => ({
      ...prevData,
      [field]: checked ? 1 : 0,
    }));
  };

  const fetchCouponEdit = async (id: number) => {
    const response = await couponEdit(id);
    if (response.status_code === 200) {
      const formattedExpireDate = response.data.expire_date.split("T")[0];
      setCouponEdit({
        name: response.data.name,
        coupon_code: response.data.coupon_code,
        description: response.data.description,
        expire_date: formattedExpireDate,
        percentage: response.data.percentage,
        max_time_use: response.data.max_time_use,
        min_purchase_amount: response.data.min_purchase_amount,
        is_active: response.data.is_active,
        slug: response.data.slug,
        image_path: response.data.image_path,
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchCouponEdit(id);
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const payload = {
          ...CouponEdit,
          image_path: CouponEdit.image_path.toString() || "",
          percentage: Number(CouponEdit.percentage),
          max_time_use: Number(CouponEdit.max_time_use),
          min_purchase_amount: Number(CouponEdit.min_purchase_amount),
          coupon_id: id,
        };
        const response = await updatedCoupon(payload);
        if (response.status_code === 200) {
          toast.success("Coupon Updated successfully");
          handleClose();
          onActionComplete();
        } else {
          toast.error("Coupon Updation Failed");
        }
      } catch (error) {
        toast.error("Coupon Updation Failed");
        console.log("error");
      }
    }
  };

  const handleDeleteImage = () => {
    setCouponEdit((prevState) => ({
      ...prevState,
      image_path: "",
    }));
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <Modal style={{ zIndex: 1299 }} open={open} onClose={undefined}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Edit Coupon
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <div
                className="modal-body"
                style={{ display: "grid", gap: "20px" }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <TextField
                    id="name"
                    name="name"
                    label="Coupon Name"
                    variant="outlined"
                    fullWidth
                    value={CouponEdit.name}
                    onChange={handleTextFieldChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                  <TextField
                    variant="outlined"
                    id="coupon_code"
                    fullWidth
                    label="Coupon Code"
                    value={CouponEdit.coupon_code}
                    onChange={handleTextFieldChange}
                    error={!!errors.coupon_code}
                    helperText={
                      errors.coupon_code || "Please enter the Coupon Code."
                    }
                  />
                </div>
                <div className="form-group">
                  <TextField
                    variant="outlined"
                    id="description"
                    fullWidth
                    multiline
                    label="Description"
                    rows={3}
                    value={CouponEdit.description}
                    onChange={handleTextFieldChange}
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "10px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    id="percentage"
                    label="Percentage"
                    fullWidth
                    type="number"
                    value={CouponEdit.percentage}
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => {
                      const value = e.target.value;
                      if (value.length <= 4 && Number(value) <= 100) {
                        handleTextFieldChange(
                          e as React.ChangeEvent<HTMLInputElement>
                        );
                      }
                    }}
                    error={!!errors.percentage}
                    helperText={
                      errors.percentage ||
                      "Please enter Coupon percentage (up to 3 digits)."
                    }
                  />

                  <TextField
                    variant="outlined"
                    label="Max Time Use"
                    id="max_time_use"
                    fullWidth
                    type="number"
                    value={CouponEdit.max_time_use}
                    onChange={handleTextFieldChange}
                    error={!!errors.max_time_use}
                    helperText={
                      errors.max_time_use || "Please enter the max time usage."
                    }
                  />
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      id="expire_date"
                      type="date"
                      className={`form-control ${
                        errors.expire_date ? "is-invalid" : ""
                      }`}
                      style={{ width: "100%", padding: "8px" }}
                      min={today}
                      value={
                        CouponEdit.expire_date
                          ? CouponEdit.expire_date.slice(0, 10)
                          : ""
                      }
                      onChange={handleTextFieldChange}
                    />
                    {errors.expire_date && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        {errors.expire_date ||
                          "Please enter Coupon expiry date."}
                      </p>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    gap: "10px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Minimum Purchase Amount"
                    id="min_purchase_amount"
                    fullWidth
                    type="number"
                    value={CouponEdit.min_purchase_amount}
                    onChange={handleTextFieldChange}
                    error={!!errors.min_purchase_amount}
                    helperText={errors.min_purchase_amount}
                  />
                  <Toggle
                    checked={CouponEdit.is_active === 1}
                    onChange={(checked) =>
                      handleToggleChange(checked, "is_active")
                    }
                  />
                </div>
                <div className="form-group">
                  <div
                    style={{
                      width: "100%",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "20px",
                        border: "1px dashed #ccc",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "10px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {CouponEdit.image_path ? (
                        <>
                          <img
                            src={`${AppUrl}${CouponEdit.image_path}`}
                            alt="Selected"
                            style={{
                              width: "150px",
                              transition: "transform 0.2s",
                              cursor: "pointer",
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.transform = "scale(1.05)";
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.transform = "scale(1)";
                            }}
                          />
                          <button
                            style={{
                              backgroundColor: "rgba(255, 0, 0, 0.7)",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              fontSize: "16px",
                              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
                              transition: "background-color 0.2s",
                            }}
                            onClick={handleDeleteImage}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </>
                      ) : (
                        <p style={{ color: "orange" }}>
                          <FontAwesomeIcon icon={faCircleExclamation} />
                          &nbsp; &nbsp; No image selected. Please upload an
                          image.
                        </p>
                      )}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={handleOpenModal}
                        style={{
                          padding: "5px 20px",
                          fontSize: "13px",
                          borderRadius: "0px",
                          border: "none",
                          backgroundColor: "#007bff",
                          color: "#fff",
                          cursor: "pointer",
                          marginTop: "10px",
                          transition: "background-color 0.2s",
                        }}
                      >
                        <FontAwesomeIcon icon={faFileArrowUp} /> &nbsp; &nbsp;
                        Thumbnail Image
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </form>
          </Typography>
        </Box>
      </Modal>
      <ImageUploaderModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onImageSelect={handleImageSelected}
        allowMultiple={false}
      />
    </>
  );
};

export default CouponEdit;
