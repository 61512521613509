import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, TextField, Typography } from "@mui/material";
import Toggle from "common/Toggle";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { CreateSettingsGroupApi } from "services/apiService";

const CreateSettingsGroup = () => {
  const [formdata, setFormdata] = useState({
    name: "",
    is_active: 1,
  });
  const navigate = useNavigate();

  const [errors, setErrors] = useState<{ name?: string }>({});

  const validateForm = () => {
    const newErrors: { name?: string } = {};

    if (!formdata.name) {
      newErrors.name = "Settings Name is required.";
    } else if (!/^[a-zA-Z0-9-]+$/.test(formdata.name)) {
      newErrors.name = "Only letters, numbers, and '-' are allowed.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "name") {
      let sanitizedValue = value.replace(/[^a-zA-Z0-9-]/g, "").slice(0, 25);
      sanitizedValue =
        sanitizedValue.charAt(0).toUpperCase() + sanitizedValue.slice(1);

      setFormdata((prev) => ({ ...prev, [name]: sanitizedValue }));
    } else {
      setFormdata((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const payload = { name: formdata.name, is_active: formdata.is_active };
      const response = await CreateSettingsGroupApi(payload);
      if (response.status_code === 200) {
        toast.success("Settings group created successfully!");
        setFormdata({ name: "", is_active: 1 });
        navigate("/admin/setting-list");
      } else {
        toast.error("Creation failed. Please try again.");
      }
    } catch (error) {
      console.error("Error creating settings group:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box sx={{ padding: 4 }}>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Settings Group Name
              </Typography>
              <TextField
                variant="filled"
                name="name"
                id="name"
                value={formdata.name}
                onChange={handleInputChange}
                placeholder="Enter name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name || "Only letters, and '-' allowed."}
              />
            </Box>
            <Box className="create_category_fields" mb={2}>
              <Typography variant="subtitle1" className="text-capitalize label">
                Status
              </Typography>
              <Toggle
                checked={formdata.is_active === 1}
                onChange={(checked) =>
                  setFormdata((prevData) => ({
                    ...prevData,
                    is_active: checked ? 1 : 0,
                  }))
                }
              />
            </Box>
            <Box textAlign="end" mt={5}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp; Create Settings Group
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CreateSettingsGroup;
