import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_main shadow bg-white">
        <span className="poppins text-center">
          2024 React Admin. All rights reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;
