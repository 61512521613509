import React, { useEffect, useState } from "react";
import "../styles/nav.css";
import { adminLogout } from "../services/apiService";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import axios from "axios";
import Profile_Image from "../assect/blue-circle-with-white-user_78370-4707.avif";

const Nav = () => {
  const apiBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL;
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userData, setuserData] = useState({});

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const logout = async () => {
    try {
      const response = await adminLogout();
      if (response.status_code === 200) {
        navigate("/admin", { replace: true });
        localStorage.removeItem("admin_token");
        localStorage.removeItem("isAdminAuthenticated");
        localStorage.removeItem("productKeyState");
        localStorage.removeItem("pageid");
        toast.success("Logged out successfully");
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      if (!token) {
        throw new Error("Token not found");
      }
      const response = await axios.get(`${apiBaseUrl}/current-admin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setuserData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.response?.status === 400) {
        localStorage.removeItem("admin_token");
        localStorage.setItem("productKeyState", "true");
        toast.success(
          "You have been logged out due to a server restart. Please log in again."
        );
        navigate("/admin");
      } else {
        toast.error("An unexpected error occurred. Re-start the application ");
        localStorage.removeItem("admin_token");
        localStorage.setItem("productKeyState", "true");
      }
    }
  };

  return (
    <div className="navbar my-0 py-0 dm-sans">
      <div className="navbar_main bg-white shadow-sm d-flex align-items-center gap-3 position-relative p-1">
        <div
          className="dropdown position-relative"
          onMouseEnter={toggleDropdown}
          onMouseLeave={toggleDropdown}
        >
          <img
            src={Profile_Image}
            alt="Profile"
            className="rounded-circle"
            style={{
              width: "40px",
              height: "40px",
              cursor: "pointer",
            }}
          />

          {isDropdownOpen && (
            <div
              className="dropdown-menu show shadow rounded-3 p-2"
              style={{
                position: "absolute",
                top: "100%",
                right: "0",
                minWidth: "200px",
                zIndex: 10,
                background: "white",
                border: "1px solid #ddd",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <div
                className="text-center  mb-2"
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={Profile_Image}
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "70px",
                    height: "70px",
                  }}
                />
                <h5
                  className="mb-1"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#333",
                  }}
                >
                  {userData.full_name}
                </h5>
                <p
                  className="text-muted small mb-0"
                  style={{
                    fontSize: "14px",
                    color: "#777",
                  }}
                >
                  {userData.email}
                </p>
              </div>

              <hr
                style={{
                  margin: "15px 0",
                  borderTop: "1px solid #eaeaea",
                }}
              />

              <ul className="list-unstyled mb-0">
                <li
                  className="py-2 px-3 rounded"
                  style={{
                    cursor: "pointer",
                    transition: "background 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.background = "#f8f9fa")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.background = "transparent")
                  }
                >
                  <i
                    className="bi bi-gear-fill me-2"
                    style={{ color: "#007bff" }}
                  ></i>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333",
                    }}
                  >
                    Settings
                  </span>
                </li>
                <li
                  className="py-2 px-3 rounded"
                  style={{
                    cursor: "pointer",
                    transition: "background 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.background = "#f8f9fa")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.background = "transparent")
                  }
                >
                  <i
                    className="bi bi-box-arrow-right me-2"
                    style={{ color: "#dc3545" }}
                  ></i>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#333",
                    }}
                    onClick={() => logout()}
                  >
                    Log Out
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Nav;

{
  /* <ul className="dropdown-menu py-2 px-0 w-auto mt-2 pointer ">
            <li>
              <a
                className="dropdown-item d-flex gap-2 align-items-center"
                onClick={logout}
              >
                <i className="bi bi-box-arrow-in-right"></i>
                <span>Sign Out</span>
              </a>
            </li>
          </ul> */
}
